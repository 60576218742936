import styled from "styled-components";
import { bp } from "@styles/theme";

export const Container = styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 16px;
    margin: 0 auto;

    ${bp.large} {
        padding: 0 50px;
    }
`;

export const MediumContainer = styled.div`
    width: 100%;
    max-width: 1210px;
    padding: 0 16px;
    margin: 0 auto;

    ${bp.large} {
        padding: 0 50px;
    }
`;

export const NarrowContainer = styled.div`
    width: 100%;
    max-width: 980px;
    padding: 0 16px;
    margin: 0 auto;

    ${bp.large} {
        padding: 0 50px;
    }
`;
