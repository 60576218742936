import React, { useEffect, useState } from "react";

import { useMutation } from "graphql-hooks";
import { useSelector } from "react-redux";

import { Mutations } from "@api/graphql/queries/Mutations";
import { acceptNotification, acceptNotificationVariables, LandingNotification } from "@api/graphql/types";
import { Notification } from "@components/Notification";
import { ApplicationState } from "@redux/reducers";
import { Cookie } from "@utils/Cookie";
import { useLoctool } from "@bigfish/react-loctool";

interface Props {
    notifications: LandingNotification[];
}

export const NotificationLayer: React.FC<Props> = ({ notifications }) => {
    const Intl = useLoctool();
    const [modalOpen, setModalOpen] = useState(notifications.length > 0);
    const { user } = useSelector((state: ApplicationState) => state.auth);
    const [acceptNotification] = useMutation<acceptNotification, acceptNotificationVariables>(Mutations.acceptNotification);

    const handleClose = async (id: string) => {
        user
            ? (await acceptNotification({ variables: { id } }), Cookie.dismissNotificationWithId(id, notifications))
            : Cookie.dismissNotificationWithId(id, notifications);
        setModalOpen(false);
    };

    return (
        <>
            {notifications.map(notification => (
                <Notification
                    key={notification.id}
                    title={notification.name}
                    heading={notification.title}
                    description={notification.content ?? ""}
                    isMounted={modalOpen}
                    buttonText={
                        notification.is_accept_required
                            ? `${Intl.formatMessage({ id: "components.notification.consentButton" })}`
                            : `${Intl.formatMessage({ id: "components.notification.closeButton" })}`
                    }
                    underlayClickable={!notification.is_accept_required}
                    onClose={() => handleClose(notification.id)}
                />
            ))}
        </>
    );
};
