import { createGlobalStyle } from "styled-components";
import { showForSr } from "./helpers";
import { bp, breakpoints } from "./theme";

const GlobalStyles = createGlobalStyle`
    html {
        box-sizing: border-box;
        height: 100%;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    // Task: Ask for focus styles.
    /*
    :focus {
        outline: none;
    }
    */

    body {
        min-height: 100%;
        margin: 0;
        background-color: ${props => props.theme.color.white};
        color: ${props => props.theme.color.grayD};
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;

        ${bp.large} {
            padding-top: 0;
        }
    }

    body,
    button,
    input,
    select,
    option {
        font-family: 'Poppins', sans-serif;
    }
    
    #__next {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    button {
        background-color: transparent;
        border: none;
        color: inherit;
        cursor: pointer;
        display: block;
        font-family: inherit;
        font-size: 100%;
        margin: 0;
        padding: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    blockquote {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        quotes: "" "";

        &::before,
        &::after { 
            content: ""
        }
    }

    article,
    aside,
    header,
    footer,
    main,
    nav {
        display: block;
    }

    address {
        font-style: normal;
    }

    figure {
        margin: 0;
    }

    svg {
        display: block;
    }

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }

    ul, ol{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    iframe {
        border: none;
    }

    video {
        width: 100% !important;
        height: auto !important;
    }

    /* Utility Classes */
    .show-for-sr {
        ${showForSr}
    }

    .fw-bold {
        font-weight: bold;
    }

    /* Visibility Classes */
    .show-for-medium {
        ${bp.small} {
            display: none !important;
        }
    }

    .show-for-medium-only {
        ${`@media (max-width: ${breakpoints.medium - 1}px), (min-width: ${breakpoints.large}px)`} {
            display: none !important;
        }
    }

    .hide-for-medium {
        ${bp.medium} {
            display: none !important;
        }
    }

    .show-for-large {
        ${bp.custom(`max-width: ${breakpoints.large - 1}px`)} {
            display: none !important;
        }
    }

    .hide-for-large {
        ${bp.large} {
            display: none !important;
        }
    }

    .show-for-xlarge {
        ${bp.custom(`max-width: ${breakpoints.xlarge - 1}px`)} {
            display: none !important;
        }
    }

    .hide-for-xlarge {
        ${bp.xlarge} {
            display: none !important;
        }
    }

    .hide-for-large-only {
        ${`@media (min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.xlarge - 1}px)`} {
            display: none !important;
        }
    }

    /* vendor */
    .ReactCollapse--collapse {
        transition: height 500ms ease-in-out;
    }
`;

export default GlobalStyles;
