import React from "react";
import Modal from "@components/Modal";
import styled from "styled-components";
import { Button } from "@components/Button";
import { Flex } from "./Flex";
import { Typography } from "./Typography";
import { bp } from "@styles/theme";

interface NotificationProps {
    title: string;
    isMounted: boolean;
    heading: string;
    description: string;
    buttonText?: string;
    children?: React.ReactNode;
    underlayClickable?: boolean;
    onClose: () => void;
}

export const Notification: React.FC<NotificationProps> = ({ title, isMounted, heading, description, buttonText, children, underlayClickable, onClose }) => {
    return (
        <Modal
            titleText={title}
            mounted={isMounted}
            underlayClickable={underlayClickable}
            onModalClose={onClose}
            className="_ce_measure_widget"
            data-ce-measure-widget="popup-notification-modal"
        >
            <ModalBody>
                <ModalHeading>{heading}</ModalHeading>
                <ModalDescription>{description}</ModalDescription>
                {children}
                <ButtonWrapper>{!children && <Button.Primary onClick={onClose}>{buttonText}</Button.Primary>}</ButtonWrapper>
            </ModalBody>
        </Modal>
    );
};

const ModalBody = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $alignItems: "center",
})`
    padding: 70px 30px 40px;
    position: relative;
    background-color: ${props => props.theme.color.white};
    height: 100vh;

    ${bp.medium} {
        height: auto;
    }

    ${bp.large} {
        padding: 70px 80px 40px;
    }
`;

const ModalHeading = styled(Typography.H3)`
    text-align: center;
    margin-bottom: 22px;

    ${bp.large} {
        margin-bottom: 34px;
    }
`;

const ModalDescription = styled(Typography.P)`
    text-align: center;
    margin-bottom: 32px;

    ${bp.small} {
        margin-bottom: 45px;
    }
`;

const ButtonWrapper = styled(Flex.Container).attrs({
    $justifyContent: "center",
})`
    button {
        min-width: 190px;

        ${bp.small} {
            min-width: 100%;
        }
    }
`;
