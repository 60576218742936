import styled from "styled-components";
import { transition, hexToRGBA } from "@styles/theme";

export const BodyOverlay = styled.div<{ $isActive: boolean; $zIndex?: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${hexToRGBA("#111D2C", 0.4)};
    opacity: ${props => (props.$isActive ? 1 : 0)};
    pointer-events: ${props => (props.$isActive ? "all" : "none")};
    transition: opacity ${props => (props.$isActive ? transition.hoverOut : transition.hoverIn)},
        z-index ${props => (props.$isActive ? "0ms 0ms" : "0ms 300ms")};
    z-index: ${props => (props.$zIndex ? props.$zIndex : "100")};
`;
