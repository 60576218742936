import { gql } from "@utils/StringUtils";
import { Fragments } from "./Fragments";

class Queries {
    public static readonly getSession = gql`
        query session {
            session {
                token
                user {
                    ...User
                }
            }
        }
        ${Fragments.user}
    `;

    public static readonly userMe = gql`
        query userMe {
            userMe {
                ...User
            }
        }
        ${Fragments.user}
    `;

    public static readonly listCities = gql`
        query listCities($postcode: Int!) {
            listCities(postcode: $postcode)
        }
    `;

    public static readonly listStreets = gql`
        query listStreets($postcode: Int!, $city: String!) {
            listStreets(postcode: $postcode, city: $city)
        }
    `;

    public static readonly listPublicPlaceTypes = gql`
        query listPublicPlaceTypes($postcode: Int!, $city: String!, $street: String!) {
            listPublicPlaceTypes(postcode: $postcode, city: $city, street: $street) {
                ...PublicPlaceType
            }
        }
        ${Fragments.publicPlaceType}
    `;

    public static readonly menuItems = gql`
        query webshopMenuItems {
            webshopMenuItems {
                id
                name
                url
                order
                is_active
            }
        }
    `;

    public static readonly cmsContent = gql`
        query cmsContent($url: String!) {
            cmsContent(url: $url) {
                ...CmsContent
            }
        }
        ${Fragments.cmsContent}
    `;

    public static readonly listPublications = gql`
        query listPublications($filters: PublicationFilters, $sortBy: PublicationSort, $first: Int!, $page: Int) {
            listPublications(filters: $filters, sortBy: $sortBy, first: $first, page: $page) {
                data {
                    ...Publication
                }
            }
        }
        ${Fragments.publication}
    `;

    public static readonly homePage = gql`
        query homePage {
            highlightedPublications: customPublicationList(type: LIST_TYPE_HIGHLIGHTED) {
                name
                publications {
                    ...Publication
                }
            }
            printedPublictaions: customPublicationList(type: LIST_TYPE_PRINTED) {
                name
                publications {
                    ...Publication
                }
            }
            highlightedProducts: customProductList(type: LIST_TYPE_HIGHLIGHTED) {
                name
                products {
                    ...AnyProduct
                }
            }
            digitalProducts: customProductList(type: LIST_TYPE_DIGITAL) {
                name
                products {
                    ...AnyProduct
                }
            }
            sliders {
                ...Slider
            }
        }
        ${Fragments.publication}
        ${Fragments.anyProduct}
        ${Fragments.slider}
    `;

    public static readonly getCompanyAddress = gql`
        query getCompanyAddress($tax_number: String!) {
            getCompanyAddress(tax_number: $tax_number) {
                ...CompanyAddress
            }
        }
        ${Fragments.companyAddress}
    `;

    public static readonly getAllActiveUrlAlias = gql`
        query getAllActiveUrlAlias {
            getAllActiveUrlAlias {
                source_url
                target_url
            }
        }
    `;

    public static readonly carts = gql`
        query carts {
            carts {
                ...Cart
            }
        }
        ${Fragments.cart}
    `;

    public static readonly cart = gql`
        query cart($type: CartType!) {
            cart(type: $type) {
                ...Cart
            }
        }
        ${Fragments.cart}
    `;

    public static readonly checkout = gql`
        query checkout {
            checkout {
                ...Checkout
            }
        }
        ${Fragments.checkout}
    `;

    public static readonly checkoutPaymentMethods = gql`
        query checkoutPaymentMethods {
            checkoutPaymentMethods {
                ...PaymentMethod
            }
        }
        ${Fragments.paymentMethod}
    `;

    public static readonly landingNotifications = gql`
        query landingNotifications {
            landingNotifications {
                ...LandingNotification
            }
        }
        ${Fragments.landingNotification}
    `;

    public static readonly listOrders = gql`
        query listOrders($first: Int!, $page: Int) {
            listOrders(first: $first, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Order
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.order}
    `;

    public static readonly listSubscriptions = gql`
        query listSubscriptionBatches($first: Int!, $page: Int) {
            listSubscriptionBatches(first: $first, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...SubscriptionBatch
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.subscriptionBatch}
    `;

    public static readonly userOrder = gql`
        query userOrder($id: Int!) {
            userOrder(id: $id) {
                ...Order
            }
        }
        ${Fragments.order}
    `;

    public static readonly paymentStatus = gql`
        query paymentStatus($transaction_code: String!) {
            paymentStatus(transaction_code: $transaction_code) {
                ...PaymentStatusResult
            }
        }
        ${Fragments.paymentStatusResult}
    `;

    public static readonly getOrderBatchCode = gql`
        query getOrderBatchCode($transaction_code: String!) {
            paymentStatus(transaction_code: $transaction_code) {
                order_batch_code
            }
        }
    `;

    public static readonly discounts = gql`
        query discounts {
            discounts {
                ...Discount
            }
        }
        ${Fragments.discount}
    `;

    public static readonly discount = gql`
        query discount($slug: String!) {
            discount(slug: $slug) {
                ...Discount
            }
        }
        ${Fragments.discount}
    `;

    public static readonly recommendedProducts = gql`
        query recommendedProducts($first: Int!) {
            recommendedProducts(first: $first) {
                ...AnyProduct
            }
        }
        ${Fragments.anyProduct}
    `;

    public static readonly orderBatch = gql`
        query orderBatch($code: String!) {
            orderBatch(code: $code) {
                orders {
                    ...Order
                    grand_total
                }
            }
        }
        ${Fragments.order}
    `;

    public static readonly invoice = gql`
        query invoice($transaction_code: String!) {
            invoice(transaction_code: $transaction_code) {
                file
            }
        }
    `;

    public static readonly orderBatchPaymentMethods = gql`
        query orderBatchPaymentMethods($order_batch_code: String!) {
            orderBatchPaymentMethods(order_batch_code: $order_batch_code) {
                ...PaymentMethod
            }
        }
        ${Fragments.paymentMethod}
    `;

    public static readonly offerInit = gql`
        query offerInit($code: String!) {
            offer(code: $code) {
                ...OfferInit
            }
        }
        ${Fragments.offerInit}
    `;

    public static readonly offerProducts = gql`
        query offerProducts($code: String!) {
            offer(code: $code) {
                ...Offer
            }
        }
        ${Fragments.offer}
    `;

    public static readonly offerDetails = gql`
        query offerDetails($code: String!) {
            offer(code: $code) {
                ...OfferDetails
            }
        }
        ${Fragments.offerDetails}
    `;

    public static readonly christmasDiscount = gql`
        query christmasDiscount($product_ids: [Int!]!) {
            christmasDiscount(product_ids: $product_ids) {
                ...DiscountChristmas
            }
        }
        ${Fragments.discountChristmas}
    `;

    public static readonly userEmailExists = gql`
        query userEmailExists($email: String!) {
            userEmailExists(email: $email)
        }
    `;

    public static readonly listPeriodicSubscriptions = gql`
        query listPeriodicSubscriptions($first: Int!, $page: Int) {
            listPeriodicSubscriptions(first: $first, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...PeriodicSubscription
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.periodicSubscription}
    `;

    public static readonly userDeleteInfo = gql`
        query userDeleteInfo {
            userDeleteInfo {
                consented_sites
                has_active_subscription
            }
        }
    `;

    public static readonly getSettings = gql`
        query getSettings {
            settings {
                price_friendly_txt
            }
        }
    `;
}

export { Queries };
