import { useCallback, useContext } from "react";

import { ClientContext, useMutation } from "graphql-hooks";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { batchActions } from "redux-batched-actions";

import { Mutations } from "@api/graphql/queries/Mutations";
import { userLogout } from "@api/graphql/types";
import { AuthActions } from "@redux/actions/AuthActions";
import { ApplicationState } from "@redux/reducers";
import { Cookie } from "@utils/Cookie";

export const useLogout = () => {
    const graphQLClient = useContext(ClientContext);
    const dispatch = useDispatch();
    const [logoutMutation] = useMutation<userLogout>(Mutations.logout);

    const logout = useCallback(async () => {
        const { data } = await logoutMutation();
        graphQLClient.removeHeader("Authorization");
        if (data?.userLogout) {
            dispatch(batchActions([AuthActions.logout(), AuthActions.updateAuth(data.userLogout.token, false, null)]));
        } else {
            Cookie.clearAuthData();
        }
        window.location.href = "/";
    }, [dispatch, logoutMutation, graphQLClient]);

    return {
        logout,
    };
};
