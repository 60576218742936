import { PaymentMethodType, paymentStatus_paymentStatus, ProductType } from "@api/graphql/types";
import { DateUtils } from "./DateUtils";

const getProductTypeName = (productType: ProductType): string => {
    switch (productType) {
        case ProductType.PRODUCT_TYPE_BUNDLE:
            return "Bundle";
        case ProductType.PRODUCT_TYPE_DIGITAL:
            return "Digitális előfizetés";
        case ProductType.PRODUCT_TYPE_DIGITAL_SERVICE:
            return "Digitális szolgáltatás";
        case ProductType.PRODUCT_TYPE_PRINT:
            return "Nyomtatott előfizetés";
        case ProductType.PRODUCT_TYPE_PRINT_ISSUE:
            return "Nyomtatott lapszám";
        case ProductType.PRODUCT_TYPE_SERVICE:
            return "Szolgáltatás";
    }
};

const getPaymentMethodName = (paymentMethodType: PaymentMethodType | null): string => {
    switch (paymentMethodType) {
        case PaymentMethodType.PAYMENT_METHOD_BANKTRANSFER:
            return "Banki utalás";
        case PaymentMethodType.PAYMENT_METHOD_CHECK:
            return "Csekk";
        case PaymentMethodType.PAYMENT_METHOD_OTPSIMPLE:
            return "Simple Pay bankkártya";
        case PaymentMethodType.PAYMENT_METHOD_OTPSIMPLESAVED:
            return "Simple Pay mentett bankkártya";
        case PaymentMethodType.PAYMENT_METHOD_PAYPAL:
            return "PayPal";
    }
    return "";
};

export const DataLayer = {
    pushData: (data: any) => {
        if (window) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(data);
        }
    },
    pageLoad: () => {
        DataLayer.pushData({ event: "ajaxPageLoad" });
    },
    getAllProductNames: (paymentStatusResult: paymentStatus_paymentStatus): string => {
        const separator = ",";
        const productNames: string[] = [];
        paymentStatusResult.order_batch?.orders.forEach(order => {
            order.items.forEach(orderItem => {
                if (orderItem.__typename === "OrderItem") {
                    productNames.push(orderItem.product.name.replace(/\s/g, "_"));
                }
                if (orderItem.__typename === "OrderItemBundle") {
                    productNames.push(orderItem.orderItems.map(bundle => bundle.product.name.replace(/\s/g, "_")).join(","));
                }
            });
        });
        return productNames.join(separator);
    },
    logTransaction: (paymentStatusResult: paymentStatus_paymentStatus, isFailed: boolean, failReason: string, isLoggedIn: boolean) => {
        if (paymentStatusResult.success && !isFailed) {
            DataLayer.logGA4(paymentStatusResult);
            DataLayer.logUGA(paymentStatusResult, isLoggedIn);
        }
        DataLayer.logCts(paymentStatusResult, isFailed, failReason);
    },
    logCts: (paymentStatusResult: paymentStatus_paymentStatus, isFailed: boolean, failReason: string) => {
        const productName = DataLayer.getAllProductNames(paymentStatusResult);
        if (paymentStatusResult.success && !isFailed) {
            DataLayer.pushData({
                event: "payWallEvent",
                eventCategory: "payWall",
                eventAction: "transaction/success",
                eventLabel: `payWall/transaction/success/${productName}`,
            });
        } else {
            DataLayer.pushData({
                event: "payWallEvent",
                eventCategory: "payWall",
                eventAction: "transaction/fail",
                eventLabel: `payWall/transaction/fail/${paymentStatusResult.error_message ?? failReason}/${productName}`,
            });
        }
    },
    logGA4: (paymentStatusResult: paymentStatus_paymentStatus) => {
        DataLayer.pushData({ ecommerce: null });
        const items: any[] = [];
        paymentStatusResult.order_batch?.orders.forEach(order => {
            order.items.forEach(orderItem => {
                if (orderItem.__typename === "OrderItem") {
                    items.push({
                        item_id: orderItem.product.am_code.toString(),
                        item_name: orderItem.product.name,
                        currency: "HUF",
                        price: orderItem.price,
                        quantity: 1,
                    });
                }
                if (orderItem.__typename === "OrderItemBundle") {
                    items.push({
                        item_id: orderItem.orderItems.map(bundle => bundle.product.am_code.toString()).join(","),
                        item_name: orderItem.orderItems.map(bundle => bundle.product.name).join(","),
                        currency: "HUF",
                        price: orderItem.price,
                        quantity: 1,
                    });
                }
            });
        });
        DataLayer.pushData({
            event: "purchase",
            ecommerce: {
                transaction_id: paymentStatusResult.order_batch?.orders.map(order => `MCE${order.order_batch_id}`).join(","),
                value: paymentStatusResult.order_batch?.grand_total,
                tax: paymentStatusResult.order_batch?.tax_total,
                shipping: 0,
                currency: "HUF",
                coupon: paymentStatusResult.order_batch?.coupons.join(","),
                items,
            },
        });
    },
    logUGA: (paymentStatusResult: paymentStatus_paymentStatus, isLoggedIn: boolean) => {
        const transactionProducts: any[] = [];
        paymentStatusResult.order_batch?.orders.forEach(order => {
            order.items.forEach(orderItem => {
                if (orderItem.__typename === "OrderItem") {
                    transactionProducts.push({
                        name: orderItem.product.name,
                        sku: orderItem.product.am_code.toString(),
                        category: getProductTypeName(orderItem.product.type),
                        price: orderItem.price.toString(),
                        quantity: "1",
                    });
                }
                if (orderItem.__typename === "OrderItemBundle") {
                    transactionProducts.push({
                        name: orderItem.orderItems.map(bundle => bundle.product.name).join(","),
                        sku: orderItem.orderItems.map(bundle => bundle.product.am_code.toString()).join(","),
                        category: getProductTypeName(ProductType.PRODUCT_TYPE_BUNDLE),
                        price: orderItem.price.toString(),
                        quantity: "1",
                    });
                }
            });
        });
        const event = {
            event: "gatransaction",
            pageTitle: "Checkout Success",
            pageCategory: "Checkout Pages",
            visitorLoginState: isLoggedIn ? "Logged In" : "Guest",
            transactionId: paymentStatusResult.order_batch?.orders.map(order => `MCE${order.order_batch_id}`).join(","),
            transactionDate: paymentStatusResult.order_batch?.orders[0] ? DateUtils.getDateString(paymentStatusResult.order_batch?.orders[0].created_at) : "",
            transactionTotal: paymentStatusResult.order_batch?.grand_total.toString(),
            transactionCurrency: "HUF",
            transactionTax: paymentStatusResult.order_batch?.tax_total.toString(),
            transactionPaymentType: getPaymentMethodName(paymentStatusResult.payment_method_type),
            transactionShipping: "0",
            transactionShippingMethod: getPaymentMethodName(paymentStatusResult.payment_method_type),
            transactionCoupon: paymentStatusResult.order_batch?.coupons && paymentStatusResult.order_batch.coupons.length > 0 ? "true" : "false",
            transactionCouponName: paymentStatusResult.order_batch?.coupons.join(",") ?? "",
            transactionProducts,
        };
        DataLayer.pushData(event);
    },
};
