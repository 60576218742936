import styled from "styled-components";
import { bp, hexToRGBA } from "@styles/theme";

export const Card = styled.div`
    padding: 20px;
    background-color: ${props => props.theme.color.white};
    border-radius: ${props => props.theme.radius.medium};
    transition: box-shadow 300ms;

    ${bp.large} {
        &:hover {
            box-shadow: 0px 2px 10px ${props => hexToRGBA(props.theme.color.black, 0.1)}, 0px 4px 7px ${props => hexToRGBA(props.theme.color.black, 0.1)};
        }
    }
`;

export const PopupCard = styled(Card)`
    border-radius: ${props => props.theme.radius.large};
    box-shadow: 0px 15px 30px rgba(17, 29, 44, 0.15);
`;
