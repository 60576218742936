import styled from "styled-components";
import { bp } from "@styles/theme";

// Item
type ItemFlexType = "grow" | "shrink" | number;

type ItemProps = {
    // Note: $flex defines Item's width. Number: 0-1 is interpreted in %s. If $flex > 1, it is interpreted in pixels.
    $flex?: ItemFlexType;
    $gutterMargin?: number;
    $order?: number;
    $alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
} & { $bpMedium?: ItemProps; $bpLarge?: ItemProps; $bpXLarge?: ItemProps; $bpXXLarge?: ItemProps };

const getFlex = (flex: ItemFlexType, $gutterMargin: number) => {
    if (typeof flex === "number" && flex > 1) {
        return `flex-basis: ${flex}px; width: ${flex}px;`;
    } else if (typeof flex === "number" && flex <= 1) {
        return `flex: 0 0 calc(${flex * 100}% - ${$gutterMargin}px);`;
    } else if (flex === "grow") {
        return "flex: 1 1 auto;";
    } else {
        // flex === "shrink"
        return "flex: 0 1 auto;";
        // this is the default value anyway
    }
};

const itemBpStyles = (props: ItemProps) => {
    return `
        ${props.$alignSelf ? `align-self: ${props.$alignSelf};` : ""}
        ${props.$order ? `order: ${props.$order};` : ""}
        ${getFlex(props.$flex ?? "shrink", props.$gutterMargin ?? 0)}
        ${
            props.$gutterMargin
                ? `
            margin-left: ${props.$gutterMargin / 2}px;
            margin-right: ${props.$gutterMargin / 2}px;
        `
                : ""
        }
    `;
};

const StyledItem = styled.div<ItemProps>`
    align-self: ${props => props.$alignSelf || "auto"};
    ${props => props.$order && `order: ${props.$order};`}
    ${props => getFlex(props.$flex ?? "shrink", props.$gutterMargin ?? 0)}
    ${props =>
        props.$gutterMargin &&
        `
        margin-left: ${props.$gutterMargin / 2}px;
        margin-right: ${props.$gutterMargin / 2}px;
    `}

    ${props =>
        props.$bpMedium &&
        `
        ${bp.medium} {
            ${itemBpStyles(props.$bpMedium)}
        }
    `}

    ${props =>
        props.$bpLarge &&
        `
        ${bp.large} {
            ${itemBpStyles(props.$bpLarge)}
        }
    `}

    ${props =>
        props.$bpXLarge &&
        `
        ${bp.xlarge} {
            ${itemBpStyles(props.$bpXLarge)}
        }
    `}

    ${props =>
        props.$bpXXLarge &&
        `
        ${bp.xxlarge} {
            ${itemBpStyles(props.$bpXXLarge)}
        }
    `}
`;

// Container
type ContainerProps = {
    $alignContent?: "stretch" | "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    $alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    $display?: "none" | "block" | "flex" | "inline-flex";
    $flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
    $flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
    $justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    $gutterMargin?: number;
} & { $bpMedium?: ContainerProps; $bpLarge?: ContainerProps; $bpXLarge?: ContainerProps; $bpXXLarge?: ContainerProps };

const containerBpStyles = (props: ContainerProps) => {
    return `
        ${props.$display ? `display: ${props.$display};` : ""}
        ${props.$flexDirection ? `flex-direction: ${props.$flexDirection};` : ""}
        ${props.$flexWrap ? `flex-wrap: ${props.$flexWrap};` : ""}
        ${props.$justifyContent ? `justify-content: ${props.$justifyContent};` : ""}
        ${props.$alignItems ? `align-items: ${props.$alignItems};` : ""}
        ${props.$alignContent ? `align-content: ${props.$alignContent};` : ""}
        ${
            props.$gutterMargin !== undefined
                ? `
            margin-left: ${(props.$gutterMargin / 2) * -1}px;
            margin-right: ${(props.$gutterMargin / 2) * -1}px;
        `
                : ""
        }
    `;
};

const StyledContainer = styled.div<ContainerProps>`
    display: ${props => props.$display || "flex"};
    flex-direction: ${props => props.$flexDirection || "row"};
    flex-wrap: ${props => props.$flexWrap || "nowrap"};
    justify-content: ${props => props.$justifyContent || "flex-start"};
    align-items: ${props => props.$alignItems || "stretch"};
    align-content: ${props => props.$alignContent || "stretch"};
    ${props =>
        props.$gutterMargin &&
        `
        margin-left: ${(props.$gutterMargin / 2) * -1}px;
        margin-right: ${(props.$gutterMargin / 2) * -1}px;
    `}

    ${props =>
        props.$bpMedium &&
        `
        ${bp.medium} {
            ${containerBpStyles(props.$bpMedium)}
        }
    `}

    ${props =>
        props.$bpLarge &&
        `
        ${bp.large} {
            ${containerBpStyles(props.$bpLarge)}
        }
    `}

    ${props =>
        props.$bpXLarge &&
        `
        ${bp.xlarge} {
            ${containerBpStyles(props.$bpXLarge)}
        }
    `}

    ${props =>
        props.$bpXXLarge &&
        `
        ${bp.xxlarge} {
            ${containerBpStyles(props.$bpXXLarge)}
        }
    `}
`;

export const Flex = {
    Container: StyledContainer,
    Item: StyledItem,
};
