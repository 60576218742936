import React, { useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { webshopMenuItems_webshopMenuItems } from "@api/graphql/types";
import { BodyOverlay } from "@components/BodyOverlay";
import { Flex } from "@components/Flex";
import { SVGCart, SVGClose, SVGContacts, SVGHamburger, SVGHome, SVGLogin, SVGTelephone, SVGUser1, SVGUser2 } from "@components/SVGCollection";
import { useLogout } from "@hooks/useLogout";
import { ApplicationState } from "@redux/reducers";
import { hexToRGBA, transition } from "@styles/theme";
import { ExternalPath, Path } from "@utils/Path";
import { useLoctool } from "@bigfish/react-loctool";

interface MenuProps {
    className?: string;
    menuItems: webshopMenuItems_webshopMenuItems[];
}

export const Menu = ({ className, menuItems }: MenuProps) => {
    const Intl = useLoctool();
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useSelector((state: ApplicationState) => state.auth);
    const { logout } = useLogout();
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            setIsOpen(false);
        };

        router.events.on("routeChangeStart", handleRouteChange);

        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [isOpen]);

    const handleLogout = () => {
        logout();
        setIsOpen(false);
    };

    return (
        <>
            <MenuOpenButton className={className} aria-label={Intl.formatMessage({ id: "components.menu.openButtonLabel" })} onClick={() => setIsOpen(true)}>
                <SVGHamburger />
            </MenuOpenButton>
            <StyledMenu $isOpen={isOpen} className={className}>
                <MenuCloseButton aria-label={Intl.formatMessage({ id: "components.menu.closeButtonLabel" })} onClick={() => setIsOpen(false)}>
                    <SVGClose />
                </MenuCloseButton>
                <TopNav>
                    {menuItems.map(menuItem => (
                        <Link href={menuItem.url} key={menuItem.id}>
                            <NavItem>{menuItem.name}</NavItem>
                        </Link>
                    ))}
                </TopNav>
                <MiddleNav>
                    {user ? (
                        <Link href={Path.profileHome}>
                            <MiddleNavItem>
                                <MiddleNavItemIcon>
                                    <SVGUser1 />
                                </MiddleNavItemIcon>
                                {user.contact_lastname && user.contact_firstname ? `${user.contact_lastname} ${user.contact_firstname}` : `${user.email}`}
                            </MiddleNavItem>
                        </Link>
                    ) : (
                        <Link href={Path.login}>
                            <MiddleNavItem>
                                <MiddleNavItemIcon>
                                    <SVGUser1 />
                                </MiddleNavItemIcon>
                                {Intl.formatMessage({ id: "components.menu.login" })}
                            </MiddleNavItem>
                        </Link>
                    )}
                </MiddleNav>
                {user && (
                    <UserNav>
                        <Link href={Path.profileHome}>
                            <UserNavItem>
                                <SVGHome />
                                {Intl.formatMessage({ id: "components.menu.user.homepage" })}
                            </UserNavItem>
                        </Link>
                        <Link href={Path.profileOwnData}>
                            <UserNavItem>
                                <SVGCart />
                                {Intl.formatMessage({ id: "components.menu.user.ownData" })}
                            </UserNavItem>
                        </Link>
                        <Link href={Path.profileShipping}>
                            <UserNavItem>
                                <SVGUser2 />
                                {Intl.formatMessage({ id: "components.menu.user.shippingAdresses" })}
                            </UserNavItem>
                        </Link>
                        <Link href={Path.profileShopping}>
                            <UserNavItem>
                                <SVGContacts />
                                {Intl.formatMessage({ id: "components.menu.user.shoppingHistory" })}
                            </UserNavItem>
                        </Link>
                        {/* <Link href={Path.profileSavedCards}>
                            <UserNavItem>
                                <SVGBankCard />
                                {Intl.formatMessage({ id: "components.menu.user.savedCards" })}
                            </UserNavItem>
                        </Link> */}
                        <UserNavItem onClick={handleLogout}>
                            <SVGLogin />
                            {Intl.formatMessage({ id: "components.menu.user.logout" })}
                        </UserNavItem>
                    </UserNav>
                )}
                <CustomerService>
                    <CustomerServiceTitle href={ExternalPath.customerService} data-link={ExternalPath.customerService} target="_blank">
                        {Intl.formatMessage({ id: "components.menu.customerService.title" })}
                    </CustomerServiceTitle>
                    <Flex.Container>
                        {Intl.formatMessage({ id: "components.menu.customerService.message" })}:{""}
                        <CustomerServicePhoneNumber as="a" href={`tel:${Intl.formatMessage({ id: "components.menu.customerService.phoneNumber" })}`}>
                            <SVGTelephone />
                            {Intl.formatMessage({ id: "components.menu.customerService.phoneNumber" })}
                        </CustomerServicePhoneNumber>
                    </Flex.Container>
                    <Flex.Container>{Intl.formatMessage({ id: "components.menu.customerService.openingHours" })}</Flex.Container>
                </CustomerService>
            </StyledMenu>
            <BodyOverlay $isActive={isOpen} onClick={() => setIsOpen(false)} />
        </>
    );
};

const MenuOpenButton = styled(Flex.Container).attrs({
    as: "button",
    $alignItems: "center",
    $justifyContent: "center",
})`
    border-radius: 50%;
    margin-right: 15px;
`;

const StyledMenu = styled.div<{ $isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 375px;
    height: 100vh;
    transform: ${props => (props.$isOpen ? "translate(0)" : "translate(-100%)")};
    background-color: ${props => props.theme.color.white};
    box-shadow: 0px 5px 15px rgba(55, 65, 71, 0.25);
    transition: transform ${props => (props.$isOpen ? transition.hoverOut : transition.hoverIn)};
    z-index: 200;
    overflow-y: auto;
`;

const MenuCloseButton = styled(MenuOpenButton)`
    position: absolute;
    top: 10px;
    right: 15px;
    margin-right: 0;
    width: 40px;
    height: 40px;
`;

const NavItem = styled(Flex.Container).attrs({
    as: "a",
    $alignItems: "center",
})`
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;

    svg {
        position: relative;
        margin-right: 20px;
    }
`;

const TopNav = styled.nav`
    padding: 60px 20px;
    background-color: ${props => props.theme.color.beige};

    ${NavItem}:not(:first-child) {
        margin-top: 30px;
    }
`;

const MiddleNavItem = styled(NavItem)`
    font-weight: 400 !important;
`;

const MiddleNavItemIcon = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    margin-right: 12px;
    background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.1)};
    border-radius: 50%;

    svg {
        margin: 0;
    }
`;

const MiddleNav = styled.nav`
    padding: 20px;
    border-top: 1px dashed ${props => props.theme.color.line};
    border-bottom: 1px dashed ${props => props.theme.color.line};

    ${MiddleNavItem}:not(:first-child) {
        margin-top: 30px;
    }
`;

const UserNavItem = styled(NavItem)``;

const UserNav = styled(MiddleNav)`
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 0;

    ${UserNavItem}:not(:first-child) {
        margin-top: 30px;
    }
`;

const CustomerService = styled.div`
    padding: 16px 20px;
`;

const CustomerServiceTitle = styled.a`
    display: inline-block;
    margin-bottom: 16px;
    font-weight: 700;
`;

const CustomerServicePhoneNumber = styled(Flex.Container).attrs({
    as: "a",
    $alignItems: "center",
})`
    font-weight: 700;

    svg {
        margin: 0 10px;
    }
`;
