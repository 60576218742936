export interface ImageOptions {
    width?: number;
    height?: number;
}

const optionsToCDNUrl = (options?: ImageOptions, resolution = 1) => {
    const urlFragments: string[] = [];
    if (!options) {
        return "";
    }
    if (options.width || options.height) {
        options.width && urlFragments.push(`w_${options.width * resolution}`);
        options.height && urlFragments.push(`h_${options.height * resolution}`);
    }
    return `${urlFragments.join(",")}${urlFragments.length > 0 ? "/" : ""}`;
};

export class MediaLibraryHelper {
    public static getMediaLibraryImageSrc = (filename: string, options?: ImageOptions, resolution = 1) => {
        return `${process.env.NEXT_PUBLIC_MEDIA_LIBRARY_ASSET_URL}/${optionsToCDNUrl(options, resolution)}${filename}`;
    };

    public static getMediaLibraryImageSrcSet = (filename: string, options?: ImageOptions) => {
        return `${MediaLibraryHelper.getMediaLibraryImageSrc(filename, options, 1)} 1x, ${MediaLibraryHelper.getMediaLibraryImageSrc(
            filename,
            options,
            2,
        )} 2x, ${MediaLibraryHelper.getMediaLibraryImageSrc(filename, options, 3)} 3x`;
    };
}
