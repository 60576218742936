import styled from "styled-components";
import { bp } from "@styles/theme";

const H1 = styled.h1<{ gridArea?: string }>`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    color: ${props => props.theme.color.grayD};

    ${bp.large} {
        font-size: 45px;
        line-height: 65px;
    }

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const H2 = styled.h2<{ gridArea?: string }>`
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.theme.color.grayD};
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;

    ${bp.large} {
        font-size: 40px;
        line-height: 60px;
    }

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const H3 = styled.h3<{ gridArea?: string }>`
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.theme.color.grayD};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;

    ${bp.large} {
        font-size: 30px;
        line-height: 45px;
    }

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const H4 = styled.h4<{ gridArea?: string }>`
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.theme.color.grayD};
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;

    ${bp.large} {
        font-size: 25px;
        line-height: 35px;
    }

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const Large = styled.p<{ gridArea?: string; fontWeight?: number }>`
    font-size: 18px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 0;

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const Medium = styled.p<{ gridArea?: string; fontWeight?: number }>`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
    line-height: 24px;

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const P = styled.p<{ gridArea?: string; fontWeight?: number }>`
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
    line-height: 24px;

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const Small = styled.p<{ gridArea?: string; fontWeight?: number }>`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
    line-height: 20px;

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const Tiny = styled.p<{ gridArea?: string; fontWeight?: number }>`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
    line-height: 18px;

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const UpTitle = styled.p<{ gridArea?: string }>`
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.theme.color.red};
    font-family: "Caveat", cursive;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;

    ${bp.large} {
        font-size: 28px;
        line-height: 38px;
    }

    ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

export const Typography = {
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    Tiny: Tiny,
    Small: Small,
    Medium: Medium,
    P: P,
    Large: Large,
    UpTitle: UpTitle,
};
