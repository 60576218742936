import React from "react";
import styled from "styled-components";
import { bp } from "@styles/theme";
import { hexToRGBA } from "@styles/theme";
import { Flex } from "@components/Flex";

interface AppBarButtonProps {
    label: string;
    icon: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

export const AppBarButton = React.forwardRef(({ label, icon, className, onClick }: AppBarButtonProps, ref) => {
    return (
        <StyledAppBarButton className={className} aria-label={label} onClick={onClick}>
            <AppBarButtonIcon>{icon}</AppBarButtonIcon>
            <AppBarButtonLabel>{label}</AppBarButtonLabel>
        </StyledAppBarButton>
    );
});

export const StyledAppBarButton = styled(Flex.Container).attrs({
    as: "button",
    $flexDirection: "column",
    $alignItems: "center",
})`
    margin-left: 15px;

    ${bp.large} {
        margin-left: 20px;
    }

    ${bp.xlarge} {
        margin-left: 30px;
    }
`;

export const AppBarButtonIcon = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    width: 30px;
    height: 30px;
    background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.1)};
    border-radius: 50%;
    transition: background-color 300ms;

    svg {
        width: 15px;
    }

    ${bp.large} {
        width: 40px;
        height: 40px;

        svg {
            width: 20px;
        }
    }

    ${StyledAppBarButton}:hover & {
        background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.2)};
    }
`;

export const AppBarButtonLabel = styled.span`
    display: none;
    font-size: 10px;
    line-height: 15px;
    margin-top: 6px;
    font-weight: 500;

    ${bp.large} {
        display: block;
    }
`;
