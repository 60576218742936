import { NormalProduct } from "@api/graphql/typeHelpers";
import { Discount, ProductService } from "@api/graphql/types";
import { MediaLibraryHelper, ImageOptions } from "./MediaLibraryHelper";

type HasImage = {
    image: string | null;
};

export const FALLBACK_IMAGE = "/images/mc.hu_karacsony_doboz.jpg";

function publicationImageWithFallback<T extends HasImage>(publication: T, options?: ImageOptions) {
    return {
        src: publication.image ? MediaLibraryHelper.getMediaLibraryImageSrc(publication.image, options) : FALLBACK_IMAGE,
        srcSet: publication.image ? MediaLibraryHelper.getMediaLibraryImageSrcSet(publication.image, options) : FALLBACK_IMAGE,
    };
}

export const ImageUtils = {
    publicationImageWithFallback,
    productImageWithFallback(product: NormalProduct, options?: ImageOptions) {
        const src = product.image
            ? MediaLibraryHelper.getMediaLibraryImageSrc(product.image, options)
            : product.__typename === "ProductBundle" && product.bundlePublication?.image
            ? MediaLibraryHelper.getMediaLibraryImageSrc(product.bundlePublication.image, options)
            : (product.__typename === "ProductPrint" || product.__typename === "ProductDigital" || product.__typename === "ProductPrintIssue") &&
              product.publication.image
            ? MediaLibraryHelper.getMediaLibraryImageSrc(product.publication.image, options)
            : FALLBACK_IMAGE;
        const srcSet = product.image
            ? MediaLibraryHelper.getMediaLibraryImageSrcSet(product.image, options)
            : product.__typename === "ProductBundle" && product.bundlePublication?.image
            ? MediaLibraryHelper.getMediaLibraryImageSrcSet(product.bundlePublication.image, options)
            : (product.__typename === "ProductPrint" || product.__typename === "ProductDigital" || product.__typename === "ProductPrintIssue") &&
              product.publication.image
            ? MediaLibraryHelper.getMediaLibraryImageSrcSet(product.publication.image, options)
            : FALLBACK_IMAGE;

        return {
            src,
            srcSet,
        };
    },
    bundleImageWithFallback(product: NormalProduct, options?: ImageOptions) {
        const src =
            product.__typename === "ProductBundle" && product.image
                ? MediaLibraryHelper.getMediaLibraryImageSrc(product.image, options)
                : product.__typename === "ProductBundle" && product.bundlePublication?.image
                ? MediaLibraryHelper.getMediaLibraryImageSrc(product.bundlePublication.image, options)
                : FALLBACK_IMAGE;
        const srcSet =
            product.__typename === "ProductBundle" && product.image
                ? MediaLibraryHelper.getMediaLibraryImageSrcSet(product.image, options)
                : product.__typename === "ProductBundle" && product.bundlePublication?.image
                ? MediaLibraryHelper.getMediaLibraryImageSrcSet(product.bundlePublication.image, options)
                : FALLBACK_IMAGE;

        return {
            src,
            srcSet,
        };
    },
    offerSmallImageWithFallback(offer: Discount) {
        return offer.image_background
            ? MediaLibraryHelper.getMediaLibraryImageSrc(offer.image_background, {
                  width: 768,
              })
            : "/images/special-offer-list-small.jpg";
    },
    offerBGImageWithFallback(offer: Discount) {
        return offer.image_background ? MediaLibraryHelper.getMediaLibraryImageSrc(offer.image_background) : "/images/special-offer-list-large.jpg";
    },
    serviceImageWithFallback(product: ProductService, options?: ImageOptions) {
        const src = product.image ? MediaLibraryHelper.getMediaLibraryImageSrc(product.image, options) : FALLBACK_IMAGE;
        const srcSet = product.image ? MediaLibraryHelper.getMediaLibraryImageSrcSet(product.image, options) : FALLBACK_IMAGE;

        return {
            src,
            srcSet,
        };
    },
};
