import { sanitize as domSanitize } from "isomorphic-dompurify";
import { decode } from "html-entities";

export const HtmlUtils = {
    sanitize: (dirtyHtml: string): string => {
        return domSanitize(dirtyHtml, { USE_PROFILES: { html: true } });
    },
    removeTags: (dirtyHtml: string | null | undefined): string => {
        if (!dirtyHtml) return "";
        const plainText = HtmlUtils.sanitize(dirtyHtml).replace(/(<([^>]+)>)/gi, "");
        const decodedText = decode(plainText);
        return decodedText;
    },
    addTargetBlankToLinks: (dirtyHtml: string | null | undefined): string => {
        if (!dirtyHtml) return "";
        // eslint-disable-next-line quotes
        return dirtyHtml.replace(/<a(?![^>]*\starget=)/g, '<a target="_blank"');
    },
    addSecurityAttributesToLinks: (dirtyHtml: string | null | undefined): string => {
        if (!dirtyHtml) return "";
        return dirtyHtml.replace(/<a\s+(.*?)>/g, (match, attributes) => {
            const modifiedAttributes = attributes.replace(/\s*rel="[^"]*"/, "");
            const newAttributes = `${modifiedAttributes} rel="noopener noreferrer nofollow"`;
            return `<a ${newAttributes}>`;
        });
    },
    sanitizeWithLinkSecurity: (dirtyHtml: string | null | undefined): string => {
        if (!dirtyHtml) return "";
        const sanitizedHtml = HtmlUtils.sanitize(dirtyHtml);
        const htmlWithTargetBlank = HtmlUtils.addTargetBlankToLinks(sanitizedHtml);
        const htmlWithLinkSecurity = HtmlUtils.addSecurityAttributesToLinks(htmlWithTargetBlank);
        return htmlWithLinkSecurity;
    },
};
