import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { Container } from "@components/Container";
import { Flex } from "@components/Flex";
import { bp } from "@styles/theme";
import { useLoctool } from "@bigfish/react-loctool";

export const AppBarMinimal = () => {
    const Intl = useLoctool();
    return (
        <StyledAppBar>
            <Container>
                <AppBarContainer $alignItems="center">
                    <Link href="/">
                        <SiteLogo as="a">
                            <img src="/logos/magazin-centrum.png" alt={Intl.formatMessage({ id: "common.brandName" })} />
                            <span>{Intl.formatMessage({ id: "common.brandName" })}</span>
                        </SiteLogo>
                    </Link>
                </AppBarContainer>
            </Container>
        </StyledAppBar>
    );
};

const StyledAppBar = styled.header`
    background-color: ${props => props.theme.color.white};
    z-index: 200;
`;

const AppBarContainer = styled(Flex.Container)`
    height: 60px;
`;

const SiteLogo = styled(Flex.Container).attrs({
    as: "a",
    $alignItems: "center",
})`
    img {
        width: 45px;
        height: 45px;
        margin-right: 12px;

        ${bp.large} {
            width: 40px;
            height: 40px;
        }
    }

    span {
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        width: 70%;

        ${bp.medium} {
            width: auto;
        }

        ${bp.large} {
            font-size: 20px;
            line-height: 26px;
        }
    }
`;
