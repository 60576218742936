import React, { useMemo } from "react";

import { Formik, FormikHelpers } from "formik";
import FormikErrorFocus from "formik-error-focus";
import { useMutation } from "graphql-hooks";
import { useRouter } from "next/router";
import styled from "styled-components";

import { Mutations } from "@api/graphql/queries/Mutations";
import { newsletterSubscribe, newsletterSubscribeVariables } from "@api/graphql/types";
import { handleApiValidationErrors } from "@api/ApiValidation";
import { getGraphQLErrorMessage } from "@api/GraphQLClient";
import { Button } from "@components/Button";
import { Checkbox, ErrorText } from "@components/Checkbox";
import { Container } from "@components/Container";
import { Flex } from "@components/Flex";
import { Input, InputLabel } from "@components/Input";
import { Link } from "@components/Link";
import { SVGTelephone } from "@components/SVGCollection";
import { Typography } from "@components/Typography";
import { bp, hexToRGBA, transition } from "@styles/theme";
import { NewsletterFormValueType } from "@utils/Form";
import { ExternalPath, Path } from "@utils/Path";
import { useLoctool } from "@bigfish/react-loctool";
import * as Yup from "yup";

export const paymentProviders = () => (
    <PaymentProvidersList>
        <li>
            <img src="/logos/simplepay.png" alt="Simple pay OTP" />
        </li>
        <li>
            <img src="/logos/paypal.png" srcSet="/logos/paypal.png 1x, /logos/paypal@2x.png 2x, /logos/paypal@3x.png 3x" alt="Paypal" />
        </li>
        <li>
            <img src="/logos/maestro.png" srcSet="/logos/maestro.png 1x, /logos/maestro@2x.png 2x, /logos/maestro@3x.png 3x" alt="Maestro" />
        </li>
        <li>
            <img src="/logos/mastercard.png" srcSet="/logos/mastercard.png 1x, /logos/mastercard@2x.png 2x, /logos/mastercard@3x.png 3x" alt="Mastercard" />
        </li>
        <li>
            <img src="/logos/visa.png" srcSet="/logos/visa.png 1x, /logos/visa@2x.png 2x, /logos/visa@3x.png 3x" alt="Visa" />
        </li>
        <li>
            <img
                src="/logos/visa-electron.png"
                srcSet="/logos/visa-electron.png 1x, /logos/visa-electron@2x.png 2x, /logos/visa-electron@3x.png 3x"
                alt="Visa electron"
            />
        </li>
        <li>
            <img
                src="/logos/mastercard-securecode.png"
                srcSet="/logos/mastercard-securecode.png 1x, /logos/mastercard-securecode@2x.png 2x, /logos/mastercard-securecode@3x.png 3x"
                alt="Mastercard securecode"
            />
        </li>
        <li>
            <img
                src="/logos/verified-by-visa.png"
                srcSet="/logos/verified-by-visa.png 1x, /logos/verified-by-visa@2x.png 2x, /logos/verified-by-visa@3x.png 3x"
                alt="Verified by visa"
            />
        </li>
        <li>
            <img
                src="/logos/american-express.png"
                srcSet="/logos/american-express.png 1x, /logos/american-express@2x.png 2x, /logos/american-express@3x.png 3x"
                alt="American express"
            />
        </li>
    </PaymentProvidersList>
);

interface FooterProps {
    hideNewsletter?: boolean;
}

export const Footer = (props: FooterProps) => {
    const router = useRouter();
    const [subscribe] = useMutation<newsletterSubscribe, newsletterSubscribeVariables>(Mutations.newsletterSubscribe);
    const Intl = useLoctool();
    const newsletterValidationSchema = useMemo(
        () =>
            Yup.object().shape({
                email: Yup.string()
                    .email(`${Intl.formatMessage({ id: "form.fieldError.email" })}`)
                    .required(`${Intl.formatMessage({ id: "form.fieldError.required" })}`),
                first_name: Yup.string().required(`${Intl.formatMessage({ id: "form.fieldError.required" })}`),
                last_name: Yup.string().required(`${Intl.formatMessage({ id: "form.fieldError.required" })}`),
                terms: Yup.boolean().oneOf([true], `${Intl.formatMessage({ id: "form.fieldError.required" })}`),
            }),
        [Intl],
    );

    const handleSubmit = async (values: NewsletterFormValueType, formikHelpers: FormikHelpers<NewsletterFormValueType>): Promise<void> => {
        const { email, first_name, last_name } = values;

        const { data, error } = await subscribe({
            variables: {
                input: { email, first_name, last_name },
            },
        });
        if (data?.newsletterSubscribe) {
            router.push(
                { pathname: Path.success, query: { title: `${Intl.formatMessage({ id: "pages.confirm.newsletterSubscribeSuccess" })}` } },
                Path.success,
            );
        }
        if (error) {
            const handled = handleApiValidationErrors(error, formikHelpers, ["input"]);
            if (!handled) {
                formikHelpers.setStatus({ apiErrorMessage: getGraphQLErrorMessage(error) });
            }
        }
    };

    return (
        <StyledFooter>
            {!props.hideNewsletter && (
                <Newsletter>
                    <Container>
                        <NewsLetterContainer>
                            <NewsletterContent>
                                <NewsletterTitle>{Intl.formatMessage({ id: "components.footer.title" })}</NewsletterTitle>
                                <NewsletterText>{Intl.formatMessage({ id: "components.footer.subtitle" })}</NewsletterText>
                            </NewsletterContent>
                            <Formik
                                key={Date.now()}
                                initialValues={{ email: "", first_name: "", last_name: "", terms: false }}
                                initialStatus={{ apiErrorMessage: "" }}
                                validationSchema={newsletterValidationSchema}
                                onSubmit={(values, formikHelpers) => {
                                    handleSubmit(values, formikHelpers);
                                    formikHelpers.resetForm();
                                }}
                            >
                                {({ handleChange, handleSubmit, values, errors, touched, isSubmitting, status }) => (
                                    <NewsletterForm className="footer-newsletter-form">
                                        <input id="00N3X00000LHbsJ" name="00N3X00000LHbsJ" type="hidden" value="1" />
                                        <Flex.Container
                                            $flexDirection="column"
                                            $bpMedium={{ $flexDirection: "row", $alignContent: "flex-start", $flexWrap: "wrap", $gutterMargin: 30 }}
                                        >
                                            <Flex.Item $bpMedium={{ $flex: 1 / 2, $gutterMargin: 30 }} $bpXLarge={{ $flex: 2 / 7, $gutterMargin: 30 }}>
                                                <Input.Primary
                                                    type="text"
                                                    name="last_name"
                                                    label={Intl.formatMessage({ id: "form.lastname.label" })}
                                                    hideLabel
                                                    value={values.last_name}
                                                    onChange={handleChange("last_name")}
                                                    error={!!errors.last_name && touched.last_name}
                                                    errorMessage={errors.last_name}
                                                    className="footer-newsletter-lastname-field"
                                                />
                                            </Flex.Item>
                                            <Flex.Item $bpMedium={{ $flex: 1 / 2, $gutterMargin: 30 }} $bpXLarge={{ $flex: 2 / 7, $gutterMargin: 30 }}>
                                                <Input.Primary
                                                    type="text"
                                                    name="first_name"
                                                    label={Intl.formatMessage({ id: "form.firstname.label" })}
                                                    hideLabel
                                                    value={values.first_name}
                                                    onChange={handleChange("first_name")}
                                                    error={!!errors.first_name && touched.first_name}
                                                    errorMessage={errors.first_name}
                                                    className="footer-newsletter-firstname-field"
                                                />
                                            </Flex.Item>
                                            <Flex.Item $bpMedium={{ $flex: 1 / 2, $gutterMargin: 30 }} $bpXLarge={{ $flex: 3 / 7, $gutterMargin: 30 }}>
                                                <Input.Primary
                                                    type="text"
                                                    name="email"
                                                    label={Intl.formatMessage({ id: "form.email.label" })}
                                                    hideLabel
                                                    value={values.email}
                                                    onChange={handleChange("email")}
                                                    error={!!errors.email && touched.email}
                                                    errorMessage={errors.email}
                                                    className="footer-newsletter-email-field"
                                                />
                                            </Flex.Item>
                                            <Flex.Item $bpMedium={{ $flex: 1 / 2, $gutterMargin: 30 }} $bpXLarge={{ $flex: 1, $gutterMargin: 30 }}>
                                                <Checkbox
                                                    label={
                                                        <p>
                                                            {Intl.formatMessage({ id: "components.footer.terms" })}{" "}
                                                            <Link
                                                                href={Path.privacyPolicy}
                                                                label={Intl.formatMessage({ id: "components.footer.termsLabel" })}
                                                            />
                                                        </p>
                                                    }
                                                    name="terms"
                                                    className="footer-newsletter-terms-field"
                                                    checked={values.terms}
                                                    onChange={handleChange("terms")}
                                                    error={!!errors.terms && touched.terms}
                                                    errorMessage={errors.terms}
                                                />
                                            </Flex.Item>
                                        </Flex.Container>
                                        <NewsLeterButtonWrapper>
                                            <Button.Primary
                                                type="submit"
                                                onClick={handleSubmit}
                                                disabled={isSubmitting}
                                                className="footer-newsletter-submit-button"
                                            >
                                                {Intl.formatMessage({ id: "components.footer.submit" })}
                                            </Button.Primary>
                                        </NewsLeterButtonWrapper>
                                        {status.apiErrorMessage && <ErrorText>{status.apiErrorMessage}</ErrorText>}
                                        <FormikErrorFocus offset={-200} align={"top"} focusDelay={200} ease={"linear"} duration={250} />
                                    </NewsletterForm>
                                )}
                            </Formik>
                        </NewsLetterContainer>
                    </Container>
                </Newsletter>
            )}
            <FooterProviders>
                <Container>
                    <Flex.Container
                        $flexDirection="column"
                        $bpMedium={{ $flexDirection: "row", $alignItems: "center" }}
                        $bpLarge={{ $justifyContent: "center" }}
                    >
                        <ProvidersTitle as="span">{Intl.formatMessage({ id: "components.footer.paymentProviders" })}</ProvidersTitle>
                        {paymentProviders()}
                    </Flex.Container>
                </Container>
            </FooterProviders>
            <FooterBottom>
                <FooterBottomContainer>
                    <Copyright>{Intl.formatMessage({ id: "components.footer.copyright" }, { year: new Date().getFullYear() })}</Copyright>
                    <Links>
                        <LinksContainer>
                            <ExternalLink href={ExternalPath.about} target="_blank" data-link={ExternalPath.about}>
                                {Intl.formatMessage({ id: "components.footer.links.aboutMc" })}
                            </ExternalLink>
                            <LinkSeparator />
                            <ExternalLink href={ExternalPath.mediaOffer} target="_blank" data-link={ExternalPath.mediaOffer}>
                                {Intl.formatMessage({ id: "components.footer.links.media" })}
                            </ExternalLink>
                            <LinkSeparator />
                            <StyledLink href={Path.imprint} label={Intl.formatMessage({ id: "components.footer.links.imprint" })} />
                            <LinkSeparator />
                        </LinksContainer>
                        <LinksContainer>
                            <ExternalLink href={ExternalPath.startLogin} target="_blank" data-link={ExternalPath.startLogin}>
                                {Intl.formatMessage({ id: "components.footer.links.startlogin" })}
                            </ExternalLink>
                            <LinkSeparator />
                            <StyledLink href={Path.privacyPolicy} label={Intl.formatMessage({ id: "components.footer.links.privacy" })} />
                            <LinkSeparator />
                            <StyledLink href={Path.terms} label={Intl.formatMessage({ id: "components.footer.links.terms" })} />
                        </LinksContainer>
                    </Links>
                    <MadeBy as="a" href={ExternalPath.bigfish} target="_blank" data-link={ExternalPath.bigfish}>
                        <span>{Intl.formatMessage({ id: "components.footer.madeBy" })}</span>
                        <img src="/logos/bigfish.png" srcSet="/logos/bigfish.png 1x, /logos/bigfish@2x.png 2x" alt="BIG FISH" />
                    </MadeBy>
                </FooterBottomContainer>
            </FooterBottom>
        </StyledFooter>
    );
};

export const FooterMinimal = () => {
    const Intl = useLoctool();
    return (
        <FooterMinimalContainer>
            <Container>
                <Flex.Container $flexDirection="column" $bpMedium={{ $flexDirection: "row" }}>
                    <MinimalContent>
                        <MinimalProvidersTitle>{Intl.formatMessage({ id: "components.footer.paymentProviders" })}</MinimalProvidersTitle>
                        {paymentProviders()}
                    </MinimalContent>
                    <ContactContent>
                        <ContactContentInner>
                            <MinimalContactTitle>{Intl.formatMessage({ id: "components.footer.contactTitle" })}</MinimalContactTitle>
                            <TelephoneContainer $alignItems="center">
                                <Typography.Tiny>{Intl.formatMessage({ id: "components.menu.customerService.message" })}</Typography.Tiny>
                                <SVGTelephone aria-hidden={true} />
                                <TelephoneLink
                                    href={`tel:${Intl.formatMessage({ id: "components.menu.customerService.phoneNumber" })}`}
                                    label={Intl.formatMessage({ id: "components.menu.customerService.phoneNumber" })}
                                />
                            </TelephoneContainer>
                            <p>{Intl.formatMessage({ id: "components.footer.contactDescription" })}</p>
                        </ContactContentInner>
                    </ContactContent>
                </Flex.Container>
            </Container>
        </FooterMinimalContainer>
    );
};

const StyledFooter = styled.footer`
    margin-top: auto;
`;

const Newsletter = styled.div`
    border-bottom: 1px dashed ${props => props.theme.color.grayL};
`;

const NewsLetterContainer = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $bpXLarge: {
        $flexDirection: "row",
    },
})`
    padding: 30px 0;

    ${bp.xlarge} {
        padding: 30px 0 10px;
    }
`;

const NewsletterContent = styled.div`
    margin-bottom: 20px;

    ${bp.xlarge} {
        margin-bottom: 0;
        margin-right: 40px;
    }
`;

const NewsletterTitle = styled(Typography.H2)`
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;

    ${bp.xlarge} {
        margin-bottom: 12px;
    }
`;

const NewsletterText = styled(Typography.Small)`
    margin-bottom: 8px;

    ${bp.xlarge} {
        margin-bottom: 0;
    }
`;

const NewsletterForm = styled(Flex.Container).attrs({
    as: "form",
    $flexDirection: "column",
    $bpXLarge: {
        $flexDirection: "row",
    },
})`
    ${Flex.Item} {
        margin-bottom: 20px;
    }

    .footer-newsletter-terms-field {
        ${InputLabel} {
            font-weight: 400;
            padding-top: 0;

            a {
                font-weight: 700;
            }
        }
    }
`;

const NewsLeterButtonWrapper = styled.div`
    text-align: center;

    ${bp.medium} {
        text-align: right;
    }

    ${bp.xlarge} {
        padding-left: 30px;
    }
`;

const FooterProviders = styled.div`
    padding: 20px 0;
`;

const ProvidersTitle = styled(Typography.H3)`
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;

    ${bp.medium} {
        margin-bottom: 0;
        margin-right: 30px;
    }
`;

const FooterBottom = styled.div`
    background-color: ${props => props.theme.color.grayD};
`;

const FooterBottomContainer = styled(Container)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    color: ${props => props.theme.color.white};

    ${bp.xxlarge} {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
    }
`;

const LinkSeparator = styled.span`
    display: block;
    width: 20px;
    height: 1px;
    margin: 10px 0;
    background-color: ${props => hexToRGBA(props.theme.color.white, 0.2)};

    ${bp.medium} {
        width: 1px;
        height: 20px;
        margin: 0 30px;
    }
`;

const Links = styled(Flex.Container).attrs({
    $display: "block",
    $bpMedium: {
        $display: "flex",
        $flexWrap: "wrap",
        $alignItems: "center",
    },
})`
    grid-column: 1 / 3;
`;

const LinksContainer = styled(Flex.Container).attrs({
    $display: "block",
    $bpMedium: {
        $display: "flex",
        $alignItems: "center",
    },
})`
    width: 100%;

    ${bp.medium} {
        ${LinkSeparator}:last-child {
            display: none;
        }
    }

    ${bp.xlarge} {
        width: auto;

        ${LinkSeparator}:last-child {
            display: block;
        }
    }
`;

const Copyright = styled.span`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin-right: auto;
    font-size: 12px;
`;

const StyledLink = styled(Link)`
    display: block;
    margin: 10px 0;
    color: ${props => props.theme.color.white};
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
`;

const ExternalLink = styled.a`
    display: block;
    margin: 10px 0;
    color: ${props => props.theme.color.white};
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    transition: color ${transition.hoverOut};

    &:hover {
        color: ${props => props.theme.color.red};
    }
`;

const MadeBy = styled(Flex.Container).attrs({
    as: "a",
    $alignItems: "center",
})`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-left: auto;

    span {
        margin-right: 16px;
        font-size: 12px;
    }
`;

const FooterMinimalContainer = styled.footer`
    border-top: 1px solid ${props => props.theme.color.grayL};
    background-color: ${props => props.theme.color.white};
    padding: 20px 0;
`;

const MinimalProvidersTitle = styled.span`
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;

    ${bp.medium} {
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const MinimalContactTitle = styled.span`
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;

    ${bp.medium} {
        font-size: 16px;
        margin-bottom: 8px;
    }
`;

export const PaymentProvidersList = styled(Flex.Container).attrs({
    $flexWrap: "wrap",
})`
    margin-left: -5px;

    li {
        margin: 0 5px 10px;
    }

    img {
        vertical-align: middle;
        object-fit: contain;
        height: 20px;
        width: auto;
    }
`;

const MinimalContent = styled.div`
    flex: 1;
`;

const ContactContent = styled(MinimalContent)`
    border-top: 1px solid ${props => props.theme.color.line};
    padding-top: 15px;

    ${bp.medium} {
        border-top: none;
        padding-top: 0;
        border-left: 1px solid ${props => props.theme.color.line};
    }
`;

const ContactContentInner = styled.div`
    > p {
        font-size: 10px;
        line-height: 18px;
    }

    ${bp.medium} {
        padding-left: 50px;
    }

    ${bp.xlarge} {
        padding-left: 100px;
    }
`;

const TelephoneLink = styled(Link)`
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
`;

const TelephoneContainer = styled(Flex.Container)`
    margin-bottom: 5px;

    > * {
        margin-right: 8px;
    }
`;
