import styled, { css } from "styled-components";
import { bp } from "./theme";

export const showForSr = css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const showForAll = css`
    position: unset;
    width: unset;
    height: unset;
    margin: unset;
    clip: unset;
    clip-path: unset;
    white-space: unset;
`;

export const hexToRGBA = (h: string, opacity = 1): string => {
    let r = "";
    let g = "";
    let b = "";

    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
};

export const hoverBoldJumpHack = css`
    display: block;
    font-family: bold;
    height: 0;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
`;

export const HoverBoldJumpHackElement: React.FC = props => <StyledHoverBoldJumpHackElement aria-hidden="true">{props.children}</StyledHoverBoldJumpHackElement>;

const StyledHoverBoldJumpHackElement = styled.span`
    ${hoverBoldJumpHack}
`;

export const TruncatedText = styled.span<{ $lineCount: [number, number] }>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.$lineCount[0]};
    -webkit-box-orient: vertical;

    ${bp.medium} {
        -webkit-line-clamp: ${props => props.$lineCount[1]};
    }
`;
