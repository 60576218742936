import { AuthActions, AuthActionTypes } from "@redux/actions/AuthActions";
import { User } from "@api/graphql/types";

export type AuthReducerType = {
    authToken: string | null;
    user: User | null;
};

export const initialState = {
    authToken: null,
    user: null,
};

export default function AuthReducer(state: AuthReducerType = initialState, action: AuthActions): AuthReducerType {
    switch (action.type) {
        case AuthActionTypes.updateAuth:
        case AuthActionTypes.updateUser: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
}
