import React, { ForwardedRef } from "react";
import styled, { css } from "styled-components";
import { bp, hexToRGBA } from "@styles/theme";
import { Flex } from "@components/Flex";
import { transition } from "@styles/theme";
import { SVGClose, SVGPlus, SVGMinus, SVGInfo, SVGBin } from "@components/SVGCollection";

interface ButtonProps {
    children: React.ReactNode;
    type?: "submit" | "reset";
    className?: string;
    onClick?: any;
    disabled?: boolean;
    fullWidth?: boolean;
}

const ButtonComponent = React.forwardRef(({ children, type, className, onClick, disabled, fullWidth }: ButtonProps, ref) => {
    return (
        <BaseButton className={className} type={type ? type : "button"} onClick={onClick} disabled={disabled} $fullWidth={fullWidth}>
            {children}
        </BaseButton>
    );
});

interface QuantityButtonProps {
    name: string;
    max: number;
    quantity: number;
    onAdd: (event?: React.MouseEvent) => void;
    onRemove: () => void;
}

export const QuantityButton = ({ name, max, quantity, onAdd, onRemove }: QuantityButtonProps) => {
    const increment = () => {
        if (quantity !== max) {
            onAdd();
        }
    };

    const decrement = () => {
        onRemove();
    };

    return (
        <Flex.Container $alignItems="center" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            <DecrementButton aria-label="Decrement" onClick={decrement}>
                <SVGMinus />
            </DecrementButton>
            <QuantityInput type="text" name={name} readOnly value={quantity} />
            <IncrementButton aria-label="Increment" onClick={increment}>
                <SVGPlus />
            </IncrementButton>
        </Flex.Container>
    );
};

interface DeleteButtonProps {
    label: string;
    onClick?: () => void;
    className?: string;
}

export const DeleteButton = ({ label, onClick, className }: DeleteButtonProps) => {
    return (
        <StyledDeleteButton className={className} aria-label={label} onClick={onClick}>
            <SVGBin />
        </StyledDeleteButton>
    );
};

const StyledDeleteButton = styled(ButtonComponent)`
    display: grid;
    place-items: center;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    background-color: ${props => props.theme.color.grayXL};
    border-radius: 50%;
    transition: background-color 300ms;

    &:hover {
        background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.2)};
    }
`;

const BaseButton = styled.button<{ $fullWidth?: boolean }>`
    ${props =>
        props.$fullWidth &&
        css`
            width: 100%;
        `}
`;

const StyledButton = styled(ButtonComponent)`
    display: inline-block;
    padding: 8px 30px;
    line-height: 24px;
    border-radius: ${props => props.theme.radius.medium};
    font-weight: 500;
    text-align: center;
    transition: background-color ${transition.hoverOut}, color ${transition.hoverOut};

    &:hover {
        transition: background-color ${transition.hoverIn}, color ${transition.hoverIn};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const PrimaryButton = styled(StyledButton)`
    color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.grayD};

    &:hover {
        background-color: ${props => props.theme.color.red};
    }

    &:disabled {
        background-color: ${props => props.theme.color.grayL};
    }
`;

export const SecondaryButton = styled(StyledButton)`
    border: 1px solid ${props => props.theme.color.grayD};

    &:hover {
        color: ${props => props.theme.color.white};
        background-color: ${props => props.theme.color.grayD};
    }

    &:disabled {
        color: ${props => props.theme.color.grayL};
        border-color: ${props => props.theme.color.grayL};
        background-color: transparent;
    }
`;

const LinkButton = styled(StyledButton)<{ weight?: number }>`
    font-weight: ${props => (props.weight ? props.weight : 500)};
    text-decoration: underline;

    &:hover {
        color: ${props => props.theme.color.red};
    }

    &:disabled {
        color: ${props => props.theme.color.grayL};
    }
`;

const QuantityInput = styled.input`
    width: 50px;
    border: 0;
    font-size: 18px;
    text-align: center;
`;

const QuantityButtons = styled(Flex.Container).attrs({
    as: "button",
    $alignItems: "center",
    $justifyContent: "center",
})`
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    border: 1px solid ${props => props.theme.color.red};
    border-radius: 50%;
`;

const IncrementButton = styled(QuantityButtons)`
    color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.red};
`;

const DecrementButton = styled(QuantityButtons)`
    color: ${props => props.theme.color.red};
`;

interface CloseButtonProps {
    onClick: () => void;
    className?: string;
    label?: string;
}

export const CloseButton = (props: CloseButtonProps) => {
    return (
        <StyledCloseButton className={props.className} onClick={props.onClick}>
            <span className="show-for-sr">{props.label ? props.label : "Bezárás"}</span>
            <SVGClose />
        </StyledCloseButton>
    );
};

const StyledCloseButton = styled(Flex.Container).attrs({
    as: "button",
    $display: "flex",
    $alignItems: "center",
    $justifyContent: "center",
})`
    width: 30px;
    height: 30px;
    background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.1)};
    border-radius: 50%;
    transition: background-color 300ms;

    &:hover {
        background-color: ${props => hexToRGBA(props.theme.color.grayD, 0.2)};
    }

    ${bp.large} {
        width: 40px;
        height: 40px;
    }
`;

export const Button = {
    Primary: PrimaryButton,
    Secondary: SecondaryButton,
    Link: LinkButton,
};
