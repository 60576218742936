import { Checkout } from "@api/graphql/types";
import { ActionsUnion } from "typings/declarations";
import { createAction } from "./actionHelpers";

export enum CheckoutActionTypes {
    updateCheckout = "@checkout/updateCheckout",
}

export const CheckoutActions = {
    updateCheckout: (checkout: Checkout) => createAction(CheckoutActionTypes.updateCheckout, { checkout }),
};

export type CheckoutActions = ActionsUnion<typeof CheckoutActions>;
