import { User } from "@api/graphql/types";
import { Cookie } from "@utils/Cookie";
import { ServerResponse } from "http";
import { ActionsUnion } from "typings/declarations";
import { createAction } from "./actionHelpers";

export enum AuthActionTypes {
    updateUser = "@auth/updateUser",
    updateAuth = "@auth/updateAuth",
    logout = "@auth/logout",
}

export const AuthActions = {
    updateAuth: (authToken: string, isStayLoggedIn: boolean, user: User | null) => {
        Cookie.setAuthToken(authToken, isStayLoggedIn);
        return createAction(AuthActionTypes.updateAuth, { authToken, user });
    },
    updateUser: (user: User) => createAction(AuthActionTypes.updateUser, { user }),
    logout: (response?: ServerResponse) => {
        Cookie.clearAuthData(response);
        return createAction(AuthActionTypes.logout);
    },
};

export type AuthActions = ActionsUnion<typeof AuthActions>;
