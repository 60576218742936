import LRU from "tiny-lru";
import debug from "debug";

const lruInfo = debug("ssrcache:info");
const lruDebug = debug("ssrcache:debug");

const OFFSET_BASIS_32 = 2166136261;

function fnv1aString(string: string) {
    let hash = OFFSET_BASIS_32;

    for (let i = 0; i < string.length; i++) {
        hash ^= string.charCodeAt(i);

        // 32-bit FNV prime: 2**24 + 2**8 + 0x93 = 16777619
        // Using bitshift for accuracy and performance. Numbers in JS suck.
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }

    return hash >>> 0;
}

function generateKey(keyObj: object) {
    return fnv1aString(JSON.stringify(keyObj)).toString(36);
}

type StoredValue = {
    now: number;
    maxAge?: number;
    data: any;
};

type Options = {
    size?: number;
    ttl?: number;
    initialState?: { [key: string]: any };
};

export default function memCacheWithTTL(options: Options) {
    const defaultOpts: Options = { size: 1000, ttl: 0, initialState: {} };
    const { size, ttl, initialState } = { ...defaultOpts, ...options };
    const lru = LRU<StoredValue>(size, ttl);
    lruInfo("Creating ssrcache with max size: %d and global ttl: %d ", size, ttl);
    lruDebug("!!! WARNING !!! SSRCache recreated every page compilation in DEV environment, but only one created once in PROD mode.");

    if (initialState) {
        Object.keys(initialState).map(k => {
            lru.set(k, initialState[k]);
        });
    }

    const getStoredValue = (key: object | string, removeWhenInvalid = true) => {
        let keyToUse: string = key as string;
        if (typeof key !== "string") {
            keyToUse = generateKey(key);
        }
        const storedValue = lru.get(keyToUse);
        if (!storedValue) {
            lruInfo("Stored value not found for key: %s", keyToUse);
            lruDebug("Keydata: %o", key);
            return undefined;
        }
        const valid = typeof storedValue.maxAge === "undefined" || Date.now() - storedValue.now < storedValue.maxAge;
        if (!valid && removeWhenInvalid) {
            lruInfo("Removing key due to maxAge, key: %s", keyToUse);
            lru.delete(keyToUse);
        }
        return valid ? storedValue.data : undefined;
    };

    return {
        get: (keyObj: object) => getStoredValue(keyObj),
        rawGet: (rawKey: string) => lru.get(rawKey),
        set: (keyObj: object, data: any, maxAge?: number) => {
            const key = generateKey(keyObj);
            lru.set(key, { now: Date.now(), maxAge, data });
            lruInfo("Setting key: %s with maxAge: %d", key, maxAge);
            lruDebug("Keydata: %o", keyObj);
            lruInfo("Keys active in the cache: %o", lru.keys());
        },
        delete: (keyObj: object) => lru.delete(generateKey(keyObj)),
        clear: () => lru.clear(),
        keys: () => lru.keys(),
        getInitialState: () =>
            lru.keys().reduce(
                (initialState, key) => ({
                    ...initialState,
                    [key]: getStoredValue(key),
                }),
                {},
            ),
    };
}
