import styled, { css } from "styled-components";
import { transition, bp } from "@styles/theme";
import { AppBar } from "@components/AppBar/AppBar";
import { Footer, FooterMinimal } from "@components/Footer";
import React, { PropsWithChildren } from "react";
import { AppBarMinimal } from "@components/AppBar/AppBarMinimal";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { Path } from "@utils/Path";
import Script from "next/script";

export const linkStyles = css`
    font-weight: 600;
    text-decoration: underline;
    transition: color ${transition.hoverIn};

    &:hover {
        color: ${props => props.theme.color.red};
    }
`;

export const TextButton = styled.button`
    ${linkStyles}
`;

export const underlineLeft = css`
    &::after {
        content: "";
        display: block;
        width: 70px;
        height: 4px;
        background-color: ${props => props.theme.color.red};
        margin-top: 10px;

        ${bp.large} {
            width: 100px;
            height: 5px;
            margin-top: 15px;
        }
    }
`;

export const underlineCenter = css`
    ${underlineLeft}
    &:after {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const UserDataList = styled.div.attrs({ role: "list" })`
    span {
        display: block;
        font-size: 18px;
        margin-bottom: 8px;
    }
`;

export const Main = styled.main`
    flex: 1 0 auto;
`;

export const Layout = ({ children }: PropsWithChildren<{}>) => {
    const router = useRouter();
    const pagesWithBasicAppbar = [
        Path.cartCheckoutData,
        Path.cartPaymentMethods,
        Path.cartCheckoutSubmit,
        Path.serviceCheckoutData,
        Path.servicePaymentMethods,
        Path.serviceCheckoutSubmit,
    ];

    const pagesWithoutFooterNewsletter = () => {
        if (router.asPath.includes(Path.cartOrderConfirmation)) {
            return true;
        }

        if (router.asPath.includes(Path.serviceOrderConfirmation)) {
            return true;
        }

        if (router.asPath.includes(Path.newsletter)) {
            return true;
        }

        return false;
    };

    const isBasicAppbarRoute = pagesWithBasicAppbar.includes(router.asPath);
    const menuItems = useSelector((state: ApplicationState) => state.appData.menuItems);

    return isBasicAppbarRoute ? (
        <>
            <AppBarMinimal />
            <div className="_ce_measure_top">{children}</div>
            <FooterMinimal />
        </>
    ) : (
        <>
            <div id="cts-top" />
            <div className="_ce_measure_top">
                <AppBar menuItems={menuItems} />
                {children}
            </div>
            <div id="cts-bottom" />
            <Footer hideNewsletter={pagesWithoutFooterNewsletter()} />
        </>
    );
};
