import { useState, useEffect } from "react";

export const useScrollbarWidth = () => {
    const [scrollbarWidth, setScrollbarWidth] = useState<number | undefined>(undefined);

    useEffect(() => {
        setScrollbarWidth(window.innerWidth - document.body.offsetWidth);
    }, []);

    return scrollbarWidth;
};
