import { User } from "@api/graphql/types";
import React from "react";
import { Path } from "@utils/Path";

interface Props {
    user: User | null;
    pathname: string;
}

export const CTSData: React.FC<Props> = ({ user, pathname }) => {
    let step = 0;
    if (pathname === Path.cartCheckoutData || pathname === Path.serviceCheckoutData) {
        step = 1;
    }
    if (pathname === Path.cartPaymentMethods || pathname === Path.servicePaymentMethods) {
        step = 2;
    }
    if (pathname === Path.cartCheckoutSubmit || pathname === Path.serviceCheckoutSubmit) {
        step = 3;
    }
    return (
        <script
            id="ceCtsData"
            dangerouslySetInnerHTML={{
                __html: `
                var _ceCTSData = {
                    uID: "${user?.ext_id ?? "0"}",
                    uType: "${user?.is_subscribed_to_newsletter ? "subscribed" : user ? "loggedIn" : "default"}",
                    ${step > 0 ? `step: ${step + 1},` : ""}
                };`,
            }}
        />
    );
};
