/* eslint-disable prettier/prettier */

// tslint:disable: no-hardcoded-credentials no-duplicate-string typedef max-line-length
export const localeHU = {
    "OrderStatus.feeRequest": "Díjbekérve",
    "OrderStatus.rejected": "Elutasítva",
    "common.allright": "Rendben",
    "common.brandDescription": "Ön Magyarország egyik vezető magazinkiadója, a Central Médiacsoport Zrt. előfizetési webáruházának oldalán jár. Az mc.hu oldalon megismerheti valamennyi kiadványunkat és kiválasztott magazinját azonnal meg is rendelheti. Webáruházunkban folyamatosan tájékozódhat kiadónk legfrissebb akcióiról.",
    "common.brandName": "Magazin Centrum",
    "common.cancel": "Mégsem",
    "common.continue": "Folytatás",
    "common.currency": "Ft",
    "common.delete": "Törlés",
    "common.details": "Részletek",
    "common.download": "Letöltés",
    "common.edit": "Szerkesztés",
    "common.grandTotal": "Összesen",
    "common.headTitle": "{title} - Magazin Centrum",
    "common.interested": "Érdekel",
    "common.learnMore": "Bővebben",
    "common.modify1": "Módosítás",
    "common.modify2": "Módosít",
    "common.piece": "{count} db",
    "common.product": "termék",
    "common.profile": "Profil",
    "common.save": "Mentés",
    "common.send": "Küldés",
    "common.send2": "Elküld",
    "common.subscribe": "Feliratkozás",
    "common.switchOff": "Kikapcsolom",
    "common.switchOn": "Bekapcsolom",
    "common.switchedOff": "Kikapcsolva",
    "common.switchedOn": "Bekapcsolva",
    "common.viewAll": "Összes megtekintése »",
    "components.countDown.day": "nap",
    "components.countDown.hour": "óra",
    "components.countDown.minute": "perc",
    "components.countDown.offerValidUntil": "Az ajánlat még érvényes",
    "components.countDown.second": "mp",
    "components.footer.contactDescription": "A hívás díja megegyezik a belföldi vezetékes szám hívásának díjával.",
    "components.footer.contactTitle": "Segítségre van szüksége?",
    "components.footer.copyright": "©{year} mc.hu",
    "components.footer.links.aboutMc": "Central Médiacsoportról",
    "components.footer.links.imprint": "Impresszum",
    "components.footer.links.media": "Médiaajánlat",
    "components.footer.links.privacy": "Adatkezelés",
    "components.footer.links.startlogin": "Startlogin adatkezelés",
    "components.footer.links.terms": "Általános szerződési feltételek",
    "components.footer.madeBy": "Készítette:",
    "components.footer.paymentProviders": "Fizetési lehetőségek:",
    "components.footer.submit": "Feliratkozás",
    "components.footer.subtitle": "Kedvezményekkel és akciókkal várjuk Önt.",
    "components.footer.terms": "Hozzájárulok, hogy a Central Médiacsoport Zrt. Magazin Centrum hírleveleket küldjön számomra, és közvetlen üzletszerzési céllal megkeressen az általam megadott elérhetőségeimen saját vagy üzleti partnerei ajánlatával.",
    "components.footer.termsLabel": "Adatkezelési tájékoztató",
    "components.footer.title": "Iratkozzon fel a legújabb személyre szabott ajánlatokért!",
    "components.magazinesHeader.select.directions.ASC": "ABC szerint növekvő",
    "components.magazinesHeader.select.directions.DESC": "ABC szerint csökkenő",
    "components.menu.SearchCloseButton": "Bezár",
    "components.menu.cart": "Kosár",
    "components.menu.cartMenu.cartContents": "Kosár tartalma",
    "components.menu.cartMenu.close": "Bezárás",
    "components.menu.cartMenu.continueShopping": "Tovább vásárolok",
    "components.menu.cartMenu.count": "{count} db",
    "components.menu.cartMenu.digitalSubscription": "Digitális előfizetés",
    "components.menu.cartMenu.grandTotal": "Fizetendő végösszeg",
    "components.menu.cartMenu.huf": "Ft",
    "components.menu.cartMenu.issue": "lapszám",
    "components.menu.cartMenu.month": "hónap",
    "components.menu.cartMenu.physicalSubscription": "Nyomtatott előfizetés",
    "components.menu.cartMenu.quantity": "tétel",
    "components.menu.cartMenu.title": "Kosár",
    "components.menu.closeButtonLabel": "Menü bezárása",
    "components.menu.customerService.message": "Hívjon minket",
    "components.menu.customerService.openingHours": "H-P 9-15",
    "components.menu.customerService.phoneNumber": "+36 1 510 0888",
    "components.menu.customerService.title": "Ügyfélszolgálat",
    "components.menu.items.digitalSubscriptions": "Digitális előfizetések",
    "components.menu.items.magazines": "Magazinok",
    "components.menu.items.specialOffers": "Akciós ajánlatok",
    "components.menu.items.vouchers": "Ajándékutalványok",
    "components.menu.login": "Bejelentkezés",
    "components.menu.openButtonLabel": "Menü megnyitása",
    "components.menu.search": "Keresés",
    "components.menu.searchClose": "Keresés bezárása",
    "components.menu.searchPlaceholder": "Milyen magazint keres?",
    "components.menu.user.homepage": "Kezdőlapom",
    "components.menu.user.logout": "Kilépés",
    "components.menu.user.ownData": "Saját adataim",
    "components.menu.user.savedCards": "Mentett kártyáim",
    "components.menu.user.shippingAdresses": "Szállítási címeim",
    "components.menu.user.shoppingHistory": "Vásárlási előzményeim",
    "components.notification.closeButton": "Bezárás",
    "components.notification.confirmDelete": "Biztosan törölni szeretnéd a profilodat? Ez a folyamat nem visszafordítható.",
    "components.notification.consentButton": "Megértettem és elfogadom",
    "components.notification.consentedSites": "Regisztrációja törlésével az alábbi oldalakhoz kapcsolódó regisztrációja is törlődik, így azokat nem fogja tudni belépve használni: {sites}",
    "components.notification.deleteAccount": "Fiók végleges törlése",
    "components.notification.deleteAccountButton": "Fiók törlése",
    "components.notification.deleteAccountDescription": "Biztosan törölni szeretnéd a profilodat? Ez a folyamat nem visszafordítható.",
    "components.notification.hasActiveSubscription": "Önnek jelenleg van folyamatban lévő megújuló előfizetése. Kérjük, állítsa le azt a Vásárlásaim / Aktuális előfizetéseim oldalon, utána tudja kezdeményezni fiókja törlését!",
    "components.redirectBlock.button": "Ugrás a digitális tartalomhoz",
    "components.redirectBlock.details": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
    "country.COUNTRY_ANTIGUA": "Antigua",
    "country.COUNTRY_ARGENTINA": "Argentína",
    "country.COUNTRY_ARMENIA": "Örményország",
    "country.COUNTRY_AUSTRALIA": "Ausztrália",
    "country.COUNTRY_AUSTRIA": "Ausztria",
    "country.COUNTRY_BANGLADESH": "Banglades",
    "country.COUNTRY_BELGIUM": "Belgium",
    "country.COUNTRY_BELIZE": "Belize",
    "country.COUNTRY_BRAZIL": "Brazília",
    "country.COUNTRY_BRITISH_VIRGIN_ISLAND": "Brit Virgin-szigetek",
    "country.COUNTRY_BULGARIA": "Bulgária",
    "country.COUNTRY_CAMBODIA": "Kambodzsa",
    "country.COUNTRY_CANADA": "Kanada",
    "country.COUNTRY_CAYMAN_ISLANDS": "Kajmán-szigetek",
    "country.COUNTRY_CHILE": "Chile",
    "country.COUNTRY_CHINA": "Kína",
    "country.COUNTRY_CROATIA": "Horvátország",
    "country.COUNTRY_CURACAO": "Curaçao",
    "country.COUNTRY_CYPRUS": "Ciprus",
    "country.COUNTRY_CZECH_REPUBLIC": "Csehország",
    "country.COUNTRY_DENMARK": "Dánia",
    "country.COUNTRY_ESTONIA": "Észtország",
    "country.COUNTRY_FINLAND": "Finnország",
    "country.COUNTRY_FRANCE": "Franciaország",
    "country.COUNTRY_GABON": "Gabon",
    "country.COUNTRY_GERMANY": "Németország",
    "country.COUNTRY_GIBRALTAR": "Gibraltár",
    "country.COUNTRY_GREECE": "Görögország",
    "country.COUNTRY_GUERNSEY": "Guernsey",
    "country.COUNTRY_HONG_KONG": "Hongkong",
    "country.COUNTRY_HUNGARY": "Magyarország",
    "country.COUNTRY_ICELAND": "Izland",
    "country.COUNTRY_INDIA": "India",
    "country.COUNTRY_INDONESIA": "Indonézia",
    "country.COUNTRY_IRELAND": "Írország",
    "country.COUNTRY_ISLE_OF_MAN": "Man-sziget",
    "country.COUNTRY_ISRAEL": "Izrael",
    "country.COUNTRY_ITALY": "Olaszország",
    "country.COUNTRY_JAPAN": "Japán",
    "country.COUNTRY_KAZAKHSTAN": "Kazahsztán",
    "country.COUNTRY_KOREA": "Korea",
    "country.COUNTRY_KUWAIT": "Kuvait",
    "country.COUNTRY_LATVIA": "Lettország",
    "country.COUNTRY_LEBANON": "Libanon",
    "country.COUNTRY_LIECHTENSTEIN": "Liechtenstein",
    "country.COUNTRY_LITHUANIA": "Litvánia",
    "country.COUNTRY_LUXEMBOURG": "Luxemburg",
    "country.COUNTRY_MALAYSIA": "Malajzia",
    "country.COUNTRY_MALTA": "Málta",
    "country.COUNTRY_MEXICO": "Mexikó",
    "country.COUNTRY_MONTENEGRO": "Montenegró",
    "country.COUNTRY_NETHERLANDS": "Hollandia",
    "country.COUNTRY_NEW_ZEALAND": "Új-Zéland",
    "country.COUNTRY_NIGERIA": "Nigéria",
    "country.COUNTRY_NORWAY": "Norvégia",
    "country.COUNTRY_OMAN": "Omán",
    "country.COUNTRY_PAKISTAN": "Pakisztán",
    "country.COUNTRY_PALESTINE": "Palesztina",
    "country.COUNTRY_PANAMA": "Panama",
    "country.COUNTRY_PERU": "Peru",
    "country.COUNTRY_POLAND": "Lengyelország",
    "country.COUNTRY_PORTUGAL": "Portugália",
    "country.COUNTRY_QATAR": "Katar",
    "country.COUNTRY_ROMANIA": "Románia",
    "country.COUNTRY_RUSSIAN_FEDERATION": "Oroszország",
    "country.COUNTRY_SAN_MARINO": "San Marino",
    "country.COUNTRY_SAUDI_ARABIA": "Szaúd-Arábia",
    "country.COUNTRY_SERBIA": "Szerbia",
    "country.COUNTRY_SEYCHELLES": "Seychelle-szigetek",
    "country.COUNTRY_SINGAPORE": "Szingapúr",
    "country.COUNTRY_SLOVAKIA": "Szlovákia",
    "country.COUNTRY_SLOVENIA": "Szlovénia",
    "country.COUNTRY_SOUTH_AFRICA": "Dél-afrikai Köztársaság",
    "country.COUNTRY_SPAIN": "Spanyolország",
    "country.COUNTRY_SWEDEN": "Svédország",
    "country.COUNTRY_SWITZERLAND": "Svájc",
    "country.COUNTRY_THAILAND": "Thaiföld",
    "country.COUNTRY_TUNISIA": "Tunézia",
    "country.COUNTRY_TURKEY": "Törökország",
    "country.COUNTRY_UKRAINE": "Ukrajna",
    "country.COUNTRY_UNITED_ARAB_EMIRATES": "Egyesült Arab Emírségek",
    "country.COUNTRY_UNITED_KINGDOM": "Egyesült Királyság",
    "country.COUNTRY_URUGUAY": "Uruguay",
    "country.COUNTRY_USA": "Amerikai Egyesült Államok",
    "country.COUNTRY_VIETNAM": "Vietnám",
    "form.addressName.label": "Cím elnevezése",
    "form.billingName.label": "Cím elnevezése",
    "form.buyAsCompany.label": "Cégként vásárolok",
    "form.chooseOtherShipping.label": "Másik kézbesítési címet választok",
    "form.city.label": "Város",
    "form.companyName.label": "Cégnév",
    "form.confirmEmail.label": "E-mail cím megerősítése",
    "form.contactPerson.label": "Kapcsolattartó",
    "form.country.label": "Ország",
    "form.defaultShippingAddress.label": "Alapértelmezett szállítási cím",
    "form.door.label": "Ajtó",
    "form.email.label": "E-mail cím",
    "form.fieldError.country.hungary": "Nyomtatott termék rendelésekor a számlázási címnek magyarországinak kell lennie",
    "form.fieldError.digitalRequired": "Megújuló előfizetés esetén kötelező mező",
    "form.fieldError.email": "Helytelen e-mail cím",
    "form.fieldError.emailMatch": "Az e-mail cím nem egyezik a korábban megadottal",
    "form.fieldError.houseFloor": "Az emelet megadásánál kérjük, használjon arab számokat (pl. 2.) a római számok (pl. II.) helyett.",
    "form.fieldError.houseNumber": "Maximum 15 karakter",
    "form.fieldError.messageMax": "Maximum 1000 karakter",
    "form.fieldError.messageMin": "Minimum 3 karakter",
    "form.fieldError.nameLength": "A névnek minimum 3 karakter hosszúnak kell lennie.",
    "form.fieldError.newPasswordMatch": "Az új jelszó nem egyezhet a jelenlegivel",
    "form.fieldError.passwordLength": "A jelszónak minimum 8 karakternek kell lennie",
    "form.fieldError.passwordMatch": "A jelszavak nem egyeznek",
    "form.fieldError.passwordStrength": "A jelszónak tartalmaznia kell kis- és nagybetűt, számot és speciális karaktert",
    "form.fieldError.phone": "Helytelen telefonszám",
    "form.fieldError.postcode": "Az irányítószám 4 karakter",
    "form.fieldError.postcodeNotValid": "Érvénytelen irányítószám",
    "form.fieldError.postcodeNum": "Az irányítószám csak számokból állhat",
    "form.fieldError.required": "Kötelező mező",
    "form.fieldError.tax_number": "Az adószám nem megfelelő",
    "form.firstname.label": "Keresztnév",
    "form.floor.label": "Emelet",
    "form.gateCode.label": "Kapukód",
    "form.lastname.label": "Vezetéknév",
    "form.message.label": "Üzenet",
    "form.name.label": "Név",
    "form.newBillingAddress.label": "Új számlázási cím hozzáadása",
    "form.otherAddress.label": "Egyéb címadat",
    "form.password.current": "Jelenelegi jelszó",
    "form.password.label": "Jelszó",
    "form.password.medium": "Közepes",
    "form.password.new": "Új jelszó",
    "form.password.newRepeat": "Új jelszó még egyszer",
    "form.password.repeat": "Jelszó még egyszer",
    "form.password.strength": "Jelszó erőssége",
    "form.password.strong": "Erős",
    "form.password.weak": "Gyenge",
    "form.phone.helper": "pl: +36201234567",
    "form.phone.label": "Telefonszám",
    "form.postcode.label": "Irányítószám",
    "form.prefix.label": "Előtag",
    "form.publicSpaceDescription.label": "Közterület jellege",
    "form.publicSpaceName.label": "Közterület neve",
    "form.publicSpaceNumber.label": "Házszám",
    "form.select.chooseAddress": "Válasszon címet",
    "form.select.noOptions": "Nincs találat",
    "form.selectAddress.label": "Cím kiválasztása",
    "form.selectBilling.label": "Cím kiválasztása",
    "form.shippingAddress.label": "Szállítási cím",
    "form.shippingEqualsBilling.label": "Megegyezik a számlázási címmel",
    "form.simple": "Tájékoztatjuk, hogy az Európai Unió erős ügyfél-hitelesítésre, valamint a közös és biztonságos nyílt kommunikációs standardokra vonatkozó szabályozástechnikai standardok bevezetéséről szóló 2018/389. számú irányelve alapján bankkártyás fizetés esetén felhasználó „Megrendelés véglegesítése” gombra kattintással elfogadja, hogy az alábbi személyes adatai átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. A továbbított adatok köre: vezetéknév, keresztnév, szállítási és számlázási cím, e-mail cím. Az adattovábbítás célja: a felhasználók részére történő ügyfélszolgálati segítségnyújtás, a tranzakciók visszaigazolása és a felhasználók védelme érdekében végzett csalásellenőrzés (ún. fraud-monitoring). További információk a SimplePay Adatkezelési tájékoztatóban, az alábbi linken érhetőek el: <simplePrivacyLink>Adatkezelési tájékoztató</simplePrivacyLink>. Az adatátadás szükségességéről tájékoztatás itt található: <simpleFaqLink>Szolgáltatással kapcsolatos kérdések</simpleFaqLink>",
    "form.staircase.label": "Lépcsőház",
    "form.streetAddress.label": "Utca és házszám",
    "form.taxNumber.label": "Adószám",
    "form.terms": "Az <termsLink>Általános Szerződési Feltételeket</termsLink>, beleértve az elállási jogról szóló tájékoztatást, valamint az <privacyLink>Adatkezelési tájékoztatót</privacyLink> megismertem, megértettem és elfogadom.",
    "form.title.label": "Előtag",
    "input.contact.firstname": "Keresztnév",
    "input.contact.lastname": "Vezetéknév",
    "offer.nextTransferDetails": "Előfizetése abban az esetben marad folyamatos, amennyiben az előfizetés díját {date} napig kiegyenlíti.",
    "order.VAT": "Áfa",
    "order.billingAddress": "Számlázási cím",
    "order.billingDetails": "Számlázási adatok",
    "order.details": "Rendelés részletei",
    "order.downloadBill": "Számla letöltése",
    "order.invoice.error": "Hiba történt a számla letöltése közben",
    "order.netPrice": "Nettó",
    "order.number": "Rendelés azonosítója",
    "order.overview": "Rendelés áttekintése",
    "order.paymentMethod": "Fizetési mód",
    "order.personalData": "Saját adatok",
    "order.products": "Termékek",
    "order.shippingAddress": "Szállítási cím",
    "order.status.ended": "Lejárt",
    "order.status.paid": "Fizetve",
    "order.status.pending": "Fizetésre vár",
    "orderStatus.codeAccepted": "Kód elfogadva",
    "orderStatus.completed": "Teljesített",
    "orderStatus.deleted": "Törölt",
    "orderStatus.init": "Folyamatban",
    "orderStatus.markedToCancel": "Lejárt",
    "orderStatus.paymentPending": "Fizetésre vár",
    "orderStatus.paymentReceived": "Fizetve",
    "orderStatus.paymentRefused": "Visszautasított",
    "orderStatus.paymentReminded": "Fizetési emlékeztető küldve",
    "pages.abandonedCart.message": "Elhagyott kosárat belépés után tudja megtekinteni",
    "pages.cart.addFullYearProducts": "Éves előfizetésre váltok",
    "pages.cart.allDiscounts": "Összes kedvezmény",
    "pages.cart.amountToBePaid": "Fizetendő összeg",
    "pages.cart.authModal.alreadyRegistered": "Már regisztrált nálunk?",
    "pages.cart.authModal.apple": "Bejelentkezés Apple fiókkal",
    "pages.cart.authModal.continue": "Folytatás",
    "pages.cart.authModal.emailExists": "Foglalt e-mail cím, kérjük lépjen be vagy adjon meg más e-mail címet!",
    "pages.cart.authModal.facebook": "Bejelentkezés facebook fiókkal",
    "pages.cart.authModal.guestCheckout": "Folytatás új vásárlóként",
    "pages.cart.authModal.guestDescription": "A vásárláshoz nincs szüksége regisztrációra. Amennyiben regisztrál, a profiloldalán elérheti digitális vásárlásait és futó előfizetéseit.",
    "pages.cart.authModal.login": "Bejelentkezés",
    "pages.cart.authModal.loginDescription": "Lépjen be a kényelmes és gyors vásárlásért.",
    "pages.cart.authModal.or": "Vagy",
    "pages.cart.authModal.socialError": "A bejelentkezés során váratlan hiba történt",
    "pages.cart.christmasHeader": "Karácsonyi akció 2 + 1",
    "pages.cart.christmasHeaderInfo": "További részletek",
    "pages.cart.codeLabel": "Írja be a kuponkódot...",
    "pages.cart.continueToBuy": "Tovább vásárolok",
    "pages.cart.couponApplySuccess": "Sikeres kuponbeváltás!",
    "pages.cart.digitalSubscriptionInfo": "{period} lapszám | Digitális előfizetés",
    "pages.cart.emptyCart": "Az Ön kosara üres",
    "pages.cart.emptyCartButton": "Vissza a főoldalra",
    "pages.cart.emptyCartDescription": "az elmentett kosár megtekintéséhez",
    "pages.cart.emptyCartLink": "Jelentkezzen be,",
    "pages.cart.emptyCartSubtitle": "Nem találja az elmentett termékeit?",
    "pages.cart.enterYourCoupon": "Kérjük, írja be a kuponkódját! Egy vásárlásnál egy kupont lehet érvényesíteni. A kuponok nem összevonhatók, ezért ha egyszerre több kuponkód kerül beváltásra, akkor a rendszer automatikusan a kedvezőbbet fogja alkalmazni.",
    "pages.cart.fullYearDescription": "Váltsa előfizetéseit évesre és spóroljon még legalább 20%-ot.",
    "pages.cart.fullYearText": "Egy évre még olcsóbb!",
    "pages.cart.issue": "lapszám",
    "pages.cart.month": "hónap",
    "pages.cart.numberOfPages": "Várható lapszám",
    "pages.cart.order": "Megrendelés",
    "pages.cart.physicalSubscriptionInfo": "{period} lapszám | Nyomtatott előfizetés",
    "pages.cart.quantity": "Tétel",
    "pages.cart.reedem": "Beváltás",
    "pages.cart.reedemCoupon": "Kuponkód beváltása",
    "pages.cart.removeProduct": "Termék eltávolítása",
    "pages.cart.startOfSubscription": "Előfizetés kezdete",
    "pages.cart.subscription": "Előfizetés",
    "pages.cart.subscriptionPlaceholder": "Válasszon",
    "pages.cart.subscriptionTitle": "{title} előfizetés | {period} hónap",
    "pages.cart.title": "Kosár",
    "pages.central.step1.description": "Itt tudja befizetni a Central Médiaakadémia által szervezett {course} részvételi díját. Kérjük, hogy a részvételi díjat csak a Central Médiaakadémia által visszaigazolt jelentkezést követően fizesse be!",
    "pages.central.step1.description:mobile": null,
    "pages.central.step1.description:tablet": null,
    "pages.central.step1.formTitle": "Részvételi díj befizetés",
    "pages.central.step1.helper": "Minden esetben saját személyes postafiókját kérjük megadni, amin a képzésen résztvevő személlyel tudjuk tartani a kapcsolatot!",
    "pages.central.step1.info": "A képzés helyszínéről, időpontjáról és a részvételi feltételekről részletesebb információkat a <link>Central Médiaakadémia</link> odalán olvashat.",
    "pages.central.step1.info:mobile": null,
    "pages.central.step1.info:tablet": null,
    "pages.central.step1.title": "{link}",
    "pages.central.step1.title:mobile": null,
    "pages.central.step1.title:tablet": null,
    "pages.central.step2.contactDetails": "Kapcsolattartási adatok",
    "pages.central.step2.equalsBilling": "Megegyezik a számlázási adatokkal",
    "pages.central.step2.helper": "Kérjük, hogy a számlázási név és cím adatoknál azokat az adatokat adja meg, amelyeket a számlán szeretne megjeleníttetni. (Céges elszámolás esetén ide írja munkahelye, cége adatait.)",
    "pages.central.step2.helper2": "Minden esetben saját személyes postafiókját kérjük megadni, amin a képzésen résztvevő személlyel tudjuk tartani a kapcsolatot!",
    "pages.changePassword.message": "Kérjük, tudatosan válassza meg jelszavát!  <br/><br/>Jó, ha tartalmaz:<br/><ul>  <li> minimum 8 karaktert  <li> legalább egy kis- és nagybetűt  <li> legalább egy számot</ul>",
    "pages.changePassword.title": "Jelszó módosítása",
    "pages.christmas.fullYearDiscount": "Éves előfizetés esetén",
    "pages.christmas.halfYearDiscount": "Fél éves előfizetés esetén",
    "pages.christmas.infoTitle": "Rendelje meg ajándékát 3 egyszerű lépésben",
    "pages.christmas.listPrice": "Listaár",
    "pages.christmas.magazine": "{place}. magazin",
    "pages.christmas.orderButton": "Megrendelés",
    "pages.christmas.packageTitle": "Állítsa össze magazincsomagját!",
    "pages.christmas.saving": "Megtakarítás: {saving}",
    "pages.christmas.selectPlaceholder": "Válasszon",
    "pages.christmas.step1.text": "Adjon magazin-előfizetést karácsonyra, bárkinek talál megfelelő ajándékot.",
    "pages.christmas.step1.title": "Állítsa össze saját csomagját",
    "pages.christmas.step2.text": "Minél hosszabb időszakot választ, annál kedvezőbb ajánlatok között böngészhet.",
    "pages.christmas.step2.title": "Válassza ki az előfizetés hosszát",
    "pages.christmas.step3.text": "Megrendelése után nincs más hátra, csak kényelmesen hátradőlni és várni az ünnepeket.",
    "pages.christmas.step3.title": "Rendelje meg a csomagját",
    "pages.confirm.changePaymentMethod": "Másik fizetési mód",
    "pages.confirm.forgotPasswordSuccess": "Sikeres jelszóemlékeztető igénylés",
    "pages.confirm.giftCard.description": "Töltse le és nyomtassa ki ajándék kisérőkártyánkat a magazin-előfizetéséhez, és szerezzen örömet vele!",
    "pages.confirm.giftCard.title": "Ajándékba vásárolt?",
    "pages.confirm.information": "A leadott rendelést elfogadtuk. A megrendelt nyomtatott termék(ek) a kiválasztott időszaktól kezdve kerülnek kiszállításra. A digitálisan rendelt termékeket a megadott e-mail címre elküldtük.",
    "pages.confirm.information2": "A leadott rendelést fogadtuk. Ha átutalást választott, a fizetéshez szükséges utalási adatokat hamarosan e-mailben elküldjük. Ha csekket választott, postázzuk Önnek, és kérjük, mihamarabb fizesse be azt.",
    "pages.confirm.information3": "Ezúton tájékoztatjuk, az elektronikus számlát elküldtük a megadott e-mail fiókba.",
    "pages.confirm.newsletterSubscribeSuccess": "Sikeres hírlevél feliratkozás",
    "pages.confirm.noTotal": "A leadott rendelést fogadtuk.",
    "pages.confirm.orderNumber": "Megrendelés száma",
    "pages.confirm.paymentInitFailed": "Rendelése létrejött, de fizetési rendszer átmenetileg nem elérhető, kérjük próbálja újra vagy válaszzon más fizetési módot.",
    "pages.confirm.profileContactSuccess": "Sikeres üzenetküldés",
    "pages.confirm.readMagazine": "Belelapozok",
    "pages.confirm.registration.description": "A regisztrációhoz csak a jelszavát kell megadnia, később a megadott e-mail címével tud belépni. Amennyiben regisztrál, későbbiekben a profiloldalán elérheti digitális vásárlásait és futó előfizetéseit megtekintheti.",
    "pages.confirm.registration.title": "Regisztráljon a kényelmes olvasásért",
    "pages.confirm.resetPasswordSuccess": "Sikeres jelszóvisszaállítás",
    "pages.confirm.restart": "Újra próbálom",
    "pages.confirm.simple.cancelled": "Ön megszakította a fizetést!<br />Kérjük válassza ki újra a termékeket és válasszon más fizetési módot.",
    "pages.confirm.simple.failed": "Sikertelen tranzakció.<br />Kérjük válassza ki újra a termékeket és válasszon más fizetési módot. <br />A simple pay tranzakció azonosító: {simple_transaction_id}",
    "pages.confirm.simple.pending": "Kérjük várjon! Ne zárja be az ablakot, és ne navigáljon el a tranzakció visszaigazolásáig. Ez akár 1-2 perc is lehet.",
    "pages.confirm.simple.success": "A tranzakció sikeres volt, megrendelését rögzítettük.<br />Köszönjük a vásárlást!<br />A simple pay tranzakció azonosító: {simple_transaction_id}",
    "pages.confirm.simple.timeout": "Tranzakció időtúllépés miatt megszakadt.<br />Kérjük válassza ki újra a termékeket és válasszon más fizetési módot.<br />A simple pay tranzakció azonosító: {simple_transaction_id}",
    "pages.confirm.successfulOrder": "Sikeres megrendelés",
    "pages.confirm.successfulTransaction": "Sikeres fizetés",
    "pages.confirm.transactionNumber": "Tranzakció száma",
    "pages.confirm.unsuccessfulOrder": "Sikeretelen megrendelés",
    "pages.confirm.unsuccessfulTransaction": "Sikertelen fizetés",
    "pages.confirm.updatePasswordSuccess": "Sikeres jelszóváltoztatás",
    "pages.deleteAccount.deleteButton": "Fiók végleges törlése",
    "pages.deleteAccount.message": "Figyelem, amennyiben törli a fiókját, kijelentkeztetjük, és nem fog tudni többet belépni az oldalra, így futó előfizetéseit sem éri el. A fiók törléssel egyidejűleg a megújuló előfizetéseit leállítjuk. Ha önnek futó időszakos előfizetése van, a fiók törlésével az előfizetés nem áll le.",
    "pages.deleteAccount.title": "Fiók megszüntetése",
    "pages.digitalMagazines.header.lead": "Szórakoztató és hasznos tartalmak bárhol, bármikor. Válasszon digitális termékeink közül!",
    "pages.digitalMagazines.header.subtitle": "Fizessen elő még ma!",
    "pages.digitalMagazines.header.title": "Digitális előfizetések",
    "pages.discountedOffer.button": "Megnézem",
    "pages.discountedOffer.title": "Ajánlatban résztvevő termékek",
    "pages.error.description": "Dolgozunk a hiba elhárításán. Nézz vissza később!",
    "pages.error.title": "Hiba történt!",
    "pages.forgottenPassword.description": "A jelszóemlékeztetőhöz kérjük adja meg az e-mail címét amivel regisztrált. Ezt követően emailben megkapja a linket ami segítségével megadhatja az új jelszavát.",
    "pages.forgottenPassword.title": "Elfelejtett jelszó",
    "pages.home.addedToCartModal.bestOffer": "Legjobb ajánlatunk!",
    "pages.home.addedToCartModal.digitalSubscription": "Digitális előfizetés",
    "pages.home.addedToCartModal.relatedProducts": "Mások ezt vásárolták még",
    "pages.home.addedToCartModal.success": "Sikeresen kosárba került a termék",
    "pages.home.digitalPublicationsLink": "Tovább a digitális előfizetésekre",
    "pages.home.highlightedBundle": "Kiemelt csomagajánlat!",
    "pages.home.highlightedPublicationsLink": "Minden ajánlat megtekintése",
    "pages.home.printedPublicationsLink": "Tovább a magazinokra",
    "pages.home.wideProductCard.addToCart": "Kosárba",
    "pages.home.wideProductCard.addToOfferCart": "Megrendelem",
    "pages.home.wideProductCard.period": "{period} hónap",
    "pages.home.wideProductCard.saving": "Megtakarítás: -{saving}%",
    "pages.login.apple": "Bejelentkezés Apple fiókkal",
    "pages.login.facebook": "Bejelentkezés facebook fiókkal",
    "pages.login.forgottenPassword": "Elfelejtett jelszó",
    "pages.login.or": "Vagy",
    "pages.login.registration.button": "Regisztráció",
    "pages.login.registration.description": "A vásárláshoz nincs szüksége regisztrációra. Amennyiben viszont regisztrál, a későbbiekben a profiloldalán elérheti digitális vásárlásait és futó előfizetéseit is megtekintheti.",
    "pages.login.registration.title": "Nincs még regisztrációja?",
    "pages.login.rememberMe": "Emlékezzen rám",
    "pages.login.title": "Bejelentkezés",
    "pages.magazines.header.lead": "Kapcsolódjon ki magazinjainkkal, vagy <br/>ajándékozzon meg valakit az <br/>olvasás élményével!",
    "pages.magazines.header.subtitle": "Fizessen elő még ma!",
    "pages.magazines.header.title": "Magazinok",
    "pages.magazines.productCard.button": "Megnézem",
    "pages.magazines.productCard.price": "Már {price}-tól",
    "pages.magazines.select.sortByAbc": "Rendezés ABC szerint",
    "pages.newsletter.description": "Ne maradjon le a legjobb ajánlatokról! Hírlevél feliratkozóinknak garantáljuk, hogy első kézből értesülnek újdonságainkról, a kedvezményes előfizetési lehetőségekről, vagy akár a limitált ajándékos ajánlatokról. Iratkozzon fel még ma!",
    "pages.newsletter.title": "Hírlevél feliratkozás",
    "pages.notFound.description": "Nézze meg aktuális ajánlatainkat!",
    "pages.notFound.homeLink": "Tovább az aktuális ajánlatokra",
    "pages.notFound.title": "Sajnos ez az ajánlat már nem elérhető!",
    "pages.offerOrder.title": "Személyre szabott hosszabbítási ajánlat",
    "pages.order.step1.title": "Vásárlás",
    "pages.order.step2.createAccountDescription": "<strong>Megújuló előfizetés vásárlása esetén szükséges a fiók létrehozása.</strong><br />Nyomtatott magazin vásárlása esetén javasoljuk, hogy hozzon létre fiókot, így könnyen nyomon követheti előfizetéseit.",
    "pages.order.step2.createAccountTitle": "Fiók létrehozása",
    "pages.order.step2.newShippingAddress": "Új szállítási cím hozzáadása",
    "pages.order.step2.nextButton": "Tovább a fizetési módra",
    "pages.order.step2.selectShippingAddressWarning": "Válasszon szállítási címet!",
    "pages.order.step2.subscriptionStart": "Előfizetés kezdete",
    "pages.order.step2.title": "Adatok megadása",
    "pages.order.step3.couponButton": "Beváltás",
    "pages.order.step3.couponDescription": "Kérjük itt adja meg a 16 jegyű (kötőjelekkel tagolt) ajándékutalvány kódját!",
    "pages.order.step3.couponTitle": "Fizetés Ajándékutalvánnyal",
    "pages.order.step3.nextButton": "Tovább a megrendelésre",
    "pages.order.step3.payPal": "PayPal",
    "pages.order.step3.placeholder": "Írja be a kódot",
    "pages.order.step3.renewablePayment": "Ismétlődő fizetés a megújuló termékeknél",
    "pages.order.step3.requestInvoice": "Nyomtatott számla postázását kérem",
    "pages.order.step3.simplePay": "Simple pay bankkártya",
    "pages.order.step3.title": "Fizetési mód",
    "pages.order.step3.transfer": "Banki átutalás",
    "pages.order.step3.transferInfo": "Az előfizetése már {start}-től elindul, ha az előfizetési díja {transfer}-ig beérkezik. Amennyiben már van előfizetése, úgy a meglévő előfizetés lejárta után indítjuk el az új előfizetését.",
    "pages.order.step4.edm": "Hozzájárulok, hogy a Central Médiacsoport Zrt. a weboldalakon tanúsított viselkedésem, az általam szolgáltatott adatok és információk alapján a megadott elérhetőségeimen közvetlen üzletszerzési céllal saját vagy üzleti partnerei személyre szabott ajánlataival megkeressen.",
    "pages.order.step4.giftCard": "Ajándékutalvány",
    "pages.order.step4.nextButton": "Megrendelés véglegesítése",
    "pages.order.step4.offerNextButton": "Meghosszabbítom az előfizetésem",
    "pages.order.step4.postInvoice": "Nyomtatott számla postázását kérem",
    "pages.order.step4.productQuantity": "{count} db termék",
    "pages.order.step4.startLoginTerms": "Kijelentem, hogy elmúltam 18 éves, a StartLogin adatkezelési tájékoztatóját és felhasználási feltételeit megismertem és személyes adataim ott leírtak szerinti, különösen a Central Médiacsoport Zrt. szolgáltatásainak egy regisztrációval történő elérésének és a különféle szolgáltatások – beleértve hirdetésmegjelenítések és tartalomajánlások, valamint külön hozzájárulás esetén a közvetlen üzletszerzési célú megkeresések - személyre szabásához, azaz a profilalkotást is magába foglaló kezeléséhez hozzájárulok. A hozzájárulás visszavonása és az adatkezeléssel kapcsolatos tájékoztatás kérés vagy igényérvényesítés a tájékoztatóban írt elérhetőségeken lehetséges.",
    "pages.order.step4.title": "Megrendelés",
    "pages.order.step4.totalDiscount": "Összes kedvezmény",
    "pages.personalData.dataProtection.link": "Jelszó módosítása",
    "pages.personalData.dataProtection.title": "Adatvédelem",
    "pages.personalData.deleteAccount.description": "Figyelem, amennyiben törli a fiókját, kijelentkeztetjük, nem fogja tudni elérni a Startlogin regisztrációhoz kapcsolódó egyéb fiókjait sem (24.hu; noklapja.hu; nosalty.hu). Ha önnek folyamatban lévő megújuló előfizetése van, kérjük, előbb állítsa le azt. Ha önnek futó időszakos előfizetése van, a fiók törlésével az előfizetés nem áll le.",
    "pages.personalData.deleteAccount.link": "Felhasználói fiók törlésének indítása",
    "pages.personalData.deleteAccount.title": "Fiók megszüntetése",
    "pages.personalData.emailInfo": "Kérjük, ellenőrizze a megadott e-mail címet, és javítsa, ha szükséges.",
    "pages.personalData.form.deleteAddressDescription": "Biztosan törlöd a számlázási címet?",
    "pages.personalData.form.deleteBilling": "Számlázási cím törlése",
    "pages.personalData.ownData.billing": "Számlázási adatok",
    "pages.personalData.ownData.personal": "Regisztrációs adatok",
    "pages.personalData.phoneInfo": "A telefonszám megadásával a szállítással kapcsolatos információkat oszthatunk meg önnel.",
    "pages.personalData.title": "Személyes adatok",
    "pages.product.buyNow": "Megvásárolom",
    "pages.product.currentSubscriptionEnd": "Önnek jelenleg van előfizetése erre a termékre eddig a napig: {date}",
    "pages.product.currentSubscriptionEndCart": "Önnek jelenleg van előfizetése erre a termékre eddig a napig: {product}: {date}",
    "pages.product.digitalProduct": "Digitális előfizetés",
    "pages.product.inCart": "Kosárban",
    "pages.product.monthlyPrice": "Havonta csak:",
    "pages.product.printedProduct": "Nyomtatott előfizetés",
    "pages.product.relatedProducts": "Mások ezt vásárolták még",
    "pages.product.uniqueOffer": "Egyedi ajánlat Önnek",
    "pages.profile.home.contact.description": "Amennyiben kérdése, vagy észrevétele lenne, vegye fel velünk a kapcsolatot.",
    "pages.profile.home.contact.title": "Kapcsolatfelvétel",
    "pages.profile.home.notice.description": "Töltse ki a hiányzó adatokat, hogy később könnyedén tudja a megrendelését leadni.",
    "pages.profile.home.notice.link": "Tovább adatok megadásra",
    "pages.profile.home.notice.title": "Tegye teljessé profilját!",
    "pages.profile.home.title": "Kezdőlap",
    "pages.profile.home.uniqueOffers": "Egyedi ajánlatok Önnek",
    "pages.profile.leftMenu.code": "vevőkód",
    "pages.profile.leftMenu.home": "Kezdőlap",
    "pages.profile.leftMenu.logout": "Kilépés",
    "pages.profile.leftMenu.ownData": "Személyes adatok",
    "pages.profile.leftMenu.purchases": "Vásárlásaim",
    "pages.profile.leftMenu.savedCards": "Mentett kártyáim",
    "pages.profile.leftMenu.shippingAddresses": "Szállítási címek",
    "pages.profile.purchases.current": "Aktuális előfizetéseim",
    "pages.profile.purchases.emptyOrders": "Nincs korábbi vásárlása.",
    "pages.profile.purchases.emptyPeriodicSubscriptions": "Nincs magazin előfizetése.",
    "pages.profile.purchases.emptySubscriptions": "Jelenleg nincs elérhető aktív előfizetése.",
    "pages.profile.purchases.past": "Korábbi vásárlásaim",
    "pages.profile.purchases.pendingPayment.cta": "Fizetés",
    "pages.profile.purchases.pendingPayment.label": "Véglegesítse a megrendelését:",
    "pages.profile.purchases.periodic": "Magazin előfizetéseim",
    "pages.profile.purchases.title": "Vásárlásaim",
    "pages.profile.purchases.warning.button": "Cím módosítása",
    "pages.profile.purchases.warning.description": "<strong>Figyelem! Aktív előfizetéséhez tartozó cím módosítását kezdeményezte.</strong> Amennyiben módosítja a szállítási címet a következő lapszámot már a módosult címre küldjük. Biztosan szeretné a címet módosítani?",
    "pages.profile.purchases.warning.title": "Szállítási cím megváltozott",
    "pages.registration.apple": "Bejelentkezés Apple fiókkal",
    "pages.registration.code": "Hol találom a partnerkódom?",
    "pages.registration.codeDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam massa diam, commodo vitae accumsan in, blandit quis neque. Aenean dapibus elit id leo pellentesque, vitae faucibus",
    "pages.registration.description": "A vásárláshoz nincs szüksége regisztrációra. Ha regisztrál, profiloldalán elérheti digitális vásárlásait és futó előfizetéseit is megtekintheti.",
    "pages.registration.emailLabel": "E-mail cím",
    "pages.registration.facebook": "Bejelentkezés facebook fiókkal",
    "pages.registration.firstnameLabel": "Keresztnév",
    "pages.registration.forgottenPassword": "Elfelejtett jelszó",
    "pages.registration.lastnameLabel": "Vezetéknév",
    "pages.registration.login.button": "Bejelentkezés",
    "pages.registration.login.description": "Amennyiben regisztrál, legközelebb egyszerűbben, gyorsabban vásárolhat és profiloldalán futó előfizetéseit is megtekintheti.",
    "pages.registration.login.title": "Már regisztrált?",
    "pages.registration.or": "Vagy",
    "pages.registration.successText": "Köszönjük, hogy regisztráltál az mc.hu-ra!<br/>A regisztrációt megerősítő linket a(z) {email} e-mail címedre küldtük el.",
    "pages.registration.terms": "Kijelentem, hogy elmúltam 18 éves, a <privacyLink>StartLogin adatkezelési tájékoztatóját</privacyLink> és <termsLink>felhasználási feltételeit</termsLink> megismertem és személyes adataim ott leírtak szerinti, különösen a Central Médiacsoport Zrt. szolgáltatásainak egy regisztrációval történő elérésének és a különféle szolgáltatások – beleértve hirdetésmegjelenítések és tartalomajánlások, valamint külön hozzájárulás esetén a közvetlen üzletszerzési célú megkeresések - személyre szabásához, azaz a profilalkotást is magába foglaló kezeléséhez hozzájárulok. A hozzájárulás visszavonása és az adatkezeléssel kapcsolatos tájékoztatás kérés vagy igényérvényesítés a tájékoztatóban írt elérhetőségeken lehetséges.",
    "pages.registration.title": "Regisztráció",
    "pages.registrationConfirm.successful": "Sikeres regisztráció",
    "pages.registrationConfirm.unsuccessful": "Sikertelen regisztráció",
    "pages.renewPage.notVisible": "Az ajánlat lejárt, kérjük válasszon <magazineLink>Magazin</magazineLink> előfizetéseink közül.",
    "pages.resetPassword.description": "A megadott jelszóval tud a későbbiekben bejelentkezni.",
    "pages.resetPassword.title": "Adja meg az új jelszavát",
    "pages.savedCards.active": "active",
    "pages.savedCards.expired": "lejárt",
    "pages.savedCards.message": "A megújuló előfizetések esetén tároljuk a bankkártya adatait. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam massa diam, commodo vitae accumsan in, blandit quis neque. Aenean dapibus elit id leo pellentesque, vitae faucibus nisl posuere.",
    "pages.savedCards.title": "Mentett kártyáim",
    "pages.search.noResult": "A megadott kulcsszóra nincs találat.",
    "pages.shippingAddress.defaultAddress": "Alapértelmezett",
    "pages.shippingAddress.deleteAddress": "Szállítási cím törlése",
    "pages.shippingAddress.deleteAddressButton": "Cím törlése",
    "pages.shippingAddress.deleteAddressDescription": "Biztosan törlöd a szállítási címet?",
    "pages.shippingAddress.newAddress": "Új cím felvétele",
    "pages.shippingAddress.title": "Szállítási címek",
    "periodicSubscription.end": "Előfizetés vége",
    "periodicSubscription.shippingAddress": "Szállítási cím",
    "periodicSubscription.start": "Előfizetés kezdete",
    "subscription.details": "Előfizetés részletei",
    "subscription.end": "Előfizetés lejár",
    "subscription.expireSoon": "Hamarosan lejár!",
    "subscription.hideDetails": "Részletek elrejtése",
    "subscription.purchaseDate": "Vásárlás dátuma",
    "subscription.renew": "Előfizetés megújítása",
    "subscription.renew.info": "Aktív előfizetés esetén a megújulási dátumon automatikusan meghosszabbítjuk előfizetését.",
    "subscription.renewAutomatically": "Előfizetés automatikus megújulása",
    "subscription.renewDate": "Megújítás dátuma",
    "subscription.renewSubscription": "Újítsa meg előfizetését kedvezményesen!",
    "subscription.renewSubscriptionNow": "Újítsa meg előfizetését most kedvezményesen!",
    "subscription.start": "Előfizetés kezdete",
    "subscription.startingIssue": "Kezdő lapszám",
    "subscription.stop.button": "Előfizetés leállítása",
    "subscription.stop.confirm": "Szeretné leállítani előfizetését?",
    "subscription.stop.text": "<strong>Figyelem! Jelenleg aktív előfizetésének leállítását kezdeményezte.</strong> Amennyiben megújuló előfizetését leállítja, szolgáltatását az előfizetési periódus lejáratáig éri el. Nyomtatott magazin estén pedig a megújítási periódus végig kapja meg a lapszámokat.",
    "subscription.stop.title": "Előfizetés leállítása"
};
