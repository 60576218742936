import { Reducer, combineReducers } from "redux";
import authReducer, { AuthReducerType, initialState as authInitialState } from "@redux/reducers/AuthReducer";
import cartReducer, { CartReducerType, initialState as cartInitialState } from "@redux/reducers/CartReducer";
import appDataReducer, { AppDataReducerType, initialState as appDataInitialState } from "@redux/reducers/AppDataReducer";
import CheckoutReducer, { CheckoutReducerType, initialState as checkoutInitialState } from "./CheckoutReducer";

export interface ApplicationState {
    auth: AuthReducerType;
    cart: CartReducerType;
    checkout: CheckoutReducerType;
    appData: AppDataReducerType;
}

export const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    auth: authReducer,
    cart: cartReducer,
    checkout: CheckoutReducer,
    appData: appDataReducer,
});

export const reduxInitialState: ApplicationState = Object.freeze({
    auth: authInitialState,
    cart: cartInitialState,
    checkout: checkoutInitialState,
    appData: appDataInitialState,
});
