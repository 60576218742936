import {
    AnyCartItem,
    AnyProduct,
    ProductBundle,
    ProductDigital,
    ProductDigitalService,
    ProductPrint,
    ProductPrintIssue,
    ProductService,
    Publication,
} from "./types";

export type NormalProduct = ProductPrint | ProductDigital | ProductPrintIssue | ProductBundle | ProductDigitalService;

export const productIsDigital = (product: AnyProduct): product is ProductDigital => {
    return product.__typename === "ProductDigital" || product.__typename === "ProductDigitalService";
};

export const productIsBundle = (product: AnyProduct): product is ProductBundle => {
    return product.__typename === "ProductBundle";
};

export const bundleGotDigitalProduct = (product: AnyProduct) => {
    return product.__typename === "ProductBundle" && product.products.some(pr => pr.__typename === "ProductDigital");
};

export const productIsPrint = (product: AnyProduct): product is ProductPrint => {
    return product.__typename === "ProductPrint" || product.__typename === "ProductPrintIssue";
};

export const publicationIsDigital = (publication: Publication) => {
    return publication.digital_products.length > 0 && publication.products.length === 0;
};

export const productIsService = (product: AnyProduct): product is ProductService => {
    return product.__typename === "ProductService";
};
