import { getCTSURLFromPage } from "@utils/Path";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const CTSLoader = () => {
    const router = useRouter();

    useEffect(() => {
        router.events.on("routeChangeComplete", initCTSPoints);
        // router.events.on("routeChangeStart", removeCTSPoints);
        initCTSPoints();
        return () => {
            router.events.off("routeChangeComplete", initCTSPoints);
            // router.events.off("routeChangeStart", removeCTSPoints);
        };
    }, []);

    // After changing the route we need to remove the previously added data-cts-tag,
    // so we can initiate again the tagging.renderAsync call when the new route loads
    const removeCTSPoints = () => {
        try {
            document.querySelector("#cts-top")?.removeAttribute("data-cts-tag");
            document.querySelector("#cts-bottom")?.removeAttribute("data-cts-tag");
            document.querySelector("#cts-list-content-top")?.removeAttribute("data-cts-tag");
            document.querySelector("#cts-body-end-adv")?.removeAttribute("data-cts-tag");
            document.querySelector("#cts-top")?.replaceChildren();
            document.querySelector("#cts-bottom")?.replaceChildren();
            document.querySelector("#cts-list-content-top")?.replaceChildren();
            document.querySelector("#cts-body-end-adv")?.replaceChildren();
        } catch (err) {
            console.log(err);
        }
    };

    // On page load we try to find the divs with given ids and render tagging lib to it
    // If it succeeds, a new data-cts-tag attribute shows up in the given element
    const initCTSPoints = (route?: string | undefined) => {
        try {
            removeCTSPoints();
            if (route) {
                const newCTSUrl = getCTSURLFromPage(route);
                if (newCTSUrl) {
                    const script = document.createElement("script");
                    script.src = newCTSUrl;
                    script.onload = () => {
                        tagging = null;
                        tagging = new Tagging();
                        tagging.renderAsync("top", "#cts-top");
                        tagging.renderAsync("bottom", "#cts-bottom");
                        tagging.renderAsync("list-content-top", "#cts-list-content-top");
                        tagging.renderAsync("body-end-adv", "#cts-body-end-adv");
                    };
                    document.head.appendChild(script);
                    return;
                }
            }
            if (tagging) {
                tagging.renderAsync("top", "#cts-top");
                tagging.renderAsync("bottom", "#cts-bottom");
                tagging.renderAsync("list-content-top", "#cts-list-content-top");
                tagging.renderAsync("body-end-adv", "#cts-body-end-adv");
                console.log("tagging rendered");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return null;
};
