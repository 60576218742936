import { NormalProduct } from "@api/graphql/typeHelpers";

export class Path {
    public static readonly home = "/";
    public static readonly login = "/bejelentkezes";
    public static readonly register = "/regisztracio";
    public static readonly emailConfirm = "/email-megerosites";
    public static readonly forgotPassword = "/elfelejtett-jelszo";
    public static readonly discountedOffers = "/aktualis-ajanlatok";
    public static readonly christmasOffer = "/karacsonyi-ajanlat";
    public static readonly newsletter = "/hirlevel";
    public static readonly profileHome = "/profil/kezdolapom";
    public static readonly profileOwnData = "/profil/szemelyes-adatok";
    public static readonly profileChangePassword = "/profil/szemelyes-adatok/jelszo-modositasa";
    public static readonly profileDeleteAccount = "/profil/szemelyes-adatok/fiok-megszuntetese";
    public static readonly profileShipping = "/profil/szallitasi-cimek";
    public static readonly profileShopping = "/profil/vasarlasaim";
    public static readonly profileSavedCards = "/profil/mentett-kartyaim";
    public static readonly success = "/megerosites";
    public static readonly magazines = "/magazinok";
    public static readonly currentOffers = "/aktualis-ajanlatok";
    public static readonly digitalMagazines = "/digitalis-elofizetesek";
    public static readonly product = "/termek";
    public static readonly cart = "/kosar";
    public static readonly cartCheckoutData = "/kosar/adatok-megadasa";
    public static readonly cartPaymentMethods = "/kosar/fizetesi-mod";
    public static readonly cartCheckoutSubmit = "/kosar/megrendeles";
    public static readonly offerCheckoutSubmit = "/ajanlat/megrendeles";
    public static readonly cartOrder = "/kosar/rendeles";
    public static readonly cartOrderConfirmation = "/kosar/megrendeles-visszaigazolas";
    public static readonly service = "/central-akademia";
    public static readonly serviceCheckoutStart = `${Path.service}/vasarlas`;
    public static readonly serviceCheckoutData = `${Path.service}/adatok-megadasa`;
    public static readonly servicePaymentMethods = `${Path.service}/fizetesi-mod`;
    public static readonly serviceCheckoutSubmit = `${Path.service}/megrendeles`;
    public static readonly serviceCartOrder = `${Path.service}/rendeles`;
    public static readonly serviceOrderConfirmation = `${Path.service}/megrendeles-visszaigazolas`;
    public static readonly search = (searchTerm: string) => `/kereses${searchTerm !== "" ? `/${searchTerm}` : ""}`;
    public static readonly article = (articleSlug: string) => `/cikkek/${articleSlug}`;
    public static readonly terms = "/cikkek/aszf";
    public static readonly privacyPolicy = "/cikkek/adatkezeles";
    public static readonly imprint = "/cikkek/impresszum";
    public static readonly paymentFailed = (code: string) => `/sikertelen-fizetes/${code}`;
}

export const ExternalPath = {
    customerService: "https://ugyfelkapu.mc.hu",
    fb: "https://www.facebook.com/magazincentrum.hu",
    about: "https://centralmediacsoport.hu/rolunk/",
    mediaOffer: "http://www.cmsales.hu/",
    startLogin: "https://startlogin.hu/privacy-policy",
    bigfish: "https://bigfish.hu",
    simplePayPrivacy: "https://simplepay.hu/adatkezelesi-tajekoztatok/",
    simplePayFaq: "https://simplepay.hu/gyik/",
};

export const BasePath = "https://www.mc.hu";

export const getProductPageLinkParams = (product: NormalProduct) => {
    switch (product.__typename) {
        case "ProductDigital":
            return {
                pathname: `${Path.product}/${product.publication.short_name}`,
                query: { id: product.publication.id },
            };
        case "ProductPrint": {
            return {
                pathname: `${Path.product}/${product.publication.short_name}`,
                query: { id: product.publication.id },
            };
        }
        case "ProductPrintIssue": {
            return {
                pathname: `${Path.product}/${product.publication.short_name}`,
                query: { id: product.publication.id },
            };
        }
        case "ProductBundle": {
            return {
                pathname: `${Path.product}/`,
            };
        }
        default:
            return {
                pathname: "/",
            };
    }
};

export const CTSUrls = {
    default: "//cts.p24.hu/service/js/mc2/default/",
    mainpage: "//cts.p24.hu/service/js/mc2/nyitooldal/",
    magazines: "//cts.p24.hu/service/js/mc2/magazinok/",
    digitalMagazines: "//cts.p24.hu/service/js/mc2/digitalis-elofizetesek/",
    discountedOffers: "//cts.p24.hu/service/js/mc2/akcios-ajanlatok/",
    clubsmart: "//cts.p24.hu/service/js/mc2/clubsmart/",
    search: "//cts.p24.hu/service/js/mc2/kereses/",
    login: "//cts.p24.hu/service/js/mc2/bejelentkezes/",
    cart: "//cts.p24.hu/service/js/mc2/kosar/",
    profile: "//cts.p24.hu/service/js/mc2/profil/",
};

export const getCTSURLFromPage = (page: string, asPath: string = "") => {
    switch (page) {
        case "/":
            return CTSUrls.mainpage;
        case Path.magazines:
            return CTSUrls.magazines;
        case Path.digitalMagazines:
            return CTSUrls.digitalMagazines;
        case Path.discountedOffers:
            return CTSUrls.discountedOffers;
        case Path.article("[url]"): {
            if (asPath === Path.article("smart")) {
                return CTSUrls.clubsmart;
            }
            break;
        }
        case Path.search(""):
        case Path.search("[searchTerm]"):
            return CTSUrls.search;
        case Path.login:
            return CTSUrls.login;
        case Path.cart:
            return CTSUrls.cart;
        case Path.profileChangePassword:
        case Path.profileDeleteAccount:
        case Path.profileHome:
        case Path.profileOwnData:
        case Path.profileSavedCards:
        case Path.profileShipping:
        case Path.profileShopping:
            return CTSUrls.profile;
        default: {
            return CTSUrls.default;
        }
    }
};
