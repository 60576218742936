import { Cart, AnyProduct } from "@api/graphql/types";
import { ActionsUnion } from "typings/declarations";
import { createAction } from "./actionHelpers";

export enum CartActionTypes {
    updateCart = "@cart/updateCart",
    updateCarts = "@cart/updateCarts",
    updateRecommendedProducts = "@cart/updateRecommendedProducts",
}

export const CartActions = {
    updateCart: (cart: Cart) => createAction(CartActionTypes.updateCart, { cart }),
    updateCarts: (carts: Cart[]) => createAction(CartActionTypes.updateCarts, { carts }),
    updateRecommendedProducts: (recommendedProducts: AnyProduct[]) => createAction(CartActionTypes.updateRecommendedProducts, { recommendedProducts }),
};

export type CartActions = ActionsUnion<typeof CartActions>;
