import { default as NextLink } from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { transition } from "@styles/theme";

interface LinkProps {
    href: string;
    label: string | React.ReactNode;
    weight?: number;
    className?: string;
    disabled?: boolean;
}

export const LinkComponent = ({ href, label, weight, className, disabled }: LinkProps) => {
    return (
        <NextLink href={href}>
            <StyledLink className={className} $weight={weight} $disabled={disabled}>
                {label}
            </StyledLink>
        </NextLink>
    );
};

const StyledLink = styled.a<{ $disabled?: boolean; $weight?: number }>`
    position: relative;

    ${props =>
        props.$weight &&
        css`
            font-weight: ${props.$weight};
        `}

    ${props =>
        props.$disabled &&
        css`
            color: ${props => props.theme.color.grayL} !important;
            pointer-events: none;
        `};
`;

export const Link = styled(LinkComponent)`
    position: relative;
    color: ${props => props.theme.color.grayD};
    font-size: inherit;
    text-decoration: underline;
    transition: color ${transition.hoverOut};

    &:hover {
        color: ${props => props.theme.color.red};
    }
`;
