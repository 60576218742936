/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userLogin
// ====================================================

export interface userLogin_userLogin_user_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userLogin_userLogin_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userLogin_userLogin_user_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userLogin_userLogin_user_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userLogin_userLogin_user_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userLogin_userLogin_user_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userLogin_userLogin_user_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface userLogin_userLogin_user_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLogin_userLogin_user_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userLogin_userLogin_user_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface userLogin_userLogin_user_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: userLogin_userLogin_user_orders_items_OrderItem_product_publication_default_product | null;
}

export interface userLogin_userLogin_user_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userLogin_userLogin_user_orders_items_OrderItem_product_price | null;
  publication: userLogin_userLogin_user_orders_items_OrderItem_product_publication | null;
}

export interface userLogin_userLogin_user_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLogin_userLogin_user_orders_items_OrderItem {
  __typename: "OrderItem";
  product: userLogin_userLogin_user_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userLogin_userLogin_user_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userLogin_userLogin_user_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLogin_userLogin_user_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: userLogin_userLogin_user_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userLogin_userLogin_user_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type userLogin_userLogin_user_orders_items = userLogin_userLogin_user_orders_items_OrderItem | userLogin_userLogin_user_orders_items_OrderItemBundle;

export interface userLogin_userLogin_user_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface userLogin_userLogin_user_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: userLogin_userLogin_user_orders_batch_latest_transaction | null;
}

export interface userLogin_userLogin_user_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: userLogin_userLogin_user_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: userLogin_userLogin_user_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: userLogin_userLogin_user_orders_items[];
  batch: userLogin_userLogin_user_orders_batch;
}

export interface userLogin_userLogin_user {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: userLogin_userLogin_user_shipping_addresses[];
  billing_address: userLogin_userLogin_user_billing_address | null;
  orders: userLogin_userLogin_user_orders[];
  offer_ids: string[] | null;
}

export interface userLogin_userLogin {
  __typename: "UserAuthenticationResult";
  token: string;
  user: userLogin_userLogin_user | null;
}

export interface userLogin {
  userLogin: userLogin_userLogin;
}

export interface userLoginVariables {
  credentials: UserLoginDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userLoginSocial
// ====================================================

export interface userLoginSocial_userLoginSocial_user_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userLoginSocial_userLoginSocial_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userLoginSocial_userLoginSocial_user_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userLoginSocial_userLoginSocial_user_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userLoginSocial_userLoginSocial_user_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userLoginSocial_userLoginSocial_user_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product_publication_default_product | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product_price | null;
  publication: userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product_publication | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItem {
  __typename: "OrderItem";
  product: userLoginSocial_userLoginSocial_user_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userLoginSocial_userLoginSocial_user_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type userLoginSocial_userLoginSocial_user_orders_items = userLoginSocial_userLoginSocial_user_orders_items_OrderItem | userLoginSocial_userLoginSocial_user_orders_items_OrderItemBundle;

export interface userLoginSocial_userLoginSocial_user_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface userLoginSocial_userLoginSocial_user_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: userLoginSocial_userLoginSocial_user_orders_batch_latest_transaction | null;
}

export interface userLoginSocial_userLoginSocial_user_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: userLoginSocial_userLoginSocial_user_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: userLoginSocial_userLoginSocial_user_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: userLoginSocial_userLoginSocial_user_orders_items[];
  batch: userLoginSocial_userLoginSocial_user_orders_batch;
}

export interface userLoginSocial_userLoginSocial_user {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: userLoginSocial_userLoginSocial_user_shipping_addresses[];
  billing_address: userLoginSocial_userLoginSocial_user_billing_address | null;
  orders: userLoginSocial_userLoginSocial_user_orders[];
  offer_ids: string[] | null;
}

export interface userLoginSocial_userLoginSocial {
  __typename: "UserAuthenticationResult";
  token: string;
  user: userLoginSocial_userLoginSocial_user | null;
}

export interface userLoginSocial {
  userLoginSocial: userLoginSocial_userLoginSocial;
}

export interface userLoginSocialVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userRegister
// ====================================================

export interface userRegister {
  userRegister: boolean;
}

export interface userRegisterVariables {
  input: UserRegisterDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userVerifyEmail
// ====================================================

export interface userVerifyEmail {
  userVerifyEmail: boolean;
}

export interface userVerifyEmailVariables {
  email: string;
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userUpdate
// ====================================================

export interface userUpdate_userUpdate_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userUpdate_userUpdate_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userUpdate_userUpdate_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userUpdate_userUpdate_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userUpdate_userUpdate_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userUpdate_userUpdate_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userUpdate_userUpdate_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface userUpdate_userUpdate_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userUpdate_userUpdate_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userUpdate_userUpdate_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface userUpdate_userUpdate_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: userUpdate_userUpdate_orders_items_OrderItem_product_publication_default_product | null;
}

export interface userUpdate_userUpdate_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userUpdate_userUpdate_orders_items_OrderItem_product_price | null;
  publication: userUpdate_userUpdate_orders_items_OrderItem_product_publication | null;
}

export interface userUpdate_userUpdate_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userUpdate_userUpdate_orders_items_OrderItem {
  __typename: "OrderItem";
  product: userUpdate_userUpdate_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userUpdate_userUpdate_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userUpdate_userUpdate_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userUpdate_userUpdate_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: userUpdate_userUpdate_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userUpdate_userUpdate_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type userUpdate_userUpdate_orders_items = userUpdate_userUpdate_orders_items_OrderItem | userUpdate_userUpdate_orders_items_OrderItemBundle;

export interface userUpdate_userUpdate_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface userUpdate_userUpdate_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: userUpdate_userUpdate_orders_batch_latest_transaction | null;
}

export interface userUpdate_userUpdate_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: userUpdate_userUpdate_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: userUpdate_userUpdate_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: userUpdate_userUpdate_orders_items[];
  batch: userUpdate_userUpdate_orders_batch;
}

export interface userUpdate_userUpdate {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: userUpdate_userUpdate_shipping_addresses[];
  billing_address: userUpdate_userUpdate_billing_address | null;
  orders: userUpdate_userUpdate_orders[];
  offer_ids: string[] | null;
}

export interface userUpdate {
  userUpdate: userUpdate_userUpdate;
}

export interface userUpdateVariables {
  input: UserUpdateDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userDelete
// ====================================================

export interface userDelete_userDelete_user_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userDelete_userDelete_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userDelete_userDelete_user_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userDelete_userDelete_user_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userDelete_userDelete_user_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userDelete_userDelete_user_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userDelete_userDelete_user_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface userDelete_userDelete_user_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userDelete_userDelete_user_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userDelete_userDelete_user_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface userDelete_userDelete_user_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: userDelete_userDelete_user_orders_items_OrderItem_product_publication_default_product | null;
}

export interface userDelete_userDelete_user_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userDelete_userDelete_user_orders_items_OrderItem_product_price | null;
  publication: userDelete_userDelete_user_orders_items_OrderItem_product_publication | null;
}

export interface userDelete_userDelete_user_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userDelete_userDelete_user_orders_items_OrderItem {
  __typename: "OrderItem";
  product: userDelete_userDelete_user_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userDelete_userDelete_user_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userDelete_userDelete_user_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userDelete_userDelete_user_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: userDelete_userDelete_user_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userDelete_userDelete_user_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type userDelete_userDelete_user_orders_items = userDelete_userDelete_user_orders_items_OrderItem | userDelete_userDelete_user_orders_items_OrderItemBundle;

export interface userDelete_userDelete_user_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface userDelete_userDelete_user_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: userDelete_userDelete_user_orders_batch_latest_transaction | null;
}

export interface userDelete_userDelete_user_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: userDelete_userDelete_user_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: userDelete_userDelete_user_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: userDelete_userDelete_user_orders_items[];
  batch: userDelete_userDelete_user_orders_batch;
}

export interface userDelete_userDelete_user {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: userDelete_userDelete_user_shipping_addresses[];
  billing_address: userDelete_userDelete_user_billing_address | null;
  orders: userDelete_userDelete_user_orders[];
  offer_ids: string[] | null;
}

export interface userDelete_userDelete {
  __typename: "UserAuthenticationResult";
  token: string;
  user: userDelete_userDelete_user | null;
}

export interface userDelete {
  userDelete: userDelete_userDelete;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userShippingAddressCreate
// ====================================================

export interface userShippingAddressCreate_userShippingAddressCreate_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userShippingAddressCreate_userShippingAddressCreate {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userShippingAddressCreate_userShippingAddressCreate_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userShippingAddressCreate {
  userShippingAddressCreate: userShippingAddressCreate_userShippingAddressCreate;
}

export interface userShippingAddressCreateVariables {
  address?: UserShippingAddressInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userShippingAddressUpdate
// ====================================================

export interface userShippingAddressUpdate_userShippingAddressUpdate_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userShippingAddressUpdate_userShippingAddressUpdate {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userShippingAddressUpdate_userShippingAddressUpdate_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userShippingAddressUpdate {
  userShippingAddressUpdate: userShippingAddressUpdate_userShippingAddressUpdate;
}

export interface userShippingAddressUpdateVariables {
  id: number;
  address?: UserShippingAddressInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userShippingAddressDelete
// ====================================================

export interface userShippingAddressDelete {
  userShippingAddressDelete: boolean;
}

export interface userShippingAddressDeleteVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendUserContact
// ====================================================

export interface sendUserContact {
  sendUserContact: boolean | null;
}

export interface sendUserContactVariables {
  input: UserContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userLogout
// ====================================================

export interface userLogout_userLogout_user {
  __typename: "User";
  id: number | null;
}

export interface userLogout_userLogout {
  __typename: "UserAuthenticationResult";
  token: string;
  user: userLogout_userLogout_user | null;
}

export interface userLogout {
  userLogout: userLogout_userLogout;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userPasswordReset
// ====================================================

export interface userPasswordReset {
  userPasswordReset: GqlVoid | null;
}

export interface userPasswordResetVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetUserPassword
// ====================================================

export interface resetUserPassword {
  resetUserPassword: boolean;
}

export interface resetUserPasswordVariables {
  input: SetUserPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userPasswordUpdate
// ====================================================

export interface userPasswordUpdate {
  userPasswordUpdate: boolean | null;
}

export interface userPasswordUpdateVariables {
  input: UserPasswordUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cartAddProduct
// ====================================================

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddProduct_cartAddProduct_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products = cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_price;
  products: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemNormal_product = cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductService | cartAddProduct_cartAddProduct_items_CartItemNormal_product_ProductDigitalService;

export interface cartAddProduct_cartAddProduct_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddProduct_cartAddProduct_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: cartAddProduct_cartAddProduct_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: cartAddProduct_cartAddProduct_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddProduct_cartAddProduct_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products = cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_price;
  products: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemChristmas_product = cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductService | cartAddProduct_cartAddProduct_items_CartItemChristmas_product_ProductDigitalService;

export interface cartAddProduct_cartAddProduct_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddProduct_cartAddProduct_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: cartAddProduct_cartAddProduct_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: cartAddProduct_cartAddProduct_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products = cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product = cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductService | cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemBundle_product_products = cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemBundle_product_products_ProductService;

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemBundle_product_price;
  products: cartAddProduct_cartAddProduct_items_CartItemBundle_product_products[];
  bundlePublication: cartAddProduct_cartAddProduct_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddProduct_cartAddProduct_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: cartAddProduct_cartAddProduct_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: cartAddProduct_cartAddProduct_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: cartAddProduct_cartAddProduct_items_CartItemBundle_product;
  shipping_address: cartAddProduct_cartAddProduct_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddProduct_cartAddProduct_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products = cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_price;
  products: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemGratis_product = cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductService | cartAddProduct_cartAddProduct_items_CartItemGratis_product_ProductDigitalService;

export interface cartAddProduct_cartAddProduct_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddProduct_cartAddProduct_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: cartAddProduct_cartAddProduct_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: cartAddProduct_cartAddProduct_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddProduct_cartAddProduct_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products = cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_price;
  products: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddProduct_cartAddProduct_items_CartItemOffer_product = cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrint | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductBundle | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductPrintIssue | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigital | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductService | cartAddProduct_cartAddProduct_items_CartItemOffer_product_ProductDigitalService;

export interface cartAddProduct_cartAddProduct_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddProduct_cartAddProduct_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: cartAddProduct_cartAddProduct_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: cartAddProduct_cartAddProduct_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type cartAddProduct_cartAddProduct_items = cartAddProduct_cartAddProduct_items_CartItemNormal | cartAddProduct_cartAddProduct_items_CartItemChristmas | cartAddProduct_cartAddProduct_items_CartItemBundle | cartAddProduct_cartAddProduct_items_CartItemGratis | cartAddProduct_cartAddProduct_items_CartItemOffer;

export interface cartAddProduct_cartAddProduct {
  __typename: "Cart";
  type: CartType;
  items: cartAddProduct_cartAddProduct_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface cartAddProduct {
  cartAddProduct: cartAddProduct_cartAddProduct;
}

export interface cartAddProductVariables {
  product_id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cartRemove
// ====================================================

export interface cartRemove_cartRemove_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartRemove_cartRemove_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartRemove_cartRemove_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products = cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductBundle | cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrint | cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductDigital | cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_price;
  products: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemNormal_product = cartRemove_cartRemove_items_CartItemNormal_product_ProductPrint | cartRemove_cartRemove_items_CartItemNormal_product_ProductBundle | cartRemove_cartRemove_items_CartItemNormal_product_ProductPrintIssue | cartRemove_cartRemove_items_CartItemNormal_product_ProductDigital | cartRemove_cartRemove_items_CartItemNormal_product_ProductService | cartRemove_cartRemove_items_CartItemNormal_product_ProductDigitalService;

export interface cartRemove_cartRemove_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartRemove_cartRemove_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: cartRemove_cartRemove_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: cartRemove_cartRemove_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartRemove_cartRemove_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products = cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_price;
  products: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemChristmas_product = cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrint | cartRemove_cartRemove_items_CartItemChristmas_product_ProductBundle | cartRemove_cartRemove_items_CartItemChristmas_product_ProductPrintIssue | cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigital | cartRemove_cartRemove_items_CartItemChristmas_product_ProductService | cartRemove_cartRemove_items_CartItemChristmas_product_ProductDigitalService;

export interface cartRemove_cartRemove_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartRemove_cartRemove_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: cartRemove_cartRemove_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: cartRemove_cartRemove_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartRemove_cartRemove_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products = cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemBundle_cartItems_product = cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrint | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductBundle | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductPrintIssue | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigital | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductService | cartRemove_cartRemove_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface cartRemove_cartRemove_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: cartRemove_cartRemove_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: cartRemove_cartRemove_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: cartRemove_cartRemove_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemBundle_product_products = cartRemove_cartRemove_items_CartItemBundle_product_products_ProductBundle | cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrint | cartRemove_cartRemove_items_CartItemBundle_product_products_ProductPrintIssue | cartRemove_cartRemove_items_CartItemBundle_product_products_ProductDigital | cartRemove_cartRemove_items_CartItemBundle_product_products_ProductService;

export interface cartRemove_cartRemove_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartRemove_cartRemove_items_CartItemBundle_product_price;
  products: cartRemove_cartRemove_items_CartItemBundle_product_products[];
  bundlePublication: cartRemove_cartRemove_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartRemove_cartRemove_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartRemove_cartRemove_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartRemove_cartRemove_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: cartRemove_cartRemove_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: cartRemove_cartRemove_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: cartRemove_cartRemove_items_CartItemBundle_product;
  shipping_address: cartRemove_cartRemove_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartRemove_cartRemove_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartRemove_cartRemove_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products = cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductBundle | cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrint | cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductDigital | cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_price;
  products: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemGratis_product = cartRemove_cartRemove_items_CartItemGratis_product_ProductPrint | cartRemove_cartRemove_items_CartItemGratis_product_ProductBundle | cartRemove_cartRemove_items_CartItemGratis_product_ProductPrintIssue | cartRemove_cartRemove_items_CartItemGratis_product_ProductDigital | cartRemove_cartRemove_items_CartItemGratis_product_ProductService | cartRemove_cartRemove_items_CartItemGratis_product_ProductDigitalService;

export interface cartRemove_cartRemove_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartRemove_cartRemove_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: cartRemove_cartRemove_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: cartRemove_cartRemove_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartRemove_cartRemove_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartRemove_cartRemove_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products = cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductBundle | cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrint | cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductDigital | cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_price;
  products: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartRemove_cartRemove_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartRemove_cartRemove_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartRemove_cartRemove_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartRemove_cartRemove_items_CartItemOffer_product = cartRemove_cartRemove_items_CartItemOffer_product_ProductPrint | cartRemove_cartRemove_items_CartItemOffer_product_ProductBundle | cartRemove_cartRemove_items_CartItemOffer_product_ProductPrintIssue | cartRemove_cartRemove_items_CartItemOffer_product_ProductDigital | cartRemove_cartRemove_items_CartItemOffer_product_ProductService | cartRemove_cartRemove_items_CartItemOffer_product_ProductDigitalService;

export interface cartRemove_cartRemove_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartRemove_cartRemove_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: cartRemove_cartRemove_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: cartRemove_cartRemove_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type cartRemove_cartRemove_items = cartRemove_cartRemove_items_CartItemNormal | cartRemove_cartRemove_items_CartItemChristmas | cartRemove_cartRemove_items_CartItemBundle | cartRemove_cartRemove_items_CartItemGratis | cartRemove_cartRemove_items_CartItemOffer;

export interface cartRemove_cartRemove {
  __typename: "Cart";
  type: CartType;
  items: cartRemove_cartRemove_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface cartRemove {
  cartRemove: cartRemove_cartRemove;
}

export interface cartRemoveVariables {
  cart_item_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cartFlush
// ====================================================

export interface cartFlush_cartFlush_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartFlush_cartFlush_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartFlush_cartFlush_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products = cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductBundle | cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrint | cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductDigital | cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_price;
  products: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemNormal_product = cartFlush_cartFlush_items_CartItemNormal_product_ProductPrint | cartFlush_cartFlush_items_CartItemNormal_product_ProductBundle | cartFlush_cartFlush_items_CartItemNormal_product_ProductPrintIssue | cartFlush_cartFlush_items_CartItemNormal_product_ProductDigital | cartFlush_cartFlush_items_CartItemNormal_product_ProductService | cartFlush_cartFlush_items_CartItemNormal_product_ProductDigitalService;

export interface cartFlush_cartFlush_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartFlush_cartFlush_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: cartFlush_cartFlush_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: cartFlush_cartFlush_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartFlush_cartFlush_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products = cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_price;
  products: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemChristmas_product = cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrint | cartFlush_cartFlush_items_CartItemChristmas_product_ProductBundle | cartFlush_cartFlush_items_CartItemChristmas_product_ProductPrintIssue | cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigital | cartFlush_cartFlush_items_CartItemChristmas_product_ProductService | cartFlush_cartFlush_items_CartItemChristmas_product_ProductDigitalService;

export interface cartFlush_cartFlush_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartFlush_cartFlush_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: cartFlush_cartFlush_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: cartFlush_cartFlush_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartFlush_cartFlush_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products = cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemBundle_cartItems_product = cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrint | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductBundle | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductPrintIssue | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigital | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductService | cartFlush_cartFlush_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface cartFlush_cartFlush_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: cartFlush_cartFlush_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: cartFlush_cartFlush_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: cartFlush_cartFlush_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemBundle_product_products = cartFlush_cartFlush_items_CartItemBundle_product_products_ProductBundle | cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrint | cartFlush_cartFlush_items_CartItemBundle_product_products_ProductPrintIssue | cartFlush_cartFlush_items_CartItemBundle_product_products_ProductDigital | cartFlush_cartFlush_items_CartItemBundle_product_products_ProductService;

export interface cartFlush_cartFlush_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartFlush_cartFlush_items_CartItemBundle_product_price;
  products: cartFlush_cartFlush_items_CartItemBundle_product_products[];
  bundlePublication: cartFlush_cartFlush_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartFlush_cartFlush_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartFlush_cartFlush_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartFlush_cartFlush_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: cartFlush_cartFlush_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: cartFlush_cartFlush_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: cartFlush_cartFlush_items_CartItemBundle_product;
  shipping_address: cartFlush_cartFlush_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartFlush_cartFlush_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartFlush_cartFlush_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products = cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductBundle | cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrint | cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductDigital | cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_price;
  products: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemGratis_product = cartFlush_cartFlush_items_CartItemGratis_product_ProductPrint | cartFlush_cartFlush_items_CartItemGratis_product_ProductBundle | cartFlush_cartFlush_items_CartItemGratis_product_ProductPrintIssue | cartFlush_cartFlush_items_CartItemGratis_product_ProductDigital | cartFlush_cartFlush_items_CartItemGratis_product_ProductService | cartFlush_cartFlush_items_CartItemGratis_product_ProductDigitalService;

export interface cartFlush_cartFlush_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartFlush_cartFlush_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: cartFlush_cartFlush_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: cartFlush_cartFlush_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartFlush_cartFlush_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartFlush_cartFlush_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products = cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductBundle | cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrint | cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductDigital | cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_price;
  products: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartFlush_cartFlush_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartFlush_cartFlush_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartFlush_cartFlush_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartFlush_cartFlush_items_CartItemOffer_product = cartFlush_cartFlush_items_CartItemOffer_product_ProductPrint | cartFlush_cartFlush_items_CartItemOffer_product_ProductBundle | cartFlush_cartFlush_items_CartItemOffer_product_ProductPrintIssue | cartFlush_cartFlush_items_CartItemOffer_product_ProductDigital | cartFlush_cartFlush_items_CartItemOffer_product_ProductService | cartFlush_cartFlush_items_CartItemOffer_product_ProductDigitalService;

export interface cartFlush_cartFlush_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartFlush_cartFlush_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: cartFlush_cartFlush_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: cartFlush_cartFlush_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type cartFlush_cartFlush_items = cartFlush_cartFlush_items_CartItemNormal | cartFlush_cartFlush_items_CartItemChristmas | cartFlush_cartFlush_items_CartItemBundle | cartFlush_cartFlush_items_CartItemGratis | cartFlush_cartFlush_items_CartItemOffer;

export interface cartFlush_cartFlush {
  __typename: "Cart";
  type: CartType;
  items: cartFlush_cartFlush_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface cartFlush {
  cartFlush: cartFlush_cartFlush[];
}

export interface cartFlushVariables {
  type: CartType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: newsletterSubscribe
// ====================================================

export interface newsletterSubscribe {
  newsletterSubscribe: boolean;
}

export interface newsletterSubscribeVariables {
  input: NewsletterSubscribeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetShippingAddressGuest
// ====================================================

export interface checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest_cart_items_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
}

export interface checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest_cart_items {
  __typename: "CartItemNormal" | "CartItemChristmas" | "CartItemBundle" | "CartItemGratis" | "CartItemOffer";
  shipping_address: checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest_cart_items_shipping_address | null;
}

export interface checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest_cart {
  __typename: "Cart";
  items: checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest_cart_items[];
}

export interface checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest {
  __typename: "Checkout";
  cart: checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest_cart;
}

export interface checkoutSetShippingAddressGuest {
  checkoutSetShippingAddressGuest: checkoutSetShippingAddressGuest_checkoutSetShippingAddressGuest;
}

export interface checkoutSetShippingAddressGuestVariables {
  cart_item_id: string;
  input: UserShippingAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetShippingAddressUser
// ====================================================

export interface checkoutSetShippingAddressUser_checkoutSetShippingAddressUser_cart_items_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
}

export interface checkoutSetShippingAddressUser_checkoutSetShippingAddressUser_cart_items {
  __typename: "CartItemNormal" | "CartItemChristmas" | "CartItemBundle" | "CartItemGratis" | "CartItemOffer";
  shipping_address: checkoutSetShippingAddressUser_checkoutSetShippingAddressUser_cart_items_shipping_address | null;
}

export interface checkoutSetShippingAddressUser_checkoutSetShippingAddressUser_cart {
  __typename: "Cart";
  items: checkoutSetShippingAddressUser_checkoutSetShippingAddressUser_cart_items[];
}

export interface checkoutSetShippingAddressUser_checkoutSetShippingAddressUser {
  __typename: "Checkout";
  cart: checkoutSetShippingAddressUser_checkoutSetShippingAddressUser_cart;
}

export interface checkoutSetShippingAddressUser {
  checkoutSetShippingAddressUser: checkoutSetShippingAddressUser_checkoutSetShippingAddressUser;
}

export interface checkoutSetShippingAddressUserVariables {
  cart_item_id: string;
  shipping_address_id?: number | null;
  billing_address_id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetBillingAddressGuest
// ====================================================

export interface checkoutSetBillingAddressGuest_checkoutSetBillingAddressGuest_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
}

export interface checkoutSetBillingAddressGuest_checkoutSetBillingAddressGuest {
  __typename: "Checkout";
  billing_address: checkoutSetBillingAddressGuest_checkoutSetBillingAddressGuest_billing_address | null;
}

export interface checkoutSetBillingAddressGuest {
  checkoutSetBillingAddressGuest: checkoutSetBillingAddressGuest_checkoutSetBillingAddressGuest;
}

export interface checkoutSetBillingAddressGuestVariables {
  address: CheckoutBillingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetBillingAddressUser
// ====================================================

export interface checkoutSetBillingAddressUser_checkoutSetBillingAddressUser_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
}

export interface checkoutSetBillingAddressUser_checkoutSetBillingAddressUser {
  __typename: "Checkout";
  billing_address: checkoutSetBillingAddressUser_checkoutSetBillingAddressUser_billing_address | null;
}

export interface checkoutSetBillingAddressUser {
  checkoutSetBillingAddressUser: checkoutSetBillingAddressUser_checkoutSetBillingAddressUser;
}

export interface checkoutSetBillingAddressUserVariables {
  billing_address_id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userBillingAddressCreate
// ====================================================

export interface userBillingAddressCreate_userBillingAddressCreate_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userBillingAddressCreate_userBillingAddressCreate {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userBillingAddressCreate_userBillingAddressCreate_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userBillingAddressCreate {
  userBillingAddressCreate: userBillingAddressCreate_userBillingAddressCreate;
}

export interface userBillingAddressCreateVariables {
  address?: UserBillingAddressInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userBillingAddressUpdate
// ====================================================

export interface userBillingAddressUpdate_userBillingAddressUpdate_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userBillingAddressUpdate_userBillingAddressUpdate {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userBillingAddressUpdate_userBillingAddressUpdate_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userBillingAddressUpdate {
  userBillingAddressUpdate: userBillingAddressUpdate_userBillingAddressUpdate;
}

export interface userBillingAddressUpdateVariables {
  address?: UserBillingAddressInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userBillingAddressDelete
// ====================================================

export interface userBillingAddressDelete {
  userBillingAddressDelete: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutInit
// ====================================================

export interface checkoutInit_checkoutInit_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface checkoutInit_checkoutInit_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutInit_checkoutInit_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product_price | null;
  publication: checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product_publication | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: checkoutInit_checkoutInit_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutInit_checkoutInit_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type checkoutInit_checkoutInit_user_register_orders_items = checkoutInit_checkoutInit_user_register_orders_items_OrderItem | checkoutInit_checkoutInit_user_register_orders_items_OrderItemBundle;

export interface checkoutInit_checkoutInit_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface checkoutInit_checkoutInit_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: checkoutInit_checkoutInit_user_register_orders_batch_latest_transaction | null;
}

export interface checkoutInit_checkoutInit_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: checkoutInit_checkoutInit_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: checkoutInit_checkoutInit_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: checkoutInit_checkoutInit_user_register_orders_items[];
  batch: checkoutInit_checkoutInit_user_register_orders_batch;
}

export interface checkoutInit_checkoutInit_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: checkoutInit_checkoutInit_user_register_shipping_addresses[];
  billing_address: checkoutInit_checkoutInit_user_register_billing_address | null;
  orders: checkoutInit_checkoutInit_user_register_orders[];
  offer_ids: string[] | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products = checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemNormal_product = checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductService | checkoutInit_checkoutInit_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutInit_checkoutInit_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutInit_checkoutInit_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutInit_checkoutInit_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutInit_checkoutInit_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemChristmas_product = checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductService | checkoutInit_checkoutInit_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutInit_checkoutInit_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutInit_checkoutInit_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutInit_checkoutInit_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutInit_checkoutInit_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product = checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products = checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_price;
  products: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutInit_checkoutInit_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutInit_checkoutInit_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutInit_checkoutInit_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutInit_checkoutInit_cart_items_CartItemBundle_product;
  shipping_address: checkoutInit_checkoutInit_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products = checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemGratis_product = checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductService | checkoutInit_checkoutInit_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutInit_checkoutInit_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutInit_checkoutInit_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutInit_checkoutInit_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutInit_checkoutInit_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutInit_checkoutInit_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products = checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutInit_checkoutInit_cart_items_CartItemOffer_product = checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrint | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductBundle | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigital | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductService | checkoutInit_checkoutInit_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutInit_checkoutInit_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutInit_checkoutInit_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutInit_checkoutInit_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutInit_checkoutInit_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutInit_checkoutInit_cart_items = checkoutInit_checkoutInit_cart_items_CartItemNormal | checkoutInit_checkoutInit_cart_items_CartItemChristmas | checkoutInit_checkoutInit_cart_items_CartItemBundle | checkoutInit_checkoutInit_cart_items_CartItemGratis | checkoutInit_checkoutInit_cart_items_CartItemOffer;

export interface checkoutInit_checkoutInit_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutInit_checkoutInit_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutInit_checkoutInit_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface checkoutInit_checkoutInit_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: checkoutInit_checkoutInit_coupons_discount | null;
  discount_amount: number;
}

export interface checkoutInit_checkoutInit_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface checkoutInit_checkoutInit {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: checkoutInit_checkoutInit_gift_card | null;
  billing_address: checkoutInit_checkoutInit_billing_address | null;
  payment_method: checkoutInit_checkoutInit_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: checkoutInit_checkoutInit_user_register | null;
  cart: checkoutInit_checkoutInit_cart;
  coupons: (checkoutInit_checkoutInit_coupons | null)[];
  discounts: checkoutInit_checkoutInit_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

export interface checkoutInit {
  checkoutInit: checkoutInit_checkoutInit;
}

export interface checkoutInitVariables {
  cart_type: CartType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutStart
// ====================================================

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutStart_checkoutStart_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products = checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemNormal_product = checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductService | checkoutStart_checkoutStart_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutStart_checkoutStart_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutStart_checkoutStart_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutStart_checkoutStart_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutStart_checkoutStart_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutStart_checkoutStart_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemChristmas_product = checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductService | checkoutStart_checkoutStart_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutStart_checkoutStart_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutStart_checkoutStart_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutStart_checkoutStart_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutStart_checkoutStart_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product = checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products = checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_price;
  products: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutStart_checkoutStart_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutStart_checkoutStart_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutStart_checkoutStart_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutStart_checkoutStart_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutStart_checkoutStart_cart_items_CartItemBundle_product;
  shipping_address: checkoutStart_checkoutStart_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutStart_checkoutStart_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products = checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemGratis_product = checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductService | checkoutStart_checkoutStart_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutStart_checkoutStart_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutStart_checkoutStart_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutStart_checkoutStart_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutStart_checkoutStart_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutStart_checkoutStart_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products = checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutStart_checkoutStart_cart_items_CartItemOffer_product = checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrint | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductBundle | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigital | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductService | checkoutStart_checkoutStart_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutStart_checkoutStart_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutStart_checkoutStart_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutStart_checkoutStart_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutStart_checkoutStart_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutStart_checkoutStart_cart_items = checkoutStart_checkoutStart_cart_items_CartItemNormal | checkoutStart_checkoutStart_cart_items_CartItemChristmas | checkoutStart_checkoutStart_cart_items_CartItemBundle | checkoutStart_checkoutStart_cart_items_CartItemGratis | checkoutStart_checkoutStart_cart_items_CartItemOffer;

export interface checkoutStart_checkoutStart_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutStart_checkoutStart_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutStart_checkoutStart {
  __typename: "Checkout";
  cart: checkoutStart_checkoutStart_cart;
}

export interface checkoutStart {
  checkoutStart: checkoutStart_checkoutStart;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetSubscriptionStart
// ====================================================

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductService | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductService | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_price;
  products: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_product;
  shipping_address: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductService | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrint | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigital | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductService | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items = checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemNormal | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemChristmas | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemBundle | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemGratis | checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items_CartItemOffer;

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutSetSubscriptionStart_checkoutSetSubscriptionStart {
  __typename: "Checkout";
  cart: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart_cart;
}

export interface checkoutSetSubscriptionStart {
  checkoutSetSubscriptionStart: checkoutSetSubscriptionStart_checkoutSetSubscriptionStart;
}

export interface checkoutSetSubscriptionStartVariables {
  cart_item_id: string;
  subscription_start: GqlDate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetUser
// ====================================================

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products = checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product = checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductService | checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product = checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductService | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product = checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products = checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_price;
  products: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_product;
  shipping_address: checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products = checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product = checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductService | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products = checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product = checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrint | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigital | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductService | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutSetUser_checkoutSetUser_cart_items = checkoutSetUser_checkoutSetUser_cart_items_CartItemNormal | checkoutSetUser_checkoutSetUser_cart_items_CartItemChristmas | checkoutSetUser_checkoutSetUser_cart_items_CartItemBundle | checkoutSetUser_checkoutSetUser_cart_items_CartItemGratis | checkoutSetUser_checkoutSetUser_cart_items_CartItemOffer;

export interface checkoutSetUser_checkoutSetUser_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutSetUser_checkoutSetUser_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutSetUser_checkoutSetUser {
  __typename: "Checkout";
  cart: checkoutSetUser_checkoutSetUser_cart;
}

export interface checkoutSetUser {
  checkoutSetUser: checkoutSetUser_checkoutSetUser;
}

export interface checkoutSetUserVariables {
  input: UserRegisterDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetPaymentMethod
// ====================================================

export interface checkoutSetPaymentMethod_checkoutSetPaymentMethod_payment_method {
  __typename: "PaymentMethod";
  id: string;
}

export interface checkoutSetPaymentMethod_checkoutSetPaymentMethod {
  __typename: "Checkout";
  payment_method: checkoutSetPaymentMethod_checkoutSetPaymentMethod_payment_method | null;
}

export interface checkoutSetPaymentMethod {
  checkoutSetPaymentMethod: checkoutSetPaymentMethod_checkoutSetPaymentMethod;
}

export interface checkoutSetPaymentMethodVariables {
  payment_method_type: PaymentMethodType;
  postInvoice?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSubmit
// ====================================================

export interface checkoutSubmit_checkoutSubmit_orders {
  __typename: "Order";
  id: number;
}

export interface checkoutSubmit_checkoutSubmit {
  __typename: "OrderBatch";
  code: string;
  orders: checkoutSubmit_checkoutSubmit_orders[];
}

export interface checkoutSubmit {
  checkoutSubmit: checkoutSubmit_checkoutSubmit | null;
}

export interface checkoutSubmitVariables {
  subscribe_newsletter: boolean;
  subscribe_price_friendly_newsletter?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptNotification
// ====================================================

export interface acceptNotification {
  acceptLandingNotification: GqlVoid | null;
}

export interface acceptNotificationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: paymentInit
// ====================================================

export interface paymentInit_paymentInit_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
  simple_transaction_id: string | null;
  payment_method_type: PaymentMethodType | null;
  success: boolean;
  error_message: string | null;
  order_batch_code: string;
  redirect_url: string;
}

export interface paymentInit_paymentInit {
  __typename: "InitPaymentResult";
  transaction: paymentInit_paymentInit_transaction;
  redirect_url: string;
}

export interface paymentInit {
  paymentInit: paymentInit_paymentInit;
}

export interface paymentInitVariables {
  order_batch_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: paymentCancel
// ====================================================

export interface paymentCancel {
  paymentCancel: boolean;
}

export interface paymentCancelVariables {
  transaction_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutReset
// ====================================================

export interface checkoutReset_checkoutReset_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface checkoutReset_checkoutReset_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutReset_checkoutReset_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product_price | null;
  publication: checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product_publication | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: checkoutReset_checkoutReset_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutReset_checkoutReset_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type checkoutReset_checkoutReset_user_register_orders_items = checkoutReset_checkoutReset_user_register_orders_items_OrderItem | checkoutReset_checkoutReset_user_register_orders_items_OrderItemBundle;

export interface checkoutReset_checkoutReset_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface checkoutReset_checkoutReset_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: checkoutReset_checkoutReset_user_register_orders_batch_latest_transaction | null;
}

export interface checkoutReset_checkoutReset_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: checkoutReset_checkoutReset_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: checkoutReset_checkoutReset_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: checkoutReset_checkoutReset_user_register_orders_items[];
  batch: checkoutReset_checkoutReset_user_register_orders_batch;
}

export interface checkoutReset_checkoutReset_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: checkoutReset_checkoutReset_user_register_shipping_addresses[];
  billing_address: checkoutReset_checkoutReset_user_register_billing_address | null;
  orders: checkoutReset_checkoutReset_user_register_orders[];
  offer_ids: string[] | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products = checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemNormal_product = checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductService | checkoutReset_checkoutReset_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutReset_checkoutReset_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutReset_checkoutReset_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutReset_checkoutReset_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutReset_checkoutReset_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemChristmas_product = checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductService | checkoutReset_checkoutReset_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutReset_checkoutReset_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutReset_checkoutReset_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutReset_checkoutReset_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutReset_checkoutReset_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product = checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products = checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_price;
  products: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutReset_checkoutReset_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutReset_checkoutReset_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutReset_checkoutReset_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutReset_checkoutReset_cart_items_CartItemBundle_product;
  shipping_address: checkoutReset_checkoutReset_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products = checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemGratis_product = checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductService | checkoutReset_checkoutReset_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutReset_checkoutReset_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutReset_checkoutReset_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutReset_checkoutReset_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutReset_checkoutReset_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutReset_checkoutReset_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products = checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutReset_checkoutReset_cart_items_CartItemOffer_product = checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrint | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductBundle | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigital | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductService | checkoutReset_checkoutReset_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutReset_checkoutReset_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutReset_checkoutReset_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutReset_checkoutReset_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutReset_checkoutReset_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutReset_checkoutReset_cart_items = checkoutReset_checkoutReset_cart_items_CartItemNormal | checkoutReset_checkoutReset_cart_items_CartItemChristmas | checkoutReset_checkoutReset_cart_items_CartItemBundle | checkoutReset_checkoutReset_cart_items_CartItemGratis | checkoutReset_checkoutReset_cart_items_CartItemOffer;

export interface checkoutReset_checkoutReset_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutReset_checkoutReset_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutReset_checkoutReset_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface checkoutReset_checkoutReset_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: checkoutReset_checkoutReset_coupons_discount | null;
  discount_amount: number;
}

export interface checkoutReset_checkoutReset_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface checkoutReset_checkoutReset {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: checkoutReset_checkoutReset_gift_card | null;
  billing_address: checkoutReset_checkoutReset_billing_address | null;
  payment_method: checkoutReset_checkoutReset_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: checkoutReset_checkoutReset_user_register | null;
  cart: checkoutReset_checkoutReset_cart;
  coupons: (checkoutReset_checkoutReset_coupons | null)[];
  discounts: checkoutReset_checkoutReset_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

export interface checkoutReset {
  checkoutReset: checkoutReset_checkoutReset;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutApplyCoupon
// ====================================================

export interface checkoutApplyCoupon_checkoutApplyCoupon_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product_price | null;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product_publication | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items = checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItem | checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items_OrderItemBundle;

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_batch_latest_transaction | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_items[];
  batch: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders_batch;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: checkoutApplyCoupon_checkoutApplyCoupon_user_register_shipping_addresses[];
  billing_address: checkoutApplyCoupon_checkoutApplyCoupon_user_register_billing_address | null;
  orders: checkoutApplyCoupon_checkoutApplyCoupon_user_register_orders[];
  offer_ids: string[] | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductService | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductService | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_price;
  products: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_product;
  shipping_address: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductService | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrint | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigital | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductService | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutApplyCoupon_checkoutApplyCoupon_cart_items = checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemNormal | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemChristmas | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemBundle | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemGratis | checkoutApplyCoupon_checkoutApplyCoupon_cart_items_CartItemOffer;

export interface checkoutApplyCoupon_checkoutApplyCoupon_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutApplyCoupon_checkoutApplyCoupon_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: checkoutApplyCoupon_checkoutApplyCoupon_coupons_discount | null;
  discount_amount: number;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface checkoutApplyCoupon_checkoutApplyCoupon {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: checkoutApplyCoupon_checkoutApplyCoupon_gift_card | null;
  billing_address: checkoutApplyCoupon_checkoutApplyCoupon_billing_address | null;
  payment_method: checkoutApplyCoupon_checkoutApplyCoupon_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: checkoutApplyCoupon_checkoutApplyCoupon_user_register | null;
  cart: checkoutApplyCoupon_checkoutApplyCoupon_cart;
  coupons: (checkoutApplyCoupon_checkoutApplyCoupon_coupons | null)[];
  discounts: checkoutApplyCoupon_checkoutApplyCoupon_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

export interface checkoutApplyCoupon {
  checkoutApplyCoupon: checkoutApplyCoupon_checkoutApplyCoupon;
}

export interface checkoutApplyCouponVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutRemoveCoupon
// ====================================================

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product_price | null;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product_publication | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items = checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItem | checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items_OrderItemBundle;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_batch_latest_transaction | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_items[];
  batch: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders_batch;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_shipping_addresses[];
  billing_address: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_billing_address | null;
  orders: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register_orders[];
  offer_ids: string[] | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductService | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductService | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_price;
  products: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_product;
  shipping_address: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductService | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrint | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigital | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductService | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items = checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemNormal | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemChristmas | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemBundle | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemGratis | checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items_CartItemOffer;

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutRemoveCoupon_checkoutRemoveCoupon_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: checkoutRemoveCoupon_checkoutRemoveCoupon_coupons_discount | null;
  discount_amount: number;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface checkoutRemoveCoupon_checkoutRemoveCoupon {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: checkoutRemoveCoupon_checkoutRemoveCoupon_gift_card | null;
  billing_address: checkoutRemoveCoupon_checkoutRemoveCoupon_billing_address | null;
  payment_method: checkoutRemoveCoupon_checkoutRemoveCoupon_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: checkoutRemoveCoupon_checkoutRemoveCoupon_user_register | null;
  cart: checkoutRemoveCoupon_checkoutRemoveCoupon_cart;
  coupons: (checkoutRemoveCoupon_checkoutRemoveCoupon_coupons | null)[];
  discounts: checkoutRemoveCoupon_checkoutRemoveCoupon_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

export interface checkoutRemoveCoupon {
  checkoutRemoveCoupon: checkoutRemoveCoupon_checkoutRemoveCoupon;
}

export interface checkoutRemoveCouponVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setBatchPaymentMethod
// ====================================================

export interface setBatchPaymentMethod_paymentMethod {
  __typename: "OrderBatch";
  code: string;
}

export interface setBatchPaymentMethod {
  paymentMethod: setBatchPaymentMethod_paymentMethod | null;
}

export interface setBatchPaymentMethodVariables {
  order_batch_code: string;
  payment_method_type: PaymentMethodType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cartAddOfferProduct
// ====================================================

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemNormal {
  __typename: "CartItemNormal" | "CartItemChristmas" | "CartItemBundle" | "CartItemGratis";
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products = cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductBundle | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrint | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductDigital | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_price;
  products: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product = cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrint | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductBundle | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductPrintIssue | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigital | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductService | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product_ProductDigitalService;

export interface cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer {
  __typename: "CartItemOffer";
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discounts: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer_product;
}

export type cartAddOfferProduct_cartAddOfferProduct_items = cartAddOfferProduct_cartAddOfferProduct_items_CartItemNormal | cartAddOfferProduct_cartAddOfferProduct_items_CartItemOffer;

export interface cartAddOfferProduct_cartAddOfferProduct {
  __typename: "Cart";
  type: CartType;
  items: cartAddOfferProduct_cartAddOfferProduct_items[];
  sub_total: number;
}

export interface cartAddOfferProduct {
  cartAddOfferProduct: cartAddOfferProduct_cartAddOfferProduct;
}

export interface cartAddOfferProductVariables {
  offer_product_id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cartAddChristmasProducts
// ====================================================

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_price;
  products: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductService | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product_ProductDigitalService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_price;
  products: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductService | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product_ProductDigitalService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductService | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products_ProductService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_price;
  products: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_products[];
  bundlePublication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_product;
  shipping_address: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_price;
  products: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductService | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product_ProductDigitalService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_price;
  products: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrint | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductPrintIssue | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigital | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductService | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product_ProductDigitalService;

export interface cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type cartAddChristmasProducts_cartAddChristmasProducts_items = cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemNormal | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemChristmas | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemBundle | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemGratis | cartAddChristmasProducts_cartAddChristmasProducts_items_CartItemOffer;

export interface cartAddChristmasProducts_cartAddChristmasProducts {
  __typename: "Cart";
  type: CartType;
  items: cartAddChristmasProducts_cartAddChristmasProducts_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface cartAddChristmasProducts {
  cartAddChristmasProducts: cartAddChristmasProducts_cartAddChristmasProducts;
}

export interface cartAddChristmasProductsVariables {
  product_ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkoutSetChannelCode
// ====================================================

export interface checkoutSetChannelCode_checkoutSetChannelCode_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product_price | null;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product_publication | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items = checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItem | checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items_OrderItemBundle;

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_batch_latest_transaction | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_items[];
  batch: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders_batch;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: checkoutSetChannelCode_checkoutSetChannelCode_user_register_shipping_addresses[];
  billing_address: checkoutSetChannelCode_checkoutSetChannelCode_user_register_billing_address | null;
  orders: checkoutSetChannelCode_checkoutSetChannelCode_user_register_orders[];
  offer_ids: string[] | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductService | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductService | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductService | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products_ProductService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_price;
  products: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_product;
  shipping_address: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductService | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrint | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductPrintIssue | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigital | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductService | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkoutSetChannelCode_checkoutSetChannelCode_cart_items = checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemNormal | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemChristmas | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemBundle | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemGratis | checkoutSetChannelCode_checkoutSetChannelCode_cart_items_CartItemOffer;

export interface checkoutSetChannelCode_checkoutSetChannelCode_cart {
  __typename: "Cart";
  type: CartType;
  items: checkoutSetChannelCode_checkoutSetChannelCode_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: checkoutSetChannelCode_checkoutSetChannelCode_coupons_discount | null;
  discount_amount: number;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface checkoutSetChannelCode_checkoutSetChannelCode {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: checkoutSetChannelCode_checkoutSetChannelCode_gift_card | null;
  billing_address: checkoutSetChannelCode_checkoutSetChannelCode_billing_address | null;
  payment_method: checkoutSetChannelCode_checkoutSetChannelCode_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: checkoutSetChannelCode_checkoutSetChannelCode_user_register | null;
  cart: checkoutSetChannelCode_checkoutSetChannelCode_cart;
  coupons: (checkoutSetChannelCode_checkoutSetChannelCode_coupons | null)[];
  discounts: checkoutSetChannelCode_checkoutSetChannelCode_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

export interface checkoutSetChannelCode {
  checkoutSetChannelCode: checkoutSetChannelCode_checkoutSetChannelCode | null;
}

export interface checkoutSetChannelCodeVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: stopSubscription
// ====================================================

export interface stopSubscription_stopSubscription {
  __typename: "SubscriptionBatch";
  code: string;
}

export interface stopSubscription {
  stopSubscription: stopSubscription_stopSubscription;
}

export interface stopSubscriptionVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: session
// ====================================================

export interface session_session_user_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface session_session_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: session_session_user_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface session_session_user_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface session_session_user_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: session_session_user_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface session_session_user_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface session_session_user_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface session_session_user_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface session_session_user_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface session_session_user_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: session_session_user_orders_items_OrderItem_product_publication_default_product | null;
}

export interface session_session_user_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: session_session_user_orders_items_OrderItem_product_price | null;
  publication: session_session_user_orders_items_OrderItem_product_publication | null;
}

export interface session_session_user_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface session_session_user_orders_items_OrderItem {
  __typename: "OrderItem";
  product: session_session_user_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: session_session_user_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface session_session_user_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface session_session_user_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: session_session_user_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface session_session_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface session_session_user_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: session_session_user_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: session_session_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface session_session_user_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface session_session_user_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: session_session_user_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: session_session_user_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type session_session_user_orders_items = session_session_user_orders_items_OrderItem | session_session_user_orders_items_OrderItemBundle;

export interface session_session_user_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface session_session_user_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: session_session_user_orders_batch_latest_transaction | null;
}

export interface session_session_user_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: session_session_user_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: session_session_user_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: session_session_user_orders_items[];
  batch: session_session_user_orders_batch;
}

export interface session_session_user {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: session_session_user_shipping_addresses[];
  billing_address: session_session_user_billing_address | null;
  orders: session_session_user_orders[];
  offer_ids: string[] | null;
}

export interface session_session {
  __typename: "UserAuthenticationResult";
  token: string;
  user: session_session_user | null;
}

export interface session {
  session: session_session;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userMe
// ====================================================

export interface userMe_userMe_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userMe_userMe_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userMe_userMe_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userMe_userMe_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface userMe_userMe_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: userMe_userMe_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface userMe_userMe_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface userMe_userMe_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userMe_userMe_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userMe_userMe_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface userMe_userMe_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: userMe_userMe_orders_items_OrderItem_product_publication_default_product | null;
}

export interface userMe_userMe_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userMe_userMe_orders_items_OrderItem_product_price | null;
  publication: userMe_userMe_orders_items_OrderItem_product_publication | null;
}

export interface userMe_userMe_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userMe_userMe_orders_items_OrderItem {
  __typename: "OrderItem";
  product: userMe_userMe_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userMe_userMe_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userMe_userMe_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userMe_userMe_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userMe_userMe_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface userMe_userMe_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userMe_userMe_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: userMe_userMe_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userMe_userMe_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userMe_userMe_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userMe_userMe_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: userMe_userMe_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userMe_userMe_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type userMe_userMe_orders_items = userMe_userMe_orders_items_OrderItem | userMe_userMe_orders_items_OrderItemBundle;

export interface userMe_userMe_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface userMe_userMe_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: userMe_userMe_orders_batch_latest_transaction | null;
}

export interface userMe_userMe_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: userMe_userMe_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: userMe_userMe_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: userMe_userMe_orders_items[];
  batch: userMe_userMe_orders_batch;
}

export interface userMe_userMe {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: userMe_userMe_shipping_addresses[];
  billing_address: userMe_userMe_billing_address | null;
  orders: userMe_userMe_orders[];
  offer_ids: string[] | null;
}

export interface userMe {
  userMe: userMe_userMe;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listCities
// ====================================================

export interface listCities {
  listCities: string[];
}

export interface listCitiesVariables {
  postcode: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listStreets
// ====================================================

export interface listStreets {
  listStreets: string[];
}

export interface listStreetsVariables {
  postcode: number;
  city: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPublicPlaceTypes
// ====================================================

export interface listPublicPlaceTypes_listPublicPlaceTypes {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface listPublicPlaceTypes {
  listPublicPlaceTypes: listPublicPlaceTypes_listPublicPlaceTypes[];
}

export interface listPublicPlaceTypesVariables {
  postcode: number;
  city: string;
  street: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: webshopMenuItems
// ====================================================

export interface webshopMenuItems_webshopMenuItems {
  __typename: "WebshopMenuItem";
  id: string;
  name: string;
  url: string;
  order: number;
  is_active: boolean;
}

export interface webshopMenuItems {
  webshopMenuItems: webshopMenuItems_webshopMenuItems[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsContent
// ====================================================

export interface cmsContent_cmsContent {
  __typename: "CmsContent";
  id: string;
  url: string;
  title: string;
  lead: string;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_description: string | null;
  meta_image: string | null;
  meta_keywords: string | null;
}

export interface cmsContent {
  cmsContent: cmsContent_cmsContent | null;
}

export interface cmsContentVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPublications
// ====================================================

export interface listPublications_listPublications_data_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_products_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_products_ProductBundle_products_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_products_ProductBundle_products = listPublications_listPublications_data_products_ProductBundle_products_ProductBundle | listPublications_listPublications_data_products_ProductBundle_products_ProductPrint | listPublications_listPublications_data_products_ProductBundle_products_ProductPrintIssue | listPublications_listPublications_data_products_ProductBundle_products_ProductDigital | listPublications_listPublications_data_products_ProductBundle_products_ProductService;

export interface listPublications_listPublications_data_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: listPublications_listPublications_data_products_ProductBundle_price;
  products: listPublications_listPublications_data_products_ProductBundle_products[];
  bundlePublication: listPublications_listPublications_data_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_products_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_products = listPublications_listPublications_data_products_ProductPrint | listPublications_listPublications_data_products_ProductBundle | listPublications_listPublications_data_products_ProductPrintIssue | listPublications_listPublications_data_products_ProductDigital | listPublications_listPublications_data_products_ProductService | listPublications_listPublications_data_products_ProductDigitalService;

export interface listPublications_listPublications_data_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_digital_products_ProductBundle_products = listPublications_listPublications_data_digital_products_ProductBundle_products_ProductBundle | listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrint | listPublications_listPublications_data_digital_products_ProductBundle_products_ProductPrintIssue | listPublications_listPublications_data_digital_products_ProductBundle_products_ProductDigital | listPublications_listPublications_data_digital_products_ProductBundle_products_ProductService;

export interface listPublications_listPublications_data_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: listPublications_listPublications_data_digital_products_ProductBundle_price;
  products: listPublications_listPublications_data_digital_products_ProductBundle_products[];
  bundlePublication: listPublications_listPublications_data_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_digital_products_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_digital_products = listPublications_listPublications_data_digital_products_ProductPrint | listPublications_listPublications_data_digital_products_ProductBundle | listPublications_listPublications_data_digital_products_ProductPrintIssue | listPublications_listPublications_data_digital_products_ProductDigital | listPublications_listPublications_data_digital_products_ProductService | listPublications_listPublications_data_digital_products_ProductDigitalService;

export interface listPublications_listPublications_data_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_default_product_ProductBundle_products = listPublications_listPublications_data_default_product_ProductBundle_products_ProductBundle | listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrint | listPublications_listPublications_data_default_product_ProductBundle_products_ProductPrintIssue | listPublications_listPublications_data_default_product_ProductBundle_products_ProductDigital | listPublications_listPublications_data_default_product_ProductBundle_products_ProductService;

export interface listPublications_listPublications_data_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: listPublications_listPublications_data_default_product_ProductBundle_price;
  products: listPublications_listPublications_data_default_product_ProductBundle_products[];
  bundlePublication: listPublications_listPublications_data_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_default_product_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_default_product = listPublications_listPublications_data_default_product_ProductPrint | listPublications_listPublications_data_default_product_ProductBundle | listPublications_listPublications_data_default_product_ProductPrintIssue | listPublications_listPublications_data_default_product_ProductDigital | listPublications_listPublications_data_default_product_ProductService | listPublications_listPublications_data_default_product_ProductDigitalService;

export interface listPublications_listPublications_data_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface listPublications_listPublications_data_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface listPublications_listPublications_data_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: listPublications_listPublications_data_bundles_products_ProductPrint_publication;
  quiz_required: listPublications_listPublications_data_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: listPublications_listPublications_data_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: listPublications_listPublications_data_bundles_products_ProductPrintIssue_price;
  publication: listPublications_listPublications_data_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: listPublications_listPublications_data_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listPublications_listPublications_data_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: listPublications_listPublications_data_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: listPublications_listPublications_data_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type listPublications_listPublications_data_bundles_products = listPublications_listPublications_data_bundles_products_ProductBundle | listPublications_listPublications_data_bundles_products_ProductPrint | listPublications_listPublications_data_bundles_products_ProductPrintIssue | listPublications_listPublications_data_bundles_products_ProductDigital | listPublications_listPublications_data_bundles_products_ProductService;

export interface listPublications_listPublications_data_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface listPublications_listPublications_data_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: listPublications_listPublications_data_bundles_price;
  products: listPublications_listPublications_data_bundles_products[];
  bundlePublication: listPublications_listPublications_data_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface listPublications_listPublications_data {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: listPublications_listPublications_data_products[];
  digital_products: listPublications_listPublications_data_digital_products[];
  default_product: listPublications_listPublications_data_default_product | null;
  price: listPublications_listPublications_data_price | null;
  bundles: listPublications_listPublications_data_bundles[] | null;
  is_featured: boolean;
}

export interface listPublications_listPublications {
  __typename: "PublicationPaginator";
  /**
   * A list of Publication items.
   */
  data: listPublications_listPublications_data[];
}

export interface listPublications {
  listPublications: listPublications_listPublications | null;
}

export interface listPublicationsVariables {
  filters?: PublicationFilters | null;
  sortBy?: PublicationSort | null;
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: homePage
// ====================================================

export interface homePage_highlightedPublications_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_products_ProductBundle_products = homePage_highlightedPublications_publications_products_ProductBundle_products_ProductBundle | homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrint | homePage_highlightedPublications_publications_products_ProductBundle_products_ProductPrintIssue | homePage_highlightedPublications_publications_products_ProductBundle_products_ProductDigital | homePage_highlightedPublications_publications_products_ProductBundle_products_ProductService;

export interface homePage_highlightedPublications_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_highlightedPublications_publications_products_ProductBundle_price;
  products: homePage_highlightedPublications_publications_products_ProductBundle_products[];
  bundlePublication: homePage_highlightedPublications_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_products_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_products = homePage_highlightedPublications_publications_products_ProductPrint | homePage_highlightedPublications_publications_products_ProductBundle | homePage_highlightedPublications_publications_products_ProductPrintIssue | homePage_highlightedPublications_publications_products_ProductDigital | homePage_highlightedPublications_publications_products_ProductService | homePage_highlightedPublications_publications_products_ProductDigitalService;

export interface homePage_highlightedPublications_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_digital_products_ProductBundle_products = homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductBundle | homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrint | homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductPrintIssue | homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductDigital | homePage_highlightedPublications_publications_digital_products_ProductBundle_products_ProductService;

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_highlightedPublications_publications_digital_products_ProductBundle_price;
  products: homePage_highlightedPublications_publications_digital_products_ProductBundle_products[];
  bundlePublication: homePage_highlightedPublications_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_digital_products_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_digital_products = homePage_highlightedPublications_publications_digital_products_ProductPrint | homePage_highlightedPublications_publications_digital_products_ProductBundle | homePage_highlightedPublications_publications_digital_products_ProductPrintIssue | homePage_highlightedPublications_publications_digital_products_ProductDigital | homePage_highlightedPublications_publications_digital_products_ProductService | homePage_highlightedPublications_publications_digital_products_ProductDigitalService;

export interface homePage_highlightedPublications_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_default_product_ProductBundle_products = homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductBundle | homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrint | homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductPrintIssue | homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductDigital | homePage_highlightedPublications_publications_default_product_ProductBundle_products_ProductService;

export interface homePage_highlightedPublications_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_highlightedPublications_publications_default_product_ProductBundle_price;
  products: homePage_highlightedPublications_publications_default_product_ProductBundle_products[];
  bundlePublication: homePage_highlightedPublications_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_default_product_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_default_product = homePage_highlightedPublications_publications_default_product_ProductPrint | homePage_highlightedPublications_publications_default_product_ProductBundle | homePage_highlightedPublications_publications_default_product_ProductPrintIssue | homePage_highlightedPublications_publications_default_product_ProductDigital | homePage_highlightedPublications_publications_default_product_ProductService | homePage_highlightedPublications_publications_default_product_ProductDigitalService;

export interface homePage_highlightedPublications_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_bundles_products_ProductPrint_publication;
  quiz_required: homePage_highlightedPublications_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedPublications_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedPublications_publications_bundles_products_ProductPrintIssue_price;
  publication: homePage_highlightedPublications_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedPublications_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedPublications_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedPublications_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedPublications_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedPublications_publications_bundles_products = homePage_highlightedPublications_publications_bundles_products_ProductBundle | homePage_highlightedPublications_publications_bundles_products_ProductPrint | homePage_highlightedPublications_publications_bundles_products_ProductPrintIssue | homePage_highlightedPublications_publications_bundles_products_ProductDigital | homePage_highlightedPublications_publications_bundles_products_ProductService;

export interface homePage_highlightedPublications_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedPublications_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_highlightedPublications_publications_bundles_price;
  products: homePage_highlightedPublications_publications_bundles_products[];
  bundlePublication: homePage_highlightedPublications_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_highlightedPublications_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: homePage_highlightedPublications_publications_products[];
  digital_products: homePage_highlightedPublications_publications_digital_products[];
  default_product: homePage_highlightedPublications_publications_default_product | null;
  price: homePage_highlightedPublications_publications_price | null;
  bundles: homePage_highlightedPublications_publications_bundles[] | null;
  is_featured: boolean;
}

export interface homePage_highlightedPublications {
  __typename: "CustomPublicationList";
  name: string;
  publications: homePage_highlightedPublications_publications[];
}

export interface homePage_printedPublictaions_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_products_ProductBundle_products = homePage_printedPublictaions_publications_products_ProductBundle_products_ProductBundle | homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrint | homePage_printedPublictaions_publications_products_ProductBundle_products_ProductPrintIssue | homePage_printedPublictaions_publications_products_ProductBundle_products_ProductDigital | homePage_printedPublictaions_publications_products_ProductBundle_products_ProductService;

export interface homePage_printedPublictaions_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_printedPublictaions_publications_products_ProductBundle_price;
  products: homePage_printedPublictaions_publications_products_ProductBundle_products[];
  bundlePublication: homePage_printedPublictaions_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_products_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_products = homePage_printedPublictaions_publications_products_ProductPrint | homePage_printedPublictaions_publications_products_ProductBundle | homePage_printedPublictaions_publications_products_ProductPrintIssue | homePage_printedPublictaions_publications_products_ProductDigital | homePage_printedPublictaions_publications_products_ProductService | homePage_printedPublictaions_publications_products_ProductDigitalService;

export interface homePage_printedPublictaions_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_digital_products_ProductBundle_products = homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductBundle | homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrint | homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductPrintIssue | homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductDigital | homePage_printedPublictaions_publications_digital_products_ProductBundle_products_ProductService;

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_printedPublictaions_publications_digital_products_ProductBundle_price;
  products: homePage_printedPublictaions_publications_digital_products_ProductBundle_products[];
  bundlePublication: homePage_printedPublictaions_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_digital_products_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_digital_products = homePage_printedPublictaions_publications_digital_products_ProductPrint | homePage_printedPublictaions_publications_digital_products_ProductBundle | homePage_printedPublictaions_publications_digital_products_ProductPrintIssue | homePage_printedPublictaions_publications_digital_products_ProductDigital | homePage_printedPublictaions_publications_digital_products_ProductService | homePage_printedPublictaions_publications_digital_products_ProductDigitalService;

export interface homePage_printedPublictaions_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_default_product_ProductBundle_products = homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductBundle | homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrint | homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductPrintIssue | homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductDigital | homePage_printedPublictaions_publications_default_product_ProductBundle_products_ProductService;

export interface homePage_printedPublictaions_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_printedPublictaions_publications_default_product_ProductBundle_price;
  products: homePage_printedPublictaions_publications_default_product_ProductBundle_products[];
  bundlePublication: homePage_printedPublictaions_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_default_product_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_default_product = homePage_printedPublictaions_publications_default_product_ProductPrint | homePage_printedPublictaions_publications_default_product_ProductBundle | homePage_printedPublictaions_publications_default_product_ProductPrintIssue | homePage_printedPublictaions_publications_default_product_ProductDigital | homePage_printedPublictaions_publications_default_product_ProductService | homePage_printedPublictaions_publications_default_product_ProductDigitalService;

export interface homePage_printedPublictaions_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_bundles_products_ProductPrint_publication;
  quiz_required: homePage_printedPublictaions_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_printedPublictaions_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_printedPublictaions_publications_bundles_products_ProductPrintIssue_price;
  publication: homePage_printedPublictaions_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_printedPublictaions_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_printedPublictaions_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_printedPublictaions_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_printedPublictaions_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_printedPublictaions_publications_bundles_products = homePage_printedPublictaions_publications_bundles_products_ProductBundle | homePage_printedPublictaions_publications_bundles_products_ProductPrint | homePage_printedPublictaions_publications_bundles_products_ProductPrintIssue | homePage_printedPublictaions_publications_bundles_products_ProductDigital | homePage_printedPublictaions_publications_bundles_products_ProductService;

export interface homePage_printedPublictaions_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_printedPublictaions_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_printedPublictaions_publications_bundles_price;
  products: homePage_printedPublictaions_publications_bundles_products[];
  bundlePublication: homePage_printedPublictaions_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_printedPublictaions_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: homePage_printedPublictaions_publications_products[];
  digital_products: homePage_printedPublictaions_publications_digital_products[];
  default_product: homePage_printedPublictaions_publications_default_product | null;
  price: homePage_printedPublictaions_publications_price | null;
  bundles: homePage_printedPublictaions_publications_bundles[] | null;
  is_featured: boolean;
}

export interface homePage_printedPublictaions {
  __typename: "CustomPublicationList";
  name: string;
  publications: homePage_printedPublictaions_publications[];
}

export interface homePage_highlightedProducts_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedProducts_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedProducts_products_ProductPrint_publication;
  quiz_required: homePage_highlightedProducts_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedProducts_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_highlightedProducts_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_highlightedProducts_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_highlightedProducts_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedProducts_products_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_highlightedProducts_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedProducts_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedProducts_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedProducts_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedProducts_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedProducts_products_ProductBundle_products = homePage_highlightedProducts_products_ProductBundle_products_ProductBundle | homePage_highlightedProducts_products_ProductBundle_products_ProductPrint | homePage_highlightedProducts_products_ProductBundle_products_ProductPrintIssue | homePage_highlightedProducts_products_ProductBundle_products_ProductDigital | homePage_highlightedProducts_products_ProductBundle_products_ProductService;

export interface homePage_highlightedProducts_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_highlightedProducts_products_ProductBundle_price;
  products: homePage_highlightedProducts_products_ProductBundle_products[];
  bundlePublication: homePage_highlightedProducts_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_highlightedProducts_products_ProductPrintIssue_price;
  publication: homePage_highlightedProducts_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_highlightedProducts_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedProducts_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedProducts_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedProducts_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_highlightedProducts_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_highlightedProducts_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_highlightedProducts_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_highlightedProducts_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_highlightedProducts_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_highlightedProducts_products = homePage_highlightedProducts_products_ProductPrint | homePage_highlightedProducts_products_ProductBundle | homePage_highlightedProducts_products_ProductPrintIssue | homePage_highlightedProducts_products_ProductDigital | homePage_highlightedProducts_products_ProductService | homePage_highlightedProducts_products_ProductDigitalService;

export interface homePage_highlightedProducts {
  __typename: "CustomProductList";
  name: string;
  products: homePage_highlightedProducts_products[];
}

export interface homePage_digitalProducts_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_digitalProducts_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_digitalProducts_products_ProductPrint_publication;
  quiz_required: homePage_digitalProducts_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_digitalProducts_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: homePage_digitalProducts_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: homePage_digitalProducts_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: homePage_digitalProducts_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_digitalProducts_products_ProductBundle_products_ProductPrintIssue_price;
  publication: homePage_digitalProducts_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_digitalProducts_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_digitalProducts_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_digitalProducts_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_digitalProducts_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_digitalProducts_products_ProductBundle_products = homePage_digitalProducts_products_ProductBundle_products_ProductBundle | homePage_digitalProducts_products_ProductBundle_products_ProductPrint | homePage_digitalProducts_products_ProductBundle_products_ProductPrintIssue | homePage_digitalProducts_products_ProductBundle_products_ProductDigital | homePage_digitalProducts_products_ProductBundle_products_ProductService;

export interface homePage_digitalProducts_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: homePage_digitalProducts_products_ProductBundle_price;
  products: homePage_digitalProducts_products_ProductBundle_products[];
  bundlePublication: homePage_digitalProducts_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface homePage_digitalProducts_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: homePage_digitalProducts_products_ProductPrintIssue_price;
  publication: homePage_digitalProducts_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: homePage_digitalProducts_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_digitalProducts_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_digitalProducts_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_digitalProducts_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface homePage_digitalProducts_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface homePage_digitalProducts_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface homePage_digitalProducts_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: homePage_digitalProducts_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: homePage_digitalProducts_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type homePage_digitalProducts_products = homePage_digitalProducts_products_ProductPrint | homePage_digitalProducts_products_ProductBundle | homePage_digitalProducts_products_ProductPrintIssue | homePage_digitalProducts_products_ProductDigital | homePage_digitalProducts_products_ProductService | homePage_digitalProducts_products_ProductDigitalService;

export interface homePage_digitalProducts {
  __typename: "CustomProductList";
  name: string;
  products: homePage_digitalProducts_products[];
}

export interface homePage_sliders {
  __typename: "Slider";
  id: string;
  image_url: string;
  subtitle: string | null;
  title: string | null;
  url: string | null;
  headline: string | null;
}

export interface homePage {
  highlightedPublications: homePage_highlightedPublications;
  printedPublictaions: homePage_printedPublictaions;
  highlightedProducts: homePage_highlightedProducts;
  digitalProducts: homePage_digitalProducts;
  sliders: homePage_sliders[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCompanyAddress
// ====================================================

export interface getCompanyAddress_getCompanyAddress_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface getCompanyAddress_getCompanyAddress {
  __typename: "CompanyAddress";
  company_name: string;
  tax_number: string;
  postcode: string | null;
  city: string | null;
  street: string | null;
  public_place_type: getCompanyAddress_getCompanyAddress_public_place_type | null;
  street_number: string | null;
}

export interface getCompanyAddress {
  getCompanyAddress: getCompanyAddress_getCompanyAddress | null;
}

export interface getCompanyAddressVariables {
  tax_number: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllActiveUrlAlias
// ====================================================

export interface getAllActiveUrlAlias_getAllActiveUrlAlias {
  __typename: "UrlAlias";
  source_url: string;
  target_url: string;
}

export interface getAllActiveUrlAlias {
  getAllActiveUrlAlias: getAllActiveUrlAlias_getAllActiveUrlAlias[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: carts
// ====================================================

export interface carts_carts_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface carts_carts_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: carts_carts_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface carts_carts_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemNormal_product_ProductBundle_products = carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductBundle | carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrint | carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductDigital | carts_carts_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface carts_carts_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: carts_carts_items_CartItemNormal_product_ProductBundle_price;
  products: carts_carts_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: carts_carts_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemNormal_product = carts_carts_items_CartItemNormal_product_ProductPrint | carts_carts_items_CartItemNormal_product_ProductBundle | carts_carts_items_CartItemNormal_product_ProductPrintIssue | carts_carts_items_CartItemNormal_product_ProductDigital | carts_carts_items_CartItemNormal_product_ProductService | carts_carts_items_CartItemNormal_product_ProductDigitalService;

export interface carts_carts_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: carts_carts_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: carts_carts_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: carts_carts_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface carts_carts_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface carts_carts_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: carts_carts_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface carts_carts_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemChristmas_product_ProductBundle_products = carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | carts_carts_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface carts_carts_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: carts_carts_items_CartItemChristmas_product_ProductBundle_price;
  products: carts_carts_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: carts_carts_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemChristmas_product = carts_carts_items_CartItemChristmas_product_ProductPrint | carts_carts_items_CartItemChristmas_product_ProductBundle | carts_carts_items_CartItemChristmas_product_ProductPrintIssue | carts_carts_items_CartItemChristmas_product_ProductDigital | carts_carts_items_CartItemChristmas_product_ProductService | carts_carts_items_CartItemChristmas_product_ProductDigitalService;

export interface carts_carts_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: carts_carts_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: carts_carts_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: carts_carts_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface carts_carts_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: carts_carts_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products = carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: carts_carts_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemBundle_cartItems_product = carts_carts_items_CartItemBundle_cartItems_product_ProductPrint | carts_carts_items_CartItemBundle_cartItems_product_ProductBundle | carts_carts_items_CartItemBundle_cartItems_product_ProductPrintIssue | carts_carts_items_CartItemBundle_cartItems_product_ProductDigital | carts_carts_items_CartItemBundle_cartItems_product_ProductService | carts_carts_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface carts_carts_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: carts_carts_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: carts_carts_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: carts_carts_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface carts_carts_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface carts_carts_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemBundle_product_products = carts_carts_items_CartItemBundle_product_products_ProductBundle | carts_carts_items_CartItemBundle_product_products_ProductPrint | carts_carts_items_CartItemBundle_product_products_ProductPrintIssue | carts_carts_items_CartItemBundle_product_products_ProductDigital | carts_carts_items_CartItemBundle_product_products_ProductService;

export interface carts_carts_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: carts_carts_items_CartItemBundle_product_price;
  products: carts_carts_items_CartItemBundle_product_products[];
  bundlePublication: carts_carts_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface carts_carts_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface carts_carts_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: carts_carts_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface carts_carts_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: carts_carts_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: carts_carts_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: carts_carts_items_CartItemBundle_product;
  shipping_address: carts_carts_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface carts_carts_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface carts_carts_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: carts_carts_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface carts_carts_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemGratis_product_ProductBundle_products = carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductBundle | carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrint | carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductDigital | carts_carts_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface carts_carts_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: carts_carts_items_CartItemGratis_product_ProductBundle_price;
  products: carts_carts_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: carts_carts_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemGratis_product = carts_carts_items_CartItemGratis_product_ProductPrint | carts_carts_items_CartItemGratis_product_ProductBundle | carts_carts_items_CartItemGratis_product_ProductPrintIssue | carts_carts_items_CartItemGratis_product_ProductDigital | carts_carts_items_CartItemGratis_product_ProductService | carts_carts_items_CartItemGratis_product_ProductDigitalService;

export interface carts_carts_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: carts_carts_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: carts_carts_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: carts_carts_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface carts_carts_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface carts_carts_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: carts_carts_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface carts_carts_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemOffer_product_ProductBundle_products = carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductBundle | carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrint | carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductDigital | carts_carts_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface carts_carts_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: carts_carts_items_CartItemOffer_product_ProductBundle_price;
  products: carts_carts_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: carts_carts_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: carts_carts_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: carts_carts_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface carts_carts_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface carts_carts_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: carts_carts_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: carts_carts_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type carts_carts_items_CartItemOffer_product = carts_carts_items_CartItemOffer_product_ProductPrint | carts_carts_items_CartItemOffer_product_ProductBundle | carts_carts_items_CartItemOffer_product_ProductPrintIssue | carts_carts_items_CartItemOffer_product_ProductDigital | carts_carts_items_CartItemOffer_product_ProductService | carts_carts_items_CartItemOffer_product_ProductDigitalService;

export interface carts_carts_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: carts_carts_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: carts_carts_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: carts_carts_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type carts_carts_items = carts_carts_items_CartItemNormal | carts_carts_items_CartItemChristmas | carts_carts_items_CartItemBundle | carts_carts_items_CartItemGratis | carts_carts_items_CartItemOffer;

export interface carts_carts {
  __typename: "Cart";
  type: CartType;
  items: carts_carts_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface carts {
  carts: carts_carts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cart
// ====================================================

export interface cart_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cart_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cart_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cart_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemNormal_product_ProductBundle_products = cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | cart_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface cart_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cart_cart_items_CartItemNormal_product_ProductBundle_price;
  products: cart_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: cart_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemNormal_product = cart_cart_items_CartItemNormal_product_ProductPrint | cart_cart_items_CartItemNormal_product_ProductBundle | cart_cart_items_CartItemNormal_product_ProductPrintIssue | cart_cart_items_CartItemNormal_product_ProductDigital | cart_cart_items_CartItemNormal_product_ProductService | cart_cart_items_CartItemNormal_product_ProductDigitalService;

export interface cart_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cart_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: cart_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: cart_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cart_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cart_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cart_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cart_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemChristmas_product_ProductBundle_products = cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | cart_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface cart_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cart_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: cart_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: cart_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemChristmas_product = cart_cart_items_CartItemChristmas_product_ProductPrint | cart_cart_items_CartItemChristmas_product_ProductBundle | cart_cart_items_CartItemChristmas_product_ProductPrintIssue | cart_cart_items_CartItemChristmas_product_ProductDigital | cart_cart_items_CartItemChristmas_product_ProductService | cart_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface cart_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cart_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: cart_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: cart_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cart_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cart_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: cart_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemBundle_cartItems_product = cart_cart_items_CartItemBundle_cartItems_product_ProductPrint | cart_cart_items_CartItemBundle_cartItems_product_ProductBundle | cart_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | cart_cart_items_CartItemBundle_cartItems_product_ProductDigital | cart_cart_items_CartItemBundle_cartItems_product_ProductService | cart_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface cart_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: cart_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: cart_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: cart_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cart_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cart_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemBundle_product_products = cart_cart_items_CartItemBundle_product_products_ProductBundle | cart_cart_items_CartItemBundle_product_products_ProductPrint | cart_cart_items_CartItemBundle_product_products_ProductPrintIssue | cart_cart_items_CartItemBundle_product_products_ProductDigital | cart_cart_items_CartItemBundle_product_products_ProductService;

export interface cart_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cart_cart_items_CartItemBundle_product_price;
  products: cart_cart_items_CartItemBundle_product_products[];
  bundlePublication: cart_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cart_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cart_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cart_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cart_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: cart_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: cart_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: cart_cart_items_CartItemBundle_product;
  shipping_address: cart_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface cart_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cart_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cart_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cart_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemGratis_product_ProductBundle_products = cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | cart_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface cart_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cart_cart_items_CartItemGratis_product_ProductBundle_price;
  products: cart_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: cart_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemGratis_product = cart_cart_items_CartItemGratis_product_ProductPrint | cart_cart_items_CartItemGratis_product_ProductBundle | cart_cart_items_CartItemGratis_product_ProductPrintIssue | cart_cart_items_CartItemGratis_product_ProductDigital | cart_cart_items_CartItemGratis_product_ProductService | cart_cart_items_CartItemGratis_product_ProductDigitalService;

export interface cart_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cart_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: cart_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: cart_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface cart_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface cart_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: cart_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface cart_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemOffer_product_ProductBundle_products = cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | cart_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface cart_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: cart_cart_items_CartItemOffer_product_ProductBundle_price;
  products: cart_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: cart_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: cart_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: cart_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface cart_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface cart_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: cart_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: cart_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type cart_cart_items_CartItemOffer_product = cart_cart_items_CartItemOffer_product_ProductPrint | cart_cart_items_CartItemOffer_product_ProductBundle | cart_cart_items_CartItemOffer_product_ProductPrintIssue | cart_cart_items_CartItemOffer_product_ProductDigital | cart_cart_items_CartItemOffer_product_ProductService | cart_cart_items_CartItemOffer_product_ProductDigitalService;

export interface cart_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: cart_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: cart_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: cart_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type cart_cart_items = cart_cart_items_CartItemNormal | cart_cart_items_CartItemChristmas | cart_cart_items_CartItemBundle | cart_cart_items_CartItemGratis | cart_cart_items_CartItemOffer;

export interface cart_cart {
  __typename: "Cart";
  type: CartType;
  items: cart_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface cart {
  cart: cart_cart;
}

export interface cartVariables {
  type: CartType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkout
// ====================================================

export interface checkout_checkout_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface checkout_checkout_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkout_checkout_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkout_checkout_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkout_checkout_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface checkout_checkout_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: checkout_checkout_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface checkout_checkout_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkout_checkout_user_register_orders_items_OrderItem_product_price | null;
  publication: checkout_checkout_user_register_orders_items_OrderItem_product_publication | null;
}

export interface checkout_checkout_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkout_checkout_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: checkout_checkout_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkout_checkout_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface checkout_checkout_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface checkout_checkout_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: checkout_checkout_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: checkout_checkout_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type checkout_checkout_user_register_orders_items = checkout_checkout_user_register_orders_items_OrderItem | checkout_checkout_user_register_orders_items_OrderItemBundle;

export interface checkout_checkout_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface checkout_checkout_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: checkout_checkout_user_register_orders_batch_latest_transaction | null;
}

export interface checkout_checkout_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: checkout_checkout_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: checkout_checkout_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: checkout_checkout_user_register_orders_items[];
  batch: checkout_checkout_user_register_orders_batch;
}

export interface checkout_checkout_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: checkout_checkout_user_register_shipping_addresses[];
  billing_address: checkout_checkout_user_register_billing_address | null;
  orders: checkout_checkout_user_register_orders[];
  offer_ids: string[] | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products = checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_price;
  products: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: checkout_checkout_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemNormal_product = checkout_checkout_cart_items_CartItemNormal_product_ProductPrint | checkout_checkout_cart_items_CartItemNormal_product_ProductBundle | checkout_checkout_cart_items_CartItemNormal_product_ProductPrintIssue | checkout_checkout_cart_items_CartItemNormal_product_ProductDigital | checkout_checkout_cart_items_CartItemNormal_product_ProductService | checkout_checkout_cart_items_CartItemNormal_product_ProductDigitalService;

export interface checkout_checkout_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkout_checkout_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: checkout_checkout_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: checkout_checkout_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products = checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemChristmas_product = checkout_checkout_cart_items_CartItemChristmas_product_ProductPrint | checkout_checkout_cart_items_CartItemChristmas_product_ProductBundle | checkout_checkout_cart_items_CartItemChristmas_product_ProductPrintIssue | checkout_checkout_cart_items_CartItemChristmas_product_ProductDigital | checkout_checkout_cart_items_CartItemChristmas_product_ProductService | checkout_checkout_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface checkout_checkout_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkout_checkout_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: checkout_checkout_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: checkout_checkout_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemBundle_cartItems_product = checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductService | checkout_checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface checkout_checkout_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: checkout_checkout_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: checkout_checkout_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: checkout_checkout_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemBundle_product_products = checkout_checkout_cart_items_CartItemBundle_product_products_ProductBundle | checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrint | checkout_checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue | checkout_checkout_cart_items_CartItemBundle_product_products_ProductDigital | checkout_checkout_cart_items_CartItemBundle_product_products_ProductService;

export interface checkout_checkout_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkout_checkout_cart_items_CartItemBundle_product_price;
  products: checkout_checkout_cart_items_CartItemBundle_product_products[];
  bundlePublication: checkout_checkout_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: checkout_checkout_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: checkout_checkout_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: checkout_checkout_cart_items_CartItemBundle_product;
  shipping_address: checkout_checkout_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products = checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_price;
  products: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: checkout_checkout_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemGratis_product = checkout_checkout_cart_items_CartItemGratis_product_ProductPrint | checkout_checkout_cart_items_CartItemGratis_product_ProductBundle | checkout_checkout_cart_items_CartItemGratis_product_ProductPrintIssue | checkout_checkout_cart_items_CartItemGratis_product_ProductDigital | checkout_checkout_cart_items_CartItemGratis_product_ProductService | checkout_checkout_cart_items_CartItemGratis_product_ProductDigitalService;

export interface checkout_checkout_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkout_checkout_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: checkout_checkout_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: checkout_checkout_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface checkout_checkout_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: checkout_checkout_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products = checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_price;
  products: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: checkout_checkout_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface checkout_checkout_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: checkout_checkout_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: checkout_checkout_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type checkout_checkout_cart_items_CartItemOffer_product = checkout_checkout_cart_items_CartItemOffer_product_ProductPrint | checkout_checkout_cart_items_CartItemOffer_product_ProductBundle | checkout_checkout_cart_items_CartItemOffer_product_ProductPrintIssue | checkout_checkout_cart_items_CartItemOffer_product_ProductDigital | checkout_checkout_cart_items_CartItemOffer_product_ProductService | checkout_checkout_cart_items_CartItemOffer_product_ProductDigitalService;

export interface checkout_checkout_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: checkout_checkout_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: checkout_checkout_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: checkout_checkout_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type checkout_checkout_cart_items = checkout_checkout_cart_items_CartItemNormal | checkout_checkout_cart_items_CartItemChristmas | checkout_checkout_cart_items_CartItemBundle | checkout_checkout_cart_items_CartItemGratis | checkout_checkout_cart_items_CartItemOffer;

export interface checkout_checkout_cart {
  __typename: "Cart";
  type: CartType;
  items: checkout_checkout_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface checkout_checkout_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface checkout_checkout_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: checkout_checkout_coupons_discount | null;
  discount_amount: number;
}

export interface checkout_checkout_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface checkout_checkout {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: checkout_checkout_gift_card | null;
  billing_address: checkout_checkout_billing_address | null;
  payment_method: checkout_checkout_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: checkout_checkout_user_register | null;
  cart: checkout_checkout_cart;
  coupons: (checkout_checkout_coupons | null)[];
  discounts: checkout_checkout_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

export interface checkout {
  checkout: checkout_checkout;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkoutPaymentMethods
// ====================================================

export interface checkoutPaymentMethods_checkoutPaymentMethods {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface checkoutPaymentMethods {
  checkoutPaymentMethods: checkoutPaymentMethods_checkoutPaymentMethods[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: landingNotifications
// ====================================================

export interface landingNotifications_landingNotifications {
  __typename: "LandingNotification";
  id: string;
  name: string;
  title: string;
  content: string | null;
  is_accept_required: boolean;
  is_active: boolean;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
}

export interface landingNotifications {
  landingNotifications: landingNotifications_landingNotifications[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listOrders
// ====================================================

export interface listOrders_listOrders_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
  /**
   * Index of the first item in the current page.
   */
  firstItem: number | null;
  /**
   * Are there more pages after this one?
   */
  hasMorePages: boolean;
  /**
   * Index of the last item in the current page.
   */
  lastItem: number | null;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Number of items per page.
   */
  perPage: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listOrders_listOrders_data_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface listOrders_listOrders_data_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface listOrders_listOrders_data_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listOrders_listOrders_data_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface listOrders_listOrders_data_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: listOrders_listOrders_data_items_OrderItem_product_publication_default_product | null;
}

export interface listOrders_listOrders_data_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: listOrders_listOrders_data_items_OrderItem_product_price | null;
  publication: listOrders_listOrders_data_items_OrderItem_product_publication | null;
}

export interface listOrders_listOrders_data_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface listOrders_listOrders_data_items_OrderItem {
  __typename: "OrderItem";
  product: listOrders_listOrders_data_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: listOrders_listOrders_data_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface listOrders_listOrders_data_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listOrders_listOrders_data_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: listOrders_listOrders_data_items_OrderItemBundle_orderItems_product_price | null;
}

export interface listOrders_listOrders_data_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface listOrders_listOrders_data_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: listOrders_listOrders_data_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: listOrders_listOrders_data_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface listOrders_listOrders_data_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface listOrders_listOrders_data_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: listOrders_listOrders_data_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: listOrders_listOrders_data_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type listOrders_listOrders_data_items = listOrders_listOrders_data_items_OrderItem | listOrders_listOrders_data_items_OrderItemBundle;

export interface listOrders_listOrders_data_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface listOrders_listOrders_data_batch {
  __typename: "OrderBatch";
  latest_transaction: listOrders_listOrders_data_batch_latest_transaction | null;
}

export interface listOrders_listOrders_data {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: listOrders_listOrders_data_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: listOrders_listOrders_data_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: listOrders_listOrders_data_items[];
  batch: listOrders_listOrders_data_batch;
}

export interface listOrders_listOrders {
  __typename: "OrderPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listOrders_listOrders_paginatorInfo;
  /**
   * A list of Order items.
   */
  data: listOrders_listOrders_data[];
}

export interface listOrders {
  listOrders: listOrders_listOrders | null;
}

export interface listOrdersVariables {
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listSubscriptionBatches
// ====================================================

export interface listSubscriptionBatches_listSubscriptionBatches_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
  /**
   * Index of the first item in the current page.
   */
  firstItem: number | null;
  /**
   * Are there more pages after this one?
   */
  hasMorePages: boolean;
  /**
   * Index of the last item in the current page.
   */
  lastItem: number | null;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Number of items per page.
   */
  perPage: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product_publication {
  __typename: "Publication";
  id: number;
  default_product: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product_publication_default_product | null;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product_price | null;
  publication: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product_publication | null;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_order {
  __typename: "Order";
  id: number;
  order_batch_id: number;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item {
  __typename: "OrderItem";
  product: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
  order: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item_order | null;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription {
  __typename: "OrderSubscription";
  id: number;
  order_item_id: number;
  subscription_start: GqlDate;
  next_charge_at: GqlDate | null;
  created_at: GqlDateTime;
  stopped_at: GqlDateTime | null;
  order_item: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription_order_item;
}

export interface listSubscriptionBatches_listSubscriptionBatches_data {
  __typename: "SubscriptionBatch";
  code: string;
  is_active: boolean;
  subscription_start: GqlDate | null;
  subscription_end: GqlDate | null;
  lastSubscription: listSubscriptionBatches_listSubscriptionBatches_data_lastSubscription | null;
}

export interface listSubscriptionBatches_listSubscriptionBatches {
  __typename: "SubscriptionBatchPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listSubscriptionBatches_listSubscriptionBatches_paginatorInfo;
  /**
   * A list of SubscriptionBatch items.
   */
  data: listSubscriptionBatches_listSubscriptionBatches_data[];
}

export interface listSubscriptionBatches {
  listSubscriptionBatches: listSubscriptionBatches_listSubscriptionBatches | null;
}

export interface listSubscriptionBatchesVariables {
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userOrder
// ====================================================

export interface userOrder_userOrder_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface userOrder_userOrder_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userOrder_userOrder_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userOrder_userOrder_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface userOrder_userOrder_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: userOrder_userOrder_items_OrderItem_product_publication_default_product | null;
}

export interface userOrder_userOrder_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userOrder_userOrder_items_OrderItem_product_price | null;
  publication: userOrder_userOrder_items_OrderItem_product_publication | null;
}

export interface userOrder_userOrder_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userOrder_userOrder_items_OrderItem {
  __typename: "OrderItem";
  product: userOrder_userOrder_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userOrder_userOrder_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userOrder_userOrder_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface userOrder_userOrder_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: userOrder_userOrder_items_OrderItemBundle_orderItems_product_price | null;
}

export interface userOrder_userOrder_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userOrder_userOrder_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: userOrder_userOrder_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userOrder_userOrder_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface userOrder_userOrder_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface userOrder_userOrder_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: userOrder_userOrder_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: userOrder_userOrder_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type userOrder_userOrder_items = userOrder_userOrder_items_OrderItem | userOrder_userOrder_items_OrderItemBundle;

export interface userOrder_userOrder_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface userOrder_userOrder_batch {
  __typename: "OrderBatch";
  latest_transaction: userOrder_userOrder_batch_latest_transaction | null;
}

export interface userOrder_userOrder {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: userOrder_userOrder_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: userOrder_userOrder_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: userOrder_userOrder_items[];
  batch: userOrder_userOrder_batch;
}

export interface userOrder {
  userOrder: userOrder_userOrder;
}

export interface userOrderVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paymentStatus
// ====================================================

export interface paymentStatus_paymentStatus_order_batch_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  type: ProductType;
  am_code: number;
  name: string;
}

export interface paymentStatus_paymentStatus_order_batch_orders_items_OrderItem {
  __typename: "OrderItem";
  price: number;
  product: paymentStatus_paymentStatus_order_batch_orders_items_OrderItem_product;
}

export interface paymentStatus_paymentStatus_order_batch_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  type: ProductType;
  am_code: number;
  name: string;
}

export interface paymentStatus_paymentStatus_order_batch_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: paymentStatus_paymentStatus_order_batch_orders_items_OrderItemBundle_orderItems_product;
}

export interface paymentStatus_paymentStatus_order_batch_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  price: number;
  orderItems: paymentStatus_paymentStatus_order_batch_orders_items_OrderItemBundle_orderItems[];
}

export type paymentStatus_paymentStatus_order_batch_orders_items = paymentStatus_paymentStatus_order_batch_orders_items_OrderItem | paymentStatus_paymentStatus_order_batch_orders_items_OrderItemBundle;

export interface paymentStatus_paymentStatus_order_batch_orders {
  __typename: "Order";
  created_at: GqlDateTime;
  order_batch_id: number;
  items: paymentStatus_paymentStatus_order_batch_orders_items[];
}

export interface paymentStatus_paymentStatus_order_batch {
  __typename: "OrderBatch";
  orders: paymentStatus_paymentStatus_order_batch_orders[];
  /**
   * Összesített bruttó ár
   */
  grand_total: number;
  /**
   * Összesített nettó ár
   */
  net_grand_total: number;
  /**
   * Összesített ÁFA
   */
  tax_total: number;
  /**
   * Összes kuponkód
   */
  coupons: string[];
}

export interface paymentStatus_paymentStatus {
  __typename: "OpkTransaction";
  code: string;
  status: string;
  simple_transaction_id: string | null;
  payment_method_type: PaymentMethodType | null;
  success: boolean;
  error_message: string | null;
  order_batch_code: string;
  redirect_url: string;
  order_batch: paymentStatus_paymentStatus_order_batch | null;
}

export interface paymentStatus {
  paymentStatus: paymentStatus_paymentStatus | null;
}

export interface paymentStatusVariables {
  transaction_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOrderBatchCode
// ====================================================

export interface getOrderBatchCode_paymentStatus {
  __typename: "OpkTransaction";
  order_batch_code: string;
}

export interface getOrderBatchCode {
  paymentStatus: getOrderBatchCode_paymentStatus | null;
}

export interface getOrderBatchCodeVariables {
  transaction_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discounts
// ====================================================

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products = discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_price;
  products: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_products = discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductService | discounts_discounts_DiscountNormal_product_segment_publications_products_ProductDigitalService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products = discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_price;
  products: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_digital_products = discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductService | discounts_discounts_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products = discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_price;
  products: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_default_product = discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductService | discounts_discounts_DiscountNormal_product_segment_publications_default_product_ProductDigitalService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_publications_bundles_products = discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_publications_bundles_products_ProductService;

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_publications_bundles_price;
  products: discounts_discounts_DiscountNormal_product_segment_publications_bundles_products[];
  bundlePublication: discounts_discounts_DiscountNormal_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: discounts_discounts_DiscountNormal_product_segment_publications_products[];
  digital_products: discounts_discounts_DiscountNormal_product_segment_publications_digital_products[];
  default_product: discounts_discounts_DiscountNormal_product_segment_publications_default_product | null;
  price: discounts_discounts_DiscountNormal_product_segment_publications_price | null;
  bundles: discounts_discounts_DiscountNormal_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products = discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_price;
  products: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountNormal_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountNormal_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountNormal_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountNormal_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountNormal_product_segment_products = discounts_discounts_DiscountNormal_product_segment_products_ProductPrint | discounts_discounts_DiscountNormal_product_segment_products_ProductBundle | discounts_discounts_DiscountNormal_product_segment_products_ProductPrintIssue | discounts_discounts_DiscountNormal_product_segment_products_ProductDigital | discounts_discounts_DiscountNormal_product_segment_products_ProductService | discounts_discounts_DiscountNormal_product_segment_products_ProductDigitalService;

export interface discounts_discounts_DiscountNormal_product_segment {
  __typename: "ProductSegment";
  publications: discounts_discounts_DiscountNormal_product_segment_publications[];
  products: discounts_discounts_DiscountNormal_product_segment_products[];
}

export interface discounts_discounts_DiscountNormal {
  __typename: "DiscountNormal";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: discounts_discounts_DiscountNormal_product_segment | null;
  description: string | null;
  top_description: string | null;
  image_alignment_right: boolean;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products = discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_price;
  products: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_products = discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductService | discounts_discounts_DiscountChristmas_product_segment_publications_products_ProductDigitalService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products = discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price;
  products: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_digital_products = discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductService | discounts_discounts_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products = discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_price;
  products: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_default_product = discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductService | discounts_discounts_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products = discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products_ProductService;

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_price;
  products: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_products[];
  bundlePublication: discounts_discounts_DiscountChristmas_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: discounts_discounts_DiscountChristmas_product_segment_publications_products[];
  digital_products: discounts_discounts_DiscountChristmas_product_segment_publications_digital_products[];
  default_product: discounts_discounts_DiscountChristmas_product_segment_publications_default_product | null;
  price: discounts_discounts_DiscountChristmas_product_segment_publications_price | null;
  bundles: discounts_discounts_DiscountChristmas_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products = discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService;

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_price;
  products: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_products[];
  bundlePublication: discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductPrintIssue_price;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discounts_discounts_DiscountChristmas_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discounts_discounts_DiscountChristmas_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discounts_discounts_DiscountChristmas_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discounts_discounts_DiscountChristmas_product_segment_products = discounts_discounts_DiscountChristmas_product_segment_products_ProductPrint | discounts_discounts_DiscountChristmas_product_segment_products_ProductBundle | discounts_discounts_DiscountChristmas_product_segment_products_ProductPrintIssue | discounts_discounts_DiscountChristmas_product_segment_products_ProductDigital | discounts_discounts_DiscountChristmas_product_segment_products_ProductService | discounts_discounts_DiscountChristmas_product_segment_products_ProductDigitalService;

export interface discounts_discounts_DiscountChristmas_product_segment {
  __typename: "ProductSegment";
  publications: discounts_discounts_DiscountChristmas_product_segment_publications[];
  products: discounts_discounts_DiscountChristmas_product_segment_products[];
}

export interface discounts_discounts_DiscountChristmas_half_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface discounts_discounts_DiscountChristmas_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface discounts_discounts_DiscountChristmas {
  __typename: "DiscountChristmas";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: discounts_discounts_DiscountChristmas_product_segment | null;
  description: string | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  half_year: discounts_discounts_DiscountChristmas_half_year | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  year: discounts_discounts_DiscountChristmas_year | null;
  top_description: string | null;
}

export type discounts_discounts = discounts_discounts_DiscountNormal | discounts_discounts_DiscountChristmas;

export interface discounts {
  discounts: discounts_discounts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: discount
// ====================================================

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products = discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService;

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_price;
  products: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_products = discount_discount_DiscountNormal_product_segment_publications_products_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_products_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_products_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_products_ProductService | discount_discount_DiscountNormal_product_segment_publications_products_ProductDigitalService;

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products = discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_price;
  products: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_digital_products = discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductService | discount_discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService;

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products = discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_price;
  products: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_default_product = discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductService | discount_discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService;

export interface discount_discount_DiscountNormal_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_publications_bundles_products = discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductBundle | discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint | discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital | discount_discount_DiscountNormal_product_segment_publications_bundles_products_ProductService;

export interface discount_discount_DiscountNormal_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_publications_bundles_price;
  products: discount_discount_DiscountNormal_product_segment_publications_bundles_products[];
  bundlePublication: discount_discount_DiscountNormal_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: discount_discount_DiscountNormal_product_segment_publications_products[];
  digital_products: discount_discount_DiscountNormal_product_segment_publications_digital_products[];
  default_product: discount_discount_DiscountNormal_product_segment_publications_default_product | null;
  price: discount_discount_DiscountNormal_product_segment_publications_price | null;
  bundles: discount_discount_DiscountNormal_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_products_ProductBundle_products = discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle | discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint | discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital | discount_discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService;

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_products_ProductBundle_price;
  products: discount_discount_DiscountNormal_product_segment_products_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountNormal_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountNormal_product_segment_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountNormal_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountNormal_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountNormal_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountNormal_product_segment_products = discount_discount_DiscountNormal_product_segment_products_ProductPrint | discount_discount_DiscountNormal_product_segment_products_ProductBundle | discount_discount_DiscountNormal_product_segment_products_ProductPrintIssue | discount_discount_DiscountNormal_product_segment_products_ProductDigital | discount_discount_DiscountNormal_product_segment_products_ProductService | discount_discount_DiscountNormal_product_segment_products_ProductDigitalService;

export interface discount_discount_DiscountNormal_product_segment {
  __typename: "ProductSegment";
  publications: discount_discount_DiscountNormal_product_segment_publications[];
  products: discount_discount_DiscountNormal_product_segment_products[];
}

export interface discount_discount_DiscountNormal {
  __typename: "DiscountNormal";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: discount_discount_DiscountNormal_product_segment | null;
  description: string | null;
  top_description: string | null;
  image_alignment_right: boolean;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products = discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService;

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_price;
  products: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_products = discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_products_ProductService | discount_discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService;

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products = discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price;
  products: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_digital_products = discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductService | discount_discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService;

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products = discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_price;
  products: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_default_product = discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductService | discount_discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService;

export interface discount_discount_DiscountChristmas_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_publications_bundles_products = discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService;

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_publications_bundles_price;
  products: discount_discount_DiscountChristmas_product_segment_publications_bundles_products[];
  bundlePublication: discount_discount_DiscountChristmas_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: discount_discount_DiscountChristmas_product_segment_publications_products[];
  digital_products: discount_discount_DiscountChristmas_product_segment_publications_digital_products[];
  default_product: discount_discount_DiscountChristmas_product_segment_publications_default_product | null;
  price: discount_discount_DiscountChristmas_product_segment_publications_price | null;
  bundles: discount_discount_DiscountChristmas_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products = discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService;

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_price;
  products: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_products[];
  bundlePublication: discount_discount_DiscountChristmas_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductPrintIssue_price;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface discount_discount_DiscountChristmas_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: discount_discount_DiscountChristmas_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: discount_discount_DiscountChristmas_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type discount_discount_DiscountChristmas_product_segment_products = discount_discount_DiscountChristmas_product_segment_products_ProductPrint | discount_discount_DiscountChristmas_product_segment_products_ProductBundle | discount_discount_DiscountChristmas_product_segment_products_ProductPrintIssue | discount_discount_DiscountChristmas_product_segment_products_ProductDigital | discount_discount_DiscountChristmas_product_segment_products_ProductService | discount_discount_DiscountChristmas_product_segment_products_ProductDigitalService;

export interface discount_discount_DiscountChristmas_product_segment {
  __typename: "ProductSegment";
  publications: discount_discount_DiscountChristmas_product_segment_publications[];
  products: discount_discount_DiscountChristmas_product_segment_products[];
}

export interface discount_discount_DiscountChristmas_half_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface discount_discount_DiscountChristmas_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface discount_discount_DiscountChristmas {
  __typename: "DiscountChristmas";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: discount_discount_DiscountChristmas_product_segment | null;
  description: string | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  half_year: discount_discount_DiscountChristmas_half_year | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  year: discount_discount_DiscountChristmas_year | null;
  top_description: string | null;
}

export type discount_discount = discount_discount_DiscountNormal | discount_discount_DiscountChristmas;

export interface discount {
  discount: discount_discount | null;
}

export interface discountVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: recommendedProducts
// ====================================================

export interface recommendedProducts_recommendedProducts_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface recommendedProducts_recommendedProducts_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductPrint_publication;
  quiz_required: recommendedProducts_recommendedProducts_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: recommendedProducts_recommendedProducts_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint_publication;
  quiz_required: recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrintIssue_price;
  publication: recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: recommendedProducts_recommendedProducts_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: recommendedProducts_recommendedProducts_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type recommendedProducts_recommendedProducts_ProductBundle_products = recommendedProducts_recommendedProducts_ProductBundle_products_ProductBundle | recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrint | recommendedProducts_recommendedProducts_ProductBundle_products_ProductPrintIssue | recommendedProducts_recommendedProducts_ProductBundle_products_ProductDigital | recommendedProducts_recommendedProducts_ProductBundle_products_ProductService;

export interface recommendedProducts_recommendedProducts_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: recommendedProducts_recommendedProducts_ProductBundle_price;
  products: recommendedProducts_recommendedProducts_ProductBundle_products[];
  bundlePublication: recommendedProducts_recommendedProducts_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: recommendedProducts_recommendedProducts_ProductPrintIssue_price;
  publication: recommendedProducts_recommendedProducts_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: recommendedProducts_recommendedProducts_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: recommendedProducts_recommendedProducts_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface recommendedProducts_recommendedProducts_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface recommendedProducts_recommendedProducts_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface recommendedProducts_recommendedProducts_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: recommendedProducts_recommendedProducts_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: recommendedProducts_recommendedProducts_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type recommendedProducts_recommendedProducts = recommendedProducts_recommendedProducts_ProductPrint | recommendedProducts_recommendedProducts_ProductBundle | recommendedProducts_recommendedProducts_ProductPrintIssue | recommendedProducts_recommendedProducts_ProductDigital | recommendedProducts_recommendedProducts_ProductService | recommendedProducts_recommendedProducts_ProductDigitalService;

export interface recommendedProducts {
  recommendedProducts: recommendedProducts_recommendedProducts[];
}

export interface recommendedProductsVariables {
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderBatch
// ====================================================

export interface orderBatch_orderBatch_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface orderBatch_orderBatch_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface orderBatch_orderBatch_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface orderBatch_orderBatch_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface orderBatch_orderBatch_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: orderBatch_orderBatch_orders_items_OrderItem_product_publication_default_product | null;
}

export interface orderBatch_orderBatch_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: orderBatch_orderBatch_orders_items_OrderItem_product_price | null;
  publication: orderBatch_orderBatch_orders_items_OrderItem_product_publication | null;
}

export interface orderBatch_orderBatch_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface orderBatch_orderBatch_orders_items_OrderItem {
  __typename: "OrderItem";
  product: orderBatch_orderBatch_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: orderBatch_orderBatch_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface orderBatch_orderBatch_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface orderBatch_orderBatch_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: orderBatch_orderBatch_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: orderBatch_orderBatch_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type orderBatch_orderBatch_orders_items = orderBatch_orderBatch_orders_items_OrderItem | orderBatch_orderBatch_orders_items_OrderItemBundle;

export interface orderBatch_orderBatch_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface orderBatch_orderBatch_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: orderBatch_orderBatch_orders_batch_latest_transaction | null;
}

export interface orderBatch_orderBatch_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: orderBatch_orderBatch_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: orderBatch_orderBatch_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: orderBatch_orderBatch_orders_items[];
  batch: orderBatch_orderBatch_orders_batch;
  grand_total: number;
}

export interface orderBatch_orderBatch {
  __typename: "OrderBatch";
  orders: orderBatch_orderBatch_orders[];
}

export interface orderBatch {
  orderBatch: orderBatch_orderBatch;
}

export interface orderBatchVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invoice
// ====================================================

export interface invoice_invoice {
  __typename: "OpkInvoice";
  file: string;
}

export interface invoice {
  invoice: invoice_invoice | null;
}

export interface invoiceVariables {
  transaction_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: orderBatchPaymentMethods
// ====================================================

export interface orderBatchPaymentMethods_orderBatchPaymentMethods {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface orderBatchPaymentMethods {
  orderBatchPaymentMethods: orderBatchPaymentMethods_orderBatchPaymentMethods[];
}

export interface orderBatchPaymentMethodsVariables {
  order_batch_code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: offerInit
// ====================================================

export interface offerInit_offer_offerProducts_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
}

export interface offerInit_offer_offerProducts_product {
  __typename: "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService" | "ProductBundle";
  id: number;
}

export interface offerInit_offer_offerProducts {
  __typename: "OfferProduct";
  id: number;
  publication: offerInit_offer_offerProducts_publication;
  product: offerInit_offer_offerProducts_product;
}

export interface offerInit_offer {
  __typename: "Offer";
  id: number;
  code: string;
  is_active: boolean;
  is_visible: boolean;
  active_from: GqlDateTime;
  active_to: GqlDateTime;
  offerProducts: offerInit_offer_offerProducts[];
}

export interface offerInit {
  offer: offerInit_offer;
}

export interface offerInitVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: offerProducts
// ====================================================

export interface offerProducts_offer_offerProducts_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface offerProducts_offer_offerProducts_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductPrint_publication;
  quiz_required: offerProducts_offer_offerProducts_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: offerProducts_offer_offerProducts_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_price;
  publication: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: offerProducts_offer_offerProducts_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type offerProducts_offer_offerProducts_product_ProductBundle_products = offerProducts_offer_offerProducts_product_ProductBundle_products_ProductBundle | offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrint | offerProducts_offer_offerProducts_product_ProductBundle_products_ProductPrintIssue | offerProducts_offer_offerProducts_product_ProductBundle_products_ProductDigital | offerProducts_offer_offerProducts_product_ProductBundle_products_ProductService;

export interface offerProducts_offer_offerProducts_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: offerProducts_offer_offerProducts_product_ProductBundle_price;
  products: offerProducts_offer_offerProducts_product_ProductBundle_products[];
  bundlePublication: offerProducts_offer_offerProducts_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: offerProducts_offer_offerProducts_product_ProductPrintIssue_price;
  publication: offerProducts_offer_offerProducts_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: offerProducts_offer_offerProducts_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: offerProducts_offer_offerProducts_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface offerProducts_offer_offerProducts_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface offerProducts_offer_offerProducts_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface offerProducts_offer_offerProducts_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: offerProducts_offer_offerProducts_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: offerProducts_offer_offerProducts_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type offerProducts_offer_offerProducts_product = offerProducts_offer_offerProducts_product_ProductPrint | offerProducts_offer_offerProducts_product_ProductBundle | offerProducts_offer_offerProducts_product_ProductPrintIssue | offerProducts_offer_offerProducts_product_ProductDigital | offerProducts_offer_offerProducts_product_ProductService | offerProducts_offer_offerProducts_product_ProductDigitalService;

export interface offerProducts_offer_offerProducts {
  __typename: "OfferProduct";
  id: number;
  publication: offerProducts_offer_offerProducts_publication;
  product: offerProducts_offer_offerProducts_product;
  price: number;
  original_price: number;
  discount_percent: number;
  vat: number;
}

export interface offerProducts_offer {
  __typename: "Offer";
  id: number;
  code: string;
  is_active: boolean;
  active_from: GqlDateTime;
  active_to: GqlDateTime;
  lead: string | null;
  offerProducts: offerProducts_offer_offerProducts[];
}

export interface offerProducts {
  offer: offerProducts_offer;
}

export interface offerProductsVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: offerDetails
// ====================================================

export interface offerDetails_offer_shipping_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface offerDetails_offer_billing_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface offerDetails_offer_offerProducts_product {
  __typename: "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService" | "ProductBundle";
}

export interface offerDetails_offer_offerProducts {
  __typename: "OfferProduct";
  id: number;
  price: number;
  original_price: number;
  discount_percent: number;
  vat: number;
  product: offerDetails_offer_offerProducts_product;
  next_transfer_date: GqlDate | null;
}

export interface offerDetails_offer_paymentMethod {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface offerDetails_offer {
  __typename: "Offer";
  id: number;
  code: string;
  lead: string | null;
  is_active: boolean;
  active_from: GqlDateTime;
  active_to: GqlDateTime;
  subscription_start: GqlDateTime;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string;
  contact_lastname: string;
  contact_phone: string;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: offerDetails_offer_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
  shipping_other: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_company_contact: string | null;
  billing_tax_number: string | null;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_country: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: offerDetails_offer_billing_public_place_type;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  billing_other: string | null;
  offerProducts: offerDetails_offer_offerProducts[];
  paymentMethod: offerDetails_offer_paymentMethod;
}

export interface offerDetails {
  offer: offerDetails_offer;
}

export interface offerDetailsVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: christmasDiscount
// ====================================================

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products = christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products_ProductService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_price;
  products: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_products = christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductService | christmasDiscount_christmasDiscount_product_segment_publications_products_ProductDigitalService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products = christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_price;
  products: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_digital_products = christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductService | christmasDiscount_christmasDiscount_product_segment_publications_digital_products_ProductDigitalService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products = christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_price;
  products: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_default_product = christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductService | christmasDiscount_christmasDiscount_product_segment_publications_default_product_ProductDigitalService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_publications_bundles_products = christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_publications_bundles_products_ProductService;

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_publications_bundles_price;
  products: christmasDiscount_christmasDiscount_product_segment_publications_bundles_products[];
  bundlePublication: christmasDiscount_christmasDiscount_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: christmasDiscount_christmasDiscount_product_segment_publications_products[];
  digital_products: christmasDiscount_christmasDiscount_product_segment_publications_digital_products[];
  default_product: christmasDiscount_christmasDiscount_product_segment_publications_default_product | null;
  price: christmasDiscount_christmasDiscount_product_segment_publications_price | null;
  bundles: christmasDiscount_christmasDiscount_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products = christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products_ProductService;

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_price;
  products: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_products[];
  bundlePublication: christmasDiscount_christmasDiscount_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductPrintIssue_price;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface christmasDiscount_christmasDiscount_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: christmasDiscount_christmasDiscount_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: christmasDiscount_christmasDiscount_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type christmasDiscount_christmasDiscount_product_segment_products = christmasDiscount_christmasDiscount_product_segment_products_ProductPrint | christmasDiscount_christmasDiscount_product_segment_products_ProductBundle | christmasDiscount_christmasDiscount_product_segment_products_ProductPrintIssue | christmasDiscount_christmasDiscount_product_segment_products_ProductDigital | christmasDiscount_christmasDiscount_product_segment_products_ProductService | christmasDiscount_christmasDiscount_product_segment_products_ProductDigitalService;

export interface christmasDiscount_christmasDiscount_product_segment {
  __typename: "ProductSegment";
  publications: christmasDiscount_christmasDiscount_product_segment_publications[];
  products: christmasDiscount_christmasDiscount_product_segment_products[];
}

export interface christmasDiscount_christmasDiscount_half_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface christmasDiscount_christmasDiscount_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface christmasDiscount_christmasDiscount {
  __typename: "DiscountChristmas";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: christmasDiscount_christmasDiscount_product_segment | null;
  description: string | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  half_year: christmasDiscount_christmasDiscount_half_year | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  year: christmasDiscount_christmasDiscount_year | null;
  top_description: string | null;
}

export interface christmasDiscount {
  christmasDiscount: christmasDiscount_christmasDiscount;
}

export interface christmasDiscountVariables {
  product_ids: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userEmailExists
// ====================================================

export interface userEmailExists {
  userEmailExists: boolean;
}

export interface userEmailExistsVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listPeriodicSubscriptions
// ====================================================

export interface listPeriodicSubscriptions_listPeriodicSubscriptions_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
  /**
   * Index of the first item in the current page.
   */
  firstItem: number | null;
  /**
   * Are there more pages after this one?
   */
  hasMorePages: boolean;
  /**
   * Index of the last item in the current page.
   */
  lastItem: number | null;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Number of items per page.
   */
  perPage: number;
  /**
   * Number of total available items.
   */
  total: number;
}

export interface listPeriodicSubscriptions_listPeriodicSubscriptions_data {
  __typename: "PeriodicSubscription";
  id: number;
  name: string;
  shipping_name: string;
  shipping_city: string;
  shipping_address: string;
  from: GqlDate;
  to: GqlDate;
  length: number;
}

export interface listPeriodicSubscriptions_listPeriodicSubscriptions {
  __typename: "PeriodicSubscriptionPaginator";
  /**
   * Pagination information about the list of items.
   */
  paginatorInfo: listPeriodicSubscriptions_listPeriodicSubscriptions_paginatorInfo;
  /**
   * A list of PeriodicSubscription items.
   */
  data: listPeriodicSubscriptions_listPeriodicSubscriptions_data[];
}

export interface listPeriodicSubscriptions {
  listPeriodicSubscriptions: listPeriodicSubscriptions_listPeriodicSubscriptions | null;
}

export interface listPeriodicSubscriptionsVariables {
  first: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userDeleteInfo
// ====================================================

export interface userDeleteInfo_userDeleteInfo {
  __typename: "UserDeleteInfo";
  consented_sites: string[] | null;
  has_active_subscription: boolean;
}

export interface userDeleteInfo {
  userDeleteInfo: userDeleteInfo_userDeleteInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSettings
// ====================================================

export interface getSettings_settings {
  __typename: "Settings";
  price_friendly_txt: string;
}

export interface getSettings {
  settings: getSettings_settings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductPrint
// ====================================================

export interface ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductPrint_publication;
  quiz_required: ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductPrintIssue
// ====================================================

export interface ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductPrintIssue_price;
  publication: ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductDigital
// ====================================================

export interface ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductService
// ====================================================

export interface ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBundle
// ====================================================

export interface ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductBundle_products_ProductPrint_publication;
  quiz_required: ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductBundle_products_ProductPrintIssue_price;
  publication: ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductBundle_products = ProductBundle_products_ProductBundle | ProductBundle_products_ProductPrint | ProductBundle_products_ProductPrintIssue | ProductBundle_products_ProductDigital | ProductBundle_products_ProductService;

export interface ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: ProductBundle_price;
  products: ProductBundle_products[];
  bundlePublication: ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductDigitalService
// ====================================================

export interface ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductOrder
// ====================================================

export interface ProductOrder_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductOrder {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: ProductOrder_price | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnyProduct
// ====================================================

export interface AnyProduct_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyProduct_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyProduct_ProductPrint_publication;
  quiz_required: AnyProduct_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyProduct_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyProduct_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyProduct_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyProduct_ProductBundle_products_ProductPrint_publication;
  quiz_required: AnyProduct_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyProduct_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyProduct_ProductBundle_products_ProductPrintIssue_price;
  publication: AnyProduct_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyProduct_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyProduct_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyProduct_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyProduct_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyProduct_ProductBundle_products = AnyProduct_ProductBundle_products_ProductBundle | AnyProduct_ProductBundle_products_ProductPrint | AnyProduct_ProductBundle_products_ProductPrintIssue | AnyProduct_ProductBundle_products_ProductDigital | AnyProduct_ProductBundle_products_ProductService;

export interface AnyProduct_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyProduct_ProductBundle_price;
  products: AnyProduct_ProductBundle_products[];
  bundlePublication: AnyProduct_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyProduct_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyProduct_ProductPrintIssue_price;
  publication: AnyProduct_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyProduct_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyProduct_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyProduct_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyProduct_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyProduct_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyProduct_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyProduct_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyProduct_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyProduct_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyProduct = AnyProduct_ProductPrint | AnyProduct_ProductBundle | AnyProduct_ProductPrintIssue | AnyProduct_ProductDigital | AnyProduct_ProductService | AnyProduct_ProductDigitalService;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserShippingAddress
// ====================================================

export interface UserShippingAddress_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface UserShippingAddress {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: UserShippingAddress_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserBillingAddress
// ====================================================

export interface UserBillingAddress_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface UserBillingAddress {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: UserBillingAddress_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderItem
// ====================================================

export interface OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: OrderItem_product_price | null;
}

export interface OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface OrderItem {
  __typename: "OrderItem";
  product: OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderItemBundle
// ====================================================

export interface OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: OrderItemBundle_orderItems_product_price | null;
}

export interface OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnyOrderItem
// ====================================================

export interface AnyOrderItem_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyOrderItem_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface AnyOrderItem_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: AnyOrderItem_OrderItem_product_publication_default_product | null;
}

export interface AnyOrderItem_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: AnyOrderItem_OrderItem_product_price | null;
  publication: AnyOrderItem_OrderItem_product_publication | null;
}

export interface AnyOrderItem_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface AnyOrderItem_OrderItem {
  __typename: "OrderItem";
  product: AnyOrderItem_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: AnyOrderItem_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface AnyOrderItem_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyOrderItem_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: AnyOrderItem_OrderItemBundle_orderItems_product_price | null;
}

export interface AnyOrderItem_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface AnyOrderItem_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: AnyOrderItem_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: AnyOrderItem_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface AnyOrderItem_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface AnyOrderItem_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: AnyOrderItem_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: AnyOrderItem_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type AnyOrderItem = AnyOrderItem_OrderItem | AnyOrderItem_OrderItemBundle;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethod
// ====================================================

export interface PaymentMethod {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Order
// ====================================================

export interface Order_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface Order_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Order_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Order_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface Order_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: Order_items_OrderItem_product_publication_default_product | null;
}

export interface Order_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: Order_items_OrderItem_product_price | null;
  publication: Order_items_OrderItem_product_publication | null;
}

export interface Order_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Order_items_OrderItem {
  __typename: "OrderItem";
  product: Order_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: Order_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface Order_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Order_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: Order_items_OrderItemBundle_orderItems_product_price | null;
}

export interface Order_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Order_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: Order_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: Order_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface Order_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Order_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: Order_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: Order_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type Order_items = Order_items_OrderItem | Order_items_OrderItemBundle;

export interface Order_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface Order_batch {
  __typename: "OrderBatch";
  latest_transaction: Order_batch_latest_transaction | null;
}

export interface Order {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: Order_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: Order_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: Order_items[];
  batch: Order_batch;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderSubscription
// ====================================================

export interface OrderSubscription_order_item_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface OrderSubscription_order_item_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface OrderSubscription_order_item_product_publication {
  __typename: "Publication";
  id: number;
  default_product: OrderSubscription_order_item_product_publication_default_product | null;
}

export interface OrderSubscription_order_item_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: OrderSubscription_order_item_product_price | null;
  publication: OrderSubscription_order_item_product_publication | null;
}

export interface OrderSubscription_order_item_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface OrderSubscription_order_item_order {
  __typename: "Order";
  id: number;
  order_batch_id: number;
}

export interface OrderSubscription_order_item {
  __typename: "OrderItem";
  product: OrderSubscription_order_item_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: OrderSubscription_order_item_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
  order: OrderSubscription_order_item_order | null;
}

export interface OrderSubscription {
  __typename: "OrderSubscription";
  id: number;
  order_item_id: number;
  subscription_start: GqlDate;
  next_charge_at: GqlDate | null;
  created_at: GqlDateTime;
  stopped_at: GqlDateTime | null;
  order_item: OrderSubscription_order_item;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubscriptionBatch
// ====================================================

export interface SubscriptionBatch_lastSubscription_order_item_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface SubscriptionBatch_lastSubscription_order_item_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface SubscriptionBatch_lastSubscription_order_item_product_publication {
  __typename: "Publication";
  id: number;
  default_product: SubscriptionBatch_lastSubscription_order_item_product_publication_default_product | null;
}

export interface SubscriptionBatch_lastSubscription_order_item_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: SubscriptionBatch_lastSubscription_order_item_product_price | null;
  publication: SubscriptionBatch_lastSubscription_order_item_product_publication | null;
}

export interface SubscriptionBatch_lastSubscription_order_item_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface SubscriptionBatch_lastSubscription_order_item_order {
  __typename: "Order";
  id: number;
  order_batch_id: number;
}

export interface SubscriptionBatch_lastSubscription_order_item {
  __typename: "OrderItem";
  product: SubscriptionBatch_lastSubscription_order_item_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: SubscriptionBatch_lastSubscription_order_item_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
  order: SubscriptionBatch_lastSubscription_order_item_order | null;
}

export interface SubscriptionBatch_lastSubscription {
  __typename: "OrderSubscription";
  id: number;
  order_item_id: number;
  subscription_start: GqlDate;
  next_charge_at: GqlDate | null;
  created_at: GqlDateTime;
  stopped_at: GqlDateTime | null;
  order_item: SubscriptionBatch_lastSubscription_order_item;
}

export interface SubscriptionBatch {
  __typename: "SubscriptionBatch";
  code: string;
  is_active: boolean;
  subscription_start: GqlDate | null;
  subscription_end: GqlDate | null;
  lastSubscription: SubscriptionBatch_lastSubscription | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: User
// ====================================================

export interface User_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface User_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: User_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface User_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface User_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: User_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface User_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface User_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface User_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface User_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface User_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: User_orders_items_OrderItem_product_publication_default_product | null;
}

export interface User_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: User_orders_items_OrderItem_product_price | null;
  publication: User_orders_items_OrderItem_product_publication | null;
}

export interface User_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface User_orders_items_OrderItem {
  __typename: "OrderItem";
  product: User_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: User_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface User_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface User_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: User_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface User_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface User_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: User_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: User_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface User_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface User_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: User_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: User_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type User_orders_items = User_orders_items_OrderItem | User_orders_items_OrderItemBundle;

export interface User_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface User_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: User_orders_batch_latest_transaction | null;
}

export interface User_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: User_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: User_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: User_orders_items[];
  batch: User_orders_batch;
}

export interface User {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: User_shipping_addresses[];
  billing_address: User_billing_address | null;
  orders: User_orders[];
  offer_ids: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAuthenticationResult
// ====================================================

export interface UserAuthenticationResult_user_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface UserAuthenticationResult_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: UserAuthenticationResult_user_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface UserAuthenticationResult_user_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface UserAuthenticationResult_user_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: UserAuthenticationResult_user_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface UserAuthenticationResult_user_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface UserAuthenticationResult_user_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface UserAuthenticationResult_user_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface UserAuthenticationResult_user_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface UserAuthenticationResult_user_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: UserAuthenticationResult_user_orders_items_OrderItem_product_publication_default_product | null;
}

export interface UserAuthenticationResult_user_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: UserAuthenticationResult_user_orders_items_OrderItem_product_price | null;
  publication: UserAuthenticationResult_user_orders_items_OrderItem_product_publication | null;
}

export interface UserAuthenticationResult_user_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface UserAuthenticationResult_user_orders_items_OrderItem {
  __typename: "OrderItem";
  product: UserAuthenticationResult_user_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: UserAuthenticationResult_user_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface UserAuthenticationResult_user_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface UserAuthenticationResult_user_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: UserAuthenticationResult_user_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: UserAuthenticationResult_user_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type UserAuthenticationResult_user_orders_items = UserAuthenticationResult_user_orders_items_OrderItem | UserAuthenticationResult_user_orders_items_OrderItemBundle;

export interface UserAuthenticationResult_user_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface UserAuthenticationResult_user_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: UserAuthenticationResult_user_orders_batch_latest_transaction | null;
}

export interface UserAuthenticationResult_user_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: UserAuthenticationResult_user_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: UserAuthenticationResult_user_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: UserAuthenticationResult_user_orders_items[];
  batch: UserAuthenticationResult_user_orders_batch;
}

export interface UserAuthenticationResult_user {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: UserAuthenticationResult_user_shipping_addresses[];
  billing_address: UserAuthenticationResult_user_billing_address | null;
  orders: UserAuthenticationResult_user_orders[];
  offer_ids: string[] | null;
}

export interface UserAuthenticationResult {
  __typename: "UserAuthenticationResult";
  token: string;
  user: UserAuthenticationResult_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CmsContent
// ====================================================

export interface CmsContent {
  __typename: "CmsContent";
  id: string;
  url: string;
  title: string;
  lead: string;
  lead_image: string | null;
  content: string | null;
  meta_title: string | null;
  meta_description: string | null;
  meta_image: string | null;
  meta_keywords: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PublicPlaceType
// ====================================================

export interface PublicPlaceType {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatorInfo
// ====================================================

export interface PaginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Number of items in the current page.
   */
  count: number;
  /**
   * Index of the current page.
   */
  currentPage: number;
  /**
   * Index of the first item in the current page.
   */
  firstItem: number | null;
  /**
   * Are there more pages after this one?
   */
  hasMorePages: boolean;
  /**
   * Index of the last item in the current page.
   */
  lastItem: number | null;
  /**
   * Index of the last available page.
   */
  lastPage: number;
  /**
   * Number of items per page.
   */
  perPage: number;
  /**
   * Number of total available items.
   */
  total: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Publication
// ====================================================

export interface Publication_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_products_ProductPrint_publication;
  quiz_required: Publication_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Publication_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Publication_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Publication_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_products_ProductBundle_products = Publication_products_ProductBundle_products_ProductBundle | Publication_products_ProductBundle_products_ProductPrint | Publication_products_ProductBundle_products_ProductPrintIssue | Publication_products_ProductBundle_products_ProductDigital | Publication_products_ProductBundle_products_ProductService;

export interface Publication_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Publication_products_ProductBundle_price;
  products: Publication_products_ProductBundle_products[];
  bundlePublication: Publication_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Publication_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_products_ProductPrintIssue_price;
  publication: Publication_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_products = Publication_products_ProductPrint | Publication_products_ProductBundle | Publication_products_ProductPrintIssue | Publication_products_ProductDigital | Publication_products_ProductService | Publication_products_ProductDigitalService;

export interface Publication_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_digital_products_ProductPrint_publication;
  quiz_required: Publication_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Publication_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Publication_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Publication_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_digital_products_ProductBundle_products = Publication_digital_products_ProductBundle_products_ProductBundle | Publication_digital_products_ProductBundle_products_ProductPrint | Publication_digital_products_ProductBundle_products_ProductPrintIssue | Publication_digital_products_ProductBundle_products_ProductDigital | Publication_digital_products_ProductBundle_products_ProductService;

export interface Publication_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Publication_digital_products_ProductBundle_price;
  products: Publication_digital_products_ProductBundle_products[];
  bundlePublication: Publication_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Publication_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_digital_products_ProductPrintIssue_price;
  publication: Publication_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_digital_products = Publication_digital_products_ProductPrint | Publication_digital_products_ProductBundle | Publication_digital_products_ProductPrintIssue | Publication_digital_products_ProductDigital | Publication_digital_products_ProductService | Publication_digital_products_ProductDigitalService;

export interface Publication_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_default_product_ProductPrint_publication;
  quiz_required: Publication_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Publication_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Publication_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Publication_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_default_product_ProductBundle_products = Publication_default_product_ProductBundle_products_ProductBundle | Publication_default_product_ProductBundle_products_ProductPrint | Publication_default_product_ProductBundle_products_ProductPrintIssue | Publication_default_product_ProductBundle_products_ProductDigital | Publication_default_product_ProductBundle_products_ProductService;

export interface Publication_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Publication_default_product_ProductBundle_price;
  products: Publication_default_product_ProductBundle_products[];
  bundlePublication: Publication_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Publication_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_default_product_ProductPrintIssue_price;
  publication: Publication_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_default_product = Publication_default_product_ProductPrint | Publication_default_product_ProductBundle | Publication_default_product_ProductPrintIssue | Publication_default_product_ProductDigital | Publication_default_product_ProductService | Publication_default_product_ProductDigitalService;

export interface Publication_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface Publication_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Publication_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Publication_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Publication_bundles_products_ProductPrint_publication;
  quiz_required: Publication_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Publication_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Publication_bundles_products_ProductPrintIssue_price;
  publication: Publication_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Publication_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Publication_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Publication_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Publication_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Publication_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Publication_bundles_products = Publication_bundles_products_ProductBundle | Publication_bundles_products_ProductPrint | Publication_bundles_products_ProductPrintIssue | Publication_bundles_products_ProductDigital | Publication_bundles_products_ProductService;

export interface Publication_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Publication_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Publication_bundles_price;
  products: Publication_bundles_products[];
  bundlePublication: Publication_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: Publication_products[];
  digital_products: Publication_digital_products[];
  default_product: Publication_default_product | null;
  price: Publication_price | null;
  bundles: Publication_bundles[] | null;
  is_featured: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Slider
// ====================================================

export interface Slider {
  __typename: "Slider";
  id: string;
  image_url: string;
  subtitle: string | null;
  title: string | null;
  url: string | null;
  headline: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CompanyAddress
// ====================================================

export interface CompanyAddress_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CompanyAddress {
  __typename: "CompanyAddress";
  company_name: string;
  tax_number: string;
  postcode: string | null;
  city: string | null;
  street: string | null;
  public_place_type: CompanyAddress_public_place_type | null;
  street_number: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartItemNormal
// ====================================================

export interface CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItemNormal_product_ProductPrint_publication;
  quiz_required: CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItemNormal_product_ProductBundle_products = CartItemNormal_product_ProductBundle_products_ProductBundle | CartItemNormal_product_ProductBundle_products_ProductPrint | CartItemNormal_product_ProductBundle_products_ProductPrintIssue | CartItemNormal_product_ProductBundle_products_ProductDigital | CartItemNormal_product_ProductBundle_products_ProductService;

export interface CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: CartItemNormal_product_ProductBundle_price;
  products: CartItemNormal_product_ProductBundle_products[];
  bundlePublication: CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItemNormal_product_ProductPrintIssue_price;
  publication: CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItemNormal_product = CartItemNormal_product_ProductPrint | CartItemNormal_product_ProductBundle | CartItemNormal_product_ProductPrintIssue | CartItemNormal_product_ProductDigital | CartItemNormal_product_ProductService | CartItemNormal_product_ProductDigitalService;

export interface CartItemNormal {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: CartItemNormal_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartItemOffer
// ====================================================

export interface CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItemOffer_product_ProductPrint_publication;
  quiz_required: CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItemOffer_product_ProductBundle_products = CartItemOffer_product_ProductBundle_products_ProductBundle | CartItemOffer_product_ProductBundle_products_ProductPrint | CartItemOffer_product_ProductBundle_products_ProductPrintIssue | CartItemOffer_product_ProductBundle_products_ProductDigital | CartItemOffer_product_ProductBundle_products_ProductService;

export interface CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: CartItemOffer_product_ProductBundle_price;
  products: CartItemOffer_product_ProductBundle_products[];
  bundlePublication: CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItemOffer_product_ProductPrintIssue_price;
  publication: CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItemOffer_product = CartItemOffer_product_ProductPrint | CartItemOffer_product_ProductBundle | CartItemOffer_product_ProductPrintIssue | CartItemOffer_product_ProductDigital | CartItemOffer_product_ProductService | CartItemOffer_product_ProductDigitalService;

export interface CartItemOffer {
  __typename: "CartItemOffer";
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartItem
// ====================================================

export interface CartItem_CartItemGratis {
  __typename: "CartItemGratis" | "CartItemOffer";
}

export interface CartItem_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CartItem_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: CartItem_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface CartItem_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface CartItem_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductPrint_publication;
  quiz_required: CartItem_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: CartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemNormal_product_ProductBundle_products = CartItem_CartItemNormal_product_ProductBundle_products_ProductBundle | CartItem_CartItemNormal_product_ProductBundle_products_ProductPrint | CartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | CartItem_CartItemNormal_product_ProductBundle_products_ProductDigital | CartItem_CartItemNormal_product_ProductBundle_products_ProductService;

export interface CartItem_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: CartItem_CartItemNormal_product_ProductBundle_price;
  products: CartItem_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: CartItem_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemNormal_product_ProductPrintIssue_price;
  publication: CartItem_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemNormal_product = CartItem_CartItemNormal_product_ProductPrint | CartItem_CartItemNormal_product_ProductBundle | CartItem_CartItemNormal_product_ProductPrintIssue | CartItem_CartItemNormal_product_ProductDigital | CartItem_CartItemNormal_product_ProductService | CartItem_CartItemNormal_product_ProductDigitalService;

export interface CartItem_CartItemNormal {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: CartItem_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: CartItem_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: CartItem_CartItemNormal_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface CartItem_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CartItem_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: CartItem_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface CartItem_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface CartItem_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: CartItem_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemChristmas_product_ProductBundle_products = CartItem_CartItemChristmas_product_ProductBundle_products_ProductBundle | CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint | CartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | CartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital | CartItem_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface CartItem_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: CartItem_CartItemChristmas_product_ProductBundle_price;
  products: CartItem_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: CartItem_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemChristmas_product_ProductPrintIssue_price;
  publication: CartItem_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemChristmas_product = CartItem_CartItemChristmas_product_ProductPrint | CartItem_CartItemChristmas_product_ProductBundle | CartItem_CartItemChristmas_product_ProductPrintIssue | CartItem_CartItemChristmas_product_ProductDigital | CartItem_CartItemChristmas_product_ProductService | CartItem_CartItemChristmas_product_ProductDigitalService;

export interface CartItem_CartItemChristmas {
  __typename: "CartItemChristmas";
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: CartItem_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: CartItem_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: CartItem_CartItemChristmas_product;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface CartItem_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CartItem_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: CartItem_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface CartItem_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: CartItem_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemBundle_cartItems_product_ProductBundle_products = CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | CartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: CartItem_CartItemBundle_cartItems_product_ProductBundle_price;
  products: CartItem_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: CartItem_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: CartItem_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemBundle_cartItems_product = CartItem_CartItemBundle_cartItems_product_ProductPrint | CartItem_CartItemBundle_cartItems_product_ProductBundle | CartItem_CartItemBundle_cartItems_product_ProductPrintIssue | CartItem_CartItemBundle_cartItems_product_ProductDigital | CartItem_CartItemBundle_cartItems_product_ProductService | CartItem_CartItemBundle_cartItems_product_ProductDigitalService;

export interface CartItem_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: CartItem_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: CartItem_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: CartItem_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface CartItem_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface CartItem_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface CartItem_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface CartItem_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: CartItem_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: CartItem_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: CartItem_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: CartItem_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: CartItem_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: CartItem_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface CartItem_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface CartItem_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: CartItem_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: CartItem_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type CartItem_CartItemBundle_product_products = CartItem_CartItemBundle_product_products_ProductBundle | CartItem_CartItemBundle_product_products_ProductPrint | CartItem_CartItemBundle_product_products_ProductPrintIssue | CartItem_CartItemBundle_product_products_ProductDigital | CartItem_CartItemBundle_product_products_ProductService;

export interface CartItem_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface CartItem_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: CartItem_CartItemBundle_product_price;
  products: CartItem_CartItemBundle_product_products[];
  bundlePublication: CartItem_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface CartItem_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface CartItem_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: CartItem_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface CartItem_CartItemBundle {
  __typename: "CartItemBundle";
  id: string;
  cartItems: CartItem_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: CartItem_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: CartItem_CartItemBundle_product;
  shipping_address: CartItem_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
}

export type CartItem = CartItem_CartItemGratis | CartItem_CartItemNormal | CartItem_CartItemChristmas | CartItem_CartItemBundle;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AnyCartItem
// ====================================================

export interface AnyCartItem_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface AnyCartItem_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: AnyCartItem_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface AnyCartItem_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemNormal_product_ProductBundle_products = AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductBundle | AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrint | AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductDigital | AnyCartItem_CartItemNormal_product_ProductBundle_products_ProductService;

export interface AnyCartItem_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyCartItem_CartItemNormal_product_ProductBundle_price;
  products: AnyCartItem_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: AnyCartItem_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemNormal_product_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemNormal_product = AnyCartItem_CartItemNormal_product_ProductPrint | AnyCartItem_CartItemNormal_product_ProductBundle | AnyCartItem_CartItemNormal_product_ProductPrintIssue | AnyCartItem_CartItemNormal_product_ProductDigital | AnyCartItem_CartItemNormal_product_ProductService | AnyCartItem_CartItemNormal_product_ProductDigitalService;

export interface AnyCartItem_CartItemNormal {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: AnyCartItem_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: AnyCartItem_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: AnyCartItem_CartItemNormal_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface AnyCartItem_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface AnyCartItem_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: AnyCartItem_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface AnyCartItem_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemChristmas_product_ProductBundle_products = AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductBundle | AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrint | AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductDigital | AnyCartItem_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface AnyCartItem_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyCartItem_CartItemChristmas_product_ProductBundle_price;
  products: AnyCartItem_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: AnyCartItem_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemChristmas_product_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemChristmas_product = AnyCartItem_CartItemChristmas_product_ProductPrint | AnyCartItem_CartItemChristmas_product_ProductBundle | AnyCartItem_CartItemChristmas_product_ProductPrintIssue | AnyCartItem_CartItemChristmas_product_ProductDigital | AnyCartItem_CartItemChristmas_product_ProductService | AnyCartItem_CartItemChristmas_product_ProductDigitalService;

export interface AnyCartItem_CartItemChristmas {
  __typename: "CartItemChristmas";
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: AnyCartItem_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: AnyCartItem_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: AnyCartItem_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
}

export interface AnyCartItem_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface AnyCartItem_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: AnyCartItem_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products = AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_price;
  products: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: AnyCartItem_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemBundle_cartItems_product = AnyCartItem_CartItemBundle_cartItems_product_ProductPrint | AnyCartItem_CartItemBundle_cartItems_product_ProductBundle | AnyCartItem_CartItemBundle_cartItems_product_ProductPrintIssue | AnyCartItem_CartItemBundle_cartItems_product_ProductDigital | AnyCartItem_CartItemBundle_cartItems_product_ProductService | AnyCartItem_CartItemBundle_cartItems_product_ProductDigitalService;

export interface AnyCartItem_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: AnyCartItem_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: AnyCartItem_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: AnyCartItem_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface AnyCartItem_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface AnyCartItem_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemBundle_product_products = AnyCartItem_CartItemBundle_product_products_ProductBundle | AnyCartItem_CartItemBundle_product_products_ProductPrint | AnyCartItem_CartItemBundle_product_products_ProductPrintIssue | AnyCartItem_CartItemBundle_product_products_ProductDigital | AnyCartItem_CartItemBundle_product_products_ProductService;

export interface AnyCartItem_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyCartItem_CartItemBundle_product_price;
  products: AnyCartItem_CartItemBundle_product_products[];
  bundlePublication: AnyCartItem_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyCartItem_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface AnyCartItem_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: AnyCartItem_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface AnyCartItem_CartItemBundle {
  __typename: "CartItemBundle";
  id: string;
  cartItems: AnyCartItem_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: AnyCartItem_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: AnyCartItem_CartItemBundle_product;
  shipping_address: AnyCartItem_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
}

export interface AnyCartItem_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface AnyCartItem_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: AnyCartItem_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface AnyCartItem_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemGratis_product_ProductBundle_products = AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductBundle | AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrint | AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductDigital | AnyCartItem_CartItemGratis_product_ProductBundle_products_ProductService;

export interface AnyCartItem_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyCartItem_CartItemGratis_product_ProductBundle_price;
  products: AnyCartItem_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: AnyCartItem_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemGratis_product_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemGratis_product = AnyCartItem_CartItemGratis_product_ProductPrint | AnyCartItem_CartItemGratis_product_ProductBundle | AnyCartItem_CartItemGratis_product_ProductPrintIssue | AnyCartItem_CartItemGratis_product_ProductDigital | AnyCartItem_CartItemGratis_product_ProductService | AnyCartItem_CartItemGratis_product_ProductDigitalService;

export interface AnyCartItem_CartItemGratis {
  __typename: "CartItemGratis";
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: AnyCartItem_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: AnyCartItem_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: AnyCartItem_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
}

export interface AnyCartItem_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface AnyCartItem_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: AnyCartItem_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface AnyCartItem_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemOffer_product_ProductBundle_products = AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductBundle | AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrint | AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductDigital | AnyCartItem_CartItemOffer_product_ProductBundle_products_ProductService;

export interface AnyCartItem_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: AnyCartItem_CartItemOffer_product_ProductBundle_price;
  products: AnyCartItem_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: AnyCartItem_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: AnyCartItem_CartItemOffer_product_ProductPrintIssue_price;
  publication: AnyCartItem_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface AnyCartItem_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface AnyCartItem_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: AnyCartItem_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: AnyCartItem_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type AnyCartItem_CartItemOffer_product = AnyCartItem_CartItemOffer_product_ProductPrint | AnyCartItem_CartItemOffer_product_ProductBundle | AnyCartItem_CartItemOffer_product_ProductPrintIssue | AnyCartItem_CartItemOffer_product_ProductDigital | AnyCartItem_CartItemOffer_product_ProductService | AnyCartItem_CartItemOffer_product_ProductDigitalService;

export interface AnyCartItem_CartItemOffer {
  __typename: "CartItemOffer";
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: AnyCartItem_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: AnyCartItem_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: AnyCartItem_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
}

export type AnyCartItem = AnyCartItem_CartItemNormal | AnyCartItem_CartItemChristmas | AnyCartItem_CartItemBundle | AnyCartItem_CartItemGratis | AnyCartItem_CartItemOffer;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Cart
// ====================================================

export interface Cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: Cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemNormal_product_ProductBundle_products = Cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | Cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | Cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | Cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface Cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Cart_items_CartItemNormal_product_ProductBundle_price;
  products: Cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: Cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: Cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemNormal_product = Cart_items_CartItemNormal_product_ProductPrint | Cart_items_CartItemNormal_product_ProductBundle | Cart_items_CartItemNormal_product_ProductPrintIssue | Cart_items_CartItemNormal_product_ProductDigital | Cart_items_CartItemNormal_product_ProductService | Cart_items_CartItemNormal_product_ProductDigitalService;

export interface Cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: Cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: Cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: Cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemChristmas_product_ProductBundle_products = Cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | Cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | Cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | Cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface Cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Cart_items_CartItemChristmas_product_ProductBundle_price;
  products: Cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: Cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: Cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemChristmas_product = Cart_items_CartItemChristmas_product_ProductPrint | Cart_items_CartItemChristmas_product_ProductBundle | Cart_items_CartItemChristmas_product_ProductPrintIssue | Cart_items_CartItemChristmas_product_ProductDigital | Cart_items_CartItemChristmas_product_ProductService | Cart_items_CartItemChristmas_product_ProductDigitalService;

export interface Cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: Cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: Cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: Cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemBundle_cartItems_product_ProductBundle_products = Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | Cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: Cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: Cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemBundle_cartItems_product = Cart_items_CartItemBundle_cartItems_product_ProductPrint | Cart_items_CartItemBundle_cartItems_product_ProductBundle | Cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | Cart_items_CartItemBundle_cartItems_product_ProductDigital | Cart_items_CartItemBundle_cartItems_product_ProductService | Cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface Cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: Cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: Cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: Cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: Cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: Cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemBundle_product_products = Cart_items_CartItemBundle_product_products_ProductBundle | Cart_items_CartItemBundle_product_products_ProductPrint | Cart_items_CartItemBundle_product_products_ProductPrintIssue | Cart_items_CartItemBundle_product_products_ProductDigital | Cart_items_CartItemBundle_product_products_ProductService;

export interface Cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Cart_items_CartItemBundle_product_price;
  products: Cart_items_CartItemBundle_product_products[];
  bundlePublication: Cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: Cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: Cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: Cart_items_CartItemBundle_product;
  shipping_address: Cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface Cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: Cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemGratis_product_ProductBundle_products = Cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | Cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | Cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | Cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface Cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Cart_items_CartItemGratis_product_ProductBundle_price;
  products: Cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: Cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: Cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemGratis_product = Cart_items_CartItemGratis_product_ProductPrint | Cart_items_CartItemGratis_product_ProductBundle | Cart_items_CartItemGratis_product_ProductPrintIssue | Cart_items_CartItemGratis_product_ProductDigital | Cart_items_CartItemGratis_product_ProductService | Cart_items_CartItemGratis_product_ProductDigitalService;

export interface Cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: Cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: Cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: Cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemOffer_product_ProductBundle_products = Cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | Cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | Cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | Cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface Cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Cart_items_CartItemOffer_product_ProductBundle_price;
  products: Cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: Cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: Cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Cart_items_CartItemOffer_product = Cart_items_CartItemOffer_product_ProductPrint | Cart_items_CartItemOffer_product_ProductBundle | Cart_items_CartItemOffer_product_ProductPrintIssue | Cart_items_CartItemOffer_product_ProductDigital | Cart_items_CartItemOffer_product_ProductService | Cart_items_CartItemOffer_product_ProductDigitalService;

export interface Cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: Cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: Cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type Cart_items = Cart_items_CartItemNormal | Cart_items_CartItemChristmas | Cart_items_CartItemBundle | Cart_items_CartItemGratis | Cart_items_CartItemOffer;

export interface Cart {
  __typename: "Cart";
  type: CartType;
  items: Cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftCard
// ====================================================

export interface GiftCard {
  __typename: "GiftCard";
  code: string;
  value: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CheckoutCoupon
// ====================================================

export interface CheckoutCoupon_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface CheckoutCoupon {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: CheckoutCoupon_discount | null;
  discount_amount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartDiscount
// ====================================================

export interface CartDiscount {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Checkout
// ====================================================

export interface Checkout_gift_card {
  __typename: "GiftCard";
  code: string;
  value: number;
}

export interface Checkout_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface Checkout_user_register_shipping_addresses_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_user_register_shipping_addresses {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_user_register_shipping_addresses_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_user_register_billing_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_user_register_billing_address {
  __typename: "UserBillingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  tax_number: string | null;
  company_contact: string | null;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_user_register_billing_address_public_place_type | null;
  public_place: string | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_user_register_orders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface Checkout_user_register_orders_billing_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Checkout_user_register_orders_items_OrderItem_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_user_register_orders_items_OrderItem_product_publication_default_product {
  __typename: "ProductBundle" | "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService";
  id: number;
}

export interface Checkout_user_register_orders_items_OrderItem_product_publication {
  __typename: "Publication";
  id: number;
  default_product: Checkout_user_register_orders_items_OrderItem_product_publication_default_product | null;
}

export interface Checkout_user_register_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: Checkout_user_register_orders_items_OrderItem_product_price | null;
  publication: Checkout_user_register_orders_items_OrderItem_product_publication | null;
}

export interface Checkout_user_register_orders_items_OrderItem_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Checkout_user_register_orders_items_OrderItem {
  __typename: "OrderItem";
  product: Checkout_user_register_orders_items_OrderItem_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: Checkout_user_register_orders_items_OrderItem_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface Checkout_user_register_orders_items_OrderItemBundle_orderItems_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_user_register_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  id: number;
  am_code: number;
  name: string;
  slug: string | null;
  external_url: string | null;
  image: string | null;
  type_info: string;
  text_featured: string | null;
  text_more: string | null;
  text_gift: string | null;
  marketing_code: number;
  discount_price: number | null;
  susperiod: number;
  is_visible: boolean | null;
  price: Checkout_user_register_orders_items_OrderItemBundle_orderItems_product_price | null;
}

export interface Checkout_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Checkout_user_register_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: Checkout_user_register_orders_items_OrderItemBundle_orderItems_product;
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: Checkout_user_register_orders_items_OrderItemBundle_orderItems_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export interface Checkout_user_register_orders_items_OrderItemBundle_shipping_public_place_type {
  __typename: "PublicPlaceType";
  name: string;
}

export interface Checkout_user_register_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  is_expanded: boolean;
  orderItems: Checkout_user_register_orders_items_OrderItemBundle_orderItems[];
  subscription_start: GqlDate | null;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: Checkout_user_register_orders_items_OrderItemBundle_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
}

export type Checkout_user_register_orders_items = Checkout_user_register_orders_items_OrderItem | Checkout_user_register_orders_items_OrderItemBundle;

export interface Checkout_user_register_orders_batch_latest_transaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
}

export interface Checkout_user_register_orders_batch {
  __typename: "OrderBatch";
  latest_transaction: Checkout_user_register_orders_batch_latest_transaction | null;
}

export interface Checkout_user_register_orders {
  __typename: "Order";
  id: number;
  order_batch_id: number;
  status: OrderStatus;
  payment_method: Checkout_user_register_orders_payment_method;
  created_at: GqlDateTime;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: Checkout_user_register_orders_billing_public_place_type | null;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  items: Checkout_user_register_orders_items[];
  batch: Checkout_user_register_orders_batch;
}

export interface Checkout_user_register {
  __typename: "User";
  id: number | null;
  ext_id: string | null;
  partner_code: string | null;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  contact_phone: string | null;
  is_subscribed_to_newsletter: boolean;
  shipping_addresses: Checkout_user_register_shipping_addresses[];
  billing_address: Checkout_user_register_billing_address | null;
  orders: Checkout_user_register_orders[];
  offer_ids: string[] | null;
}

export interface Checkout_cart_items_CartItemNormal_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_cart_items_CartItemNormal_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_cart_items_CartItemNormal_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_cart_items_CartItemNormal_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemNormal_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemNormal_product_ProductBundle_products = Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductBundle | Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrint | Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductPrintIssue | Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductDigital | Checkout_cart_items_CartItemNormal_product_ProductBundle_products_ProductService;

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Checkout_cart_items_CartItemNormal_product_ProductBundle_price;
  products: Checkout_cart_items_CartItemNormal_product_ProductBundle_products[];
  bundlePublication: Checkout_cart_items_CartItemNormal_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemNormal_product_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemNormal_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemNormal_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemNormal_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemNormal_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemNormal_product = Checkout_cart_items_CartItemNormal_product_ProductPrint | Checkout_cart_items_CartItemNormal_product_ProductBundle | Checkout_cart_items_CartItemNormal_product_ProductPrintIssue | Checkout_cart_items_CartItemNormal_product_ProductDigital | Checkout_cart_items_CartItemNormal_product_ProductService | Checkout_cart_items_CartItemNormal_product_ProductDigitalService;

export interface Checkout_cart_items_CartItemNormal {
  __typename: "CartItemNormal";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Checkout_cart_items_CartItemNormal_shipping_address | null;
  discount_amount: number;
  discounts: Checkout_cart_items_CartItemNormal_discounts[];
  price: number;
  price_original: number;
  product: Checkout_cart_items_CartItemNormal_product;
  is_recurring: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Checkout_cart_items_CartItemChristmas_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_cart_items_CartItemChristmas_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_cart_items_CartItemChristmas_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemChristmas_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemChristmas_product_ProductBundle_products = Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductBundle | Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrint | Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductPrintIssue | Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductDigital | Checkout_cart_items_CartItemChristmas_product_ProductBundle_products_ProductService;

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Checkout_cart_items_CartItemChristmas_product_ProductBundle_price;
  products: Checkout_cart_items_CartItemChristmas_product_ProductBundle_products[];
  bundlePublication: Checkout_cart_items_CartItemChristmas_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemChristmas_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemChristmas_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemChristmas_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemChristmas_product = Checkout_cart_items_CartItemChristmas_product_ProductPrint | Checkout_cart_items_CartItemChristmas_product_ProductBundle | Checkout_cart_items_CartItemChristmas_product_ProductPrintIssue | Checkout_cart_items_CartItemChristmas_product_ProductDigital | Checkout_cart_items_CartItemChristmas_product_ProductService | Checkout_cart_items_CartItemChristmas_product_ProductDigitalService;

export interface Checkout_cart_items_CartItemChristmas {
  __typename: "CartItemChristmas";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Checkout_cart_items_CartItemChristmas_shipping_address | null;
  discount_amount: number;
  discounts: Checkout_cart_items_CartItemChristmas_discounts[];
  price: number;
  price_original: number;
  product: Checkout_cart_items_CartItemChristmas_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_cart_items_CartItemBundle_cartItems_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products = Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductBundle | Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrint | Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductPrintIssue | Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductDigital | Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products_ProductService;

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_price;
  products: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_products[];
  bundlePublication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemBundle_cartItems_product = Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrint | Checkout_cart_items_CartItemBundle_cartItems_product_ProductBundle | Checkout_cart_items_CartItemBundle_cartItems_product_ProductPrintIssue | Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigital | Checkout_cart_items_CartItemBundle_cartItems_product_ProductService | Checkout_cart_items_CartItemBundle_cartItems_product_ProductDigitalService;

export interface Checkout_cart_items_CartItemBundle_cartItems {
  __typename: "CartItemNormal";
  id: string;
  subscription_start: GqlDate | null;
  next_subscription_start: GqlDate | null;
  shipping_address: Checkout_cart_items_CartItemBundle_cartItems_shipping_address | null;
  discount_amount: number;
  discounts: Checkout_cart_items_CartItemBundle_cartItems_discounts[];
  price: number;
  price_original: number;
  product: Checkout_cart_items_CartItemBundle_cartItems_product;
  is_recurring: boolean;
  is_next_subscription_start_overridden: boolean;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Checkout_cart_items_CartItemBundle_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Checkout_cart_items_CartItemBundle_product_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_product_products_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemBundle_product_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemBundle_product_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_product_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_product_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemBundle_product_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemBundle_product_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemBundle_product_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemBundle_product_products = Checkout_cart_items_CartItemBundle_product_products_ProductBundle | Checkout_cart_items_CartItemBundle_product_products_ProductPrint | Checkout_cart_items_CartItemBundle_product_products_ProductPrintIssue | Checkout_cart_items_CartItemBundle_product_products_ProductDigital | Checkout_cart_items_CartItemBundle_product_products_ProductService;

export interface Checkout_cart_items_CartItemBundle_product_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemBundle_product {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Checkout_cart_items_CartItemBundle_product_price;
  products: Checkout_cart_items_CartItemBundle_product_products[];
  bundlePublication: Checkout_cart_items_CartItemBundle_product_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Checkout_cart_items_CartItemBundle_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_cart_items_CartItemBundle_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_cart_items_CartItemBundle_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_cart_items_CartItemBundle {
  __typename: "CartItemBundle";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  cartItems: Checkout_cart_items_CartItemBundle_cartItems[];
  discount_amount: number;
  discounts: Checkout_cart_items_CartItemBundle_discounts[];
  is_expanded: boolean;
  price: number;
  price_original: number;
  product: Checkout_cart_items_CartItemBundle_product;
  shipping_address: Checkout_cart_items_CartItemBundle_shipping_address | null;
  subscription_start: GqlDate | null;
  transfer: GqlDate | null;
}

export interface Checkout_cart_items_CartItemGratis_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_cart_items_CartItemGratis_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_cart_items_CartItemGratis_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_cart_items_CartItemGratis_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemGratis_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemGratis_product_ProductBundle_products = Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductBundle | Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrint | Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductPrintIssue | Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductDigital | Checkout_cart_items_CartItemGratis_product_ProductBundle_products_ProductService;

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Checkout_cart_items_CartItemGratis_product_ProductBundle_price;
  products: Checkout_cart_items_CartItemGratis_product_ProductBundle_products[];
  bundlePublication: Checkout_cart_items_CartItemGratis_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemGratis_product_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemGratis_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemGratis_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemGratis_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemGratis_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemGratis_product = Checkout_cart_items_CartItemGratis_product_ProductPrint | Checkout_cart_items_CartItemGratis_product_ProductBundle | Checkout_cart_items_CartItemGratis_product_ProductPrintIssue | Checkout_cart_items_CartItemGratis_product_ProductDigital | Checkout_cart_items_CartItemGratis_product_ProductService | Checkout_cart_items_CartItemGratis_product_ProductDigitalService;

export interface Checkout_cart_items_CartItemGratis {
  __typename: "CartItemGratis";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Checkout_cart_items_CartItemGratis_shipping_address | null;
  discount_amount: number;
  discounts: Checkout_cart_items_CartItemGratis_discounts[];
  price: number;
  price_original: number;
  product: Checkout_cart_items_CartItemGratis_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export interface Checkout_cart_items_CartItemOffer_shipping_address_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface Checkout_cart_items_CartItemOffer_shipping_address {
  __typename: "UserShippingAddress";
  id: number | null;
  is_company: boolean;
  company_name: string | null;
  company_contact: string | null;
  tax_number: string | null;
  title: string | null;
  is_default: boolean;
  contact_prefix: string | null;
  contact_firstname: string | null;
  contact_lastname: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type: Checkout_cart_items_CartItemOffer_shipping_address_public_place_type | null;
  street_number: string;
  staircase: string | null;
  floor: string | null;
  door_bell: string | null;
  door_number: string | null;
  other: string | null;
}

export interface Checkout_cart_items_CartItemOffer_discounts {
  __typename: "CartDiscount";
  amount: number;
  title: string;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemOffer_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemOffer_product_ProductBundle_products = Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductBundle | Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrint | Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductPrintIssue | Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductDigital | Checkout_cart_items_CartItemOffer_product_ProductBundle_products_ProductService;

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Checkout_cart_items_CartItemOffer_product_ProductBundle_price;
  products: Checkout_cart_items_CartItemOffer_product_ProductBundle_products[];
  bundlePublication: Checkout_cart_items_CartItemOffer_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Checkout_cart_items_CartItemOffer_product_ProductPrintIssue_price;
  publication: Checkout_cart_items_CartItemOffer_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Checkout_cart_items_CartItemOffer_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Checkout_cart_items_CartItemOffer_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Checkout_cart_items_CartItemOffer_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Checkout_cart_items_CartItemOffer_product = Checkout_cart_items_CartItemOffer_product_ProductPrint | Checkout_cart_items_CartItemOffer_product_ProductBundle | Checkout_cart_items_CartItemOffer_product_ProductPrintIssue | Checkout_cart_items_CartItemOffer_product_ProductDigital | Checkout_cart_items_CartItemOffer_product_ProductService | Checkout_cart_items_CartItemOffer_product_ProductDigitalService;

export interface Checkout_cart_items_CartItemOffer {
  __typename: "CartItemOffer";
  next_subscription_start: GqlDate | null;
  is_next_subscription_start_overridden: boolean;
  id: string;
  subscription_start: GqlDate | null;
  shipping_address: Checkout_cart_items_CartItemOffer_shipping_address | null;
  discount_amount: number;
  discount_percent: number;
  discounts: Checkout_cart_items_CartItemOffer_discounts[];
  price: number;
  price_original: number;
  product: Checkout_cart_items_CartItemOffer_product;
  transfer: GqlDate | null;
  subscription_dates: (GqlDate | null)[] | null;
}

export type Checkout_cart_items = Checkout_cart_items_CartItemNormal | Checkout_cart_items_CartItemChristmas | Checkout_cart_items_CartItemBundle | Checkout_cart_items_CartItemGratis | Checkout_cart_items_CartItemOffer;

export interface Checkout_cart {
  __typename: "Cart";
  type: CartType;
  items: Checkout_cart_items[];
  discount_total: number;
  grand_total: number;
  sub_total: number;
  messages: string[];
}

export interface Checkout_coupons_discount {
  __typename: "DiscountNormal";
  title: string;
  discount_type: DiscountType;
}

export interface Checkout_coupons {
  __typename: "CheckoutCoupon";
  code: string;
  is_valid: boolean;
  discount: Checkout_coupons_discount | null;
  discount_amount: number;
}

export interface Checkout_discounts {
  __typename: "CartDiscount";
  title: string;
  amount: number;
  partner_code: string | null;
}

export interface Checkout {
  __typename: "Checkout";
  sub_total: number;
  discount_total: number;
  grand_total: number;
  gift_card: Checkout_gift_card | null;
  billing_address: Checkout_billing_address | null;
  payment_method: Checkout_payment_method | null;
  require_billing_address: boolean;
  post_invoice: boolean;
  user_register: Checkout_user_register | null;
  cart: Checkout_cart;
  coupons: (Checkout_coupons | null)[];
  discounts: Checkout_discounts[];
  coupon_earned: string | null;
  is_register: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LandingNotification
// ====================================================

export interface LandingNotification {
  __typename: "LandingNotification";
  id: string;
  name: string;
  title: string;
  content: string | null;
  is_accept_required: boolean;
  is_active: boolean;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OpkTransaction
// ====================================================

export interface OpkTransaction {
  __typename: "OpkTransaction";
  code: string;
  status: string;
  simple_transaction_id: string | null;
  payment_method_type: PaymentMethodType | null;
  success: boolean;
  error_message: string | null;
  order_batch_code: string;
  redirect_url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductSegment
// ====================================================

export interface ProductSegment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_products_ProductPrint_publication;
  quiz_required: ProductSegment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: ProductSegment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: ProductSegment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_products_ProductBundle_products = ProductSegment_publications_products_ProductBundle_products_ProductBundle | ProductSegment_publications_products_ProductBundle_products_ProductPrint | ProductSegment_publications_products_ProductBundle_products_ProductPrintIssue | ProductSegment_publications_products_ProductBundle_products_ProductDigital | ProductSegment_publications_products_ProductBundle_products_ProductService;

export interface ProductSegment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: ProductSegment_publications_products_ProductBundle_price;
  products: ProductSegment_publications_products_ProductBundle_products[];
  bundlePublication: ProductSegment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface ProductSegment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_products_ProductPrintIssue_price;
  publication: ProductSegment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_products = ProductSegment_publications_products_ProductPrint | ProductSegment_publications_products_ProductBundle | ProductSegment_publications_products_ProductPrintIssue | ProductSegment_publications_products_ProductDigital | ProductSegment_publications_products_ProductService | ProductSegment_publications_products_ProductDigitalService;

export interface ProductSegment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductPrint_publication;
  quiz_required: ProductSegment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: ProductSegment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_digital_products_ProductBundle_products = ProductSegment_publications_digital_products_ProductBundle_products_ProductBundle | ProductSegment_publications_digital_products_ProductBundle_products_ProductPrint | ProductSegment_publications_digital_products_ProductBundle_products_ProductPrintIssue | ProductSegment_publications_digital_products_ProductBundle_products_ProductDigital | ProductSegment_publications_digital_products_ProductBundle_products_ProductService;

export interface ProductSegment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: ProductSegment_publications_digital_products_ProductBundle_price;
  products: ProductSegment_publications_digital_products_ProductBundle_products[];
  bundlePublication: ProductSegment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_digital_products_ProductPrintIssue_price;
  publication: ProductSegment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_digital_products = ProductSegment_publications_digital_products_ProductPrint | ProductSegment_publications_digital_products_ProductBundle | ProductSegment_publications_digital_products_ProductPrintIssue | ProductSegment_publications_digital_products_ProductDigital | ProductSegment_publications_digital_products_ProductService | ProductSegment_publications_digital_products_ProductDigitalService;

export interface ProductSegment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_default_product_ProductPrint_publication;
  quiz_required: ProductSegment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: ProductSegment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: ProductSegment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_default_product_ProductBundle_products = ProductSegment_publications_default_product_ProductBundle_products_ProductBundle | ProductSegment_publications_default_product_ProductBundle_products_ProductPrint | ProductSegment_publications_default_product_ProductBundle_products_ProductPrintIssue | ProductSegment_publications_default_product_ProductBundle_products_ProductDigital | ProductSegment_publications_default_product_ProductBundle_products_ProductService;

export interface ProductSegment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: ProductSegment_publications_default_product_ProductBundle_price;
  products: ProductSegment_publications_default_product_ProductBundle_products[];
  bundlePublication: ProductSegment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_default_product_ProductPrintIssue_price;
  publication: ProductSegment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_default_product = ProductSegment_publications_default_product_ProductPrint | ProductSegment_publications_default_product_ProductBundle | ProductSegment_publications_default_product_ProductPrintIssue | ProductSegment_publications_default_product_ProductDigital | ProductSegment_publications_default_product_ProductService | ProductSegment_publications_default_product_ProductDigitalService;

export interface ProductSegment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface ProductSegment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface ProductSegment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_publications_bundles_products_ProductPrint_publication;
  quiz_required: ProductSegment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_publications_bundles_products_ProductPrintIssue_price;
  publication: ProductSegment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_publications_bundles_products = ProductSegment_publications_bundles_products_ProductBundle | ProductSegment_publications_bundles_products_ProductPrint | ProductSegment_publications_bundles_products_ProductPrintIssue | ProductSegment_publications_bundles_products_ProductDigital | ProductSegment_publications_bundles_products_ProductService;

export interface ProductSegment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: ProductSegment_publications_bundles_price;
  products: ProductSegment_publications_bundles_products[];
  bundlePublication: ProductSegment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface ProductSegment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: ProductSegment_publications_products[];
  digital_products: ProductSegment_publications_digital_products[];
  default_product: ProductSegment_publications_default_product | null;
  price: ProductSegment_publications_price | null;
  bundles: ProductSegment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface ProductSegment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_products_ProductPrint_publication;
  quiz_required: ProductSegment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface ProductSegment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface ProductSegment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: ProductSegment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: ProductSegment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: ProductSegment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: ProductSegment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_products_ProductBundle_products = ProductSegment_products_ProductBundle_products_ProductBundle | ProductSegment_products_ProductBundle_products_ProductPrint | ProductSegment_products_ProductBundle_products_ProductPrintIssue | ProductSegment_products_ProductBundle_products_ProductDigital | ProductSegment_products_ProductBundle_products_ProductService;

export interface ProductSegment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: ProductSegment_products_ProductBundle_price;
  products: ProductSegment_products_ProductBundle_products[];
  bundlePublication: ProductSegment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface ProductSegment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: ProductSegment_products_ProductPrintIssue_price;
  publication: ProductSegment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: ProductSegment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface ProductSegment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface ProductSegment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface ProductSegment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: ProductSegment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: ProductSegment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type ProductSegment_products = ProductSegment_products_ProductPrint | ProductSegment_products_ProductBundle | ProductSegment_products_ProductPrintIssue | ProductSegment_products_ProductDigital | ProductSegment_products_ProductService | ProductSegment_products_ProductDigitalService;

export interface ProductSegment {
  __typename: "ProductSegment";
  publications: ProductSegment_publications[];
  products: ProductSegment_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DiscountNormal
// ====================================================

export interface DiscountNormal_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_products_ProductBundle_products = DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle | DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint | DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital | DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService;

export interface DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountNormal_product_segment_publications_products_ProductBundle_price;
  products: DiscountNormal_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_products = DiscountNormal_product_segment_publications_products_ProductPrint | DiscountNormal_product_segment_publications_products_ProductBundle | DiscountNormal_product_segment_publications_products_ProductPrintIssue | DiscountNormal_product_segment_publications_products_ProductDigital | DiscountNormal_product_segment_publications_products_ProductService | DiscountNormal_product_segment_publications_products_ProductDigitalService;

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_digital_products_ProductBundle_products = DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountNormal_product_segment_publications_digital_products_ProductBundle_price;
  products: DiscountNormal_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_digital_products = DiscountNormal_product_segment_publications_digital_products_ProductPrint | DiscountNormal_product_segment_publications_digital_products_ProductBundle | DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue | DiscountNormal_product_segment_publications_digital_products_ProductDigital | DiscountNormal_product_segment_publications_digital_products_ProductService | DiscountNormal_product_segment_publications_digital_products_ProductDigitalService;

export interface DiscountNormal_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_default_product_ProductBundle_products = DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle | DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint | DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital | DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountNormal_product_segment_publications_default_product_ProductBundle_price;
  products: DiscountNormal_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_default_product = DiscountNormal_product_segment_publications_default_product_ProductPrint | DiscountNormal_product_segment_publications_default_product_ProductBundle | DiscountNormal_product_segment_publications_default_product_ProductPrintIssue | DiscountNormal_product_segment_publications_default_product_ProductDigital | DiscountNormal_product_segment_publications_default_product_ProductService | DiscountNormal_product_segment_publications_default_product_ProductDigitalService;

export interface DiscountNormal_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_publications_bundles_products = DiscountNormal_product_segment_publications_bundles_products_ProductBundle | DiscountNormal_product_segment_publications_bundles_products_ProductPrint | DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue | DiscountNormal_product_segment_publications_bundles_products_ProductDigital | DiscountNormal_product_segment_publications_bundles_products_ProductService;

export interface DiscountNormal_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountNormal_product_segment_publications_bundles_price;
  products: DiscountNormal_product_segment_publications_bundles_products[];
  bundlePublication: DiscountNormal_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountNormal_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: DiscountNormal_product_segment_publications_products[];
  digital_products: DiscountNormal_product_segment_publications_digital_products[];
  default_product: DiscountNormal_product_segment_publications_default_product | null;
  price: DiscountNormal_product_segment_publications_price | null;
  bundles: DiscountNormal_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface DiscountNormal_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_products_ProductBundle_products = DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle | DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint | DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue | DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital | DiscountNormal_product_segment_products_ProductBundle_products_ProductService;

export interface DiscountNormal_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountNormal_product_segment_products_ProductBundle_price;
  products: DiscountNormal_product_segment_products_ProductBundle_products[];
  bundlePublication: DiscountNormal_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountNormal_product_segment_products_ProductPrintIssue_price;
  publication: DiscountNormal_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountNormal_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountNormal_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountNormal_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountNormal_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountNormal_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountNormal_product_segment_products = DiscountNormal_product_segment_products_ProductPrint | DiscountNormal_product_segment_products_ProductBundle | DiscountNormal_product_segment_products_ProductPrintIssue | DiscountNormal_product_segment_products_ProductDigital | DiscountNormal_product_segment_products_ProductService | DiscountNormal_product_segment_products_ProductDigitalService;

export interface DiscountNormal_product_segment {
  __typename: "ProductSegment";
  publications: DiscountNormal_product_segment_publications[];
  products: DiscountNormal_product_segment_products[];
}

export interface DiscountNormal {
  __typename: "DiscountNormal";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: DiscountNormal_product_segment | null;
  description: string | null;
  top_description: string | null;
  image_alignment_right: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChristmasDiscountPrice
// ====================================================

export interface ChristmasDiscountPrice {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DiscountChristmas
// ====================================================

export interface DiscountChristmas_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_products_ProductBundle_products = DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle | DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint | DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital | DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService;

export interface DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountChristmas_product_segment_publications_products_ProductBundle_price;
  products: DiscountChristmas_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_products = DiscountChristmas_product_segment_publications_products_ProductPrint | DiscountChristmas_product_segment_publications_products_ProductBundle | DiscountChristmas_product_segment_publications_products_ProductPrintIssue | DiscountChristmas_product_segment_publications_products_ProductDigital | DiscountChristmas_product_segment_publications_products_ProductService | DiscountChristmas_product_segment_publications_products_ProductDigitalService;

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products = DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price;
  products: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_digital_products = DiscountChristmas_product_segment_publications_digital_products_ProductPrint | DiscountChristmas_product_segment_publications_digital_products_ProductBundle | DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue | DiscountChristmas_product_segment_publications_digital_products_ProductDigital | DiscountChristmas_product_segment_publications_digital_products_ProductService | DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService;

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_default_product_ProductBundle_products = DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle | DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint | DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital | DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountChristmas_product_segment_publications_default_product_ProductBundle_price;
  products: DiscountChristmas_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_default_product = DiscountChristmas_product_segment_publications_default_product_ProductPrint | DiscountChristmas_product_segment_publications_default_product_ProductBundle | DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue | DiscountChristmas_product_segment_publications_default_product_ProductDigital | DiscountChristmas_product_segment_publications_default_product_ProductService | DiscountChristmas_product_segment_publications_default_product_ProductDigitalService;

export interface DiscountChristmas_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_publications_bundles_products = DiscountChristmas_product_segment_publications_bundles_products_ProductBundle | DiscountChristmas_product_segment_publications_bundles_products_ProductPrint | DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue | DiscountChristmas_product_segment_publications_bundles_products_ProductDigital | DiscountChristmas_product_segment_publications_bundles_products_ProductService;

export interface DiscountChristmas_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountChristmas_product_segment_publications_bundles_price;
  products: DiscountChristmas_product_segment_publications_bundles_products[];
  bundlePublication: DiscountChristmas_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountChristmas_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: DiscountChristmas_product_segment_publications_products[];
  digital_products: DiscountChristmas_product_segment_publications_digital_products[];
  default_product: DiscountChristmas_product_segment_publications_default_product | null;
  price: DiscountChristmas_product_segment_publications_price | null;
  bundles: DiscountChristmas_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface DiscountChristmas_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_products_ProductBundle_products = DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle | DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint | DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue | DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital | DiscountChristmas_product_segment_products_ProductBundle_products_ProductService;

export interface DiscountChristmas_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: DiscountChristmas_product_segment_products_ProductBundle_price;
  products: DiscountChristmas_product_segment_products_ProductBundle_products[];
  bundlePublication: DiscountChristmas_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: DiscountChristmas_product_segment_products_ProductPrintIssue_price;
  publication: DiscountChristmas_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface DiscountChristmas_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface DiscountChristmas_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface DiscountChristmas_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: DiscountChristmas_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: DiscountChristmas_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type DiscountChristmas_product_segment_products = DiscountChristmas_product_segment_products_ProductPrint | DiscountChristmas_product_segment_products_ProductBundle | DiscountChristmas_product_segment_products_ProductPrintIssue | DiscountChristmas_product_segment_products_ProductDigital | DiscountChristmas_product_segment_products_ProductService | DiscountChristmas_product_segment_products_ProductDigitalService;

export interface DiscountChristmas_product_segment {
  __typename: "ProductSegment";
  publications: DiscountChristmas_product_segment_publications[];
  products: DiscountChristmas_product_segment_products[];
}

export interface DiscountChristmas_half_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface DiscountChristmas_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface DiscountChristmas {
  __typename: "DiscountChristmas";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: DiscountChristmas_product_segment | null;
  description: string | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  half_year: DiscountChristmas_half_year | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  year: DiscountChristmas_year | null;
  top_description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Discount
// ====================================================

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products = Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductBundle | Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrint | Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductDigital | Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products_ProductService;

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_price;
  products: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: Discount_DiscountNormal_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_products = Discount_DiscountNormal_product_segment_publications_products_ProductPrint | Discount_DiscountNormal_product_segment_publications_products_ProductBundle | Discount_DiscountNormal_product_segment_publications_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_products_ProductDigital | Discount_DiscountNormal_product_segment_publications_products_ProductService | Discount_DiscountNormal_product_segment_publications_products_ProductDigitalService;

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products = Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_price;
  products: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_digital_products = Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrint | Discount_DiscountNormal_product_segment_publications_digital_products_ProductBundle | Discount_DiscountNormal_product_segment_publications_digital_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigital | Discount_DiscountNormal_product_segment_publications_digital_products_ProductService | Discount_DiscountNormal_product_segment_publications_digital_products_ProductDigitalService;

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products = Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductBundle | Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrint | Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductDigital | Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_price;
  products: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_default_product = Discount_DiscountNormal_product_segment_publications_default_product_ProductPrint | Discount_DiscountNormal_product_segment_publications_default_product_ProductBundle | Discount_DiscountNormal_product_segment_publications_default_product_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_default_product_ProductDigital | Discount_DiscountNormal_product_segment_publications_default_product_ProductService | Discount_DiscountNormal_product_segment_publications_default_product_ProductDigitalService;

export interface Discount_DiscountNormal_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_publications_bundles_products = Discount_DiscountNormal_product_segment_publications_bundles_products_ProductBundle | Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrint | Discount_DiscountNormal_product_segment_publications_bundles_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_publications_bundles_products_ProductDigital | Discount_DiscountNormal_product_segment_publications_bundles_products_ProductService;

export interface Discount_DiscountNormal_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountNormal_product_segment_publications_bundles_price;
  products: Discount_DiscountNormal_product_segment_publications_bundles_products[];
  bundlePublication: Discount_DiscountNormal_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: Discount_DiscountNormal_product_segment_publications_products[];
  digital_products: Discount_DiscountNormal_product_segment_publications_digital_products[];
  default_product: Discount_DiscountNormal_product_segment_publications_default_product | null;
  price: Discount_DiscountNormal_product_segment_publications_price | null;
  bundles: Discount_DiscountNormal_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_products_ProductBundle_products = Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductBundle | Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrint | Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductDigital | Discount_DiscountNormal_product_segment_products_ProductBundle_products_ProductService;

export interface Discount_DiscountNormal_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountNormal_product_segment_products_ProductBundle_price;
  products: Discount_DiscountNormal_product_segment_products_ProductBundle_products[];
  bundlePublication: Discount_DiscountNormal_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountNormal_product_segment_products_ProductPrintIssue_price;
  publication: Discount_DiscountNormal_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountNormal_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountNormal_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountNormal_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountNormal_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountNormal_product_segment_products = Discount_DiscountNormal_product_segment_products_ProductPrint | Discount_DiscountNormal_product_segment_products_ProductBundle | Discount_DiscountNormal_product_segment_products_ProductPrintIssue | Discount_DiscountNormal_product_segment_products_ProductDigital | Discount_DiscountNormal_product_segment_products_ProductService | Discount_DiscountNormal_product_segment_products_ProductDigitalService;

export interface Discount_DiscountNormal_product_segment {
  __typename: "ProductSegment";
  publications: Discount_DiscountNormal_product_segment_publications[];
  products: Discount_DiscountNormal_product_segment_products[];
}

export interface Discount_DiscountNormal {
  __typename: "DiscountNormal";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: Discount_DiscountNormal_product_segment | null;
  description: string | null;
  top_description: string | null;
  image_alignment_right: boolean;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products = Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductBundle | Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrint | Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductDigital | Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products_ProductService;

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_price;
  products: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_products[];
  bundlePublication: Discount_DiscountChristmas_product_segment_publications_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_products = Discount_DiscountChristmas_product_segment_publications_products_ProductPrint | Discount_DiscountChristmas_product_segment_publications_products_ProductBundle | Discount_DiscountChristmas_product_segment_publications_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_products_ProductDigital | Discount_DiscountChristmas_product_segment_publications_products_ProductService | Discount_DiscountChristmas_product_segment_publications_products_ProductDigitalService;

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products = Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductBundle | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrint | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductDigital | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products_ProductService;

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_price;
  products: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_products[];
  bundlePublication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_digital_products = Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrint | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductBundle | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigital | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductService | Discount_DiscountChristmas_product_segment_publications_digital_products_ProductDigitalService;

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products = Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductBundle | Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrint | Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductDigital | Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products_ProductService;

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_price;
  products: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_products[];
  bundlePublication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_default_product = Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrint | Discount_DiscountChristmas_product_segment_publications_default_product_ProductBundle | Discount_DiscountChristmas_product_segment_publications_default_product_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigital | Discount_DiscountChristmas_product_segment_publications_default_product_ProductService | Discount_DiscountChristmas_product_segment_publications_default_product_ProductDigitalService;

export interface Discount_DiscountChristmas_product_segment_publications_price {
  __typename: "PublicationPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_publications_bundles_products = Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductBundle | Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrint | Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductDigital | Discount_DiscountChristmas_product_segment_publications_bundles_products_ProductService;

export interface Discount_DiscountChristmas_product_segment_publications_bundles_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_publications_bundles {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountChristmas_product_segment_publications_bundles_price;
  products: Discount_DiscountChristmas_product_segment_publications_bundles_products[];
  bundlePublication: Discount_DiscountChristmas_product_segment_publications_bundles_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_publications {
  __typename: "Publication";
  id: number;
  short_name: string;
  name: string;
  image: string | null;
  lead: string | null;
  issues_year: number | null;
  products: Discount_DiscountChristmas_product_segment_publications_products[];
  digital_products: Discount_DiscountChristmas_product_segment_publications_digital_products[];
  default_product: Discount_DiscountChristmas_product_segment_publications_default_product | null;
  price: Discount_DiscountChristmas_product_segment_publications_price | null;
  bundles: Discount_DiscountChristmas_product_segment_publications_bundles[] | null;
  is_featured: boolean;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_publication;
  quiz_required: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_products_ProductBundle_products = Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductBundle | Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrint | Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductDigital | Discount_DiscountChristmas_product_segment_products_ProductBundle_products_ProductService;

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Discount_DiscountChristmas_product_segment_products_ProductBundle_price;
  products: Discount_DiscountChristmas_product_segment_products_ProductBundle_products[];
  bundlePublication: Discount_DiscountChristmas_product_segment_products_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Discount_DiscountChristmas_product_segment_products_ProductPrintIssue_price;
  publication: Discount_DiscountChristmas_product_segment_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Discount_DiscountChristmas_product_segment_products_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Discount_DiscountChristmas_product_segment_products_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Discount_DiscountChristmas_product_segment_products_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Discount_DiscountChristmas_product_segment_products = Discount_DiscountChristmas_product_segment_products_ProductPrint | Discount_DiscountChristmas_product_segment_products_ProductBundle | Discount_DiscountChristmas_product_segment_products_ProductPrintIssue | Discount_DiscountChristmas_product_segment_products_ProductDigital | Discount_DiscountChristmas_product_segment_products_ProductService | Discount_DiscountChristmas_product_segment_products_ProductDigitalService;

export interface Discount_DiscountChristmas_product_segment {
  __typename: "ProductSegment";
  publications: Discount_DiscountChristmas_product_segment_publications[];
  products: Discount_DiscountChristmas_product_segment_products[];
}

export interface Discount_DiscountChristmas_half_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface Discount_DiscountChristmas_year {
  __typename: "ChristmasDiscountPrice";
  price_original: number;
  price: number;
  products: number[];
}

export interface Discount_DiscountChristmas {
  __typename: "DiscountChristmas";
  title: string;
  subtitle: string | null;
  lead: string | null;
  slug: string;
  image: string | null;
  image_background: string | null;
  url_terms: string | null;
  cart_total_limit: number;
  active_from: GqlDateTime | null;
  active_to: GqlDateTime | null;
  product_segment: Discount_DiscountChristmas_product_segment | null;
  description: string | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  half_year: Discount_DiscountChristmas_half_year | null;
  /**
   * Filled, when christmasDiscount query used only
   */
  year: Discount_DiscountChristmas_year | null;
  top_description: string | null;
}

export type Discount = Discount_DiscountNormal | Discount_DiscountChristmas;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OfferInit
// ====================================================

export interface OfferInit_offerProducts_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
}

export interface OfferInit_offerProducts_product {
  __typename: "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService" | "ProductBundle";
  id: number;
}

export interface OfferInit_offerProducts {
  __typename: "OfferProduct";
  id: number;
  publication: OfferInit_offerProducts_publication;
  product: OfferInit_offerProducts_product;
}

export interface OfferInit {
  __typename: "Offer";
  id: number;
  code: string;
  is_active: boolean;
  is_visible: boolean;
  active_from: GqlDateTime;
  active_to: GqlDateTime;
  offerProducts: OfferInit_offerProducts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Offer
// ====================================================

export interface Offer_offerProducts_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Offer_offerProducts_product_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Offer_offerProducts_product_ProductPrint_publication;
  quiz_required: Offer_offerProducts_product_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Offer_offerProducts_product_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductBundle_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductBundle {
  __typename: "ProductBundle" | "ProductDigitalService";
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrint_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrint_quiz_required {
  __typename: "Quiz";
  id: number;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrint_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrint {
  __typename: "ProductPrint";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  is_renewable: boolean;
  name: string;
  publication: Offer_offerProducts_product_ProductBundle_products_ProductPrint_publication;
  quiz_required: Offer_offerProducts_product_ProductBundle_products_ProductPrint_quiz_required | null;
  renew_period_month_discounted: number | null;
  susperiod: number;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  standard_price: number | null;
  monthly_standard_price: number | null;
  price: Offer_offerProducts_product_ProductBundle_products_ProductPrint_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_price;
  publication: Offer_offerProducts_product_ProductBundle_products_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Offer_offerProducts_product_ProductBundle_products_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Offer_offerProducts_product_ProductBundle_products_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductBundle_products_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Offer_offerProducts_product_ProductBundle_products_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Offer_offerProducts_product_ProductBundle_products_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Offer_offerProducts_product_ProductBundle_products = Offer_offerProducts_product_ProductBundle_products_ProductBundle | Offer_offerProducts_product_ProductBundle_products_ProductPrint | Offer_offerProducts_product_ProductBundle_products_ProductPrintIssue | Offer_offerProducts_product_ProductBundle_products_ProductDigital | Offer_offerProducts_product_ProductBundle_products_ProductService;

export interface Offer_offerProducts_product_ProductBundle_bundlePublication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductBundle {
  __typename: "ProductBundle";
  id: number;
  gift_limit: number | null;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  price: Offer_offerProducts_product_ProductBundle_price;
  products: Offer_offerProducts_product_ProductBundle_products[];
  bundlePublication: Offer_offerProducts_product_ProductBundle_bundlePublication | null;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
  has_more_addresses: boolean | null;
}

export interface Offer_offerProducts_product_ProductPrintIssue_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductPrintIssue_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductPrintIssue {
  __typename: "ProductPrintIssue";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  price: Offer_offerProducts_product_ProductPrintIssue_price;
  publication: Offer_offerProducts_product_ProductPrintIssue_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductDigital_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductDigital_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductDigital {
  __typename: "ProductDigital";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  issue_nr: string | null;
  name: string;
  publication: Offer_offerProducts_product_ProductDigital_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Offer_offerProducts_product_ProductDigital_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductService {
  __typename: "ProductService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Offer_offerProducts_product_ProductService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Offer_offerProducts_product_ProductService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export interface Offer_offerProducts_product_ProductDigitalService_publication {
  __typename: "Publication";
  id: number;
  short_name: string;
  image: string | null;
}

export interface Offer_offerProducts_product_ProductDigitalService_price {
  __typename: "ProductPrice";
  unit_price: number;
}

export interface Offer_offerProducts_product_ProductDigitalService {
  __typename: "ProductDigitalService";
  gift_limit: number | null;
  id: number;
  image: string | null;
  discount_price: number | null;
  is_badge_discount: boolean;
  name: string;
  publication: Offer_offerProducts_product_ProductDigitalService_publication;
  text_featured: string | null;
  text_gift: string | null;
  text_more: string | null;
  type_info: string;
  external_url: string | null;
  price: Offer_offerProducts_product_ProductDigitalService_price;
  current_subscription_end: GqlDateTime | null;
  is_public: boolean | null;
  /**
   * Return only the first subscription start date when this bool is true
   */
  fix_subscription_start_date: boolean | null;
}

export type Offer_offerProducts_product = Offer_offerProducts_product_ProductPrint | Offer_offerProducts_product_ProductBundle | Offer_offerProducts_product_ProductPrintIssue | Offer_offerProducts_product_ProductDigital | Offer_offerProducts_product_ProductService | Offer_offerProducts_product_ProductDigitalService;

export interface Offer_offerProducts {
  __typename: "OfferProduct";
  id: number;
  publication: Offer_offerProducts_publication;
  product: Offer_offerProducts_product;
  price: number;
  original_price: number;
  discount_percent: number;
  vat: number;
}

export interface Offer {
  __typename: "Offer";
  id: number;
  code: string;
  is_active: boolean;
  active_from: GqlDateTime;
  active_to: GqlDateTime;
  lead: string | null;
  offerProducts: Offer_offerProducts[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OfferDetails
// ====================================================

export interface OfferDetails_shipping_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface OfferDetails_billing_public_place_type {
  __typename: "PublicPlaceType";
  id: number;
  name: string;
}

export interface OfferDetails_offerProducts_product {
  __typename: "ProductPrint" | "ProductPrintIssue" | "ProductDigital" | "ProductService" | "ProductDigitalService" | "ProductBundle";
}

export interface OfferDetails_offerProducts {
  __typename: "OfferProduct";
  id: number;
  price: number;
  original_price: number;
  discount_percent: number;
  vat: number;
  product: OfferDetails_offerProducts_product;
  next_transfer_date: GqlDate | null;
}

export interface OfferDetails_paymentMethod {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  type: PaymentMethodType;
  description: string | null;
}

export interface OfferDetails {
  __typename: "Offer";
  id: number;
  code: string;
  lead: string | null;
  is_active: boolean;
  active_from: GqlDateTime;
  active_to: GqlDateTime;
  subscription_start: GqlDateTime;
  email: string;
  contact_prefix: string | null;
  contact_firstname: string;
  contact_lastname: string;
  contact_phone: string;
  shipping_is_company: boolean | null;
  shipping_company_name: string | null;
  shipping_company_contact: string | null;
  shipping_tax_number: string | null;
  shipping_contact_prefix: string | null;
  shipping_contact_firstname: string | null;
  shipping_contact_lastname: string | null;
  shipping_postcode: string | null;
  shipping_city: string | null;
  shipping_street: string | null;
  shipping_public_place_type: OfferDetails_shipping_public_place_type | null;
  shipping_street_number: string | null;
  shipping_staircase: string | null;
  shipping_floor: string | null;
  shipping_door_number: string | null;
  shipping_door_bell: string | null;
  shipping_other: string | null;
  billing_is_company: boolean | null;
  billing_company_name: string | null;
  billing_company_contact: string | null;
  billing_tax_number: string | null;
  billing_contact_prefix: string | null;
  billing_contact_firstname: string | null;
  billing_contact_lastname: string | null;
  billing_country: string | null;
  billing_postcode: string | null;
  billing_city: string | null;
  billing_street: string | null;
  billing_public_place_type: OfferDetails_billing_public_place_type;
  billing_street_number: string | null;
  billing_staircase: string | null;
  billing_floor: string | null;
  billing_door_number: string | null;
  billing_other: string | null;
  offerProducts: OfferDetails_offerProducts[];
  paymentMethod: OfferDetails_paymentMethod;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PeriodicSubscription
// ====================================================

export interface PeriodicSubscription {
  __typename: "PeriodicSubscription";
  id: number;
  name: string;
  shipping_name: string;
  shipping_city: string;
  shipping_address: string;
  from: GqlDate;
  to: GqlDate;
  length: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentStatusResult
// ====================================================

export interface PaymentStatusResult_order_batch_orders_items_OrderItem_product {
  __typename: "ProductOrder";
  type: ProductType;
  am_code: number;
  name: string;
}

export interface PaymentStatusResult_order_batch_orders_items_OrderItem {
  __typename: "OrderItem";
  price: number;
  product: PaymentStatusResult_order_batch_orders_items_OrderItem_product;
}

export interface PaymentStatusResult_order_batch_orders_items_OrderItemBundle_orderItems_product {
  __typename: "ProductOrder";
  type: ProductType;
  am_code: number;
  name: string;
}

export interface PaymentStatusResult_order_batch_orders_items_OrderItemBundle_orderItems {
  __typename: "OrderItem";
  product: PaymentStatusResult_order_batch_orders_items_OrderItemBundle_orderItems_product;
}

export interface PaymentStatusResult_order_batch_orders_items_OrderItemBundle {
  __typename: "OrderItemBundle";
  price: number;
  orderItems: PaymentStatusResult_order_batch_orders_items_OrderItemBundle_orderItems[];
}

export type PaymentStatusResult_order_batch_orders_items = PaymentStatusResult_order_batch_orders_items_OrderItem | PaymentStatusResult_order_batch_orders_items_OrderItemBundle;

export interface PaymentStatusResult_order_batch_orders {
  __typename: "Order";
  created_at: GqlDateTime;
  order_batch_id: number;
  items: PaymentStatusResult_order_batch_orders_items[];
}

export interface PaymentStatusResult_order_batch {
  __typename: "OrderBatch";
  orders: PaymentStatusResult_order_batch_orders[];
  /**
   * Összesített bruttó ár
   */
  grand_total: number;
  /**
   * Összesített nettó ár
   */
  net_grand_total: number;
  /**
   * Összesített ÁFA
   */
  tax_total: number;
  /**
   * Összes kuponkód
   */
  coupons: string[];
}

export interface PaymentStatusResult {
  __typename: "OpkTransaction";
  code: string;
  status: string;
  simple_transaction_id: string | null;
  payment_method_type: PaymentMethodType | null;
  success: boolean;
  error_message: string | null;
  order_batch_code: string;
  redirect_url: string;
  order_batch: PaymentStatusResult_order_batch | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CartType {
  CART_TYPE_NORMAL = "CART_TYPE_NORMAL",
  CART_TYPE_RENEW = "CART_TYPE_RENEW",
  CART_TYPE_SERVICE = "CART_TYPE_SERVICE",
  CART_TYPE_SUBSCRIPTION_RENEW = "CART_TYPE_SUBSCRIPTION_RENEW",
}

export enum Country {
  COUNTRY_ANTIGUA = "COUNTRY_ANTIGUA",
  COUNTRY_ARGENTINA = "COUNTRY_ARGENTINA",
  COUNTRY_ARMENIA = "COUNTRY_ARMENIA",
  COUNTRY_AUSTRALIA = "COUNTRY_AUSTRALIA",
  COUNTRY_AUSTRIA = "COUNTRY_AUSTRIA",
  COUNTRY_BANGLADESH = "COUNTRY_BANGLADESH",
  COUNTRY_BELGIUM = "COUNTRY_BELGIUM",
  COUNTRY_BELIZE = "COUNTRY_BELIZE",
  COUNTRY_BRAZIL = "COUNTRY_BRAZIL",
  COUNTRY_BRITISH_VIRGIN_ISLAND = "COUNTRY_BRITISH_VIRGIN_ISLAND",
  COUNTRY_BULGARIA = "COUNTRY_BULGARIA",
  COUNTRY_CAMBODIA = "COUNTRY_CAMBODIA",
  COUNTRY_CANADA = "COUNTRY_CANADA",
  COUNTRY_CAYMAN_ISLANDS = "COUNTRY_CAYMAN_ISLANDS",
  COUNTRY_CHILE = "COUNTRY_CHILE",
  COUNTRY_CHINA = "COUNTRY_CHINA",
  COUNTRY_CROATIA = "COUNTRY_CROATIA",
  COUNTRY_CURACAO = "COUNTRY_CURACAO",
  COUNTRY_CYPRUS = "COUNTRY_CYPRUS",
  COUNTRY_CZECH_REPUBLIC = "COUNTRY_CZECH_REPUBLIC",
  COUNTRY_DENMARK = "COUNTRY_DENMARK",
  COUNTRY_ESTONIA = "COUNTRY_ESTONIA",
  COUNTRY_FINLAND = "COUNTRY_FINLAND",
  COUNTRY_FRANCE = "COUNTRY_FRANCE",
  COUNTRY_GABON = "COUNTRY_GABON",
  COUNTRY_GERMANY = "COUNTRY_GERMANY",
  COUNTRY_GIBRALTAR = "COUNTRY_GIBRALTAR",
  COUNTRY_GREECE = "COUNTRY_GREECE",
  COUNTRY_GUERNSEY = "COUNTRY_GUERNSEY",
  COUNTRY_HONG_KONG = "COUNTRY_HONG_KONG",
  COUNTRY_HUNGARY = "COUNTRY_HUNGARY",
  COUNTRY_ICELAND = "COUNTRY_ICELAND",
  COUNTRY_INDIA = "COUNTRY_INDIA",
  COUNTRY_INDONESIA = "COUNTRY_INDONESIA",
  COUNTRY_IRELAND = "COUNTRY_IRELAND",
  COUNTRY_ISLE_OF_MAN = "COUNTRY_ISLE_OF_MAN",
  COUNTRY_ISRAEL = "COUNTRY_ISRAEL",
  COUNTRY_ITALY = "COUNTRY_ITALY",
  COUNTRY_JAPAN = "COUNTRY_JAPAN",
  COUNTRY_KAZAKHSTAN = "COUNTRY_KAZAKHSTAN",
  COUNTRY_KOREA = "COUNTRY_KOREA",
  COUNTRY_KUWAIT = "COUNTRY_KUWAIT",
  COUNTRY_LATVIA = "COUNTRY_LATVIA",
  COUNTRY_LEBANON = "COUNTRY_LEBANON",
  COUNTRY_LIECHTENSTEIN = "COUNTRY_LIECHTENSTEIN",
  COUNTRY_LITHUANIA = "COUNTRY_LITHUANIA",
  COUNTRY_LUXEMBOURG = "COUNTRY_LUXEMBOURG",
  COUNTRY_MALAYSIA = "COUNTRY_MALAYSIA",
  COUNTRY_MALTA = "COUNTRY_MALTA",
  COUNTRY_MEXICO = "COUNTRY_MEXICO",
  COUNTRY_MONTENEGRO = "COUNTRY_MONTENEGRO",
  COUNTRY_NETHERLANDS = "COUNTRY_NETHERLANDS",
  COUNTRY_NEW_ZEALAND = "COUNTRY_NEW_ZEALAND",
  COUNTRY_NIGERIA = "COUNTRY_NIGERIA",
  COUNTRY_NORWAY = "COUNTRY_NORWAY",
  COUNTRY_OMAN = "COUNTRY_OMAN",
  COUNTRY_PAKISTAN = "COUNTRY_PAKISTAN",
  COUNTRY_PALESTINE = "COUNTRY_PALESTINE",
  COUNTRY_PANAMA = "COUNTRY_PANAMA",
  COUNTRY_PERU = "COUNTRY_PERU",
  COUNTRY_POLAND = "COUNTRY_POLAND",
  COUNTRY_PORTUGAL = "COUNTRY_PORTUGAL",
  COUNTRY_QATAR = "COUNTRY_QATAR",
  COUNTRY_ROMANIA = "COUNTRY_ROMANIA",
  COUNTRY_RUSSIAN_FEDERATION = "COUNTRY_RUSSIAN_FEDERATION",
  COUNTRY_SAN_MARINO = "COUNTRY_SAN_MARINO",
  COUNTRY_SAUDI_ARABIA = "COUNTRY_SAUDI_ARABIA",
  COUNTRY_SERBIA = "COUNTRY_SERBIA",
  COUNTRY_SEYCHELLES = "COUNTRY_SEYCHELLES",
  COUNTRY_SINGAPORE = "COUNTRY_SINGAPORE",
  COUNTRY_SLOVAKIA = "COUNTRY_SLOVAKIA",
  COUNTRY_SLOVENIA = "COUNTRY_SLOVENIA",
  COUNTRY_SOUTH_AFRICA = "COUNTRY_SOUTH_AFRICA",
  COUNTRY_SPAIN = "COUNTRY_SPAIN",
  COUNTRY_SWEDEN = "COUNTRY_SWEDEN",
  COUNTRY_SWITZERLAND = "COUNTRY_SWITZERLAND",
  COUNTRY_THAILAND = "COUNTRY_THAILAND",
  COUNTRY_TUNISIA = "COUNTRY_TUNISIA",
  COUNTRY_TURKEY = "COUNTRY_TURKEY",
  COUNTRY_UKRAINE = "COUNTRY_UKRAINE",
  COUNTRY_UNITED_ARAB_EMIRATES = "COUNTRY_UNITED_ARAB_EMIRATES",
  COUNTRY_UNITED_KINGDOM = "COUNTRY_UNITED_KINGDOM",
  COUNTRY_URUGUAY = "COUNTRY_URUGUAY",
  COUNTRY_USA = "COUNTRY_USA",
  COUNTRY_VIETNAM = "COUNTRY_VIETNAM",
}

export enum DiscountType {
  DISCOUNT_TYPE_GRATIS_PRODUCT = "DISCOUNT_TYPE_GRATIS_PRODUCT",
  DISCOUNT_TYPE_NORMAL = "DISCOUNT_TYPE_NORMAL",
  DISCOUNT_TYPE_PRICE_FRIENDLY = "DISCOUNT_TYPE_PRICE_FRIENDLY",
  DISCOUNT_TYPE_PRODUCT_REPLACEMENT = "DISCOUNT_TYPE_PRODUCT_REPLACEMENT",
  DISCOUNT_TYPE_SET = "DISCOUNT_TYPE_SET",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum OrderStatus {
  ORDER_STATUS_CODE_ACCEPTED = "ORDER_STATUS_CODE_ACCEPTED",
  ORDER_STATUS_COMPLETED = "ORDER_STATUS_COMPLETED",
  ORDER_STATUS_DELETED = "ORDER_STATUS_DELETED",
  ORDER_STATUS_FEE_REQUEST = "ORDER_STATUS_FEE_REQUEST",
  ORDER_STATUS_INIT = "ORDER_STATUS_INIT",
  ORDER_STATUS_MARKED_TO_CANCEL = "ORDER_STATUS_MARKED_TO_CANCEL",
  ORDER_STATUS_PAYMENT_PENDING = "ORDER_STATUS_PAYMENT_PENDING",
  ORDER_STATUS_PAYMENT_RECEIVED = "ORDER_STATUS_PAYMENT_RECEIVED",
  ORDER_STATUS_PAYMENT_REFUSED = "ORDER_STATUS_PAYMENT_REFUSED",
  ORDER_STATUS_PAYMENT_REMINDED = "ORDER_STATUS_PAYMENT_REMINDED",
  ORDER_STATUS_REJECTED = "ORDER_STATUS_REJECTED",
}

export enum PaymentMethodType {
  PAYMENT_METHOD_BANKTRANSFER = "PAYMENT_METHOD_BANKTRANSFER",
  PAYMENT_METHOD_CHECK = "PAYMENT_METHOD_CHECK",
  PAYMENT_METHOD_OTPSIMPLE = "PAYMENT_METHOD_OTPSIMPLE",
  PAYMENT_METHOD_OTPSIMPLESAVED = "PAYMENT_METHOD_OTPSIMPLESAVED",
  PAYMENT_METHOD_PAYPAL = "PAYMENT_METHOD_PAYPAL",
}

export enum ProductType {
  PRODUCT_TYPE_BUNDLE = "PRODUCT_TYPE_BUNDLE",
  PRODUCT_TYPE_DIGITAL = "PRODUCT_TYPE_DIGITAL",
  PRODUCT_TYPE_DIGITAL_SERVICE = "PRODUCT_TYPE_DIGITAL_SERVICE",
  PRODUCT_TYPE_PRINT = "PRODUCT_TYPE_PRINT",
  PRODUCT_TYPE_PRINT_ISSUE = "PRODUCT_TYPE_PRINT_ISSUE",
  PRODUCT_TYPE_SERVICE = "PRODUCT_TYPE_SERVICE",
}

export enum PublicationSortField {
  ID = "ID",
  NAME = "NAME",
  NAME_OVERRIDE = "NAME_OVERRIDE",
}

export interface CheckoutBillingInput {
  is_company: boolean;
  company_name?: string | null;
  company_contact?: string | null;
  tax_number?: string | null;
  contact_prefix?: string | null;
  contact_firstname?: string | null;
  contact_lastname?: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type_id: number;
  street_number: string;
  staircase?: string | null;
  floor?: string | null;
  door_bell?: string | null;
  door_number?: string | null;
}

export interface NewsletterSubscribeInput {
  email: string;
  first_name: string;
  last_name: string;
}

export interface PublicationFilters {
  id?: number | null;
  name?: string | null;
  barcode?: string | null;
  is_listed?: boolean | null;
}

export interface PublicationSort {
  field: PublicationSortField;
  direction: OrderDirection;
}

export interface SetUserPasswordInput {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

export interface UserBillingAddressInput {
  is_company: boolean;
  company_name?: string | null;
  company_contact?: string | null;
  tax_number?: string | null;
  contact_prefix?: string | null;
  contact_firstname?: string | null;
  contact_lastname?: string | null;
  country: Country;
  postcode: number;
  city: string;
  street: string;
  public_place_type_id?: number | null;
  public_place?: string | null;
  street_number: string;
  staircase?: string | null;
  floor?: string | null;
  door_bell?: string | null;
  door_number?: string | null;
  other?: string | null;
}

export interface UserContactInput {
  email: string;
  name: string;
  message: string;
  terms: boolean;
}

export interface UserLoginDataInput {
  email: string;
  password: string;
}

export interface UserPasswordUpdateInput {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface UserRegisterDataInput {
  email: string;
  contact_phone?: string | null;
  contact_prefix?: string | null;
  contact_firstname: string;
  contact_lastname: string;
  password?: string | null;
  password_confirmation?: string | null;
}

export interface UserShippingAddressInput {
  title?: string | null;
  is_default?: boolean | null;
  is_company?: boolean | null;
  company_name?: string | null;
  company_contact?: string | null;
  tax_number?: string | null;
  contact_prefix?: string | null;
  contact_firstname?: string | null;
  contact_lastname?: string | null;
  postcode: number;
  city: string;
  street: string;
  public_place_type_id?: number | null;
  public_place?: string | null;
  street_number: string;
  staircase?: string | null;
  floor?: string | null;
  door_bell?: string | null;
  door_number?: string | null;
  other?: string | null;
}

export interface UserUpdateDataInput {
  email: string;
  contact_prefix?: string | null;
  contact_firstname: string;
  contact_lastname: string;
  contact_phone?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
