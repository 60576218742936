import { Checkout } from "@api/graphql/types";
import { CheckoutActions, CheckoutActionTypes } from "@redux/actions/CheckoutAction";

export interface CheckoutReducerType {
    checkout: Checkout | null;
}

export const initialState: CheckoutReducerType = {
    checkout: null,
};

export default function CheckoutReducer(state: CheckoutReducerType = initialState, action: CheckoutActions): CheckoutReducerType {
    switch (action.type) {
        case CheckoutActionTypes.updateCheckout: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
}
