import { FormikHelpers } from "formik";
import { APIError } from "graphql-hooks";

/**
 * @param { Error } error The actual error
 * @param { FormikHelpers } formikHelpers Formik helper
 * @param { string[] } argName The argument names which fields exists in the actual form
 * @returns If the errors has been handled
 */
export function handleApiValidationErrors<T>(error: APIError<any>, formikHelpers: FormikHelpers<T>, argName: string[], fieldName?: string): boolean {
    if (error instanceof Object && typeof error.graphQLErrors !== "undefined" && error.graphQLErrors.length > 0) {
        const { graphQLErrors } = error;
        if ((graphQLErrors || [])[0]?.extensions?.validation) {
            const validationErrors = (graphQLErrors || [])[0]?.extensions?.validation;
            if (argName.length) {
                argName.forEach(argName => {
                    if (validationErrors[argName]) {
                        Object.entries(validationErrors[argName]).forEach(([key, value]) => {
                            formikHelpers.setFieldError(fieldName ? `${fieldName}.${key}` : key, (value as string[]).join(", "));
                        });
                    }
                });
            } else {
                Object.entries(validationErrors).forEach(([key, value]) => {
                    formikHelpers.setFieldError(key, (value as string[]).join(", "));
                });
            }
            return true;
        }
    }
    return false;
}

export const getCartError = (error: APIError): string => {
    let message = "";
    if (error instanceof Object && typeof error.graphQLErrors !== "undefined" && error.graphQLErrors.length > 0) {
        const { graphQLErrors } = error;
        // @ts-ignore
        if ((graphQLErrors || [])[0]?.extensions?.validation) {
            // @ts-ignore
            const validationErrors = (graphQLErrors || [])[0]?.extensions?.validation;

            if (validationErrors.cart) {
                message =
                    (Object.values(validationErrors.cart)[0] as string).length > 1
                        ? (Object.values(validationErrors.cart)[0] as string)
                        : (validationErrors.cart as string);
            }
        }
    }
    return message;
};

export const getApiErrorMessage = (error: APIError): string => {
    let message = "";
    if (error instanceof Object && typeof error.graphQLErrors !== "undefined" && error.graphQLErrors.length > 0) {
        const { graphQLErrors } = error;
        // @ts-ignore
        if ((graphQLErrors || [])[0]?.message) {
            // @ts-ignore
            message = graphQLErrors[0].message;
        }
    }
    return message;
};
