import React, { useState } from "react";

import { useRouter } from "next/router";
import styled from "styled-components";

import { Button, CloseButton } from "@components/Button";
import { Container } from "@components/Container";
import { Flex } from "@components/Flex";
import { Input } from "@components/Input";
import { SVGSearch } from "@components/SVGCollection";
import { bp, hexToRGBA } from "@styles/theme";
import { Path } from "@utils/Path";
import { useScrollbarWidth } from "@hooks/useScrollbarWidth";
import { useLoctool } from "@bigfish/react-loctool";

interface AppBarSearchProps {
    isActive: boolean;
    clickOnClose: () => void;
}

export const AppBarSearch = ({ isActive, clickOnClose }: AppBarSearchProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const router = useRouter();
    const scrollbarWidth = useScrollbarWidth();
    const Intl = useLoctool();

    return (
        <Search
            $isActive={isActive}
            onSubmit={e => {
                e.preventDefault();
                router.push(Path.search(searchTerm));
                clickOnClose();
                setSearchTerm("");
            }}
            className="header-search-form"
            scrollbarWidth={scrollbarWidth}
        >
            <SearchInner>
                <Container>
                    <SearchCloseButton onClick={clickOnClose} label={Intl.formatMessage({ id: "components.menu.SearchCloseButton" })} />
                    <SearchForm $justifyContent="center">
                        <SearchInput
                            type="text"
                            name="searchTerm"
                            label={Intl.formatMessage({ id: "components.menu.searchPlaceholder" })}
                            hideLabel
                            value={searchTerm}
                            onChange={e => {
                                setSearchTerm(e.currentTarget.value);
                            }}
                        />
                        <SearchButton type="submit" aria-label="Keresés">
                            <span>{Intl.formatMessage({ id: "components.menu.search" })}</span>
                            <SVGSearch aria-hidden={true} />
                        </SearchButton>
                    </SearchForm>
                </Container>
            </SearchInner>
        </Search>
    );
};

const SearchInner = styled.div`
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: ${props => props.theme.color.white};
    border-top: 1px dashed ${props => props.theme.color.line};
    box-shadow: 0px 30px 30px rgba(17, 29, 44, 0.4);
`;

const SearchCloseButton = styled(CloseButton)`
    display: none;

    ${bp.large} {
        display: flex;
        margin: 0 0 0 auto;
    }
`;

export const SearchForm = styled(Flex.Container).attrs({
    as: "form",
})`
    margin: 0 auto;
`;

export const SearchButton = styled(Button.Primary)`
    display: grid;
    place-items: center;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    padding: 0;
    margin-top: 0;
    margin-left: 20px;
    border-radius: 50%;

    span {
        display: none;
    }

    ${bp.large} {
        display: block;
        min-width: unset;
        width: unset;
        min-height: 50px;
        height: 50px;
        padding: 8px 30px;
        margin-left: 10px;
        border-radius: ${props => props.theme.radius.medium};

        span {
            display: block;
        }

        svg {
            display: none;
        }
    }
`;

export const SearchInput = styled(Input.Primary)`
    ${bp.medium} {
        max-width: 280px;
    }

    ${bp.large} {
        max-width: 540px;
    }

    input {
        ${bp.large} {
            height: 50px;
        }
    }
`;

const Search = styled.div<{ $isActive: boolean; scrollbarWidth?: number }>`
    display: ${props => (props.$isActive ? "block" : "none")};
    position: absolute;
    top: 60px;
    width: 100%;
    overflow: visible;

    ${bp.large} {
        top: 140px;
        max-height: calc(100% - 140px);
    }
`;
