import React from "react";

import styled from "styled-components";

import { NormalProduct, productIsDigital } from "@api/graphql/typeHelpers";
import { Button } from "@components/Button";
import { Gift, ProductImage } from "@components/Card/Elements";
import { DigitalImageFrame } from "@components/DigitalImageFrame";
import { Flex } from "@components/Flex";
import { Typography } from "@components/Typography";
import { bp } from "@styles/theme";
import { ImageUtils } from "@utils/ImageUtils";
import { useLoctool } from "@bigfish/react-loctool";
import { CURRENCY_FORMAT_OPTIONS } from "@utils/Constants";
import { HtmlUtils } from "@utils/HtmlUtils";

interface CartMenuItemProps {
    product: NormalProduct;
    quantity: number;
    className?: string;
}

export const CartMenuItem = ({ product, quantity, className }: CartMenuItemProps) => {
    const Intl = useLoctool();

    return (
        <StyledCartMenuItem className={className}>
            {productIsDigital(product) ? (
                <CartMenuItemDigitalImage
                    image={ImageUtils.productImageWithFallback(product, { width: 90 })}
                    alternate={HtmlUtils.removeTags(product.name)}
                    hideShadow
                />
            ) : (
                <CartMenuItemImage image={ImageUtils.productImageWithFallback(product, { width: 90 })} alternate={HtmlUtils.removeTags(product.name)} />
            )}
            <CartMenuItemHeader>
                <Typography.H4>
                    <div dangerouslySetInnerHTML={{ __html: HtmlUtils.sanitize(product.name) }} />
                </Typography.H4>
                <CartMenuItemPrice>
                    <Typography.H4>{Intl.formatNumber((product.discount_price ?? product.price.unit_price) * quantity, CURRENCY_FORMAT_OPTIONS)}</Typography.H4>
                    <CartMenuItemQuantity>{Intl.formatMessage({ id: "components.menu.cartMenu.count" }, { count: quantity })}</CartMenuItemQuantity>
                </CartMenuItemPrice>
            </CartMenuItemHeader>
            {product.text_gift && <StyledGift>{product.text_gift}</StyledGift>}
        </StyledCartMenuItem>
    );
};

const StyledGift = styled(Gift)`
    display: inline-flex;
    width: fit-content;
`;

const StyledCartMenuItem = styled.div`
    display: grid;
    grid-template-areas:
        "image header"
        "image details"
        "image comment";
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid ${props => props.theme.color.line};
`;

const CartMenuItemImage = styled(ProductImage)`
    grid-area: image;
    width: 100px;
`;

const CartMenuItemDigitalImage = styled(DigitalImageFrame)`
    grid-area: image;
    width: 100px;
`;

const CartMenuItemHeader = styled(Flex.Container).attrs({
    $display: "block",
    $bpMedium: {
        $display: "flex",
    },
})`
    justify-content: space-between;
    padding-bottom: 16px;

    h4 {
        font-size: 18px;
        line-height: 25px;
    }
`;

const CartMenuItemPrice = styled(Flex.Container).attrs({
    $alignItems: "center",
    $bpMedium: {
        $display: "flex",
    },
})`
    margin-top: 16px;
    white-space: nowrap;

    ${bp.medium} {
        flex-direction: column;
        align-items: flex-end;
        margin-top: 0;
        margin-left: 20px;
    }
`;

const CartMenuItemQuantity = styled(Typography.Medium)`
    margin-left: auto;

    ${bp.medium} {
        margin: 0;
    }
`;
