import styled from "styled-components";
import { Flex } from "@components/Flex";

interface DigitalImageFrameProps {
    image: {
        src: string;
        srcSet: string;
    };
    alternate: string;
    className?: string;
    hideShadow?: boolean;
}

export const DigitalImageFrame = ({ image, alternate, className, hideShadow }: DigitalImageFrameProps) => {
    return (
        <StyledDigitalImageFrame className={className}>
            <DigitalImageFrameBody>
                <DigitalImageFrameTablet src="/images/digital-frame.png" alt="" />
                <DigitalImageFrameImageWrapper>
                    <DigitalImageFrameImage {...image} alt={alternate} />
                </DigitalImageFrameImageWrapper>
                {!hideShadow && <DigitalImageShadow src="/images/digital-frame-shadow.png" alt="" />}
            </DigitalImageFrameBody>
        </StyledDigitalImageFrame>
    );
};

const StyledDigitalImageFrame = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 131.25%;
    z-index: 0;
`;

const DigitalImageFrameBody = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    position: absolute;
    top: 0;
`;

const DigitalImageFrameTablet = styled.img`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 2;
`;

const DigitalImageFrameImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: ${p => p.theme.color.imageFrame};
    overflow: hidden;
    z-index: 1;
`;

const DigitalImageFrameImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
`;

const DigitalImageShadow = styled.img`
    position: absolute;
    width: calc(100% + 40px);
    bottom: -7px;
    left: -20px;
`;
