import { Cart, AnyCartItem, CartType, AnyProduct } from "@api/graphql/types";
import { CartActions, CartActionTypes } from "@redux/actions/CartActions";

export type CartReducerType = {
    normalCart: Cart;
    serviceCart: Cart;
    allItems: AnyCartItem[];
    recommendedProducts: AnyProduct[];
};

export const initialState: CartReducerType = {
    normalCart: {
        type: CartType.CART_TYPE_NORMAL,
        items: [],
        sub_total: 0,
        grand_total: 0,
        discount_total: 0,
        messages: [],
        __typename: "Cart",
    },
    serviceCart: {
        type: CartType.CART_TYPE_SERVICE,
        items: [],
        sub_total: 0,
        grand_total: 0,
        discount_total: 0,
        messages: [],
        __typename: "Cart",
    },
    allItems: [],
    recommendedProducts: [],
};

const getCartNameByCartType = (cart: Cart): keyof CartReducerType => {
    return cart.type === CartType.CART_TYPE_NORMAL ? "normalCart" : "serviceCart";
};

export default function CartReducer(state: CartReducerType = initialState, action: CartActions): CartReducerType {
    switch (action.type) {
        case CartActionTypes.updateCart: {
            const cartName = getCartNameByCartType(action.payload.cart);
            const newState = { ...state, [cartName]: action.payload.cart };
            const allItems = [...newState.normalCart.items, ...newState.serviceCart.items];
            return { ...newState, allItems };
        }
        case CartActionTypes.updateCarts: {
            let newState = { ...state };
            action.payload.carts.forEach(cart => {
                newState = { ...newState, [getCartNameByCartType(cart)]: cart };
            });
            const allItems = [...newState.normalCart.items, ...newState.serviceCart.items];
            return { ...newState, allItems };
        }
        case CartActionTypes.updateRecommendedProducts: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
}
