import { Events } from "@utils/Constants";
import { EventEmitter } from "@utils/events";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import { hexToRGBA } from "@styles/theme";

export const UndeterminedLoadingBar = () => {
    const theme = useTheme();
    const router = useRouter();
    const [loadingVisible, setLoadingVisible] = useState(false);

    const showLoading = () => setLoadingVisible(true);
    const hideLoading = () => setLoadingVisible(false);

    useEffect(() => {
        EventEmitter.on(Events.ShowPageLoading, showLoading);
        EventEmitter.on(Events.HidePageLoading, hideLoading);
        router.events.on("routeChangeStart", showLoading);
        router.events.on("routeChangeComplete", hideLoading);
        router.events.on("routeChangeError", hideLoading);

        return () => {
            EventEmitter.off(Events.ShowPageLoading, showLoading);
            EventEmitter.off(Events.HidePageLoading, hideLoading);
            router.events.on("routeChangeStart", showLoading);
            router.events.off("routeChangeComplete", hideLoading);
            router.events.off("routeChangeError", hideLoading);
        };
    }, [router.events]);

    if (!loadingVisible) {
        return null;
    }

    return (
        <ProgressBar>
            <BarLine $color={theme.color.red} />
        </ProgressBar>
    );
};

const indeterminateAnimation = keyframes`
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.4);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
`;

const fadeInAnimation = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`;

const BarLine = styled.div<{ $color: string }>`
    display: inline-block;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        -90deg,
        ${props => hexToRGBA(props.$color, 0.1)} 0%,
        ${props => hexToRGBA(props.$color, 1)} 50%,
        ${props => hexToRGBA(props.$color, 0.1)} 100%
    );
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
    position: absolute;

    @keyframes pulse {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
`;

const ProgressBar = styled.div`
    height: 5px;
    background-color: rgba(48, 48, 48, 0.2);
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    animation: ${fadeInAnimation} 0.6s ease-in;
`;
