import { AnyCartItem } from "@api/graphql/types";
import { CART_ITEM_SUBSCRIPTION_MONTH_COUNT_FIXED, CART_ITEM_SUBSCRIPTION_MONTH_COUNT } from "@utils/Constants";

const monthNames = "Január,Február,Március,Április,Május,Június,Július,Augusztus,Szeptember,Október,November,December";

type isActiveType = {
    is_active: boolean;
    active_from?: string;
    active_to?: string;
};

function isActive<T extends isActiveType>(entity: T): boolean {
    if (!entity.is_active) {
        return false;
    }
    const now = new Date();

    if (entity.active_from) {
        const activeFrom = new Date(entity.active_from);
        if (now.getTime() < activeFrom.getTime()) {
            return false;
        }
    }

    if (entity.active_to) {
        const activeTo = new Date(entity.active_to);
        if (now.getTime() > activeTo.getTime()) {
            return false;
        }
    }
    return true;
}

export const DateUtils = {
    getOption(date: Date) {
        const month = date.getMonth() + 1;

        return {
            value: `${date.getFullYear()}-${month.toString().padStart(2, "0")}-01`,
            label: `${date.getFullYear()}. ${monthNames.split(",")[date.getMonth()]}`,
        };
    },
    subscriptionOptions(cartItem: AnyCartItem | undefined) {
        const date = cartItem?.next_subscription_start;
        const fixed = !!cartItem?.is_next_subscription_start_overridden;
        const today = date ? new Date(date) : new Date();
        if (date) {
            today.setMonth(today.getMonth() - 1);
        }

        if (cartItem?.__typename !== "CartItemBundle" && cartItem?.subscription_dates && cartItem.subscription_dates.length > 0) {
            return cartItem.subscription_dates.map(dateString => {
                return DateUtils.getOption(new Date(dateString as string));
            });
        }

        const options = [...Array(fixed ? CART_ITEM_SUBSCRIPTION_MONTH_COUNT_FIXED : CART_ITEM_SUBSCRIPTION_MONTH_COUNT).keys()].map(() => {
            return DateUtils.getOption(new Date(today.setMonth(today.getMonth() + 1)));
        });
        return options;
    },
    formatDate(start: string) {
        const date = new Date(start);

        return `${date.getFullYear()}. ${monthNames.split(",")[date.getMonth()]}`;
    },
    calculateTimeLeft(date: Date) {
        const difference = new Date(date).getTime() - new Date().getTime();
        let timeLeft = { days: "", hours: "", minutes: "", seconds: "" };
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24))
                    .toString()
                    .padStart(2, "0"),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
                    .toString()
                    .padStart(2, "0"),
                minutes: Math.floor((difference / 1000 / 60) % 60)
                    .toString()
                    .padStart(2, "0"),
                seconds: Math.floor((difference / 1000) % 60)
                    .toString()
                    .padStart(2, "0"),
            };
        }
        return timeLeft;
    },
    isActive,
    getDateString(dateString: string) {
        const date = new Date(dateString.split(" ").join("T"));
        return `${date.getFullYear()}.${date
            .getMonth()
            .toString()
            .padStart(2, "0")}.${date
            .getDay()
            .toString()
            .padStart(2, "0")}`;
    },
};
