import React from "react";
import styled from "styled-components";
import { StyledAppBarButton, AppBarButtonIcon, AppBarButtonLabel } from "@components/AppBar/AppBarButton";
import { hexToRGBA } from "@styles/theme";

interface CartButtonProps {
    label: string;
    icon: React.ReactNode;
    items?: number | null;
    onClick?: () => void;
    className?: string;
}

export const CartButton = React.forwardRef(({ label, icon, items, onClick, className }: CartButtonProps, ref) => {
    return (
        <StyledAppBarButton aria-label={label} onClick={onClick} className={className}>
            <CartButtonIcon $isEmpty={!items}>
                {icon}
                {items && <CartItems>{items}</CartItems>}
            </CartButtonIcon>
            <AppBarButtonLabel>{label}</AppBarButtonLabel>
        </StyledAppBarButton>
    );
});

const CartButtonIcon = styled(AppBarButtonIcon)<{ $isEmpty: boolean }>`
    position: relative;
    color: ${props => !props.$isEmpty && props.theme.color.white};
    background-color: ${props => !props.$isEmpty && props.theme.color.red};

    ${StyledAppBarButton}:hover & {
        background-color: ${props => (props.$isEmpty ? hexToRGBA(props.theme.color.grayD, 0.2) : props.theme.color.red)};
    }
`;

const CartItems = styled.div`
    display: grid;
    place-items: center;
    position: absolute;
    right: -14px;
    bottom: -3px;
    width: 26px;
    height: 26px;
    color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.grayD};
    border: 3px solid ${props => props.theme.color.white};
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
`;
