import React from "react";

const getTitle = (title?: string | null): JSX.Element | null => (title !== null && typeof title !== "undefined" ? <title>{title}</title> : null);

export type SVGPropType = {
    title?: string;
} & React.SVGProps<SVGSVGElement>;

export type CustomSVGElement = (props: SVGPropType) => JSX.Element;

interface SVGItemsType {
    [propName: string]: CustomSVGElement;
}

export const SVGApple = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={17} height={20} viewBox="0 0 17 20" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M5.439 20C2.462 19.983 0 13.906 0 10.812 0 5.757 3.792 4.65 5.253 4.65c.659 0 1.362.259 1.983.488.433.16.882.324 1.131.324.15 0 .502-.14.812-.263.663-.264 1.487-.592 2.448-.592h.005c.717 0 2.891.158 4.198 2.12l.306.46-.44.333c-.63.475-1.778 1.341-1.778 3.057 0 2.033 1.3 2.814 1.925 3.19.276.166.561.337.561.712 0 .244-1.95 5.49-4.781 5.49-.693 0-1.183-.208-1.615-.392-.437-.186-.814-.346-1.438-.346-.315 0-.715.15-1.138.308-.578.216-1.232.46-1.974.46h-.02zM11.927 0C12 2.66 10.099 4.504 8.199 4.388 7.886 2.266 10.1 0 11.927 0z"
            fill="currentColor"
        />
    </svg>
);

export const SVGArrowLeft = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={15} height={25} viewBox="0 0 15 25" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M12.92 23.46L2 12.54 12.54 2" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const SVGArrowRight = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={15} height={25} viewBox="0 0 15 25" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M2 2l10.92 10.92L2.38 23.46" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const SVGBag = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={20} height={23} viewBox="0 0 20 23" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M18.508 5.39H15.77A6.118 6.118 0 009.703 0a6.118 6.118 0 00-6.067 5.39H.898a.898.898 0 00-.898.9v15.812c0 .496.402.898.898.898h17.61a.898.898 0 00.898-.898V6.289a.898.898 0 00-.898-.898zM9.703 1.798a4.32 4.32 0 014.252 3.594H5.451a4.32 4.32 0 014.252-3.594zm7.906 19.406H1.797V7.188H17.609v14.015z"
            fill="currentColor"
        />
    </svg>
);

export const SVGBankCard = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M25 6.25H5a2.5 2.5 0 00-2.5 2.5v12.5a2.5 2.5 0 002.5 2.5h20a2.5 2.5 0 002.5-2.5V8.75a2.5 2.5 0 00-2.5-2.5zM2.5 17.5h25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGBasket = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M14 4l6 6-1.372 9H5.372L4 10l6-6" stroke="currentColor" strokeLinecap="square" />
        <path d="M4 10h16" stroke="currentColor" strokeLinecap="round" />
    </svg>
);

export const SVGBin = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={18} height={24} viewBox="0 0 18 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M1.286 7.607v13.214c0 .881.428 1.322 1.285 1.322H15.43c.857 0 1.285-.44 1.285-1.322V7.607M18 2.321H0h18zM11.571 1H6.43h5.142z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGCalendar = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M3 5h18v16H3V5zM21 9H3M7 5V3M17 5V3" stroke="currentColor" strokeLinecap="square" />
        <path d="M17 15h-2v2h2v-2z" stroke="currentColor" strokeLinecap="square" />
    </svg>
);

export const SVGCancel = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM5 5l14 14"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGCart = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M7.5 7.5h18.75l-1.875 11.25h-15L7.5 7.5zM11.25 25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM22.5 25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM7.5 7.5H3.75"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGCheckmark = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25z" fill="#47B795" />
        <path d="M6.25 13.75L10 17.5l8.75-8.75" stroke="#fff" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGChevron = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={17} height={10} viewBox="0 0 17 10" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M2 2l6.5 6L15 2" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGClose = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M1 15L15 1M1 1l14 14L1 1z" stroke="currentColor" strokeLinecap="square" />
    </svg>
);

export const SVGContacts = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M10 3.75v22.5M25 3.75v22.5H7.5a2.5 2.5 0 01-2.5-2.5V6.25a2.5 2.5 0 012.5-2.5H25z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="square"
        />
        <path
            d="M10 21.25c0-2.5 4.167-2.084 5.833-3.75.834-.834-1.666-.834-1.666-5 0-2.778 1.11-4.167 3.333-4.167s3.333 1.39 3.333 4.167c0 4.166-2.5 4.166-1.666 5C20.833 19.166 25 18.75 25 21.25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGDashboard = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <g clipPath="url(#i14__clip0)" stroke="currentColor" strokeLinecap="square">
            <path d="M22 2H2v20h20V2zM11 17h6M11 7h6-6zm0 5h6-6zM7 7h1m-1 5h1m-1 5h1" />
        </g>
        <defs>
            <clipPath id="i14__clip0">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export const SVGDocument = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M13 3v6h6" stroke="currentColor" strokeLinecap="round" />
        <path d="M13 3l6 6v12H5V3h8z" stroke="currentColor" strokeLinecap="square" />
        <path stroke="currentColor" d="M7 9.5h4M7 13.5h10M7 17.5h10" />
    </svg>
);

export const SVGDone = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={17} height={13} viewBox="0 0 17 13" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M2 6.875l4.063 4.063L15 2" stroke="#fff" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGError = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
        {getTitle(props.title)}
        <circle cx={12.5} cy={12.5} r={12.5} fill="#F35247" />
        <path
            d="M12.5 20a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.333zM10.833 6.667a1.667 1.667 0 013.333 0v6.666a1.667 1.667 0 11-3.333 0V6.667z"
            fill="#fff"
        />
    </svg>
);

export const SVGEye1 = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M29.333 16s-4 8-13.333 8-13.333-8-13.333-8 4-8 13.333-8 13.333 8 13.333 8z" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
        <path d="M16 20a4 4 0 100-8 4 4 0 000 8z" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGEye2 = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M29.333 16s-4 8-13.333 8-13.333-8-13.333-8 4-8 13.333-8 13.333 8 13.333 8z" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
        <path d="M16 20a4 4 0 100-8 4 4 0 000 8zM4 28L26.667 5.333" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGFacebook = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={9} height={15} viewBox="0 0 9 15" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.788 2.532c.505-.017 1.01-.005 1.516-.005h.208V.11C8.243.085 7.961.05 7.678.037 7.16.015 6.642-.008 6.123.002c-.792.016-1.54.193-2.196.625-.75.497-1.162 1.195-1.319 2.018a6.43 6.43 0 00-.092 1.037c-.014.543-.001 1.083-.001 1.626v.205H0v2.7h2.5V15h3.055V8.224h2.491l.382-2.713h-.56c-.707.003-2.334 0-2.334 0s.007-1.339.024-1.919c.024-.794.537-1.037 1.23-1.06z"
            fill="currentColor"
        />
    </svg>
);

export const SVGFile = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={18} height={22} viewBox="0 0 18 22" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M10 21.25V14.5h6.75" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        <path d="M10 21.25l6.75-6.75V1H1v20.25h9z" stroke="currentColor" strokeWidth={1.5} strokeLinecap="square" />
    </svg>
);

export const SVGHamburger = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={23} height={20} viewBox="0 0 23 20" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M1 1h21M1 9.75h21M1 18.5h21" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGHome = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M3.75 12.727V27.5h22.5V12.727L15 2.5 3.75 12.727z" stroke="currentColor" strokeWidth={1.5} strokeLinecap="square" />
        <path d="M18.75 17.5h-7.5v10h7.5v-10z" stroke="currentColor" strokeWidth={1.5} strokeLinecap="square" />
    </svg>
);

export const SVGInfo = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={20} height={21} viewBox="0 0 20 21" fill="none" {...props}>
        {getTitle(props.title)}
        <circle cx={10} cy={10.5} r={9.5} fill="currentColor" stroke="currentColor" />
        <path stroke="#fff" strokeWidth={2} d="M10.167 8.834V15.5M10.167 5.5v1.667" />
    </svg>
);

export const SVGLaptop = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M20 7v11H4V7a1 1 0 011-1h14a1 1 0 011 1zM4 17h16M2 18h20H2z" stroke="currentColor" strokeLinecap="square" />
    </svg>
);

export const SVGLogin = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M13.75 18.75L17.5 15l-3.75-3.75M5.625 15H16.25" stroke="currentColor" strokeWidth={1.5} strokeLinecap="square" />
        <path d="M17.5 15h-1.25" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
        <path d="M22.5 5v20H8.75V5H22.5z" stroke="currentColor" strokeWidth={1.5} strokeLinecap="square" />
    </svg>
);

export const SVGMinus = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={14} height={2} viewBox="0 0 14 2" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M13 1H1" stroke="currentColor" strokeLinecap="square" />
    </svg>
);

export const SVGNews = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={22} height={16} viewBox="0 0 22 16" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M21 1V13C21 14.3333 20.3333 15 19 15M19 15C17.6667 15 17 14.3333 17 13V1H1V12C1 14 2 15 4 15C6 15 11 15 19 15ZM5 10H6M10 10H13M5 6H13"
            stroke="currentColor"
            strokeLinecap="square"
        />
    </svg>
);

export const SVGNewspaper = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={23} height={23} viewBox="0 0 23 23" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M9.15 3.302H2.86V9.59h6.29V3.3zM7.801 8.243H4.208V4.65h3.594v3.594zM10.527 3.302h4.132v1.347h-4.132V3.302zM10.527 5.772h4.132V7.12h-4.132V5.772zM10.527 8.243h4.132v1.348h-4.132V8.243zM2.86 10.938h11.8v1.348H2.86v-1.348zM2.86 13.41h11.8v1.347H2.86v-1.348zM2.86 15.88h11.8v1.347H2.86V15.88zM2.86 18.35h11.8v1.348H2.86v-1.347z"
            fill="currentColor"
        />
        <path
            d="M17.52 11.547V0H0v20.368C0 21.822 1.178 23 1.69 23h17.896A3.414 3.414 0 0023 19.586v-8.039h-5.48zM1.938 21.652c-.22-.196-.59-.708-.59-1.284V1.348h14.824v18.238c0 .777.26 1.493.697 2.066H1.938zm19.714-2.066c0 1.14-.927 2.066-2.066 2.066a2.069 2.069 0 01-2.066-2.066v-6.691h4.132v6.69z"
            fill="currentColor"
        />
    </svg>
);

export const SVGPdf = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={16} height={21} viewBox="0 0 16 21" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M15.412 3.828L11.918.551A2.07 2.07 0 0010.504 0H2C.896.004 0 .844 0 1.879v16.25c0 1.035.896 1.875 2 1.875h12c1.104 0 2-.84 2-1.875V5.156c0-.496-.213-.976-.588-1.328zm-1.575 1.176h-3.17V2.03l3.17 2.973zM2 18.129V1.879h6.667V5.94c0 .52.446.938 1 .938H14v11.25H2zm10.425-5.613c-.508-.47-1.958-.34-2.683-.254-.717-.41-1.196-.977-1.534-1.809.163-.629.421-1.586.225-2.187-.175-1.024-1.575-.922-1.775-.23-.183.628-.016 1.503.292 2.62-.417.934-1.038 2.188-1.475 2.906-.833.403-1.958 1.024-2.125 1.805-.138.617 1.083 2.156 3.17-1.219.934-.289 1.95-.644 2.85-.785.788.399 1.71.664 2.326.664a.897.897 0 00.729-1.511zm-8.254 3.039c.212-.535 1.02-1.153 1.266-1.367-.791 1.183-1.266 1.394-1.266 1.367zm3.4-7.446c.308 0 .279 1.254.075 1.594-.183-.543-.18-1.594-.075-1.594zm-1.017 5.336c.404-.66.75-1.445 1.03-2.136.345.59.787 1.062 1.254 1.386-.867.168-1.621.512-2.284.75zm5.483-.195s-.208.234-1.554-.305c1.463-.101 1.704.211 1.554.305z"
            fill="currentColor"
        />
    </svg>
);

export const SVGPlus = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M13 7H1m6 6V1" stroke="currentColor" strokeWidth={2} strokeLinecap="square" />
    </svg>
);

export const SVGSearch = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={22} height={23} viewBox="0 0 22 23" fill="none" {...props}>
        {getTitle(props.title)}
        <circle cx={8.496} cy={8.496} r={7.496} stroke="currentColor" strokeWidth={2} />
        <path transform="rotate(46.378 -11.021 22.977) skewX(.001)" stroke="currentColor" strokeWidth={2} d="M0-1h9.834" />
    </svg>
);

export const SVGTelephone = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        {getTitle(props.title)}
        <circle cx={12} cy={12} r={11.3} stroke="currentColor" strokeWidth={1.4} />
        <path
            d="M6.147 10.001c.207 1.089.822 3.183 2.614 4.975 1.792 1.791 3.886 2.407 4.974 2.614 1.247.237 3.077.273 3.97-.621l.5-.498c.25-.252.39-.587.39-.943s-.14-.69-.39-.942l-1.994-1.993a1.323 1.323 0 00-.942-.39c-.356 0-.69.138-.943.39l-.498.498c-.304.305-.879.306-1.186.004l-1.988-2.072-.009-.009a.783.783 0 010-1.106l.498-.498c.521-.521.521-1.364 0-1.884L9.15 5.532a1.334 1.334 0 00-1.884 0l-.499.499c-.713.713-.951 2.235-.62 3.97zm1.508-3.083c.523-.511.495-.52.553-.52.02 0 .04.007.055.022 2.1 2.111 2.016 1.987 2.016 2.048 0 .021-.008.04-.023.054l-.498.499a2.036 2.036 0 00-.005 2.876l1.99 2.073.008.009c.794.793 2.168.796 2.965 0l.498-.498c.03-.03.08-.03.11 0 2.1 2.111 2.015 1.987 2.015 2.047 0 .022-.007.04-.022.055l-.499.498c-.341.342-1.429.546-2.848.276-.947-.18-2.768-.715-4.322-2.269-1.554-1.554-2.088-3.375-2.268-4.322-.27-1.418-.066-2.506.275-2.848z"
            fill="currentColor"
        />
    </svg>
);

export const SVGUnion = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={55} height={55} viewBox="0 0 55 55" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0526 30.6055V55H30.0526V30.6055L55 30.6055V25.6055L30.0526 25.6055V0H25.0526V25.6055L0 25.6055V30.6055L25.0526 30.6055Z"
            fill="currentColor"
        />
    </svg>
);

export const SVGUser1 = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={20} height={24} viewBox="0 0 20 24" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M10 0a6.382 6.382 0 00-6.375 6.375A6.382 6.382 0 0010 12.75a6.382 6.382 0 006.375-6.375A6.382 6.382 0 0010 0zm0 10.875a4.505 4.505 0 01-4.5-4.5c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zM10 12.773c-5.608 0-10 4.543-10 10.343h1.875c0-4.828 3.493-8.468 8.125-8.468s8.125 3.64 8.125 8.468H20c0-5.8-4.393-10.343-10-10.343z"
            fill="currentColor"
        />
    </svg>
);

export const SVGUser2 = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M5 25c0-3.75 5-3.75 7.5-6.25 1.25-1.25-2.5-1.25-2.5-7.5C10 7.084 11.666 5 15 5s5 2.084 5 6.25c0 6.25-3.75 6.25-2.5 7.5C20 21.25 25 21.25 25 25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGUser3 = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M6.25 27.25C9.75 25.75 12 24.5 13 23.5c1.5-1.5-3-1.5-3-9 0-5 2-7.5 6-7.5s6 2.5 6 7.5c0 7.5-4.5 7.5-3 9 1 1 3.25 2.25 6.75 3.75"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
        <path
            d="M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGWarning = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
        {getTitle(props.title)}
        <path
            d="M24 20v6M24 32v1M25.723 8.93l16.503 28.055a2 2 0 01-1.724 3.014H7.496a2 2 0 01-1.724-3.014L22.275 8.93a2 2 0 013.448 0z"
            stroke="#F35247"
            strokeWidth={2}
            strokeLinecap="square"
        />
    </svg>
);

export const SVGLoading = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width={60} height={60} viewBox="0 0 60 60" fill="none" {...props}>
        {getTitle(props.title)}
        <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#CDD2D8" />
        <path
            d="M42.3577 25.1814C43.9121 25.1814 45.3111 25.8032 46.5546 26.969L49.508 23.9379C47.6427 21.8395 45.3111 20.8291 42.4354 20.8291C40.5701 20.8291 38.938 21.2954 37.5391 22.1503C38.5494 23.3161 39.3266 24.7151 39.7152 26.2695C40.4147 25.57 41.2696 25.1814 42.3577 25.1814Z"
            fill="white"
        />
        <path
            d="M46.6322 33.1868C45.311 34.4303 43.912 35.0521 42.5131 35.0521C41.1918 35.0521 40.026 34.5857 39.2488 33.6531C38.3939 32.7205 38.0053 31.5547 38.0053 30.1557C38.0053 30.0003 38.0053 29.7671 38.0053 29.6117V28.679C38.0053 26.5806 37.2281 24.7153 35.9069 23.3163C34.4302 21.6842 32.3317 20.6738 29.9224 20.6738H29.6892C27.5131 20.6738 25.5701 21.5288 24.0934 22.9277C22.6944 21.6065 20.7514 20.7515 18.5753 20.7515H18.3421C13.912 20.7515 10.3369 24.3267 10.3369 28.7567V38.6272H15.311V28.679C15.311 26.9692 16.6322 25.6479 18.3421 25.6479H18.5753C20.2851 25.6479 21.6063 26.9692 21.6063 28.679V38.5495H26.5804V28.679C26.5804 26.9692 27.9794 25.6479 29.6115 25.6479H29.8447C31.5545 25.6479 32.8758 27.0469 32.8758 28.679V29.7671C32.8758 29.9225 32.8758 30.0003 32.8758 30.1557C32.8758 32.7205 33.7307 34.9743 35.5183 36.7619C37.3058 38.5495 39.482 39.4821 42.2022 39.4821C43.8343 39.4821 45.2333 39.1712 46.3991 38.6272C47.4872 38.0832 48.5753 37.2282 49.5079 36.2179L46.6322 33.1868Z"
            fill="white"
        />
    </svg>
);

export const SVGMcLogo = (props: SVGPropType): JSX.Element => (
    <svg focusable={false} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#F35247" />
        <path
            d="M42.3576 25.1814C43.912 25.1814 45.311 25.8032 46.5545 26.969L49.5078 23.9379C47.6426 21.8395 45.311 20.8291 42.4353 20.8291C40.57 20.8291 38.9379 21.2954 37.5389 22.1503C38.5493 23.3161 39.3265 24.7151 39.7151 26.2695C40.4146 25.57 41.2695 25.1814 42.3576 25.1814Z"
            fill="white"
        />
        <path
            d="M46.6321 33.1868C45.3109 34.4303 43.9119 35.0521 42.513 35.0521C41.1917 35.0521 40.0259 34.5857 39.2487 33.6531C38.3938 32.7205 38.0052 31.5547 38.0052 30.1557C38.0052 30.0003 38.0052 29.7671 38.0052 29.6117V28.679C38.0052 26.5806 37.228 24.7153 35.9067 23.3163C34.4301 21.6842 32.3316 20.6738 29.9223 20.6738H29.6891C27.513 20.6738 25.5699 21.5288 24.0933 22.9277C22.6943 21.6065 20.7513 20.7515 18.5751 20.7515H18.342C13.9119 20.7515 10.3368 24.3267 10.3368 28.7567V38.6272H15.3109V28.679C15.3109 26.9692 16.6321 25.6479 18.342 25.6479H18.5751C20.285 25.6479 21.6062 26.9692 21.6062 28.679V38.5495H26.5803V28.679C26.5803 26.9692 27.9793 25.6479 29.6114 25.6479H29.8446C31.5544 25.6479 32.8757 27.0469 32.8757 28.679V29.7671C32.8757 29.9225 32.8757 30.0003 32.8757 30.1557C32.8757 32.7205 33.7306 34.9743 35.5181 36.7619C37.3057 38.5495 39.4819 39.4821 42.2021 39.4821C43.8342 39.4821 45.2332 39.1712 46.399 38.6272C47.487 38.0832 48.5751 37.2282 49.5078 36.2179L46.6321 33.1868Z"
            fill="white"
        />
    </svg>
);

const allItems: SVGItemsType = {
    SVGApple,
    SVGArrowLeft,
    SVGArrowRight,
    SVGBag,
    SVGBankCard,
    SVGBasket,
    SVGBin,
    SVGCalendar,
    SVGCancel,
    SVGCart,
    SVGCheckmark,
    SVGChevron,
    SVGClose,
    SVGContacts,
    SVGDashboard,
    SVGDocument,
    SVGDone,
    SVGError,
    SVGEye1,
    SVGEye2,
    SVGFacebook,
    SVGFile,
    SVGHamburger,
    SVGHome,
    SVGInfo,
    SVGLaptop,
    SVGLogin,
    SVGMinus,
    SVGNews,
    SVGNewspaper,
    SVGPdf,
    SVGPlus,
    SVGSearch,
    SVGTelephone,
    SVGUser1,
    SVGUser2,
    SVGUser3,
    SVGWarning,
    SVGLoading,
};
export default allItems;
