import { gql } from "@utils/StringUtils";
import { Fragments } from "./Fragments";

class Mutations {
    public static readonly login = gql`
        mutation userLogin($credentials: UserLoginDataInput!) {
            userLogin(credentials: $credentials) {
                ...UserAuthenticationResult
            }
        }
        ${Fragments.userAuthenticationResult}
    `;

    public static readonly socialLogin = gql`
        mutation userLoginSocial($code: String!) {
            userLoginSocial(code: $code) {
                ...UserAuthenticationResult
            }
        }
        ${Fragments.userAuthenticationResult}
    `;

    public static readonly register = gql`
        mutation userRegister($input: UserRegisterDataInput!) {
            userRegister(input: $input)
        }
    `;

    public static readonly verifyEmail = gql`
        mutation userVerifyEmail($email: String!, $token: String!) {
            userVerifyEmail(email: $email, token: $token)
        }
    `;

    public static readonly userUpdate = gql`
        mutation userUpdate($input: UserUpdateDataInput!) {
            userUpdate(input: $input) {
                ...User
            }
        }
        ${Fragments.user}
    `;

    public static readonly userDelete = gql`
        mutation userDelete {
            userDelete {
                token
                user {
                    ...User
                }
            }
        }
        ${Fragments.user}
    `;

    public static readonly shippingAddressCreate = gql`
        mutation userShippingAddressCreate($address: UserShippingAddressInput) {
            userShippingAddressCreate(address: $address) {
                ...UserShippingAddress
            }
        }
        ${Fragments.userShippingAddress}
    `;

    public static readonly shippingAddressUpdate = gql`
        mutation userShippingAddressUpdate($id: Int!, $address: UserShippingAddressInput) {
            userShippingAddressUpdate(id: $id, address: $address) {
                ...UserShippingAddress
            }
        }
        ${Fragments.userShippingAddress}
    `;

    public static readonly shippingAddressDelete = gql`
        mutation userShippingAddressDelete($id: Int!) {
            userShippingAddressDelete(id: $id)
        }
    `;

    public static readonly sendContact = gql`
        mutation sendUserContact($input: UserContactInput!) {
            sendUserContact(input: $input)
        }
    `;

    public static readonly logout = gql`
        mutation userLogout {
            userLogout {
                token
                user {
                    id
                }
            }
        }
    `;

    public static readonly forgotPassword = gql`
        mutation userPasswordReset($email: String!) {
            userPasswordReset(email: $email)
        }
    `;

    public static readonly resetPassword = gql`
        mutation resetUserPassword($input: SetUserPasswordInput!) {
            resetUserPassword(input: $input)
        }
    `;

    public static readonly updatePassword = gql`
        mutation userPasswordUpdate($input: UserPasswordUpdateInput!) {
            userPasswordUpdate(input: $input)
        }
    `;

    public static readonly cartAddProduct = gql`
        mutation cartAddProduct($product_id: Int!) {
            cartAddProduct(product_id: $product_id) {
                ...Cart
            }
        }
        ${Fragments.cart}
    `;

    public static readonly cartRemove = gql`
        mutation cartRemove($cart_item_id: String!) {
            cartRemove(cart_item_id: $cart_item_id) {
                ...Cart
            }
        }
        ${Fragments.cart}
    `;

    public static readonly cartFlush = gql`
        mutation cartFlush($type: CartType!) {
            cartFlush(type: $type) {
                ...Cart
            }
        }
        ${Fragments.cart}
    `;

    public static readonly newsletterSubscribe = gql`
        mutation newsletterSubscribe($input: NewsletterSubscribeInput!) {
            newsletterSubscribe(input: $input)
        }
    `;

    // TODO: mutation needs response data beside success???
    public static readonly checkoutSetShippingAddressGuest = gql`
        mutation checkoutSetShippingAddressGuest($cart_item_id: String!, $input: UserShippingAddressInput!) {
            checkoutSetShippingAddressGuest(cart_item_id: $cart_item_id, input: $input) {
                cart {
                    items {
                        shipping_address {
                            id
                        }
                    }
                }
            }
        }
    `;

    // TODO: mutation needs response data beside success???
    public static readonly checkoutSetShippingAddressUser = gql`
        mutation checkoutSetShippingAddressUser($cart_item_id: String!, $shipping_address_id: Int, $billing_address_id: Int) {
            checkoutSetShippingAddressUser(cart_item_id: $cart_item_id, shipping_address_id: $shipping_address_id, billing_address_id: $billing_address_id) {
                cart {
                    items {
                        shipping_address {
                            id
                        }
                    }
                }
            }
        }
    `;

    // TODO: mutation needs response data beside success???
    public static readonly checkoutSetBillingAddressGuest = gql`
        mutation checkoutSetBillingAddressGuest($address: CheckoutBillingInput!) {
            checkoutSetBillingAddressGuest(address: $address) {
                billing_address {
                    id
                }
            }
        }
    `;

    // TODO: mutation needs response data beside success???
    public static readonly checkoutSetBillingAddressUser = gql`
        mutation checkoutSetBillingAddressUser($billing_address_id: Int!) {
            checkoutSetBillingAddressUser(billing_address_id: $billing_address_id) {
                billing_address {
                    id
                }
            }
        }
    `;

    public static readonly billingAddressCreate = gql`
        mutation userBillingAddressCreate($address: UserBillingAddressInput) {
            userBillingAddressCreate(address: $address) {
                ...UserBillingAddress
            }
        }
        ${Fragments.userBillingAddress}
    `;

    public static readonly billingAddressUpdate = gql`
        mutation userBillingAddressUpdate($address: UserBillingAddressInput) {
            userBillingAddressUpdate(address: $address) {
                ...UserBillingAddress
            }
        }
        ${Fragments.userBillingAddress}
    `;

    public static readonly billingAddressDelete = gql`
        mutation userBillingAddressDelete {
            userBillingAddressDelete
        }
    `;

    public static readonly checkoutInit = gql`
        mutation checkoutInit($cart_type: CartType!) {
            checkoutInit(cart_type: $cart_type) {
                ...Checkout
            }
        }
        ${Fragments.checkout}
    `;

    public static readonly checkoutStart = gql`
        mutation checkoutStart {
            checkoutStart {
                cart {
                    ...Cart
                }
            }
        }
        ${Fragments.cart}
    `;

    public static readonly setSubscriptionStart = gql`
        mutation checkoutSetSubscriptionStart($cart_item_id: String!, $subscription_start: Date!) {
            checkoutSetSubscriptionStart(cart_item_id: $cart_item_id, subscription_start: $subscription_start) {
                cart {
                    ...Cart
                }
            }
        }
        ${Fragments.cart}
    `;

    public static readonly checkoutSetUser = gql`
        mutation checkoutSetUser($input: UserRegisterDataInput!) {
            checkoutSetUser(input: $input) {
                cart {
                    ...Cart
                }
            }
        }
        ${Fragments.cart}
    `;

    public static readonly checkoutSetPaymentMethod = gql`
        mutation checkoutSetPaymentMethod($payment_method_type: PaymentMethodType!, $postInvoice: Boolean) {
            checkoutSetPaymentMethod(payment_method_type: $payment_method_type, postInvoice: $postInvoice) {
                payment_method {
                    id
                }
            }
        }
    `;

    public static readonly checkoutSubmit = gql`
        mutation checkoutSubmit($subscribe_newsletter: Boolean!, $subscribe_price_friendly_newsletter: Boolean) {
            checkoutSubmit(subscribe_newsletter: $subscribe_newsletter, subscribe_price_friendly_newsletter: $subscribe_price_friendly_newsletter) {
                code
                orders {
                    id
                }
            }
        }
    `;

    public static readonly acceptNotification = gql`
        mutation acceptNotification($id: ID!) {
            acceptLandingNotification(id: $id)
        }
    `;

    public static readonly paymentInit = gql`
        mutation paymentInit($order_batch_code: String!) {
            paymentInit(order_batch_code: $order_batch_code) {
                transaction {
                    ...OpkTransaction
                }
                redirect_url
            }
        }
        ${Fragments.opkTransaction}
    `;

    public static readonly paymentCancel = gql`
        mutation paymentCancel($transaction_code: String!) {
            paymentCancel(transaction_code: $transaction_code)
        }
    `;

    public static readonly checkoutReset = gql`
        mutation checkoutReset {
            checkoutReset {
                ...Checkout
            }
        }
        ${Fragments.checkout}
    `;

    public static readonly applyCoupon = gql`
        mutation checkoutApplyCoupon($code: String!) {
            checkoutApplyCoupon(code: $code) {
                ...Checkout
            }
        }
        ${Fragments.checkout}
    `;

    public static readonly removeCoupon = gql`
        mutation checkoutRemoveCoupon($code: String!) {
            checkoutRemoveCoupon(code: $code) {
                ...Checkout
            }
        }
        ${Fragments.checkout}
    `;

    public static readonly setBatchPaymentMethod = gql`
        mutation setBatchPaymentMethod($order_batch_code: String!, $payment_method_type: PaymentMethodType!) {
            paymentMethod(order_batch_code: $order_batch_code, payment_method_type: $payment_method_type) {
                code
            }
        }
    `;

    public static readonly cartAddOfferProduct = gql`
        mutation cartAddOfferProduct($offer_product_id: Int!) {
            cartAddOfferProduct(offer_product_id: $offer_product_id) {
                type
                items {
                    ... on CartItemOffer {
                        id
                        subscription_start
                        shipping_address {
                            ...UserShippingAddress
                        }
                        discount_amount
                        discounts {
                            amount
                            title
                        }
                        price
                        price_original
                        product {
                            ...AnyProduct
                        }
                    }
                    __typename
                }
                sub_total
            }
        }
        ${Fragments.userShippingAddress}
        ${Fragments.anyProduct}
    `;

    public static readonly cartAddChristmasProducts = gql`
        mutation cartAddChristmasProducts($product_ids: [Int!]!) {
            cartAddChristmasProducts(product_ids: $product_ids) {
                ...Cart
            }
        }
        ${Fragments.cart}
    `;

    public static readonly checkoutSetChannelCode = gql`
        mutation checkoutSetChannelCode($code: String!) {
            checkoutSetChannelCode(code: $code) {
                ...Checkout
            }
        }
        ${Fragments.checkout}
    `;

    public static readonly stopSubscription = gql`
        mutation stopSubscription($code: String!) {
            stopSubscription(code: $code) {
                code
            }
        }
    `;
}

export { Mutations };
