import React, { useMemo } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Button } from "@components/Button";
import { PopupCard } from "@components/Card/Card";
import { CartMenuItem } from "@components/Cart/CartMenuItem";
import { Container } from "@components/Container";
import { Flex } from "@components/Flex";
import { Typography } from "@components/Typography";
import { bp, hexToRGBA } from "@styles/theme";
import { Path } from "@utils/Path";
import { AnyCartItem, AnyProduct } from "@api/graphql/types";
import { NormalProduct } from "@api/graphql/typeHelpers";
import { useLoctool } from "@bigfish/react-loctool";
import { CURRENCY_FORMAT_OPTIONS } from "@utils/Constants";

interface CartMenuProps {
    items: AnyCartItem[];
    isActive: boolean;
    grandTotal: number;
    discountTotal: number;
    onClose: () => void;
}

type GroupedProducts = {
    product: AnyProduct;
    quantity: number;
};

export function groupByProducts(items: AnyCartItem[]): GroupedProducts[] {
    return items.reduce((accumulator: GroupedProducts[], currentValue: AnyCartItem) => {
        const index = accumulator.findIndex((item: GroupedProducts) => item.product.id === currentValue.product.id);
        const newGrouping = [...accumulator];

        if (index > -1) {
            newGrouping[index] = { product: newGrouping[index].product, quantity: newGrouping[index].quantity + 1 };

            return newGrouping;
        } else {
            return [...newGrouping, { product: currentValue.product, quantity: 1 }];
        }
    }, []);
}

export const CartMenu = ({ items, grandTotal, discountTotal, isActive, onClose }: CartMenuProps) => {
    const router = useRouter();
    const Intl = useLoctool();

    const onCartNavigateClick = () => {
        onClose();
        router.push(Path.cart);
    };

    const groupedProducts = useMemo(() => groupByProducts(items), [items]);

    return (
        <CartMenuContainer $isActive={isActive} className="_ce_measure_row">
            <div className="_ce_measure_column">
                <CartMenuArrow />
                <StyledCartMenu $isActive={isActive} className="_ce_measure_widget" data-ce-measure-widget="lenyilo-kosar">
                    <CartMenuWrapper>
                        <CartMenuHeader>
                            <CartMenuHeaderTitle>{Intl.formatMessage({ id: "components.menu.cartMenu.title" })}</CartMenuHeaderTitle>
                            <CartMenuHeaderQuantity>
                                {items.length} {Intl.formatMessage({ id: "components.menu.cartMenu.quantity" })}
                            </CartMenuHeaderQuantity>
                        </CartMenuHeader>
                        <CartMenuItems>
                            {groupedProducts.map(group => (
                                <StyledCartMenuItem key={group.product.id} product={group.product as NormalProduct} quantity={group.quantity} />
                            ))}
                        </CartMenuItems>
                        {!!discountTotal && (
                            <CartMenuCost>
                                <Typography.Medium>{Intl.formatMessage({ id: "pages.order.step4.totalDiscount" })}:</Typography.Medium>
                                <Typography.Large>{Intl.formatNumber(discountTotal, CURRENCY_FORMAT_OPTIONS)}</Typography.Large>
                            </CartMenuCost>
                        )}
                        <CartMenuCost>
                            <Typography.Medium>{Intl.formatMessage({ id: "components.menu.cartMenu.grandTotal" })}:</Typography.Medium>
                            <Typography.H2>{Intl.formatNumber(grandTotal, CURRENCY_FORMAT_OPTIONS)}</Typography.H2>
                        </CartMenuCost>
                    </CartMenuWrapper>
                    <CartMenuButtons>
                        <Button.Secondary onClick={onClose}>{Intl.formatMessage({ id: "components.menu.cartMenu.continueShopping" })}</Button.Secondary>
                        <Button.Primary onClick={() => onCartNavigateClick()} data-link={Path.cart}>
                            {Intl.formatMessage({ id: "components.menu.cartMenu.cartContents" })}
                        </Button.Primary>
                    </CartMenuButtons>
                </StyledCartMenu>
            </div>
        </CartMenuContainer>
    );
};

const CartMenuContainer = styled(Container)<{ $isActive: boolean }>`
    display: ${props => (props.$isActive ? "block" : "none")};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    overflow: visible;
    padding: 0;
    z-index: 400;

    ${bp.medium} {
        padding: 0 16px;
        pointer-events: none;
    }

    ${bp.large} {
        padding: 0 50px;
    }
`;

const CartMenuArrow = styled.div`
    display: none;
    position: absolute;
    top: 50px;
    right: 28px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid ${props => props.theme.color.white};

    ${bp.medium} {
        display: block;
    }

    ${bp.large} {
        top: 120px;
        right: 63px;
    }
`;

const StyledCartMenu = styled(PopupCard)<{ $isActive: boolean }>`
    display: ${props => (props.$isActive ? "block" : "none")};
    position: relative;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding-top: 0;
    background-color: ${props => props.theme.color.white};
    border-radius: 0;
    pointer-events: all;
    overflow: auto;
    z-index: 1000;

    ${bp.medium} {
        position: absolute;
        top: 60px;
        right: 14px;
        width: calc(100% - 32px);
        max-width: 575px;
        min-height: 420px;
        max-height: calc(100vh - 150px);
        padding: 30px 12px 0 24px;
        border-radius: ${props => props.theme.radius.large};
        overflow: hidden;
    }

    ${bp.large} {
        top: 130px;
        right: 50px;
    }
`;

const CartMenuWrapper = styled.div`
    ${bp.medium} {
        max-height: calc(100vh - 267px);
        padding-right: 12px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: ${props => hexToRGBA(props.theme.color.black, 0.33)};
        }

        &::-webkit-scrollbar-thumb:hover {
            background: ${props => hexToRGBA(props.theme.color.black, 0.33)};
        }
    }
`;

const CartMenuHeader = styled(Flex.Container).attrs({
    $alignItems: "center",
})`
    height: 60px;

    ${bp.medium} {
        height: unset;
        padding-bottom: 24px;
    }
`;

const StyledCartMenuItem = styled(CartMenuItem)``;

const CartMenuItems = styled.div`
    ${StyledCartMenuItem}:last-child {
        border-bottom: 1px solid ${props => props.theme.color.line};
    }
`;

const CartMenuHeaderTitle = styled(Typography.H3)`
    margin-right: 24px;
    line-height: 1;
`;

const CartMenuHeaderQuantity = styled(Typography.Medium)`
    line-height: 1;
`;

const CartMenuCost = styled(Flex.Container).attrs({
    $justifyContent: "space-between",
    $alignItems: "baseline",
})`
    padding-top: 24px;

    ${bp.medium} {
        padding-bottom: 24px;
    }
`;

const CartMenuButtons = styled(Flex.Container).attrs({
    $display: "block",
    $bpMedium: {
        $display: "flex",
        $justifyContent: "space-between",
    },
})`
    margin-top: 30px;
    border-top: 1px dashed ${props => props.theme.color.grayL};

    button {
        width: 100%;
        margin-top: 20px;

        ${bp.medium} {
            width: auto;
        }
    }

    ${bp.medium} {
        margin-top: 0;
        position: sticky;
        width: calc(100% - 12px);
        bottom: 0;
        padding-bottom: 24px;
        background-color: ${props => props.theme.color.white};
    }
`;
