import React from "react";
import styled, { withTheme } from "styled-components";
import AriaModal from "react-aria-modal";
import { bp, transition } from "@styles/theme";
import { hexToRGBA } from "@styles/helpers";
import { Flex } from "./Flex";
import { SVGClose } from "@components/SVGCollection";

interface State {
    modalActive: boolean;
}

interface Props {
    titleText: string;
    alert?: boolean;
    activateButton?: JSX.Element;
    mounted?: boolean;
    onModalClose?: () => void;
    children?: React.ReactNode;
    "data-test-id"?: string;
    theme?: any;
    className?: string;
    underlayClickable?: boolean;
}

export class ModalComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalActive: false,
        };

        this.activateModal = this.activateModal.bind(this);
        this.deactivateModal = this.deactivateModal.bind(this);
        this.getApplicationNode = this.getApplicationNode.bind(this);
    }

    activateModal = () => {
        this.setState({ modalActive: true });
    };

    deactivateModal = () => {
        this.setState({ modalActive: false }, () => {
            if (this.props.onModalClose) {
                this.props.onModalClose();
            }
        });
    };

    getApplicationNode = (): HTMLElement => document.getElementById("root")!;

    render(): JSX.Element {
        const { titleText, alert, activateButton, mounted, onModalClose, children, underlayClickable } = this.props;
        const { modalActive } = this.state;

        const ActivateButtonComponent = activateButton && React.cloneElement(activateButton, { onClick: this.activateModal });

        return (
            <>
                {ActivateButtonComponent}

                {(modalActive || mounted) && (
                    <AriaModal
                        alert={alert}
                        escapeExits={underlayClickable}
                        focusDialog={true}
                        getApplicationNode={this.getApplicationNode}
                        mounted={mounted}
                        onExit={this.deactivateModal}
                        titleText={titleText}
                        underlayColor={hexToRGBA(this.props.theme.color.grayD, 0.5)}
                        underlayClickExits={underlayClickable}
                        verticallyCenter={true}
                    >
                        <ModalContainer className={this.props.className} data-test-id={this.props["data-test-id"]}>
                            <RelativeContainer>
                                {underlayClickable && (
                                    <ModalCloseButton onClick={onModalClose}>
                                        <span className="show-for-sr">close modal</span>
                                        <SVGClose />
                                    </ModalCloseButton>
                                )}
                                {children}
                            </RelativeContainer>
                        </ModalContainer>
                    </AriaModal>
                )}
            </>
        );
    }
}

const ModalContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;
    box-shadow: 0px 15px 30px 0px ${hexToRGBA("#000", 0.15)};

    ${bp.medium} {
        position: static;
        width: 635px;
        border-radius: ${props => props.theme.radius.large};
    }

    ${bp.large} {
        width: 790px;
    }

    ${bp.xlarge} {
        width: 960px;
    }
`;

const RelativeContainer = styled.div`
    position: relative;
`;

const ModalCloseButton = styled(Flex.Container).attrs({
    as: "button",
    $justifyContent: "center",
    $alignItems: "center",
})`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${props => props.theme.color.grayXL};
    transition: background ${transition.hoverOut};
    z-index: 1;

    ${bp.medium} {
        top: 20px;
        right: 20px;
    }

    &:hover {
        background: ${props => props.theme.color.grayL};
        transition: background ${transition.hoverIn};
    }
`;

export default withTheme(ModalComponent);
