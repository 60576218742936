import { DefaultTheme } from "styled-components";

export enum BreakPoint {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
    xxxlarge = "xxxlarge",
}

export const breakpoints: { [key in BreakPoint]: number } = {
    small: 0,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1366,
    xxxlarge: 1920,
};

function customMediaQuery(mediaQuery: string): string {
    return `@media (${mediaQuery})`;
}

export const bp: { [key in BreakPoint]: string } & { custom: (mediaQuery: string) => string } = {
    custom: customMediaQuery,
    small: customMediaQuery(`max-width:  ${breakpoints.medium - 1}px`),
    medium: customMediaQuery(`min-width: ${breakpoints.medium}px`),
    large: customMediaQuery(`min-width: ${breakpoints.large}px`),
    xlarge: customMediaQuery(`min-width: ${breakpoints.xlarge}px`),
    xxlarge: customMediaQuery(`min-width: ${breakpoints.xxlarge}px`),
    xxxlarge: customMediaQuery(`min-width: ${breakpoints.xxxlarge}px`),
};

export const hexToRGBA = (h: string, opacity = 1): string => {
    let r = "";
    let g = "";
    let b = "";

    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
};

export enum transition {
    hoverIn = "200ms ease-in",
    hoverOut = "200ms ease-out",
}

const Theme: DefaultTheme = {
    radius: {
        small: "3px",
        medium: "5px",
        large: "15px",
        xlarge: "32px",
    },
    color: {
        red: "#F35247",
        redD: "#f01e0f",
        pink: "#F274AC",
        purple: "#6B2D64",
        lightGreen: "#EDF8F4",
        green: "#47B795",
        yellow: "#F3D51F",
        yellowL: "#F7ED9B",
        blueXL: "#EEF1F3",
        blueL: "#EAF1F4",
        grayD: "#374147",
        gray: "#ACB4BB",
        grayL: "#CDD2D8",
        grayXL: "#EBEBEB",
        beige: "#F8F6F2",
        beigeL: "#F6F6F6",
        beigeXL: "#F5F2EB",
        white: "#ffffff",
        black: "#000000",
        line: "#DADDDF",
        line1: "#D0D4D8",
        errorXL: hexToRGBA("#F35247", 0.05),
        errorL: "#F3A8A3",
        facebook: "#496EF0",
        turkiz: "#BDE4EA",
        imageFrame: "#777777",
    },
    shadow: {
        card: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    },
};

export default Theme;
