import { FormatNumberOptions } from "@formatjs/intl";

export const CACHE_TIMEOUTS = {
    menuItems: 30 * 60 * 1000, // 30 min
    userMeData: 5 * 60 * 1000, // 5 min
    urlAliases: 1 * 60 * 60 * 1000, // 1 hour
    magazinList: 10 * 60 * 1000, // 10 min
    homepageData: 10 * 60 * 1000, // 10 min
    productPageList: 10 * 60 * 1000, // 10 min
    article: 1 * 60 * 1000, // 1 min
    discount: 10 * 60 * 1000, // 10 min
    discounts: 10 * 60 * 1000, // 10 min
};

export const VAT_PERCENTAGE = 0.27;

export const HUN_TAX_NUMBER_REGEX = /^([0-9]{8})(-?)([0-9]{1})(-?)([0-9]{2})$/;
export const EU_TAX_NUMBER_REGEX = /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/;
export const mediumPWRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
export const strongPWRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{12,}$/;
export const passwordMinLength = 8;
export const maskedPhoneLength = 15;
export const isNumRegex = /^\d+$/;
export const floorNumRegex = /^[^MDCLXVI]+$/gm;

export const CURRENCY_FORMAT_OPTIONS: FormatNumberOptions = {
    currency: "HUF",
    useGrouping: true,
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};

export const CART_ITEM_SUBSCRIPTION_MONTH_COUNT = 6;
export const CART_ITEM_SUBSCRIPTION_MONTH_COUNT_FIXED = 3;

export enum Events {
    ShowPageLoading = "showPageLoading",
    HidePageLoading = "hidePageLoading",
}

export const ContactPrefixOptions = [
    { value: null, label: "Nincs" },
    { value: "Ifj.", label: "Ifj." },
    { value: "Id.", label: "Id." },
    { value: "Özv.", label: "Özv." },
    { value: "Dr.", label: "Dr." },
];
