import styled from "styled-components";
import { LinkComponent } from "@components/Link";

interface ProductImageProps {
    image: {
        src: string;
        srcSet: string;
    };
    alternate: string;
    className?: string;
}

export const ProductImage = ({ image, alternate, className }: ProductImageProps) => {
    return (
        <StyledProductImage className={className}>
            <img {...image} alt={alternate} />
        </StyledProductImage>
    );
};

const StyledProductImage = styled.div`
    position: relative;
    width: 100%;
    padding-top: 131.25%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const CardElement = styled.span`
    display: block;
    line-height: 1;
`;

export const OriginalPrice = styled(CardElement)`
    margin-right: 10px;
    font-size: 15px;
    text-decoration: line-through;
`;

export const Price = styled(CardElement)`
    font-size: 25px;
    font-weight: 700;
`;

export const Gift = styled.div`
    display: inline-block;
    max-width: 100%;
    padding: 5px 20px;
    margin: 15px 0 10px 0;
    background-color: ${props => props.theme.color.beigeXL};
    font-size: 15px;
    line-height: 22px;
`;

export const Saving = styled(CardElement)`
    font-size: 12px;
`;

export const ProductLink = styled(LinkComponent)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`;
