import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { print } from "graphql";
import { default as gqlTag } from "graphql-tag";
import { OrderStatus } from "@api/graphql/types";
import { LoctoolMessage } from "@bigfish/react-loctool";
export const StringUtils = {
    shorten(text: string, length: number): string {
        if (text.length > length) {
            const trimmedString = text.substring(0, length);

            return `${trimmedString}...`;
        } else {
            return text;
        }
    },
    capitalFirstLetters(text: string) {
        return startCase(camelCase(text));
    },
    validateTaxNumber(taxNumber: string) {
        const pattern = /^(\d{7})(\d)([1-5])(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
        const matches = taxNumber.match(pattern);
        if (matches) {
            const mul = [9, 7, 3, 1, 9, 7, 3];
            const base = matches[1].split("");
            const check = parseInt(matches[2]);
            let sum = 0;
            for (let i = 0; i < 7; i++) {
                sum += parseInt(base[i]) * mul[i];
            }
            let last = sum % 10;
            if (last > 0) {
                last = 10 - last;
            }
            return last === check;
        }
        return false;
    },
    orderStatus(status: OrderStatus) {
        switch (status) {
            case OrderStatus.ORDER_STATUS_INIT:
                return <LoctoolMessage id="orderStatus.init" />;
            case OrderStatus.ORDER_STATUS_CODE_ACCEPTED:
                return <LoctoolMessage id="orderStatus.codeAccepted" />;
            case OrderStatus.ORDER_STATUS_PAYMENT_PENDING:
                return <LoctoolMessage id="orderStatus.paymentPending" />;
            case OrderStatus.ORDER_STATUS_PAYMENT_REMINDED:
                return <LoctoolMessage id="orderStatus.paymentReminded" />;
            case OrderStatus.ORDER_STATUS_PAYMENT_RECEIVED:
                return <LoctoolMessage id="orderStatus.paymentReceived" />;
            case OrderStatus.ORDER_STATUS_COMPLETED:
                return <LoctoolMessage id="orderStatus.completed" />;
            case OrderStatus.ORDER_STATUS_PAYMENT_REFUSED:
                return <LoctoolMessage id="orderStatus.paymentRefused" />;
            case OrderStatus.ORDER_STATUS_MARKED_TO_CANCEL:
                return <LoctoolMessage id="orderStatus.markedToCancel" />;
            case OrderStatus.ORDER_STATUS_DELETED:
                return <LoctoolMessage id="orderStatus.deleted" />;
            case OrderStatus.ORDER_STATUS_FEE_REQUEST:
                return <LoctoolMessage id="OrderStatus.feeRequest" />;
            case OrderStatus.ORDER_STATUS_REJECTED:
                return <LoctoolMessage id="OrderStatus.rejected" />;
            default:
                return `Status not supported: ${status}.`;
        }
    },
    discountPercent(standardPrice: number, discountPrice: number | null, unitPrice: number): number {
        return !Number.isNaN(standardPrice) && standardPrice > 0 ? Math.round((1 - (discountPrice ?? unitPrice) / standardPrice) * 100) : 0;
    },
    withoutNull(text: string): string {
        return text.replace(/\bnull\b/gi, "").trim();
    },
};

export function gql(...args: any[]) {
    // TODO: make plugin to inline this print
    // TODO: like https://github.com/gajus/babel-plugin-graphql-tag
    return print(gqlTag`${args}`);
}
