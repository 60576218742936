import React, { useState } from "react";

import Link from "next/link";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { webshopMenuItems_webshopMenuItems } from "@api/graphql/types";
import { AppBarButton } from "@components/AppBar/AppBarButton";
import AppBarLink from "@components/AppBar/AppBarLink";
import { AppBarSearch } from "@components/AppBar/AppBarSearch";
import { BodyOverlay } from "@components/BodyOverlay";
import { CartButton } from "@components/Cart/CartButton";
import { CartMenu } from "@components/Cart/CartMenu";
import { Container } from "@components/Container";
import { Flex } from "@components/Flex";
import { Menu } from "@components/Menu";
import { SVGBag, SVGFacebook, SVGMcLogo, SVGSearch, SVGTelephone, SVGUser1 } from "@components/SVGCollection";
import { Typography } from "@components/Typography";
import { ApplicationState } from "@redux/reducers";
import { showForAll, showForSr } from "@styles/helpers";
import { bp, transition } from "@styles/theme";
import { ExternalPath, Path } from "@utils/Path";
import { useLoctool } from "@bigfish/react-loctool";
import { UndeterminedLoadingBar } from "@components/AppBar/UndeterminedLoading";

interface InitialProps {
    menuItems: webshopMenuItems_webshopMenuItems[];
}

export const AppBar: React.FC<InitialProps> = ({ menuItems }) => {
    const Intl = useLoctool();
    const [searchIsActive, setSearchIsActive] = useState(false);
    const [cartIsActive, setCartIsActive] = useState(false);
    const [overlayIndex, setOverlayIndex] = useState(100);
    const { user } = useSelector((state: ApplicationState) => state.auth);
    const { items, grand_total, discount_total } = useSelector((state: ApplicationState) => state.cart.normalCart);

    const toggleSearch = () => {
        setSearchIsActive(searchIsActive => !searchIsActive);
    };

    const openCart = () => {
        const appBar: HTMLElement | null = document.querySelector(".app-bar");

        if (appBar !== null) {
            appBar.style.zIndex = "unset";
        }

        if (searchIsActive) {
            setSearchIsActive(false);
        }

        setCartIsActive(true);
        setOverlayIndex(300);
    };

    const handleClose = () => {
        const appBar: HTMLElement | null = document.querySelector(".app-bar");

        if (appBar !== null) {
            appBar.style.zIndex = "200";
        }

        setOverlayIndex(100);

        if (searchIsActive) {
            setSearchIsActive(false);
        } else if (cartIsActive) {
            setCartIsActive(false);
        }
    };

    return (
        <>
            <StyledAppBar $searchIsActive={searchIsActive} className="app-bar">
                <TopBar className="app-bar_top">
                    <TopBarContainer>
                        <Typography.Small as="span">{Intl.formatMessage({ id: "components.menu.customerService.message" })}</Typography.Small>
                        <Link href={`tel:${Intl.formatMessage({ id: "components.menu.customerService.phoneNumber" })}`}>
                            <PhoneLink as="a" id="header-phone-link">
                                <SVGTelephone aria-hidden={true} />
                                <Typography.Small as="span">{Intl.formatMessage({ id: "components.menu.customerService.phoneNumber" })}</Typography.Small>
                            </PhoneLink>
                        </Link>
                        <TopBarSeparator />
                        <Typography.Small as="span">{Intl.formatMessage({ id: "components.menu.customerService.openingHours" })}</Typography.Small>
                        <TopBarSeparator />
                        <a href={ExternalPath.customerService} target="_blank" id="header-customer-service-link" data-link={ExternalPath.customerService}>
                            <Typography.Small>{Intl.formatMessage({ id: "components.menu.customerService.title" })}</Typography.Small>
                        </a>
                    </TopBarContainer>
                </TopBar>
                <div style={{ width: "100%" }} className="app-bar_nav">
                    <AppBarNavContainer>
                        <StyledMenu menuItems={menuItems} />
                        <Link href="/">
                            <SiteLogo as="a" id="header-logo">
                                <SVGMcLogo />
                                <span>{Intl.formatMessage({ id: "common.brandName" })}</span>
                            </SiteLogo>
                        </Link>
                        <AppBarNav id="header-menu">
                            <FacebookLink>
                                <ExternalLink
                                    as="a"
                                    href={ExternalPath.fb}
                                    data-link={ExternalPath.fb}
                                    target="_blank"
                                    aria-label="Facebook"
                                    id="header-fb-link"
                                >
                                    <SVGFacebook aria-hidden={true} />
                                </ExternalLink>
                            </FacebookLink>
                            {menuItems.map(menuItem => (
                                <AppBarLink href={menuItem.url} key={menuItem.id}>
                                    <AppBarNavItem className="header-menu">{menuItem.name}</AppBarNavItem>
                                </AppBarLink>
                            ))}
                        </AppBarNav>
                        <AppBarButtons>
                            <AppBarButton
                                label={Intl.formatMessage({ id: "components.menu.search" })}
                                icon={<SVGSearch />}
                                onClick={toggleSearch}
                                className="header-search"
                            />
                            <UserProfile>
                                {user ? (
                                    <Link href={Path.profileHome}>
                                        <AppBarButton
                                            label={
                                                user.contact_lastname && user.contact_firstname
                                                    ? `${user?.contact_prefix ?? ""} ${user?.contact_lastname} ${user?.contact_firstname}`
                                                    : `${user.email}`
                                            }
                                            icon={<SVGUser1 />}
                                            className="header-profile"
                                        />
                                    </Link>
                                ) : (
                                    <Link href={Path.login}>
                                        <AppBarButton
                                            label={Intl.formatMessage({ id: "components.menu.login" })}
                                            icon={<SVGUser1 />}
                                            className="header-login"
                                        />
                                    </Link>
                                )}
                            </UserProfile>
                            {items.length > 0 ? (
                                <>
                                    <div className="show-for-large">
                                        <CartButton
                                            label={Intl.formatMessage({ id: "components.menu.cart" })}
                                            items={items.length}
                                            icon={<SVGBag />}
                                            onClick={openCart}
                                            className="header-cart"
                                        />
                                    </div>
                                    <div className="hide-for-large">
                                        <Link href={Path.cart}>
                                            <CartButton
                                                label={Intl.formatMessage({ id: "components.menu.cart" })}
                                                icon={<SVGBag />}
                                                className="header-cart"
                                                items={items.length}
                                            />
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <Link href={Path.cart}>
                                    <CartButton label={Intl.formatMessage({ id: "components.menu.cart" })} icon={<SVGBag />} className="header-cart" />
                                </Link>
                            )}
                        </AppBarButtons>
                    </AppBarNavContainer>
                </div>
                <AppBarSearch isActive={searchIsActive} clickOnClose={handleClose} />
                {items && <CartMenu isActive={cartIsActive} items={items} onClose={handleClose} grandTotal={grand_total} discountTotal={discount_total} />}
                <UndeterminedLoadingBar />
            </StyledAppBar>
            <BodyOverlay $zIndex={overlayIndex} $isActive={searchIsActive || cartIsActive} onClick={handleClose} />
        </>
    );
};

const UserProfile = styled.div`
    display: none;

    ${bp.large} {
        display: block;
    }
`;

const TopBar = styled.div`
    display: none;
    width: 100%;

    ${bp.large} {
        display: block;
        height: 40px;
        color: ${props => props.theme.color.white};
        background-color: ${props => props.theme.color.grayD};
        text-align: right;
    }
`;

const TopBarContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    a {
        cursor: pointer;
    }

    ${Typography.Small} {
        font-weight: 500;
    }
`;

const TopBarSeparator = styled.span`
    width: 1px;
    height: 22px;
    margin: 0 16px;
    background-color: ${props => props.theme.color.white};
`;

const StyledMenu = styled(Menu)``;

const StyledAppBar = styled(Flex.Container).attrs({
    as: "header",
    $alignItems: "center",
    $flexWrap: "wrap",
})<{ $searchIsActive: boolean }>`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: ${props => props.theme.color.white};
    z-index: 200;

    ${props =>
        props.$searchIsActive &&
        css`
            box-shadow: 0px 15px 40px rgb(17 29 44 / 40%);
        `}

    ${bp.large} {
        position: relative;

        ${StyledMenu} {
            display: none;
        }
    }
`;

const AppBarButtons = styled(Flex.Container)`
    margin-left: auto;

    ${bp.large} {
        margin-bottom: -10px;
    }
`;

const AppBarNavContainer = styled(Container)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: ${props => props.theme.color.white};

    ${bp.large} {
        height: 100px;
    }
`;

const AppBarNav = styled(Flex.Container).attrs({
    $display: "none",
    $bpLarge: {
        $display: "flex",
        $alignItems: "center",
    },
})`
    margin-left: auto;
`;

const AppBarNavItem = styled.a<{ $isActive?: boolean }>`
    display: block;
    position: relative;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    white-space: nowrap;
    transition: color ${transition.hoverOut};
    color: ${props => props.$isActive && `${props.theme.color.red}`};

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -10px;
        width: ${props => (props.$isActive ? "100%" : 0)};
        height: 4px;
        background-color: ${props => props.theme.color.red};
        transition: width ${transition.hoverOut};
    }

    &:hover {
        color: ${props => props.theme.color.red};
        transition: color ${transition.hoverIn};

        &:before {
            width: 100%;
            transition: width ${transition.hoverIn};
        }
    }

    ${bp.xxlarge} {
        margin: 0 15px;
    }
`;

const FacebookLink = styled(Flex.Container)`
    width: 28px;
    height: 28px;
    margin: 0 20px;
    border: 1px solid ${props => props.theme.color.grayD};
    border-radius: 50%;

    svg {
        width: 8px;
        height: 16px;
    }
`;

const ExternalLink = styled(Flex.Container).attrs({
    as: "a",
    $justifyContent: "center",
    $alignItems: "center",
})`
    width: 100%;
    height: 100%;
`;

const SiteLogo = styled(Flex.Container).attrs({
    as: "a",
    $alignItems: "center",
})`
    svg {
        min-width: 45px;
        width: 45px;
        height: 45px;
        margin-right: 12px;

        ${bp.large} {
            min-width: 60px;
            width: 60px;
            height: 60px;
        }
    }

    span {
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;

        ${showForSr};

        @media screen and (min-width: 375px) {
            ${showForAll};
        }

        ${bp.large} {
            ${showForSr};
        }

        ${bp.xlarge} {
            ${showForAll};
            font-size: 26px;
            line-height: 32px;
        }
    }
`;

const PhoneLink = styled(Flex.Container).attrs({
    $alignItems: "center",
})`
    margin-left: 16px;

    svg {
        margin-right: 12px;
    }
`;
