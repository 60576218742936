import React, { useEffect, useState } from "react";

import debug from "debug";
import { ClientContext } from "graphql-hooks";
import { AppContext, AppProps } from "next/app";
import Head from "next/head";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import { Queries } from "@api/graphql/queries/Queries";
import {
    cart,
    carts,
    CartType,
    getAllActiveUrlAlias,
    LandingNotification,
    landingNotifications,
    recommendedProducts,
    recommendedProductsVariables,
    session,
    webshopMenuItems,
} from "@api/graphql/types";
import { createSSRClient, ssrRequest, useGraphQLClient } from "@api/GraphQLClient";
import { Layout } from "@components/Layout";
import { NotificationLayer } from "@components/NotificationLayer";
import { CartActions } from "@redux/actions/CartActions";
import { ApplicationState, reduxInitialState } from "@redux/reducers";
import { initializeStore, useStore } from "@redux/store";
import GlobalStyles from "@styles/global";
import Theme from "@styles/theme";
import { CACHE_TIMEOUTS } from "@utils/Constants";
import { Cookie } from "@utils/Cookie";
import { ObjectUtils } from "@utils/ObjectUtils";
import { CTSLoader } from "@components/CTS/CTSLoader";
import { messages } from "@i18n/Intl";
import { Language, LoctoolProvider } from "@bigfish/react-loctool";
import Script from "next/script";
import { CTSData } from "@components/CTS/CTSData";
import { DataLayer } from "@utils/DataLayer";
import { NextPage } from "next";
import cookie from "cookie";

const logAppInfo = debug("app:info");
const logAppDebug = debug("app:debug");

export type NextPageWithLayout<P> = NextPage<P> & {
    getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P> = AppProps & {
    Component: NextPageWithLayout<P>;
};

type InitialProps = {
    initialReduxState: ApplicationState;
    notifications: LandingNotification[];
};

type Props = AppPropsWithLayout<{}> & InitialProps;

const App = (props: Props) => {
    const { Component, initialReduxState, pageProps, notifications, router } = props;
    const graphQLClient = useGraphQLClient();
    const store = useStore(ObjectUtils.deepMerge(initialReduxState, pageProps?.initialReduxState ?? {}));
    const [isCookieConsentAccepted, setIsCookieConsentAccepted] = useState(false);

    if (typeof window !== "undefined" && store.getState().auth.authToken) {
        graphQLClient.setHeader("Authorization", `Bearer ${store.getState().auth.authToken}`);
    }

    function registerCookieBannerEventListener(attempt = 0) {
        if (attempt > 20) {
            return;
        }
        if (!window.__tcfapi) {
            setTimeout(() => registerCookieBannerEventListener(attempt + 1), 500);
            return;
        }
        window.__tcfapi("addEventListener", 2, (tcData: TcData) => {
            setIsCookieConsentAccepted(["tcloaded", "useractioncomplete"].includes(tcData.eventStatus));
        });
    }

    useEffect(() => {
        registerCookieBannerEventListener();
    }, []);

    useEffect(() => {
        if (router.query.channelcode) {
            if (typeof window !== "undefined") {
                window.sessionStorage.setItem("channelcode", router.query.channelcode as string);
            }
        }
    }, [router.query.channelcode]);

    useEffect(() => {
        const handleRouteChange = () => {
            DataLayer.pageLoad();
        };

        router.events.on("routeChangeComplete", handleRouteChange);

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, []);

    const getLayout = Component.getLayout || (page => <Layout>{page}</Layout>);

    return (
        <LoctoolProvider language={Language.HU} messages={messages} isPreviewEnabled={true}>
            <ClientContext.Provider value={graphQLClient}>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
                    <CTSData user={store.getState().auth.user} pathname={router.pathname} />
                </Head>
                <CTSLoader />
                <ThemeProvider theme={Theme}>
                    <GlobalStyles />
                    <Provider store={store}>
                        {getLayout(
                            <>
                                <Component {...pageProps} />
                                {notifications?.length > 0 && isCookieConsentAccepted && <NotificationLayer notifications={notifications} />}
                            </>,
                        )}
                    </Provider>
                </ThemeProvider>
            </ClientContext.Provider>
        </LoctoolProvider>
    );
};

App.getInitialProps = async ({ ctx }: AppContext) => {
    // ONLY RUNS IN SERVER SIDE
    if (typeof window === "undefined") {
        // Handle redirects if needed
        const client = createSSRClient();
        const { data } = await ssrRequest<getAllActiveUrlAlias>(
            {
                query: Queries.getAllActiveUrlAlias,
            },
            { client, cacheTimeout: CACHE_TIMEOUTS.urlAliases },
        );
        const reqPath = ctx.req?.url?.split("?")[0].toLowerCase();
        const query = ctx.req?.url?.split("?")[1];
        const target = (data?.getAllActiveUrlAlias || []).find(x => `/${x.source_url.split("?")[0].toLowerCase()}` === reqPath);
        if (target) {
            const Location =
                target.target_url.includes("?") && query && !query.startsWith("id")
                    ? `${target.target_url}&${query}`
                    : query && !query.startsWith("id")
                    ? `${target.target_url}?${query}`
                    : target.target_url;
            ctx.res?.writeHead(302, { Location });
            ctx.res?.end();
            return {};
        }

        const store = initializeStore(JSON.parse(JSON.stringify(reduxInitialState)));
        const storedAuthToken = Cookie.getAuthToken(ctx.req);
        logAppDebug("Stored auth token: %s", storedAuthToken);

        if (storedAuthToken) {
            client.setHeader("Authorization", `Bearer ${storedAuthToken}`);
        }
        const sessionResult = await ssrRequest<session>(
            { query: Queries.getSession },
            {
                useCache: false,
                client,
            },
        );
        logAppDebug("getSession result: %o", sessionResult);

        // If we got no session token it means something wrong, redirect to 500
        if (!sessionResult.data?.session.token) {
            logAppInfo("Invalid request, got no session token. Error: %o", sessionResult.error);
            ctx.res?.writeHead(500, { "Content-Type": "text/plain" });
            ctx.res?.end("API error: got no session token.");
            return {};
        }

        const newAuthToken = sessionResult.data?.session.token;
        if (newAuthToken !== storedAuthToken) {
            logAppDebug("Stored session invalid, setting new session from result");
            Cookie.setAuthToken(newAuthToken, false, ctx.res);
            client.setHeader("Authorization", `Bearer ${newAuthToken}`);
        }

        store.getState().auth.authToken = newAuthToken;
        if (sessionResult.data.session.user) {
            store.getState().auth.user = sessionResult.data.session.user;
            if (ctx.req) {
                ctx.req.isLoggedIn = true;
            }
        } else {
            if (ctx.req) {
                ctx.req.isLoggedIn = false;
            }
        }

        const cartsResult = await ssrRequest<carts>({ query: Queries.carts }, { client, useCache: false });
        if (cartsResult.data?.carts) {
            store.dispatch(CartActions.updateCarts(cartsResult.data.carts));
        }

        // carts query result doesn't contains cart discounts, so it need to be load separately (aka "gányolás")
        const normalCartResult = await ssrRequest<cart>({ query: Queries.cart, variables: { type: CartType.CART_TYPE_NORMAL } }, { client, useCache: false });
        if (normalCartResult.data?.cart) {
            store.dispatch(CartActions.updateCart(normalCartResult.data.cart));
        }

        const recommendedProductsResult = await ssrRequest<recommendedProducts, recommendedProductsVariables>(
            { query: Queries.recommendedProducts, variables: { first: 4 } },
            { client, useCache: false },
        );
        if (recommendedProductsResult.data?.recommendedProducts) {
            store.dispatch(CartActions.updateRecommendedProducts(recommendedProductsResult.data.recommendedProducts));
        }

        // Do all the common requests here that should happen in server side
        // Handle all the caching here also
        const menuResult = await ssrRequest<webshopMenuItems>({ query: Queries.menuItems }, { client, cacheTimeout: CACHE_TIMEOUTS.menuItems });
        store.getState().appData.menuItems = menuResult.data?.webshopMenuItems ?? [];

        const { data: notificationsData } = await ssrRequest<landingNotifications>({ query: Queries.landingNotifications }, { client, useCache: false });
        const notifications = Cookie.getUnseenNotification(notificationsData?.landingNotifications ?? [], ctx.req, ctx.res);

        return { initialReduxState: store.getState(), notifications };
    }

    return {};
};

export default App;
