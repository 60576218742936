import React, { ReactElement } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

interface Props {
    href: string;
    children: ReactElement;
}

const AppBarLink: React.FC<Props> = ({ href, children }) => {
    const router = useRouter();
    const child = React.Children.only(children);

    return <Link href={href}>{React.cloneElement(child, { $isActive: router.asPath === href })}</Link>;
};

export default AppBarLink;
