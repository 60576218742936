import cookie from "cookie";
import { IncomingMessage, ServerResponse } from "http";

import { LandingNotification } from "@api/graphql/types";

class Cookie {
    private static keys = {
        authToken: "authToken",
        notification: "notification",
        redirectUrl: "redirectUrl",
    };

    private static setPlainCookie(cookie: string, res?: ServerResponse) {
        if (typeof window !== "undefined") {
            window.document.cookie = cookie;
        } else {
            if (!res) {
                throw new Error("SetCookie should be called with `res` object on server side rendering");
            }

            const prevCookieHeader = res.getHeader("Set-Cookie");

            let prevCookies: string[] = [];
            if (Array.isArray(prevCookieHeader)) {
                prevCookies = prevCookieHeader;
            } else if (typeof prevCookieHeader === "string") {
                prevCookies = [prevCookieHeader];
            }

            res.setHeader("Set-Cookie", [...prevCookies, cookie]);
        }
    }

    private static setCookie(cookieName: string, cookieContent: string, res?: ServerResponse, options?: cookie.CookieSerializeOptions) {
        Cookie.setPlainCookie(
            cookie.serialize(cookieName, cookieContent, {
                path: "/",
                ...options,
            }),
            res,
        );
    }

    private static getCookie(id: string, req?: IncomingMessage): string | null {
        let savedCookie = "";
        if (typeof window === "undefined") {
            if (!req) {
                throw new Error("GetCookie should be called with `req` object on server side rendering");
            }
            savedCookie = req.headers.cookie ?? "";
        } else {
            savedCookie = window.document.cookie ?? "";
        }

        const cookies = cookie.parse(savedCookie);
        return cookies[id] ?? null;
    }

    public static getAuthToken(req?: IncomingMessage): string | null {
        return Cookie.getCookie(Cookie.keys.authToken, req);
    }

    /**
     * Set authToken & stayLoggedIn
     * AuthToken expiration based on stayLoggedIn value
     * @param authToken string
     * @param stayLoggedIn boolean
     */
    public static setAuthToken(authToken: string | null, stayLoggedIn: boolean, res?: ServerResponse): void {
        const currentDate = new Date();
        const oneMonth = new Date(currentDate.setDate(currentDate.getDate() + 30));
        const expires: Date | undefined = stayLoggedIn ? oneMonth : undefined;

        Cookie.setCookie(Cookie.keys.authToken, authToken ?? "", res, {
            expires,
            path: "/",
            sameSite: "lax",
        });
    }

    public static getCloseNotificationCookieData = (req?: IncomingMessage) => {
        let blacklistData: string[] = [];
        try {
            blacklistData = JSON.parse(Cookie.getCookie(Cookie.keys.notification, req) ?? "") || [];
            if (!Array.isArray(blacklistData)) {
                return [];
            }
        } catch (e) {
            console.dir(e);
        }
        return blacklistData;
    };

    public static getUnseenNotification = (allNotifications: LandingNotification[], req?: IncomingMessage, res?: ServerResponse) => {
        const currentDate = new Date();
        const oneYear = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));

        // Read cookie, if any is expired or missing from allBanners then clean and update cookie
        let blacklistedIDs: string[] = [];
        try {
            const blacklistData = Cookie.getCloseNotificationCookieData(req).filter(bl => allNotifications.some(b => b.id === bl));
            Cookie.setCookie(Cookie.keys.notification, JSON.stringify(blacklistData), res, {
                expires: oneYear,
            });
            blacklistedIDs = blacklistData;
        } catch (e) {
            console.dir(e);
        }
        // Filter all banners, keep not blacklisted
        return allNotifications.filter(notification => !blacklistedIDs.includes(notification.id) && notification.is_active);
    };

    public static dismissNotificationWithId = (
        notificationId: string,
        allNotifications: LandingNotification[],
        req?: IncomingMessage,
        res?: ServerResponse,
    ) => {
        const currentDate = new Date();
        const oneYear = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
        const found = allNotifications.find(b => b.id === notificationId);
        if (!found) {
            return;
        }
        try {
            const blacklistData = Cookie.getCloseNotificationCookieData(req).filter(bl => allNotifications.some(b => b.id === bl));
            blacklistData.push(notificationId);
            Cookie.setCookie(Cookie.keys.notification, JSON.stringify(blacklistData), res, {
                expires: oneYear,
            });
        } catch (e) {
            console.dir(e);
        }
    };

    public static clearAuthData(res?: ServerResponse): void {
        const expires = new Date(0);
        Cookie.setCookie(Cookie.keys.authToken, "", res, { path: "/", expires });
    }

    public static setRedirectUrl(redirectUrl: string, res?: ServerResponse): void {
        Cookie.setCookie(Cookie.keys.redirectUrl, redirectUrl, res, { path: "/" });
    }

    public static getRedirectUrl(req?: IncomingMessage): string | null {
        return Cookie.getCookie(Cookie.keys.redirectUrl, req);
    }
}

export { Cookie };
