class ObjectUtils {
    public static keys<T>(obj: T): Array<keyof T> {
        return Object.keys(obj).filter((key: string) => key !== "__typename") as Array<keyof T>;
    }

    public static enumAsArray<T>(enumType: object): T[] {
        const attributes: string[] = Object.keys(enumType);
        const keys: string[] = attributes.filter((x: string): boolean => {
            return Number.isNaN(parseInt(x, 10));
        });
        return (keys as unknown) as T[];
    }

    public static isEnumContains<T>(enumType: object, value: any): value is T {
        try {
            return ObjectUtils.enumAsArray(enumType).includes(value);
        } catch (error) {
            return false;
        }
    }

    public static removeObjectKey(object: any, key: string) {
        const obj = JSON.parse(JSON.stringify(object));
        delete obj[key];
        return obj;
    }
    public static deepMerge = (source: any, target: any) => {
        return (
            void Object.keys(target).forEach(key => {
                source[key] instanceof Object && target[key] instanceof Object
                    ? source[key] instanceof Array && target[key] instanceof Array
                        ? void (source[key] = Array.from(new Set(target[key])))
                        : !(source[key] instanceof Array) && !(target[key] instanceof Array)
                        ? void ObjectUtils.deepMerge(source[key], target[key])
                        : void (source[key] = target[key])
                    : void (source[key] = target[key]);
            }) || source
        );
    };
}

export { ObjectUtils };
