import { createStore, Store, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { ApplicationState, rootReducer } from "@redux/reducers";
import { useMemo } from "react";
import { enableBatching } from "redux-batched-actions";

type StoreType = Store<ApplicationState>;
let store: StoreType | undefined;

function initStore(preloadedState: ApplicationState | undefined) {
    preloadedState = JSON.parse(JSON.stringify(preloadedState));

    if (typeof window === "undefined") {
        return createStore(enableBatching(rootReducer), preloadedState);
    }

    return createStore(enableBatching(rootReducer), preloadedState, composeWithDevTools(applyMiddleware()));
}

export const initializeStore = (preloadedState?: ApplicationState) => {
    if (typeof window === "undefined") {
        return initStore(preloadedState);
    }
    let _store = store ?? initStore(preloadedState);

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        });
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === "undefined") return _store;
    // Create the store once in the client
    if (!store) store = _store;

    return _store;
};

export function useStore(initialState: ApplicationState) {
    if (typeof window === "undefined") {
        return initStore(initialState);
    }
    const store = useMemo(() => initializeStore(initialState), [initialState]);
    return store;
}
