import { useMemo } from "react";
import { APIError, GraphQLClient, Operation, Result } from "graphql-hooks";
import memCache from "graphql-hooks-memcache";
import { ssrCache } from "./SSRCache";

let graphQLClient: GraphQLClient;

const ssrMode = typeof window === "undefined";

function createClient(initialState?: object) {
    return new GraphQLClient({
        ssrMode,
        url: ssrMode ? process.env.NEXT_SSR_API_URL ?? process.env.NEXT_PUBLIC_API_URL : process.env.NEXT_PUBLIC_API_URL,
        cache: memCache({ initialState }),
        fetch: async (url, options: { [key: string]: any }) => {
            try {
                const postData = JSON.parse(options?.body || "{}");
                if (options.invalidateCacheKey && postData.query && postData.query.indexOf("mutation") === 0) {
                    return await fetch("/api/gqlci", {
                        method: "POST",
                        cache: "no-cache",
                        credentials: "same-origin",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ invalidateCacheKey: options.invalidateCacheKey, originalBody: options.body, originalHeaders: options.headers }),
                    });
                }
            } catch (e) {
                console.dir(e);
            }
            return fetch(url, options);
        },
    });
}

export const createSSRClient = () =>
    new GraphQLClient({
        ssrMode: true,
        url: process.env.NEXT_SSR_API_URL ?? process.env.NEXT_PUBLIC_API_URL,
        cache: ssrCache,
    });

export function initializeGraphQL(initialState?: object) {
    // For SSG and SSR always create a new GraphQL Client
    if (typeof window === "undefined") return createClient(initialState);

    const _graphQLClient = graphQLClient ?? createClient(initialState);

    // After navigating to a page with an initial GraphQL state, create a new cache with the
    // current state merged with the incoming state and set it to the GraphQL client.
    // This is necessary because the initial state of `memCache` can only be set once
    if (initialState && graphQLClient) {
        graphQLClient.cache = memCache({
            initialState: Object.assign(graphQLClient.cache.getInitialState(), initialState),
        });
    }
    if (typeof window === "undefined") return _graphQLClient;
    // Create the GraphQL Client once in the client
    if (!graphQLClient) graphQLClient = _graphQLClient;

    return _graphQLClient;
}

export type SSRRequestOptions = {
    useCache?: boolean;
    cacheTimeout?: number;
    cacheKey?: string;
    client?: GraphQLClient;
} & { [key: string]: any };

export async function ssrRequest<ResponseData, TVariables = object, TGraphQLError = object>(
    operation: Operation<TVariables>,
    options?: SSRRequestOptions,
): Promise<Result<ResponseData, TGraphQLError>> {
    const defaultOpts = { useCache: true };
    const opts = { ...defaultOpts, ...options };
    const ssrClient = options?.client || createSSRClient();
    let cacheKey: object = { operation, fetchOptions: ssrClient.getFetchOptions(operation, opts), cacheTimeout: options?.cacheTimeout };
    if (opts.cacheKey) {
        cacheKey = { cacheKey: opts.cacheKey };
    }
    const cacheHit = ssrClient.cache.get(cacheKey);
    if (cacheHit && opts.useCache) {
        return Promise.resolve(cacheHit);
    }
    // Its needed, because fetchOptions altered in the request
    const originalCacheKey = JSON.parse(JSON.stringify(cacheKey));
    const result = await ssrClient.request<ResponseData, TGraphQLError>((operation as unknown) as Operation<object>, opts);
    if (!result.error) {
        ssrClient.cache.set(originalCacheKey, result, options?.cacheTimeout);
    }
    return Promise.resolve(result);
}

export function useGraphQLClient(initialState?: object) {
    if (typeof window === "undefined") {
        return initializeGraphQL({});
    }
    const store = useMemo(() => initializeGraphQL(initialState), [initialState]);
    return store;
}

export const getGraphQLErrorMessage = (error?: APIError<{ extensions: { category: string }; message: string }>) => {
    if (!error) {
        return "unknown";
    }
    if (error.fetchError) {
        return error.fetchError.message;
    }
    if (error.httpError) {
        return error.httpError.statusText;
    }
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        return error.graphQLErrors[0].message;
    }
    return "unknown";
};
