import { webshopMenuItems_webshopMenuItems } from "@api/graphql/types";

export type AppDataReducerType = {
    menuItems: webshopMenuItems_webshopMenuItems[];
};

export const initialState: AppDataReducerType = {
    menuItems: [],
};

export default function AppDataReducer(state: AppDataReducerType = initialState): AppDataReducerType {
    return state;
}
