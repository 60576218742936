import styled, { css } from "styled-components";
import { hexToRGBA, transition } from "@styles/theme";
import { Typography } from "@components/Typography";
import React, { InputHTMLAttributes } from "react";
import InputMask from "react-input-mask";

type InputProps = {
    type: string;
    name?: string;
    label: string;
    hideLabel?: boolean;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    error?: boolean;
    errorMessage?: string | React.ReactNode;
    helperText?: string;
    readOnly?: boolean;
    ref?: any;
    gridArea?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "placeholder">;

export const InputComponent = ({
    type,
    name,
    label,
    hideLabel,
    value,
    disabled,
    placeholder,
    className,
    error,
    errorMessage,
    helperText,
    readOnly,
    ref,
    gridArea,
    ...props
}: InputProps) => {
    return (
        <Label $disabled={disabled} className={className} $error={error} $gridArea={gridArea}>
            {hideLabel ? <span className="show-for-sr">{label}</span> : <InputLabel>{label}</InputLabel>}
            <input
                ref={ref}
                type={type}
                name={name}
                value={value}
                placeholder={hideLabel ? label : placeholder}
                disabled={disabled}
                readOnly={readOnly}
                {...props}
            />
            {error ? <Typography.Tiny>{errorMessage}</Typography.Tiny> : helperText ? <Typography.Tiny>{helperText}</Typography.Tiny> : null}
        </Label>
    );
};

type MaskedInputProps = {
    type: string;
    mask: string;
    inputMode: "search" | "tel" | "none" | "text" | "url" | "email" | "numeric" | "decimal";
    name?: string;
    label: string;
    hideLabel?: boolean;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    error?: boolean;
    errorMessage?: string;
    helperText?: string;
    readOnly?: boolean;
    ref?: any;
    gridArea?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "placeholder">;

export const MaskedInput = ({
    type,
    name,
    label,
    hideLabel,
    value,
    disabled,
    placeholder,
    className,
    error,
    errorMessage,
    helperText,
    readOnly,
    ref,
    mask,
    inputMode,
    gridArea,
    ...props
}: MaskedInputProps) => {
    return (
        <Label $disabled={disabled} className={className} $error={error} $gridArea={gridArea}>
            {hideLabel ? <span className="show-for-sr">{label}</span> : <InputLabel>{label}</InputLabel>}
            <InputMask
                ref={ref}
                mask={mask}
                inputMode={inputMode}
                // @ts-ignore:next-line https://github.com/sanniassin/react-input-mask/issues/249
                maskChar={null}
                type={type}
                name={name}
                value={value}
                placeholder={hideLabel ? label : placeholder}
                disabled={disabled}
                readOnly={readOnly}
                {...props}
                alwaysShowMask
            />
            {error ? <Typography.Tiny>{errorMessage}</Typography.Tiny> : helperText ? <Typography.Tiny>{helperText}</Typography.Tiny> : null}
        </Label>
    );
};

export const InputLabel = styled.span`
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
`;

export const Label = styled.label<{ $disabled?: boolean; $error?: boolean; $gridArea?: string }>`
    ${props => props.$gridArea && `grid-area: ${props.$gridArea};`}
    display: block;
    width: 100%;

    input {
        display: block;
        width: 100%;
        height: 40px;
        padding: 0 20px;
        color: ${props => props.theme.color.grayD};
        border: 1px solid ${props => props.theme.color.grayL};
        border-radius: ${props => props.theme.radius.medium};
        font-size: 16px;
        line-height: 22px;
        transition: color ${transition.hoverOut}, background-color ${transition.hoverOut}, border-color ${transition.hoverOut};

        &:focus {
            border-color: ${props => props.theme.color.grayD};
            transition: border-color ${transition.hoverIn};
        }

        &:disabled {
            color: ${props => hexToRGBA(props.theme.color.grayD, 0.5)};
            background-color: ${props => props.theme.color.beigeL};
            border-color: ${props => props.theme.color.grayL};
            cursor: not-allowed;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    ${props =>
        props.$disabled &&
        `
            color: ${hexToRGBA(props.theme.color.grayD, 0.5)};
        `}

    ${props =>
        props.$error &&
        css`
            color: ${props => props.theme.color.red};

            input {
                color: ${props => props.theme.color.red};
                background-color: ${props => props.theme.color.errorXL};
                border-color: ${props => props.theme.color.errorL};
                transition: color ${transition.hoverIn}, background-color ${transition.hoverIn}, border-color ${transition.hoverIn};

                &:focus {
                    border-color: ${props => props.theme.color.errorL};
                }
            }
        `}
`;

const SecondaryInput = styled(InputComponent)`
    input {
        background-color: ${props => props.theme.color.beigeL};
    }
`;

const StyledMaskedInput = styled(MaskedInput)`
    input {
        background-color: ${props => props.theme.color.beigeL};
    }
`;

export const Input = {
    Primary: InputComponent,
    Secondary: SecondaryInput,
    Masked: StyledMaskedInput,
};
