import { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Flex } from "@components/Flex";
import { InputLabel } from "@components/Input";
import { SVGDone } from "@components/SVGCollection";
import { Typography } from "@components/Typography";

type CheckboxProps = {
    label: string | React.ReactNode;
    className?: string;
    error?: boolean;
    errorMessage?: string;
    gridArea?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = (props: CheckboxProps) => {
    return (
        <>
            <StyledCheckbox className={props.className} $gridArea={props.gridArea}>
                <input type="checkbox" name={props.name} defaultChecked={props.checked} onChange={props.onChange} disabled={props.disabled} />
                <Checkmark>
                    <SVGDone />
                </Checkmark>
                <InputLabel>{props.label}</InputLabel>
            </StyledCheckbox>
            {props.error && <ErrorText>{props.errorMessage}</ErrorText>}
        </>
    );
};

const StyledSvgDone = styled(SVGDone)`
    display: none;
`;

const Checkmark = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})`
    min-height: 25px;
    height: 25px;
    min-width: 25px;
    width: 25px;
    margin-right: 12px;
    background-color: ${props => props.theme.color.white};
    border: 1px solid ${props => props.theme.color.grayL};
    border-radius: ${props => props.theme.radius.small};
    cursor: pointer;
`;

const StyledCheckbox = styled(Flex.Container).attrs({
    as: "label",
    $display: "inline-flex",
    $alignItems: "flex-start",
})<{ $gridArea?: string }>`
    ${props => props.$gridArea && `grid-area: ${props.$gridArea};`}
    position: relative;
    user-select: none;

    input {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;

        &:checked ~ ${Checkmark} {
            background-color: ${props => props.theme.color.grayD};
            border-color: ${props => props.theme.color.grayD};

            ${StyledSvgDone} {
                display: block;
            }
        }

        &:disabled ~ ${Checkmark} {
            background-color: ${props => props.theme.color.gray};
            cursor: not-allowed;

            ~ ${InputLabel} {
                opacity: 0.5;
            }
        }
    }

    ${InputLabel} {
        padding-top: 2px;
    }
`;

export const TermsWrapper = styled.div`
    margin-bottom: 30px;

    ${InputLabel} {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        padding-top: 0;

        a {
            font-weight: 700;
        }
    }
`;

export const ErrorText = styled(Typography.Tiny)`
    color: ${props => props.theme.color.red};
`;
